import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Input, Select, List, message } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const { Option } = Select;

const SoapTemplateManagement = ({ showToast, from="", styles=""}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [newCustomName, setNewCustomName] = useState("");
  const [customTemplateNames, setCustomTemplateNames] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [form] = Form.useForm();
  const [editMode, setEditMode] = useState(false);
  const [currentEditingTemplate, setCurrentEditingTemplate] = useState(null);
  const [shortcut, setShortcut] = useState("");
  const [editShortcutMode, setEditShortcutMode] = useState(false);
  const isAddButtonDisabled = !selectedItem;
  const [items, setItems] = useState({
    procedures: [],
    dispensedItems: [],
    labs: [],
    vitals: [],
  });

  useEffect(() => {
    fetchItemsNames();
  }, []);

  useEffect(() => {
    setSelectedItem(null);

    if (selectedType === "custom") {
      fetchCustomTemplateNames();
    } else {
      fetchItemsNames();
    }
  }, [selectedType]);

  useEffect(() => {
    fetchTemplates();
  }, [selectedType, selectedItem]);

  const fetchTemplates = async () => {
    if (selectedType && selectedItem) {
      const url =
        selectedType === "custom"
          ? `/api/soap-note-templates/custom/${encodeURIComponent(
              selectedItem
            )}`
          : `/api/soap-note-templates/${selectedType}/${encodeURIComponent(
              selectedItem
            )}`;
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        });
        setTemplates(response.data.templates);
        setShortcut(response.data.shortcut || "");
        setEditShortcutMode(false);
      } catch (error) {
        message.error(
          `Error fetching templates for the selected ${
            selectedType === "custom" ? "custom template" : "item"
          }`
        );
      }
    }
  };

  const fetchCustomTemplateNames = async () => {
    try {
      const response = await axios.get(
        "/api/soap-note-templates/custom",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      setCustomTemplateNames(response.data.templates.map((t) => t.name));
    } catch (error) {
      message.error("Error fetching custom template names");
    }
  };

  const fetchItemsNames = async () => {
    try {
      const response = await axios.get("/items/names", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      });
      setItems(response.data);
    } catch (error) {
      message.error("Error fetching item names");
    }
  };

  const renderCustomTemplateNamesSelect = () => {
    const options = customTemplateNames.map((name) => (
      <Option key={name} value={name}>
        {name}
      </Option>
    ));
    return (
      <Select
        value={selectedItem}
        className="mx-5 md:w-auto"
        onChange={(value) => setSelectedItem(value)}
        placeholder="Select a custom template name"
      >
        {options}
      </Select>
    );
  };

  const handleCreateCustomSubcategory = async () => {
    if (!newCustomName.trim()) {
      showToast("Please enter a name for the custom template", "error");
      return;
    }

    try {
      await axios.post(
        "/api/soap-note-templates/custom",
        { name: newCustomName },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      showToast("Custom subcategory created successfully", "success");
      setNewCustomName("");
      fetchCustomTemplateNames();
      fetchTemplates();
    } catch (error) {
      showToast("Error creating custom subcategory", "error");
    }
  };

  const handleAddOrUpdateTemplate = async (values) => {
    if (
      !values.content ||
      !selectedType ||
      !["procedure", "dispense", "lab", "vital", "custom"].includes(
        selectedType
      )
    ) {
      showToast("Invalid template data", "error");
      return;
    }

    const payload = {
      type: selectedType,
      name: selectedItem,
      content: values.content, // Directly send content
    };

    try {
      if (editMode && currentEditingTemplate) {
        // Update existing template
        const url = `/api/soap-note-templates/${currentEditingTemplate._id}`;
        await axios.put(url, payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        });
        showToast("Template updated successfully", "success");
      } else {
        // Create new template
        const url = "/api/soap-note-templates";
        await axios.post(url, payload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        });
        showToast("Template added successfully", "success");
      }
      fetchTemplates();
      // setIsModalVisible(false);
      form.resetFields();
      setEditMode(false);
    } catch (error) {
      showToast("Error processing template", "error");
    }
  };

  const handleShortcutChange = (e) => {
    setShortcut(e.target.value);
  };

  const saveShortcut = async () => {
    try {
      const encodedName = encodeURIComponent(selectedItem);
      const url = `/api/soap-note-templates/shortcut/${selectedType}/${encodedName}`;
      await axios.put(
        url,
        { shortcut },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      showToast("Shortcut updated successfully", "success");
      fetchTemplates();
    } catch (error) {
      showToast("Error updating shortcut", "error");
    }
  };

  // Render shortcut input or display
  const renderShortcutSection = () => {
    return (
      <div className="shortcut-section">
        {editShortcutMode || shortcut === "" ? (
          <>
            <Input
              placeholder="Enter shortcut"
              value={shortcut}
              onChange={handleShortcutChange}
            />
            <Button onClick={saveShortcut}>Save</Button>
          </>
        ) : (
          <>
            <span>{shortcut}</span>
            <Button
              className="shortcut-edit-icon"
              icon={<EditOutlined />}
              onClick={() => setEditShortcutMode(true)}
            />
          </>
        )}
      </div>
    );
  };

  const handleEditTemplate = (template) => {
    setCurrentEditingTemplate(template);
    setEditMode(true);
    form.setFieldsValue({ content: template.content });

    // Remove setting shortcut and edit mode here to avoid interference
  };

  const handleDeleteTemplate = async (templateId) => {
    const encodedName = encodeURIComponent(selectedItem);
    await axios.delete(
      `/api/soap-note-templates/${selectedType}/${encodedName}/${templateId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }
    );
    fetchTemplates();
    showToast("Template deleted successfully", "success");
  };

  const handleSelectTemplate = async (templateId) => {
    await axios.put(
      `/api/soap-note-templates/select/${selectedType}/${templateId}`
    );
    fetchTemplates();
    showToast("Template selected successfully", "success");
  };

  const resetAndCloseModal = () => {
    setEditMode(false);
    setCurrentEditingTemplate(null);
    setSelectedType(null);
    setSelectedItem(null);
    setTemplates([]);
    form.resetFields();
    setIsModalVisible(false);
    if(from==='emr'){
      setSelectedType(null)
    }
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setCurrentEditingTemplate(null);
    form.resetFields();
  };

  const renderPlaceholderHelp = () => {
    const helpText = {
      procedure:
        "<strong>Procedure Name:</strong> {{procedure_name}} <br/>" +
        "<strong>Selected Dosage:</strong> {{dosage}}<br/>" +
        "<strong>Notes:</strong> {{notes}}" +
        "(for IV Vitamin C and Glutamine)<br/><strong>Custom Dosage:</strong> {{cusDosage}}<br/>",
      dispense:
        "<strong>Dispensed Item Name:</strong> {{item_name}}<br/>" +
        "<strong>Quantity:</strong> {{quantity}}<br/>" +
        "<strong>Dosage:</strong> {{dosage}}<br/>" +
        "<strong>Notes:</strong> {{notes}}",
      lab:
        "<strong>Lab Test Name:</strong> {{test_name}}<br/>" +
        "<strong>Item Number:</strong> {{item_number}}<br/>" +
        "<strong>Result:</strong> {{result}}",
      vital:
        "<strong>Time Recorded:</strong> {{time}}<br/>" +
        "<strong>Blood Pressure:</strong> {{BP}}<br/>" +
        "<strong>Heart Rate:</strong> {{HR}}<br/>" +
        "<strong>Respiratory Rate:</strong> {{RR}}<br/>" +
        "<strong>SPO2 Level:</strong> {{SPO2}}<br/>" +
        "<strong>Temperature:</strong> {{T}}<br/>" +
        "<strong>Weight:</strong> {{W}}",
      custom:
        "<strong>Custom Template:</strong> This is your custom template, you can create any content here for your Treatment Note usage<br/>",
    };

    return selectedType ? (
      <div>
        <h4>
          <strong>Key (for inserting values in treatment note):</strong>
        </h4>
        <p dangerouslySetInnerHTML={{ __html: helpText[selectedType] }}></p>
      </div>
    ) : null;
  };

  const renderItemsSelect = () => {
    let itemOptions;
    switch (selectedType) {
      case "procedure":
        itemOptions = items.procedures.map((item) => (
          <Option key={item} value={item}>
            {item}
          </Option>
        ));
        break;
      case "dispense":
        itemOptions = items.dispensedItems.map((item) => (
          <Option key={item} value={item}>
            {item}
          </Option>
        ));
        break;
      case "lab":
        itemOptions = items.labs.map((item) => (
          <Option key={item} value={item}>
            {item}
          </Option>
        ));
        break;
      case "vital":
        itemOptions = items.vitals.map((item) => (
          <Option key={item} value={item}>
            {item}
          </Option>
        ));
        break;
      default:
        itemOptions = [];
    }

    return (
      <Select
        value={selectedItem}
        className="mx-5 md:w-auto"
        onChange={(value) => setSelectedItem(value)}
        placeholder="Select an item"
      >
        {itemOptions}
      </Select>
    );
  };

  const filteredTemplates = templates.filter(
    (t) => t.type === selectedType && t.name === selectedItem
  );

  const modules = {
    toolbar: [
      ["bold", "italic", "underline"], // toggled buttons
      [{ list: "ordered" }, { list: "bullet" }],
      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ color: [] }, { background: [] }], // dropdown with defaults
      [{ align: [] }],
      ["clean"], // remove formatting button
    ],
  };

  return (
    <>
      {from === 'emr' ? <>
        <Button
        className={`${styles.button}`}
        onClick={() => {
          setIsModalVisible(true)
        }}
      >
        Manage SOAP Templates
      </Button>
      <Button
        className={`${styles.button}`}
        onClick={() => {
          setIsModalVisible(true)
          setSelectedType('lab')
        }}
      >
        Manage Labs SOAP Templates
      </Button>
      <Button
        className={`${styles.button}`}
        onClick={() => {
          setIsModalVisible(true)
          setSelectedType('procedure')
        }}
      >
        Manage Procedures SOAP Templates
      </Button>
      <Button
        className={`${styles.button}`}
        onClick={() => {
          setIsModalVisible(true)
          setSelectedType('dispense')
        }}
      >
        Manage Dispensed SOAP Templates
      </Button>
      </> : <Button
        className={"btn-color-BorderOnly py-2 px-5 w-auto h-fit"}
        onClick={() => setIsModalVisible(true)}
      >
        Manage SOAP Templates
      </Button>}
      <Modal
        title="Manage SOAP Templates"
        visible={isModalVisible}
        onCancel={resetAndCloseModal}
        footer={null}
      >
        <Select
          value={selectedType}
          onChange={(value) => setSelectedType(value)}
          placeholder="Select a type"
        >
          <Option value="procedure">Procedure</Option>
          <Option value="dispense">Dispense</Option>
          <Option value="lab">Lab</Option>
        </Select>
        {selectedType && selectedType !== "custom" && renderItemsSelect()}
        {selectedType === "custom" && renderCustomTemplateNamesSelect()}
        {selectedType === "custom" && (
          <div className="custom-subcategory-section">
            <Input
              placeholder="New Custom Subcategory Name"
              value={newCustomName}
              onChange={(e) => setNewCustomName(e.target.value)}
            />
            <Button onClick={handleCreateCustomSubcategory}>
              Create New Custom Subcategory
            </Button>
          </div>
        )}
        <List
          dataSource={templates}
          renderItem={(template) => (
            <List.Item
              style={{
                backgroundColor: template.selected ? "#fffacd" : "transparent",
                padding: "10px",
              }}
              actions={[
                <Button
                  icon={<CheckOutlined />}
                  onClick={() => handleSelectTemplate(template._id)}
                />,
                <Button
                  icon={<EditOutlined />}
                  onClick={() => handleEditTemplate(template)}
                />,
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => handleDeleteTemplate(template._id)}
                />,
              ]}
            >
              <div dangerouslySetInnerHTML={{ __html: template.content }}></div>
            </List.Item>
          )}
        />

        {selectedType && selectedItem && renderShortcutSection()}

        {renderPlaceholderHelp()}
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddOrUpdateTemplate}
          className="section-gap"
        >
          <Form.Item
            name="content"
            label="Template Content"
            rules={[{ required: true }]}
          >
            <ReactQuill theme="snow" modules={modules} />
          </Form.Item>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              style={{
                color: "white",
                backgroundColor: "#1976d2",
                borderRadius: "5px",
                fontWeight: "bold",
              }}
              className=" py-1 px-10"
              type="primary"
              htmlType="submit"
              disabled={isAddButtonDisabled}
            >
              {editMode ? "Update Template" : "Add Template"}
            </Button>
            {editMode && (
              <Button
                className="btn-color py-1 px-10 text-xs md:text-sm"
                icon={<CloseCircleOutlined />}
                onClick={handleCancelEdit}
              >
                Cancel Edit
              </Button>
            )}
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default SoapTemplateManagement;
