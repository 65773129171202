import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { Checkbox, Col, DatePicker, Divider, Form, Input, Row, Select } from 'antd'
import { Option } from 'antd/lib/mentions'
import Title from 'antd/lib/typography/Title'
import React from 'react'

const PersonalInformationForm = ({form,handleNext}) => {


    const goalsOptions = [
        "Improve cognitive function",
        "Increase energy",
        "Minimize symptoms of fatigue",
        "Improve depression",
        "Minimize symptoms of menopause",
        "Reduce cancer risk/care for cancer",
        "Reduce risk of osteoporosis",
        "Improve overall health",
        "Reduce cardiovascular disease",
        "Improve sleep",
        "Improve memory",
        "Stabilize mood",
        "Reduce headaches/migraines",
        "Reduce joint pain",
        "Reduce food allergies",
        "Improve digestive issues",
        "Other",
      ];

  return (
    <>
            <div className="bg-white p-6 rounded-2xl">
              <Form
              layout='vertical'
              form={form}
              >
             <Title level={4}>Personal Information</Title>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="firstName" label="First Name">
                <Input placeholder="Enter first name" />
              </Form.Item>
            </Col>
            {/* <Col span={8}>
              <Form.Item name="middleName" label="Middle Name">
                <Input placeholder="Enter middle name" />
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item name="lastName" label="Last Name">
                <Input placeholder="Enter last name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name="dateOfBirth" label="Date of Birth">
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="gender" label="Gender" >
                <Select placeholder="Select gender">
                  <Option value="male">Male</Option>
                  <Option value="female">Female</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="race" label="Race">
                <Select placeholder="Select race">
                  <Option value="asian">Asian</Option>
                  <Option value="black">Black</Option>
                  <Option value="hispanic">Hispanic</Option>
                  <Option value="white">White</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Title level={4}>Contact Information</Title>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="phone" label="Phone Number" >
                <Input placeholder="Enter phone number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="email" label="Email" rules={[{ type: 'email'}]}>
                <Input placeholder="Enter email address" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="address1" label="Address Line 1" >
            <Input placeholder="Enter street address" />
          </Form.Item>
          <Form.Item name="address2" label="Address Line 2">
            <Input placeholder="Apt, Suite, etc. (optional)" />
          </Form.Item>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item name="city" label="City">
                <Input placeholder="Enter city" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="province" label="Province">
                <Input placeholder="Enter province" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="zipCode" label="Zip Code">
                <Input placeholder="Enter zip code" />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Title level={4}>Emergency Contact</Title>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="emergencyFirstName" label="First Name">
                <Input placeholder="Emergency contact first name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="emergencyLastName" label="Last Name">
                <Input placeholder="Emergency contact last name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="emergencyRelationship" label="Relationship">
                <Input placeholder="Relationship to patient" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="emergencyPhone" label="Phone Number">
                <Input placeholder="Emergency contact phone" />
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Title level={4}>Health Information</Title>
          <Form.Item name="referral" label="How did you hear about us?">
            <Input placeholder="Enter referral source" />
        </Form.Item>
        <Form.Item name="healthGoals" label="Health Goals">
          <Checkbox.Group>
            <Row gutter={[16, 16]}>
              {goalsOptions.map((goal) => (
                <Col span={12} key={goal}>
                  <Checkbox value={goal}>{goal}</Checkbox>
                </Col>
              ))}
            </Row>

            </Checkbox.Group>
          </Form.Item>
              <div className="flex justify-between">
                <IconButton>
                  <NavigateBefore />
                </IconButton>
                <IconButton onClick={handleNext}>
                  <NavigateNext />
                </IconButton>
              </div>
              </Form>
            </div>          
    </>
  )
}

export default PersonalInformationForm