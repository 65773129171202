import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import PatientFormComparisonModal from '../PatientFormComparisonModal';
import { Warning } from '@mui/icons-material';
import { colors } from '@mui/material';

const PatientFormSubmissions = () => {
    const [isApprovalsModalVisible, setIsApprovalsModalVisible] = useState(false);
    const [isComparisonModalVisible, setIsComparisonModalVisible] = useState(false);
    const [selectedSubmission, setSelectedSubmission] = useState(null);

    const submissions = [
        {
            id: 1,
            formId: 'FORM123',
            formUrl: 'https://example.com/forms/FORM123',
            current: {
                name: 'John Doe',
                age: 30,
                address: '123 Main St',
                email: 'john@example.com',
            },
            incoming: {
                name: 'John Doe',
                age: 31,
                address: '456 Elm St',
                email: 'john.doe@example.com',
            },
        },
        {
            id: 2,
            formId: 'FORM124',
            formUrl: 'https://example.com/forms/FORM124',
            current: {
                name: 'Jane Smith',
                age: 28,
                address: '789 Pine St',
                email: 'jane.smith@example.com',
            },
            incoming: {
                name: 'Jane Smith',
                age: 29,
                address: '123 Maple Ave',
                email: 'jane.s@example.com',
            },
        },
        {
            id: 3,
            formId: 'FORM125',
            formUrl: 'https://example.com/forms/FORM125',
            current: {
                name: 'Alice Johnson',
                age: 35,
                address: '101 Oak St',
                email: 'alice.johnson@example.com',
            },
            incoming: {
                name: 'Alice Johnson',
                age: 36,
                address: '202 Birch Ln',
                email: 'alice.j@example.com',
            },
        },
    ];

    // const submissions=[]

    const showApprovalsModal = () => {
        setIsApprovalsModalVisible(true);
    };

    const closeApprovalsModal = () => {
        setIsApprovalsModalVisible(false);
    };

    const showComparisonModal = (submission) => {
        setSelectedSubmission(submission);
        setIsComparisonModalVisible(true);
    };

    const closeComparisonModal = () => {
        setIsComparisonModalVisible(false);
        setSelectedSubmission(null);
    };

    return (
        <div>
            <div className="relative inline-block">
                <Button
                    className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
                    type="primary"
                    onClick={showApprovalsModal}
                >
                    Approvals
                </Button>
                <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-red-600 text-white text-lg font-medium rounded-full h-7 w-7 flex items-center justify-center">
                    {submissions.length}
                </span>
            </div>

            <Modal
                title="Pending Approvals"
                visible={isApprovalsModalVisible}
                onCancel={closeApprovalsModal}
                footer={[
                    <Button key="close" onClick={closeApprovalsModal}>
                        Close
                    </Button>,
                ]}
                width={800}
            >
                <div>
                    {submissions.length > 0 ? submissions.map((submission) => (
                        <div
                            key={submission.id}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '10px',
                                borderBottom: '1px solid #ddd',
                            }}
                        >
                            <div>
                                <p>
                                    <strong>Form ID:</strong> {submission.formId}
                                </p>
                                <p>
                                    <strong>Form URL:</strong>{' '}
                                    <a href={submission.formUrl} target="_blank" rel="noopener noreferrer">
                                        {submission.formUrl}
                                    </a>
                                </p>

                                <p>
                                    <strong>Patient Name:</strong> {submission.current.name}
                                </p>
                            </div>
                            <Button onClick={() => showComparisonModal(submission)}>
                                View Submission
                            </Button>
                        </div>
                    )) : <div className='h-96 w-full flex items-center justify-center'>
                        <div className='flex items-center justify-center'>
                            <Warning sx={{ color: colors.yellow['700'], fontSize: "36px" }} />
                            <p className='text-lg font-medium'> There a no pending approvals to show </p>
                        </div>
                    </div>}
                </div>
            </Modal>

            {selectedSubmission && (
                <PatientFormComparisonModal
                    current={selectedSubmission.current}
                    incoming={selectedSubmission.incoming}
                    visible={isComparisonModalVisible}
                    onClose={closeComparisonModal}
                />
            )}
        </div>
    );
};

export default PatientFormSubmissions;
