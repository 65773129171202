import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, colors, Paper } from "@mui/material";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Steps,
  Tooltip,
  Typography
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { BarLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import DocumentsSection from "../../Components/DocumentsSection";
import styles from "./AddPatientFormNew.module.css";
import FormLinkModal from "./Components/FormLinkModal";
import HealthQuestionsForm from "./Components/HealthQuestionsForm";
import PersonalInformationForm from "./Components/PersonalInformationForm";
import SymptomsChecklistForm from "./Components/SymptomsChecklistForm";
import WaiversAndSignatureForm from "./Components/WaiversAndSignatureForm";
import { CopyOutlined } from '@ant-design/icons';
import { getPatientFormStatus } from '../../utils';
import PatientFormSubmissions from '../../Components/PatientFormSubmissions';


const AddPatientFormNew = () => {
  const { Step } = Steps;
  const [currentStep, setCurrentStep] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [publicForm, setPublicForm] = useState(false);

  const [activePatient, setActivePatient] = useState([]);
  const [patientName, setPatientName] = useState("");

  const [patientLoader, setPatientLoader] = useState(false);
  const [form] = Form.useForm();
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [authStatus, setAuthStatus] = useState(null);
  const [formLinkDetails, setFormLinkDetails] = useState({});
  const [formGenerateButtonVisible, setFormGenerateButtonVisible] = useState(false);
  const [validUrl, setValidUrl] = useState(false);
  const [authenticatedUser, setAuthenticatedUser] = useState(false);
  const [link, setLink] = useState("");
  const { name, formId } = useParams();

  const { Option } = Select;
  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const fetchFormLinkDetailsWithPatientId = async (patientId) => {
    if (!patientId) {
      console.error('Patient ID is required to fetch form link details.');
      return { error: 'Patient ID is required.' };
    }

    try {
      const response = await axios.get('/get-form-details-with-patient-id', {
        params: { patientId },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      });

      if (response.status === 200) {
        console.log('Form link details fetched successfully:', response.data.result);
        setFormGenerateButtonVisible(false)
        setFormLinkDetails(response?.data?.result)
      }
    } catch (error) {
      setFormGenerateButtonVisible(true)
      if (error.response) {
        console.error('Error fetching form link:', error.response.data.message);
        return { error: error.response.data.message };
      } else if (error.request) {
        console.error('No response from server:', error.request);
        return { error: 'No response from server.' };
      } else {
        console.error('Unexpected error:', error.message);
        return { error: error.message };
      }
    }
  };

  const fetchFormLinkDetailsWithFormId = async (formId) => {
    if (!formId) {
      console.error('Patient ID is required to fetch form link details.');
      return { error: 'Patient ID is required.' };
    }

    try {
      const response = await axios.get('/get-form-details-with-form-id', {
        params: { formId },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      });

      if (response.status === 200) {
        console.log('Form link details fetched successfully:', response.data.result);
        setFormGenerateButtonVisible(false)
        setFormLinkDetails(response?.data?.result)
      }
    } catch (error) {
      setFormGenerateButtonVisible(true)
      if (error.response) {
        console.error('Error fetching form link:', error.response.data.message);
        return { error: error.response.data.message };
      } else if (error.request) {
        console.error('No response from server:', error.request);
        return { error: 'No response from server.' };
      } else {
        console.error('Unexpected error:', error.message);
        return { error: error.message };
      }
    }
  };

  const validateForm = async (patientName, formId) => {
    if (!formId) {
      console.error('form Id ID is required to validate form link.');
      return { error: 'Form Id is required.' };
    }

    try {
      const response = await axios.post('/validate-form-link',
        { formId, patientName },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        });

      if (response.status === 200) {
        console.log('Form link details fetched successfully:', response.data.result);
        setValidUrl(response?.data?.valid)
      }
    } catch (error) {
      setValidUrl(false)
      console.error('Unexpected error:', error.message);
      return { error: error.message };
    }
  };

  const handlePatientChange = (value) => {
    setSelectedPatient(value);
    console.log("***************** patient change value", value)
    fetchFormLinkDetailsWithPatientId(value);
  };

  const GetActivePatientProfiles = async () => {
    try {
      setPatientLoader(true);
      const response = await axios.get(
        `/patient-profiles/active`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (response.status === 200) {
        setActivePatient(response.data);
        setPatientLoader(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {

      }
      if (error.response && error.response.status === 409) {

      }
      console.error("Error:", error);
    }
  };

  const verifyToken = async () => {
    const token = localStorage.getItem("sessionToken");
    if (!token) {
      setAuthStatus(false);
      return;
    }

    try {
      const response = await axios.post(
        "/verify-token",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setAuthStatus(true);
      } else {
        setAuthStatus(false);
      }
    } catch (error) {
      setAuthStatus(false);
    }
  };
  useEffect(() => {
    if (!name && !formId) {
      GetActivePatientProfiles();
      verifyToken();
      setPublicForm(false)
    } else {
      validateForm(name, formId)
      setPublicForm(true)
      fetchFormLinkDetailsWithFormId(formId);
    }

    const authStatus = localStorage.getItem("sessionToken") ? true : false;
    setAuthenticatedUser(authStatus);

  }, []);
  useEffect(() => {
    if (name) {
      setPatientName(decodeURIComponent(name))
    }
  }, [name]);

  const handleFormSubmit = async (values) => {
    setConfirmLoading(true);
    values.signature.date = new Date();

    try {
      const response = await fetch("/form-data", {
        method: "POST",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Include any other headers you need here
        },

        body: JSON.stringify(values),
      });
      if (response.ok) {
        setConfirmLoading(false);
        toast("Patient form added successfully", "success");
        form.resetFields();
      } else {
        setConfirmLoading(false);
        console.error("Failed to add patient form");
      }
    } catch (error) {
      setConfirmLoading(false);
      console.error("Error adding patient form:", error);
    }
  };

  useEffect(() => {
    console.log("************** selected Patient", selectedPatient)
  }, [selectedPatient])

  useEffect(() => {
    console.log("**************form data", form.getFieldsValue())

  }, [form])

  const generateFormLink = async () => {
    const protocol = window.location.protocol
    const host = window.location.host
    const selectedPatientName = activePatient.find(patient => patient._id === selectedPatient)['Full Name']
    const payload = {
      protocol,
      host,
      patientId: selectedPatient,
      name: selectedPatientName
    }
    try {
      const response = await axios.post('/generate-form-link', payload, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("sessionToken")}`
        }
      });

      if (response.status === 200 || response.status === 201) {
        setLink(response.data.result.link);
        setFormGenerateButtonVisible(false)
        setFormLinkDetails(response.data.result)
      } else {
        setFormGenerateButtonVisible(true)
        const { message } = await response.json();
        alert(`Failed to generate form link: ${message}`);
      }
    } catch (error) {
      setFormGenerateButtonVisible(true)
      console.error(error);
      alert('An error occurred while generating the form link.');
    }
  };

  const handleCopyFormLink = () => {
    navigator.clipboard.writeText(formLinkDetails?.link)
      .then(() => {
        message.success('Text copied to clipboard!');
      })
      .catch((err) => {
        message.error('Failed to copy text.');
        console.error('Copy error:', err);
      });
  };

  useEffect(() => {
    console.log("******** Form Link Details", formLinkDetails)
  }, [formLinkDetails])

  return (
    <div className='w-full'>
      {
        <>
          <div className={`${styles.headerFlexbox} mb-[10px]`}>
            {(validUrl || authenticatedUser) && <div>
              <h3 className={`${styles.exportHeading}`}>Add Patient</h3>
            </div>}
          </div>
          <ToastContainer />
          {(selectedPatient || (publicForm && validUrl))  && <Row className="px-[40px] flex justify-center" gutter={16}>
            <Col xs={20}>
              <Steps className="cursor-pointer" current={currentStep}>
                <Step onClick={() => setCurrentStep(0)} title="Step 1" />
                <Step onClick={() => setCurrentStep(1)} title="Step 2" />
                <Step onClick={() => setCurrentStep(2)} title="Step 3" />
                <Step onClick={() => setCurrentStep(3)} title="Step 4" />
                <Step onClick={() => setCurrentStep(4)} title="Step 5" />
              </Steps>
            </Col>
          </Row>}
          <div className="px-[40px]">

            {(publicForm && validUrl) && <div className="flex w-full items-center justify-between my-6">
              <p className="text-lg font-semibold">{patientName}</p>
              {formLinkDetails && Object.keys(formLinkDetails).length > 0 && formLinkDetails?.status !== undefined && (
              <div className='inline-flex items-center shadow-md py-2 px-4 rounded-2xl gap-2 border-gray-300 border-[1px] bg-white'>
                <div className='w-2 h-2 rounded-lg bg-orange-500'></div>
                <p className='text-md font-semibold'>{getPatientFormStatus(formLinkDetails?.status)}</p>
              </div>
            )}
            </div>}

            {(!publicForm) &&
              <div className="flex w-full items-center justify-between my-6">
                <div className='flex items-center gap-3'>
                {patientLoader ? (
                  <div className="m-[10px]">
                    <BarLoader color="#000" />
                  </div>
                ) : (
                  <Form form={form}>
                    {
                      <Form.Item
                        name="Customer ID"
                        rules={[{ required: true, message: "Please select Patient" }]}
                      >
                        <Select
                          className="mt-6"
                          showSearch
                          style={{ width: 200, backgroundColor: 'white' }}
                          placeholder="Select a patient"
                          optionFilterProp="children"
                          onChange={handlePatientChange}
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                            0
                          }
                        >
                          {activePatient.map((patient) => (
                            <Option key={patient["_id"]} value={patient["_id"]}>
                              {patient["Full Name"]}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    }
                  </Form>
                )}
                <PatientFormSubmissions />
                </div>
                <div className='flex gap-4 items-center'>

                  {formLinkDetails && Object.keys(formLinkDetails).length > 0 && formLinkDetails?.status !== undefined && (
                    <div className='inline-flex items-center shadow-md py-2 px-4 rounded-2xl gap-2 border-gray-300 border-[1px] bg-white'>
                      <div className='w-2 h-2 rounded-lg bg-orange-500'></div>
                      <p className='text-md font-semibold'>{getPatientFormStatus(formLinkDetails?.status)}</p>
                    </div>
                  )}
                  {(formGenerateButtonVisible && authenticatedUser) ? (
                    <Button type="primary" className="btn-color  py-2 px-6" onClick={generateFormLink}>Generate Form Link</Button>
                  ) : formLinkDetails && Object.keys(formLinkDetails).length > 0 &&
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Input
                      value={formLinkDetails?.link}
                      readOnly
                      style={{ width: '640px' }}
                    />
                    <Tooltip title="Copy to clipboard">
                      <Button icon={<CopyOutlined />} onClick={handleCopyFormLink} />
                    </Tooltip>
                  </div>
                  }
                </div>
              </div>
            }

            
            {!selectedPatient && !name && <div className="w-full h-96 shadow-xl rounded-xl flex justify-center items-center bg-white">
              <p className="flex items-center gap-2 text-lg font-semibold"><WarningAmberIcon sx={{ color: colors.orange['600'], fontSize: 24 }} /> Please select a patient first to further proceed with form </p>
            </div>}

            {((selectedPatient && authenticatedUser) || (publicForm && validUrl)) &&
              (<>
                {currentStep === 0 && (<PersonalInformationForm form={form} handleNext={handleNext} />)}
                {currentStep === 1 && (<HealthQuestionsForm form={form} handleNext={handleNext} handlePrev={handlePrev} />)}
                {currentStep === 2 && (<SymptomsChecklistForm form={form} handleNext={handleNext} handlePrev={handlePrev} />)}
                {currentStep === 3 && (
                  <>
                    <div className="bg-white p-6 rounded-2xl">
                      {
                        !selectedPatient &&
                        <div className="w-full h-2/4 flex items-center justify-center">
                          <h1>Please Select Patient First</h1>
                        </div>
                      }
                      {
                        selectedPatient &&
                        <>
                          <h1>{activePatient.find(patient => patient._id === selectedPatient)['Full Name']}</h1>
                        </>
                      }
                      {selectedPatient && <DocumentsSection selectedPatient={activePatient.find(patient => patient._id === selectedPatient)['_id']} />}
                    </div>
                  </>
                )}
                {currentStep === 4 && (<WaiversAndSignatureForm form={form} handlePrev={handlePrev} confirmLoading={confirmLoading} handleFormSubmit={handleFormSubmit} />)}
              </>)
            }
          </div>
          {!validUrl && !authenticatedUser &&
            <div className="h-96 shadow-xl w-7/12 rounded-xl flex items-centerbg-white mx-auto my-10 justify-center items-center">
              <p className="flex items-center gap-2 text-lg font-semibold"><WarningAmberIcon sx={{ color: colors.orange['600'], fontSize: 24 }} /> This form link is invalid please try to enter valid form link </p>
            </div>}
        </>
      }
      {/* <FormLinkModal isFormLinkModalOpen={isFormLinkModalOpen} handleFormLinkCancel={handleFormLinkCancel} link={link} /> */}
    </div>
  );
}

export default AddPatientFormNew;