import { NavigateBefore } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { Card, Form, Input, Space, theme, Typography } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import Paragraph from 'antd/es/typography/Paragraph'
import React from 'react'

const WaiversAndSignatureForm = ({form,handlePrev,confirmLoading,handleFormSubmit}) => {
  const { token } = theme.useToken();

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const today = new Date();
      const month = months[today.getMonth()];
      const day = today.getDate();
      const year = today.getFullYear();
    
      const formattedDate = `Day: ${day} - Month: ${month} - Year: ${year}`;


      

  return (
    <>
           <div className="bg-white p-6 rounded-2xl">
           <Form
            form={form}
            onFinish={handleFormSubmit}
            layout="vertical"
          >
            <Space direction="vertical" size="small" className="w-full">
              <div>
              <Form.Item
                  label={<Typography.Text strong>Patient Initials</Typography.Text>}
                  name={["signature", "patientInitials"]}
                  rules={[{ required: true, message: 'Please enter your initials' }]}
                >
                  <Input placeholder="Enter your initials" />
                </Form.Item>
                
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <Form.Item
                  label={<Typography.Text strong>Additional Information</Typography.Text>}
                  name={["symptomsChecklist", "additionalNotes"]}
                >
                  <TextArea
                    rows={4}
                    placeholder="Please provide any additional information that you feel is important."
                    style={{ borderRadius: token.borderRadius }}
                  />
                </Form.Item>

                <Form.Item
                  label={<Typography.Text strong>Any exceptions to medical record access</Typography.Text>}
                  name={["waivers", "medicalRecordAccessExceptions"]}
                >
                  <TextArea
                    rows={4}
                    placeholder="List any exceptions"
                    style={{ borderRadius: token.borderRadius }}
                  />
                </Form.Item>
              </div>

              <Card 
                className="bg-gray-50"
                bordered={false}
              >
                <Paragraph className="text-sm">
                  <Typography.Text strong>(a)</Typography.Text> Patient acknowledges that neither Group nor Physician guarantees that communications with Physician using electronic mail ("email"), facsimile, video chat, instant messaging, and cellular telephone are secure or confidential methods of communications. Accordingly, Patient expressly waives Group's and Physician's obligations under the Health Insurance Portability and Accountability Act of 1996 (42 U.S.C. § 1320d et seq.), as amended by the Health Information Technology for Economic and Clinical Safety Act of 2009, and all rules and regulations promulgated thereunder (collectively, "HIPAA"), and other state and federal laws and regulations applicable to the use, maintenance, and disclosure of patient-related information, to guarantee confidentiality with respect to correspondence using such means of communication. Patient acknowledges that all such communications may become a part of Patient's medical records maintained by Physician.
                </Paragraph>
                <Paragraph className="text-sm mt-4">
                  <Typography.Text strong>(b)</Typography.Text> By providing Patient's e-mail address to Physician, Patient authorizes Physician to communicate with Patient by e-mail regarding Patient's "protected health information" ("PHI") (as defined under HIPAA) and Patient understands and agrees to the following: E-mail is not necessarily a secure medium for sending or receiving PHI and, accordingly, any third party may gain access to such PHI; Although Group and Physician will make all reasonable efforts to keep e-mail communications confidential and secure, neither Group nor Physician can assure or guarantee the absolute confidentiality of such e-mail communications.
                </Paragraph>
              </Card>


              <Card 
                className="bg-gray-50"
                bordered={false}
              >
                <Paragraph className="text-sm">
                  I agree to accept responsibility for payment of all charges relating to the services provided to me, regardless of the outcomes of the services or whether any insurance applies.
                </Paragraph>
                <Paragraph className="text-sm mt-4">
                  By signing below, you, the patient of Sunridge Medical (or the legal representative or parent of the patient), are requesting and authorizing Sunridge Medical Wellness Center to perform services and to provide you with recommendations on optimizing your present and long-term health goals.
                </Paragraph>
                <div className="mt-4">
                  <Typography.Text strong className="text-sm">
                    {formattedDate}
                  </Typography.Text>
                </div>
              </Card>

              <Form.Item
                label={<Typography.Text strong>Signature</Typography.Text>}
                name={["signature", "patientInitials"]}
                rules={[{ required: true, message: 'Please provide your signature' }]}
              >
                <TextArea
                  rows={4}
                  placeholder="Please sign here"
                  style={{ borderRadius: token.borderRadius }}
                />
              </Form.Item>

              {/* <Form.Item>
                <Button type="primary" size="large" block>
                  Add Patient
                </Button>
              </Form.Item> */}
              <Form.Item>
                <button
                  type="primary"
                  htmlType="submit"
                  loading={confirmLoading}
                  className="btn-color h-[50px] py-2 px-6 mb-[10px]"
                >
                  Add Patient
                </button>
              </Form.Item>
            </Space>

          </Form>

              
              <div className="flex justify-between">
                <IconButton onClick={handlePrev}>
                  <NavigateBefore />
                </IconButton>
              </div>
            
              </div>
    </>
  )
}

export default WaiversAndSignatureForm