// Components/CellRenderers.js
import React, { useEffect, useState } from "react";
import {
  Select,
  Checkbox,
  Input,
  InputNumber,
  Popover,
  Form,
  Space,
  Tooltip,
  message,
  DatePicker,
  Button,
} from "antd";
import dayjs from "dayjs";
import LPDPopup from "../../../Components//LPDPopup/index.jsx";
import { IconButton } from "@mui/material";
import {
  CopyAllOutlined,
  CopyAllTwoTone,
  Delete,
  FileCopy,
} from "@mui/icons-material";
import { Button as MuiButton } from "@mui/material";
import axios from "axios";
import moment from "moment";

const { Option } = Select;

const checkIfFinalized = (props) => {
  if (props?.data?.review) {
    message.warning(
      "This treatment is finalized so can't make any changes in it"
    );
    return true;
  }
  return false;
};

export const DateCellRenderer = (props) => {
  const handleChange = (date) => {
    if (checkIfFinalized(props)) return;

    if (date) {
      const formattedDate = date.format("YYYY-MM-DD");
      props?.setValue(formattedDate);
    } else {
      props?.setValue(null);
    }
  };

  return (
    <DatePicker
      value={props?.value ? dayjs(props?.value) : null}
      onChange={handleChange}
      style={{ width: "100%" }}
    />
  );
};

export const DropdownCellRenderer = (props) => {
  const [value, setValue] = useState(props?.value);

  const handleChange = (newValue) => {
    if (checkIfFinalized(props)) return;

    setValue(newValue);
    props?.setValue(newValue);
  };
  const [showTooltip, setShowTooltip] = useState(false);
  return props?.from === "assignees" ? (
    <Tooltip
      onVisibleChange={(visible) => {
        if (visible && value.length > 0) {
          setShowTooltip(true);
        } else {
          setShowTooltip(false);
        }
      }}
      visible={showTooltip}
      title={
        <div>
          {value.length > 0 &&
            value?.map((item) => (
              <div key={item} className="tooltipItem">
                {item}
              </div>
            ))}
        </div>
      }
    >
      <Select
        mode={props?.from === "assignees" ? "multiple" : ""}
        style={{ width: "100%" }}
        value={value}
        onChange={handleChange}
      >
        {props?.values?.length > 0 &&
          props?.values.map((option) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
      </Select>
    </Tooltip>
  ) : (
    <Select
      mode={props?.from === "assignees" ? "multiple" : ""}
      style={{ width: "100%" }}
      value={value}
      onChange={handleChange}
    >
      {props?.values?.length > 0 &&
        props?.values.map((option) => (
          <Option key={option} value={option}>
            {option}
          </Option>
        ))}
    </Select>
  );
};

export const LPDCellRenderer = (props) => {
  const handleOpenModal = async (type) => {
    try {
      props?.setActiveTreatment(props?.treatment);
      props?.setModalType(type);
      props?.setActiveTreatmentId(props?.treatment?.orderId);
      props?.setShowModal(true);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
    }
  };
  // return <LPDPopup/>;
  const getTooltipContent = (labs, dispensed, procedures) => (
    <div>
      {labs.length > 0 && (
        <div className="mb-4">
          <strong>Labs:</strong>
          {labs.map((lab) => (
            <div key={lab._id} className="my-2">
              {lab.test_name}
            </div>
          ))}
        </div>
      )}
      {dispensed.length > 0 && (
        <div className={`mb-4 ${labs.length > 0 ? "mt-4" : ""}`}>
          <strong>Dispensed:</strong>
          {dispensed.map((item) => (
            <div key={item._id} className="my-2">
              {item.name} - Quantity: {item.quantity} - Dosage:{" "}
              {item.selectedField}
            </div>
          ))}
        </div>
      )}
      {procedures.length > 0 && (
        <div
          className={`mb-4 ${
            labs.length > 0 || dispensed.length > 0 ? "mt-4" : ""
          }`}
        >
          <strong>Procedures:</strong>
          {procedures.map((procedure) => (
            <div key={procedure._id} className="my-2">
              {procedure.procedure_name}
              {procedure.selectedField &&
                ` - Selected: ${procedure.selectedField}`}
              {procedure.note && ` - Note: ${procedure.note}`}
            </div>
          ))}
        </div>
      )}
    </div>
  );
  const getCheckedLabs = (userLabs) => {
    return userLabs.filter((lab) => lab.checked);
  };
  const getDispensedItemsWithSelectedField = (userdispensed) => {
    return userdispensed.filter((item) => item.selectedField);
  };
  const getRelevantProcedures = (userProcedures) => {
    return userProcedures.filter(
      (procedure) => procedure.checked || procedure.selectedField
    );
  };
  const tooltipLabs = getCheckedLabs(props.treatment.userLabs);
  const tooltipDispensed = getDispensedItemsWithSelectedField(
    props.treatment.userdispensed
  );
  const tooltipProcedures = getRelevantProcedures(
    props.treatment.userProcedures
  );
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <>
      <div className="flex items-center ">
        <Tooltip
          onVisibleChange={(visible) => {
            if (visible && tooltipLabs.length > 0) {
              setShowTooltip(true);
            } else {
              setShowTooltip(false);
            }
          }}
          visible={showTooltip}
          title={getTooltipContent(tooltipLabs, [], [])}
        >
          <IconButton
            size="small"
            onClick={() => {
              console.log("******Props", props);
              handleOpenModal("Labs");
            }}
            aria-label="labs"
            sx={{
              width: 24,
              height: 24,
              fontSize: "0.9rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: props?.treatment?.userLabs?.some(
                (lab) => lab.checked
              )
                ? "#b9f6ca"
                : "#e3f2fd",
              margin: "2px",
              color: "black",
            }}
          >
            L
          </IconButton>
        </Tooltip>
        <Tooltip
          onVisibleChange={(visible) => {
            if (visible && tooltipProcedures.length > 0) {
              setShowTooltip(true);
            } else {
              setShowTooltip(false);
            }
          }}
          visible={showTooltip}
          title={getTooltipContent([], [], tooltipProcedures)}
        >
          <IconButton
            size="small"
            onClick={() => {
              handleOpenModal("Procedures");
            }}
            aria-label="procedures"
            sx={{
              width: 24,
              height: 24,
              fontSize: "0.9rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: props?.treatment?.userProcedures?.some(
                (procedure) =>
                  (procedure.selectedField &&
                    procedure.selectedField.trim().length > 0) ||
                  procedure.checked
              )
                ? "#b9f6ca"
                : "#90caf9",

              margin: "2px",
              color: "black",
            }}
          >
            P
          </IconButton>
        </Tooltip>
        <Tooltip
          onVisibleChange={(visible) => {
            if (visible && tooltipDispensed.length > 0) {
              setShowTooltip(true);
            } else {
              setShowTooltip(false);
            }
          }}
          visible={showTooltip}
          title={getTooltipContent([], tooltipDispensed, [])}
        >
          <IconButton
            size="small"
            onClick={() => {
              console.log("******Props", props);
              handleOpenModal("Dispensed");
            }}
            aria-label="dispense"
            sx={{
              width: 24,
              height: 24,
              fontSize: "0.9rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: props?.treatment?.userdispensed?.some(
                (dispensed) =>
                  dispensed.selectedField &&
                  dispensed.selectedField.trim().length > 0
              )
                ? "#b9f6ca"
                : "#42a5f5",

              margin: "2px",
              color: "black",
            }}
          >
            D
          </IconButton>
        </Tooltip>
        <IconButton
          size="small"
          onClick={() => {
            handleOpenModal("Notes");
          }}
          aria-label="TreatmentNote"
          sx={{
            width: 24,
            height: 24,
            fontSize: "0.9rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: props?.treatment?.treatmentNotes?.includes(
              "Notes by:"
            )
              ? "#81e984"
              : "#0e75e5",
            margin: "2px",
            color: "black",
          }}
        >
          N
        </IconButton>
        <IconButton
          size="small"
          onClick={() => {
            handleOpenModal("Vitals");
          }}
          aria-label="dispense"
          sx={{
            width: 24,
            height: 24,
            fontSize: "0.9rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor:
              props?.treatment?.vitals?.length > 0 ? "#81e984" : "#1e88e5",
            margin: "2px",
            color: "black",
          }}
        >
          V
        </IconButton>
      </div>
    </>
  );
};

export const DelDupRenderer = (props) => {
  const handleOrderDelete = (deletedOrderId) => {
    const updatedOrders = props?.orders?.filter(
      (order) => order?._id !== deletedOrderId
    );
    props?.setOrders(updatedOrders);
  };
  const handleDeleteRow = async (orderId) => {
    try {
      const response = await fetch(
        `/patient/order/delete/${orderId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            // Include any other headers you need here
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the order");
      }
      handleOrderDelete(orderId);
      const result = await response.json();
      // Or handle the response as needed
      // Optionally, refresh the data or update the UI accordingly
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const handleDuplicateRow = async (orderId, selectedDate, values) => {
    try {
      if (selectedDate) {
        const responseDate = await fetch(
          `/patient/order/duplicate/${orderId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
            body: JSON.stringify({ selectedDate, values }),
          }
        );
        if (!responseDate.ok) {
          throw new Error("Failed to duplicate the order");
        }

        const response = await responseDate.json();
        // Or handle the response as needed
        props.setOrders((prevState) => {
          return [
            {
              ...response.duplicatedOrder,
              orderId: response.duplicatedOrder._id,
            },
            ...prevState,
          ];
        });
        // handlePatientSelect(props.treatment.PatientId);
        // handleHideEmptyColumns();
      } else {
        const response = await fetch(
          `/patient/order/duplicate/${orderId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to duplicate the order");
        }

        const duplicatedOrder = await response.json();
      }

      // Optionally, refresh the data or update the UI accordingly
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const handleOpenModal = () => {
    props?.setIsPopoverOpen(true);
  };

  const handleCloseModal = () => {
    props?.setUpdatedEvents([]);
    props?.setIsPopoverOpen(false);
  };
  const [form] = Form.useForm();
  const onFinish = (values) => {
    // Handle form submission here

    handleDuplicateRow(props?.treatment?.orderId, values.date, values);
  };

  const [allChecked, setAllChecked] = useState(true);
  const handleCheckboxChange = (checkedFieldName) => {
    const values = form.getFieldsValue(); // Get the current form values
    const otherFields = ["labs", "procedure", "dispensed"]; // Names of other checkboxes excluding "All"

    // If any other checkbox is checked, uncheck the "All" checkbox
    if (otherFields.some((field) => values[field])) {
      form.setFieldsValue({ all: false });
      setAllChecked(false);
    } else {
      form.setFieldsValue({ all: true });
      setAllChecked(true);
    }
  };
  return (
    <>
      <div className="flex items-center ">
        <IconButton
          size="small"
          onClick={() => {
            handleDeleteRow(props?.treatment?.orderId);
          }}
        >
          <Delete />
        </IconButton>
        <Popover
          content={
            <div className="py-1 px-3 bg-white ">
              <MuiButton
                variant="contained"
                startIcon={<FileCopy />}
                title="Assign events to old treatments"
                onClick={handleOpenModal}
                sx={{
                  textTransform: "capitalize",
                }}
                fullWidth
              >
                <span className="ml-2 text-base">Assign Events</span>
              </MuiButton>
              <Form
                form={form}
                onFinish={onFinish}
                className="mt-4"
                initialValues={{
                  all: true,
                  date: dayjs(moment().format("YYYY/MM/DD"), "YYYY/MM/DD"),
                  type: props?.treatment?.type,
                }}
              >
                <Form.Item name="date" className="mb-2">
                  <DatePicker
                    // defaultValue={dayjs(
                    //   moment().format("YYYY/MM/DD"),
                    //   "YYYY/MM/DD"
                    // )}
                    initialValue={dayjs(
                      moment().format("YYYY/MM/DD"),
                      "YYYY/MM/DD"
                    )}
                    className="w-full"
                  />
                </Form.Item>

                <p className="text-center text-md font-semibold">
                  What do you want to duplicate?
                </p>
                <div className="mt-2 flex flex-wrap">
                  <Form.Item
                    name="all"
                    valuePropName="checked"
                    className="mb-0 w-2/4"
                  >
                    <Checkbox
                      defaultChecked={true}
                      checked={allChecked}
                      onChange={() => setAllChecked(true)}
                    >
                      All
                    </Checkbox>
                  </Form.Item>
                  {props?.treatment?.userLabs.some((lab) => lab.checked) && (
                    <Form.Item
                      name="labs"
                      valuePropName="checked"
                      className="mb-0 w-2/4"
                    >
                      <Checkbox
                        defaultChecked={false}
                        onChange={() => handleCheckboxChange("labs")}
                      >
                        Labs
                      </Checkbox>
                    </Form.Item>
                  )}
                  {props?.treatment?.userProcedures.some(
                    (procedure) =>
                      (procedure.selectedField &&
                        procedure.selectedField.trim().length > 0) ||
                      procedure.checked
                  ) && (
                    <Form.Item
                      name="procedure"
                      valuePropName="checked"
                      className="mb-0 w-2/4"
                    >
                      <Checkbox
                        defaultChecked={false}
                        onChange={() => handleCheckboxChange("procedure")}
                      >
                        Procedures
                      </Checkbox>
                    </Form.Item>
                  )}
                  {props?.treatment?.userdispensed.some(
                    (dispensed) =>
                      dispensed.selectedField &&
                      dispensed.selectedField.trim().length > 0
                  ) && (
                    <Form.Item
                      name="dispensed"
                      valuePropName="checked"
                      className="mb-0 w-2/4"
                    >
                      <Checkbox
                        defaultChecked={false}
                        onChange={() => handleCheckboxChange("dispensed")}
                      >
                        Dispensed
                      </Checkbox>
                    </Form.Item>
                  )}
                </div>
                <div>
                  <p className="font-semibold">Type:</p>
                  <Form.Item
                    name="type"
                    initialValue={props?.treatment?.type}
                    className="mb-1"
                  >
                    <Select className="w-full">
                      {props?.treatment?.type !== "Order" && (
                        <Select.Option value="Order">Order</Select.Option>
                      )}
                      {props?.treatment?.type !== "Treatment" && (
                        <Select.Option value="Treatment">
                          Treatment
                        </Select.Option>
                      )}
                    </Select>
                  </Form.Item>
                </div>
                <Form.Item className="mt-4 mb-1">
                  <Space>
                    <Button
                      type="submit"
                      className="bg-blue-500 text-white"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                    <Button onClick={() => form.resetFields()}>Reset</Button>
                  </Space>
                </Form.Item>
              </Form>
            </div>
          }
          trigger="click"
        >
          {" "}
          <IconButton
            onClick={() => form.resetFields()}
            color="default"
            aria-label="duplicate"
          >
            {" "}
            <FileCopy />
          </IconButton>
        </Popover>
      </div>
    </>
  );
};

export const CheckboxCellRenderer = (props) => {
  const handleChange = (e) => {
    if (props?.colDef?.field !== "review" && checkIfFinalized(props)) return;

    const newValue = e.target.checked;
    props?.setValue(newValue);
  };

  return <Checkbox checked={props?.value} onChange={handleChange} />;
};

export const NoteCellRenderer = (props) => {
  const [value, setValue] = useState(props?.value);

  const handleChange = (e) => {
    if (checkIfFinalized(props)) return;
    const newValue = e.target.value;
    setValue(newValue);
  };
  const handleUpdate = (e) => {
    props?.setValue(value);
  };

  const handleAddNoteToMultipleTxNotes = (data, event) => {
    const payload = {
      note: event.target.value,
      date: data.date,
    };
    try {
      const response = axios
        .post(
          `/patient/${data.PatientId}/note?fromTreatment=true&treatmentId=${data._id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              "Content-Type": "application/json",
            },
          }
        )
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  return (
    <Input
      type="text"
      value={value}
      onChange={handleChange}
      onBlur={(e)=>{
        if(props?.fromTreatmentNote===true){
          handleUpdate(e, props?.treatment?._id);
          handleAddNoteToMultipleTxNotes(props?.treatment,e)
        }else{
        handleUpdate(e)}
      }}
    />
  );
};

export const QuantityCellRenderer = (props) => {
  const [value, setValue] = useState(props?.value);

  const handleChange = (newValue) => {
    if (checkIfFinalized(props)) return;

    setValue(newValue);
    props?.setValue(newValue);
  };

  return (
    <InputNumber
      min={0}
      value={value}
      onChange={handleChange}
      style={{ width: "100%" }}
    />
  );
};

export const CheckboxHeader = () => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    }}
  >
    <Checkbox disabled checked={true} />
  </div>
);
