import { Col, Input, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import FilterButtons from '../FilterButtons';
import TreatmentTabsContainer from '../TreatmentTabsContainer';
import moment from 'moment';
import axios from 'axios';
const { Title } = Typography;

const PatientTable = () => {
  const [treatments,setTreatments]= useState([])
  const [allTreatments,setAllTreatments]= useState([])
   const [activeTab,setActiveTab]=useState("All")
      const handleChangeActiveTab=(tab)=>{
          setActiveTab(tab)
      }


      const getTreatments = async () => {

        try {
          const sessionToken = localStorage.getItem('sessionToken');
          if (!sessionToken) {
            throw new Error('No session token found');
          }
          const date= moment().format("YYYY-MM-DD")
          const response = await axios.get(`/ordered/${date}`, {
            headers: {
              Authorization: `Bearer ${sessionToken}`,
            },
          });
    
          setAllTreatments(response.data);
        } catch (error) {
          setTreatments([]);          
          console.error('Error fetching treatments:', error);
        } finally {

        }
      }
    
  useEffect(()=>{
    getTreatments()
  },[])



  useEffect(()=>{
    if(activeTab==='All'){
    setTreatments(allTreatments)}
    else if(activeTab === 'Scheduled'){
      const updatedTreatment= allTreatments.filter(item => item.status==='scheduled')
      setTreatments(updatedTreatment)
    }
    else if(activeTab === 'Arrived'){
      const updatedTreatment= allTreatments.filter(item => item.status==='arrived')
      setTreatments(updatedTreatment)
    }
    else if(activeTab === 'Pickup'){
      const updatedTreatment= allTreatments.filter(item => item.status==='pickup')
      setTreatments(updatedTreatment)
    }
    else if(activeTab === 'Completed'){
      const updatedTreatment= allTreatments.filter(item => item.status==='complete')
      setTreatments(updatedTreatment)
    }
  },[activeTab,allTreatments])

  return (
    <div className='p-10 rounded-lg bg-white'>
      {/* First Row: Heading and Search Box */}
      <Row justify="space-between" align="middle" style={{ marginBottom: '20px' }}>
        <Col>
          <Title level={4}>All Patients</Title>
        </Col>
        <Col>
          <Input.Search
            placeholder="Search patients"
            allowClear
            style={{ width: 300 }}
          />
        </Col>
      </Row>

      {/* Second Row: Buttons */}
      <FilterButtons activeTab={activeTab} handleChangeActiveTab={handleChangeActiveTab}/>
      <div className="mt-4">
          <TreatmentTabsContainer treatments={treatments}/>
      </div>
      
    </div>
  );
};

export default PatientTable;


