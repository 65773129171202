import { EditFilled, PlusOutlined } from '@ant-design/icons';
import { Print } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Badge, Button, Dropdown, Input, Menu, message, Tooltip } from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import smsIcon from '../../../../Assets/sms.png';
import LPDPopup from '../../../../Components/LPDPopup';
import Messages from '../../../../Components/Messages';
import LPDVIconButtons from '../LPDVIconButtons';
import PrintModal from '../PrintModal';

const SOAPNotesSection = ({ createTabContent, setTabContent, setShowSOAPNotesSection, scrollToDiagnosisDiv, chiefConcern, patientProfile, initialData = {}, notesData = {}, tabKey = "", newDate = null }) => {
  const [subjective, setSubjective] = useState(notesData?.subjective || '');
  const [objective, setObjective] = useState(notesData?.objective || '');
  const [assessment, setAssessment] = useState(notesData?.assessment || '');
  const [planNote, setPlanNote] = useState(notesData?.plan?.note || '');
  const [labs, setLabs] = useState(notesData?.plan?.labs || '');
  const [procedures, setProcedures] = useState(notesData?.plan?.procedures || '');
  const [dispensed, setDispensed] = useState(notesData?.plan?.dispensed || '');
  const [customName, setCustomName] = useState(notesData?.customName || '');
  const [storedChiefConcern, setStoredChiefConcern] = useState(notesData?.chiefConcern || '');
  const [noteId, setNoteId] = useState(initialData?.noteId || notesData?._id || null);
  const [date, setDate] = useState(initialData?.date || notesData?.createdAt || moment.tz(moment(), moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss'));
  const [printModalVisible, setPrintModalVisible] = useState(false);
  const [patientId, setPatientId] = useState('');
  const [icd10CMCodes, setICD10CMCodes] = useState([])
  const userTimezone = moment.tz.guess();
  const currentDate = moment().tz(userTimezone).format('YYYY-MM-DD');
  const [orders, setOrders] = useState([]);
  const [newOrderLoading, setNewOrderLoading] = useState(false);
  const [editCustomName, setEditCustomName] = useState(false);
  const [showMessagesModal, setShowMessagesModal] = useState(false);
  const [isManuallyUpdated, setIsManuallyUpdated] = useState(false);
  const [currentTreatmentLoading, setCurrentTreatmentLoading] = useState(false);
  const [soapNoteCreated, setSoapNoteCreated] = useState(false);
  const [soapNoteCreatedFromLPD, setsoapNoteCreatedFromLPD] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showItemsMenu, setShowItemsMenu] = useState(false);
  const [itemsForSelection, setItemsForSelection] = useState([]);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [showShortcutsMenu, setShowShortcutsMenu] = useState(false);
  const [shortcuts, setShortcuts] = useState([]);
  const quillRef = useRef(null);
  const [customTemplates, setCustomTemplates] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [activeTreatment, setActiveTreatment] = useState({});


  const fetchAllICD10CMCodes = async () => {
    try {
      const response = await axios.get(`/get-codes`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
        }
      });
      setICD10CMCodes(response.data)
    } catch (error) {
      console.error("Error saving EMR data:", error);
    }
  }

  const fetchSoapNote = async (noteId) => {
    try {
      const response = await axios.get(`/get-soap-note/${noteId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
        }
      });
      if (response.status === 200 || response.status === 201) {
        setSubjective(response.data?.soapNote?.subjective)
        setObjective(response.data?.soapNote?.objective)
        setAssessment(response.data?.soapNote?.assessment)
        setPlanNote(response.data?.soapNote?.plan?.note)
        setLabs(response.data?.soapNote?.plan?.labs)
        setProcedures(response.data?.soapNote?.plan?.procedures)
        setDispensed(response.data?.soapNote?.plan?.dispensed)
        setCustomName(response.data?.soapNote?.customName)
      }
    } catch (error) {
      console.error("Error saving EMR data:", error);
    }
  }

  const chiefConcernData = icd10CMCodes.find(item => item.code === chiefConcern) || "";

  useEffect(() => {
    setPatientId(patientProfile._id)
    fetchAllICD10CMCodes()
    if (noteId) {
      fetchSoapNote(noteId)
    }
  }, [patientProfile])

  useEffect(() => {
    if (notesData?.createdAt || !noteId) {
      if (!soapNoteCreatedFromLPD) {
        fetchCurrentDateOrders(notesData?.createdAt);
      }
    }
  }, [notesData, noteId])

  const updateTabContent = (tabKey, updatedNotesData, updatedChiefConcern) => {
    setTabContent((prevTabContent) => ({
      ...prevTabContent,
      [tabKey]: createTabContent(tabKey, updatedNotesData, updatedChiefConcern), // Update content for the specific tab
    }));
  };

  const handleBlur = async () => {
    try {
      const requestBody = {
        patientId,
        chiefConcern: chiefConcernData,
        subjective,
        objective,
        assessment,
        plan: {
          note: planNote,
          labs: labs,
          procedures: procedures,
          dispensed: dispensed,
        },
        date,
        noteId,
      };

      const response = await axios.post('/soap-note', requestBody);
      console.log('SOAP Note response:', response.data);

      if (!noteId && !soapNoteCreated && response.data.soapNote._id) {
        console.log("Note id is not present")
        updateTabContent(tabKey, response.data?.soapNote, response.data?.soapNote?.chiefConcern)
        setNoteId(response.data.soapNote._id);
        setDate(response.data?.soapNote?.createdAt)
        setSoapNoteCreated(true)
      }
    } catch (error) {
      console.error('Error saving SOAP note:', error);
    }
  };

  const fetchCurrentDateOrders = async (date) => {
    const newDate = date ? date : moment.tz(moment(), moment.tz.guess()).format('YYYY-MM-DDTHH:mm:ss')
    try {
      const response = await axios.get(
        `/api/patient/${patientProfile?.['Customer ID']}/current-date-order/${newDate?.split('T')[0]}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("sessionToken")}` },
        }
      )
      if (response.status === 200 || response.status === 201) {
        setOrders([...response.data?.treatments])
        if(activeTreatment){
          const treatmentfound= response?.data?.treatments.find(item => item._id===activeTreatment._id) 
          if(treatmentfound){
            setActiveTreatment({...treatmentfound, userLabs: [...treatmentfound?.userLabs]});
          }
        }
      }

    }
    catch (error) {
      console.error("Error fetching current date orders ", error)
    }
  }

  const dataReset=()=>{
    fetchCurrentDateOrders(notesData?.createdAt);
  }

  const MessageIcon = ({ patient }) => {

    const openModal = () => {
      setShowMessagesModal(true);
    };

    const closeModal = () => {
      setShowMessagesModal(false);
    };
    const unreadCount = patient?.messages?.filter(
      (msg) => msg.readStatus === false
    ).length;

    console.log(unreadCount);
    return (
      <>
        <Badge count={unreadCount} overflowCount={99} offset={[-5, 5]}>
          <img
            src={smsIcon}
            alt="sms"
            style={{ width: "36px", height: "36px", background: "none", cursor: "pointer" }}
            onClick={openModal}
          />
        </Badge>
        {showMessagesModal && <Messages patient={patient} onClose={closeModal} />}
      </>
    );
  };

  const closeModal = () => setPrintModalVisible(false);

  const soapNotes = {
    subjective,
    objective,
    assessment,
    planNote
  }

  const handleUpdateCustomName = async () => {
    try {
      const response = await axios.post(`/update-encounter-custom-name`, {
        noteId,
        patientId,
        customName
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`
        }
      });
      setEditCustomName(false)
    } catch (error) {
      console.error("Error saving EMR data:", error);
    }
  }


  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState(false)
  const [activeTreatmentId, setActiveTreatmentId] = useState(false)

  const getActiveTreatment = () => {
    return orders.find((order) => order._id === activeTreatmentId);
  };

  const createNewTreatment = async (type) => {
    setNewOrderLoading(true)
    let payload = {}
    if (notesData) {
      payload = {
        date: notesData?.createdAt
      }
    }
    else {
      payload = {
        date: Date.now()
      }
    }
    try {
      const response = await axios.post(
        `/api/patient/order/new/${patientProfile["Customer ID"]}/${type}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        message.success(`New ${type.toLowerCase()} created successfully`);
        setOrders((prevState) => {
          return [
            { ...response.data, orderId: response.data._id },
            ...prevState,
          ];
        });
        setNewOrderLoading(false)
      } else {
        throw new Error(`Failed to create new ${type.toLowerCase()}`);
        setNewOrderLoading(false)
      }
    } catch (error) {
      console.error(`Error creating new ${type.toLowerCase()}:`, error);
      message.error(
        `Failed to create new ${type.toLowerCase()}: ${error.message}`
      );
      setNewOrderLoading(false)
    }
  };

  const getDefaultContent = (type, name) => {
    switch (type) {
      case "procedure":
        return `<p>The procedure <strong>${name}</strong> was administered to the patient</p>`;
      case "dispense":
        return `<p>The <strong>${name}</strong> was dispensed to the patient</p>`;
      case "lab":
        return `<p>The <strong>${name}</strong> lab was drawn</p></br>`;
      case "vital":
        return `<p>The patient's vitals were monitored today</p></br>`;
      default:
        return "";
    }
  };

  const filterProcedures = () => {
    return orders[0].userProcedures.filter(
      (item) =>
        (item.checked && (!item.dosages || item.dosages?.length === 0)) ||
        (item.dosages && item.dosages?.length > 0 && !!item.selectedField)
    );
  };

  const fetchSelectedTemplateByName = async (type, name) => {
    try {
      const response = await axios.get(
        `/api/soap-note-templates/type/${type}/name/${name}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      return response.data.content;
    } catch (error) {
      return getDefaultContent(type, name);
    }
  };

  const applyTemplate = (category, item, templateContent) => {
    if (!templateContent) return "";

    if (category === "custom") {
      return templateContent; // No need for replacements
    }

    switch (category) {
      case "procedure":
        templateContent = templateContent.replace(
          "{{procedure_name}}",
          item.procedure_name || ""
        );
        templateContent = templateContent.replace(
          "{{dosage}}",
          item.selectedField || "N/A"
        );
        templateContent = templateContent.replace(
          "{{notes}}",
          item.note || "N/A"
        );
        templateContent = templateContent.replace(
          "{{cusDosage}}",
          item.customDosage || "N/A"
        );
        break;
      case "dispense":
        templateContent = templateContent.replace(
          "{{item_name}}",
          item.name || ""
        );
        templateContent = templateContent.replace(
          "{{quantity}}",
          item.quantity.toString() || "0"
        );
        templateContent = templateContent.replace(
          "{{dosage}}",
          item.selectedField || "N/A"
        );
        templateContent = templateContent.replace(
          "{{notes}}",
          item.note || "N/A"
        );
        break;
      case "lab":
        templateContent = templateContent.replace(
          "{{test_name}}",
          item.test_name || ""
        );
        templateContent = templateContent.replace(
          "{{item_number}}",
          item.item_number || ""
        );
        templateContent = templateContent.replace(
          "{{result}}",
          item.result ? "Positive" : "Negative"
        );
        break;
      case "vitals":
        const hr = item.HR != null ? item.HR.toString() : "0";
        const rr = item.RR != null ? item.RR.toString() : "0";
        const spo2 = item.SPO2 != null ? item.SPO2.toString() : "0";
        const t = item.T != null ? item.T.toString() : "0";
        const w = item.W != null ? item.W.toString() : "0";

        templateContent = templateContent.replace("{{time}}", item.time || "");
        templateContent = templateContent.replace("{{BP}}", item.BP || "");
        templateContent = templateContent.replace("{{HR}}", hr);
        templateContent = templateContent.replace("{{RR}}", rr);
        templateContent = templateContent.replace("{{SPO2}}", spo2);
        templateContent = templateContent.replace("{{T}}", t);
        templateContent = templateContent.replace("{{W}}", w);
        break;
      default:
        // Handle other cases or default behavior
        break;
    }

    return `<h3>${templateContent}</h3>`;
  };

  const generateProceduresContent = async () => {
    const filteredProcedures = filterProcedures();
    let tempNote = "";

    if (filteredProcedures?.length > 0) {
      const proceduresContent = await Promise.all(
        filteredProcedures.map(async (proc) => {
          const content = await fetchSelectedTemplateByName(
            "procedure",
            proc.procedure_name
          );
          return applyTemplate("procedure", proc, content);
        })
      ).then((contents) =>
        contents.map((content) => `<h3>${content}</h3>`).join("")
        
      );
      tempNote += `<div><h2><strong>Procedures</strong></h2></br>${proceduresContent}</br></div>`;
    }

    return tempNote;
  };

  const filterDispensedItems = () => {
    return orders[0].userdispensed.filter(
      (item) =>
        (item.checked && (!item.dosages || item.dosages?.length === 0)) ||
        (item.dosages && item.dosages?.length > 0 && !!item.selectedField)
    );
  };

  const generateDispensedItemsContent = async () => {
    // Logic to generate and return dispensed items content
    const filteredDispensedItems = filterDispensedItems();
    let tempNote = "";

    if (filteredDispensedItems?.length > 0) {
      const dispensedItemsContent = await Promise.all(
        filteredDispensedItems.map(async (item) => {
          const content = await fetchSelectedTemplateByName(
            "dispense",
            item.name
          );
          return applyTemplate("dispense", item, content);
        })
      ).then((contents) =>
        contents.map((content) => `<h3>${content}</h3>`).join("")
      );
      tempNote += `<div><h2><strong>Dispensed Items</strong></h2></br>${dispensedItemsContent}</br></div>`;
    }

    return tempNote;
  };


  const fetchSpecificTemplate = async (category, itemName) => {
    try {
      const encodedItem = encodeURIComponent(itemName);
      const response = await axios.get(
        `/api/soap-note-templates/${category}/${encodedItem}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (response.data && response.data.templates) {
        const selectedTemplate = response.data.templates.find(
          (t) => t.selected
        );
        return selectedTemplate ? selectedTemplate.content : "";
      }
    } catch (error) {
      console.error("Error fetching template for", itemName, error);
      return "";
    }
  };

  const generateLabsContent = async () => {
    // Logic to generate and return labs content
    const filteredLabs = orders[0].userLabs.filter((lab) => lab.checked);
    let tempNote = "";

    if (filteredLabs?.length > 0) {
      const labsContent = filteredLabs.map((lab) => lab.test_name).join(", ");
      tempNote += `<div><h2><strong>Laboratory Orders</strong></h2></br><h3>${labsContent}</h3></br></div>`;
    }

    return tempNote;
  };

  const generateDefaultContent = async () => {
    setCurrentTreatmentLoading(true);
    let tempNote = "";
    let structure = [{ type: 'lab', enabled: true }, { type: 'procedure', enabled: true }, { type: 'dispense', enabled: true }];

    for (const element of structure) {
      if (!element.enabled) continue;

      switch (element.type) {
        case "procedure":
          tempNote += await generateProceduresContent();
          break;
        case "dispense":
          tempNote += await generateDispensedItemsContent();
          break;
        case "lab":
          tempNote += await generateLabsContent();
          break;
        default:
          break;
      }
    }

    if (tempNote === "") {
      message.error("No data available to generate notes");
      return;
    }

    const requestBody = {
      patientId,
      chiefConcern: chiefConcernData,
      subjective,
      objective,
      assessment,
      plan: {
        note: tempNote,
        labs: labs,
        procedures: procedures,
        dispensed: dispensed,
      },
      date,
      noteId,
    };
    console.log("********************* plan note", planNote)
    try {
      const response = await axios.post('/soap-note', requestBody,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (response.status === 200) {
        setIsManuallyUpdated(false);
        return tempNote;
      } else {
        message.error("Failed to Generate notes");
      }
    } catch (error) {
      message.error("Error occurred while Generating notes");
    } finally {
      setCurrentTreatmentLoading(false);
    }
  };


  const resetPlanNotes = () => {
    if (!noteId) {
      handleBlur();
    }
    if (noteId) {
      generateDefaultContent().then((content) => {
        setPlanNote(content)
      });
    }
  }


  const fetchCustomTemplates = async () => {
    try {
      const response = await axios.get(
        "/api/templates/custom",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      setCustomTemplates(response.data.templates);
    } catch (error) {
      console.error("Error fetching custom templates:", error);
      message.error("Error fetching custom templates");
    }
  };


  useEffect(() => {
    if (selectedCategory === "custom") {
      fetchCustomTemplates(); // Fetch custom templates when 'custom' is selected
    }
  }, [selectedCategory]);
  const fetchShortcuts = async (query) => {
    if (!query) return; // Exit if the query is empty

    try {
      const response = await axios.get(
        `/api/soap-note-templates/shortcut/search/${query}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      setShortcuts(response.data);
      setShowShortcutsMenu(true);
    } catch (error) {
      console.error("Error fetching shortcuts:", error);
    }
  };

  const shortcutMenuRef = useRef(null);
  const soapNoteCreatedRef = useRef(false);

  const shortcutsMenu = (
    <Menu ref={shortcutMenuRef}>
      {shortcuts.map((shortcut, index) => (
        <Menu.Item
          key={index}
          onClick={() =>
            shortcutMenuRef.current && onShortcutMenuClick(shortcut.shortcut)
          }
        >
          {shortcut.shortcut}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleLabsCheckboxChange = (labId, checked) => {
    
    orders[0]?.userLabs?.map((lab) => {
      if (lab._id === labId) {
        return { ...lab, checked: checked };
      }
      return lab;
    });
    
    const lab = orders[0]?.userLabs?.find((lab) => lab._id === labId);
    
    handleCellChange(
      orders[0]._id,
      ``,
      checked,
      "patientLabs",
      lab,
      orders[0].review
    );
    
  };

  const handleChange = (itemData, itemName, key, value, type) => {
    console.log(
      "********* handle change triggered",
      itemData,
      itemName,
      key,
      value
    );
  
    if (type === "Procedures") {
       orders[0]?.userProcedures.map((procedure) => {
        if (procedure.procedure_name === itemName && key !== "dosages") {
          let updatedProcedure = { ...procedure, [key]: value };
      if (key === "checked" && (itemData.isNew=== true || itemData.custom===true)) {
        let updatedProcedureData = { ...itemData, editItemName: false, checked:value };
        itemData = updatedProcedureData;
        handleCellChange(
          orders[0]._id,
          `${itemName}-selectedField`,
          itemData.selectedField,
          "userProcedures",
          itemData,
        );
      }
      else if (key==="checked" && (!itemData.isNew && !itemData.isCustom)){
        let updatedProcedureData = { ...itemData, editItemName: false, checked:value };
        itemData = updatedProcedureData;

        handleCellChange(
          orders[0]._id,
          `${itemName}-selectedField`,
          value?itemData.dosages[0]:"",
          "userProcedures",
          itemData,
        );
      }
      
      if ((key === "selectedField" && (value === "Empty" || value === "" ) && (!itemData?.isNew && !itemData?.custom) )) {
        // Remove the procedure from active procedures
        updatedProcedure = {
          ...updatedProcedure,
          selectedField: "",
          checked: false,
        }; // Uncheck the checkbox
        handleCellChange(
          orders[0]._id,
          `${itemName}-checked`,
          false,
          "userProcedures",
          itemData,
        );
      } else if (key === "selectedField" && value !== "" && (!itemData.isNew && !itemData.custom)) {
        // Mark the procedure as checked when a dosage is selected
        updatedProcedure.checked = true;
        handleChange(
          procedure,
          procedure.procedure_name,
          "checked",
          true,
        );
      }
          if ( !noteId && !soapNoteCreatedRef.current &&(updatedProcedure.checked || updatedProcedure.selectedField)) {
            console.log(" lpd pop up called")
            handleBlur();
            setsoapNoteCreatedFromLPD(true);
            soapNoteCreatedRef.current = true;

          }
          return updatedProcedure;
        }
        return procedure;
      });
    } else if (type === "Dispensed") {
      orders[0]?.userdispensed.map((item) => {
        if (item.name === itemName) {
          let isFilled = key === "selectedField" ? !!value : item.isFilled;
          let updatedItem = { ...item, [key]: value, isFilled };
      if(key === "quantity" && itemData.isNew && value){
        let updatedProcedureData = { ...itemData, editItemName: false }; 
        itemData = updatedProcedureData; 
      }

          if (key === "quantity") {
            updatedItem.quantity = value
            updatedItem.checked = value > 0 ? true : false
            if (itemData?.isNew === true || item?.custom === true) {
              updatedItem.selectedField = itemData.selectedField
              handleCellChange(
                orders[0]._id,
                `${itemName}-selectedField`,
                itemData.selectedField,
                "userdispensed",
                itemData
              )
            }
            else {
              updatedItem.selectedField = value > 0 ? itemData.selectedField ? itemData.selectedField : itemData.dosages[0] : ""
              handleCellChange(
                orders[0]._id,
                `${itemName}-selectedField`,
                value > 0 ? itemData.selectedField ? itemData.selectedField : itemData.dosages[0] : "",
                "userdispensed",
                itemData
              );
            }
          }
          if (key === "selectedField" && (!itemData.isNew || !itemData.custom)) {
            updatedItem.quantity = value ? itemData.quantity > 0 ? itemData.quantity : 1 : 0;
            updatedItem.checked = value ? true : false;
            if (itemData?.editItemName !== false || (itemData.custom && itemData?.custom !== true)) {
              handleCellChange(
                orders[0]._id,
                `${itemName}-quantity`,
                updatedItem.quantity,
                "userdispensed",
                itemData
              );
            }
          }
          return updatedItem;
        }
        return item;
      });
    }

    setTimeout(() => {
      if (type === "Procedures") {
        handleCellChange(
          orders[0]?._id,
          `${itemName}-${key}`,
          value,
          "userProcedures",
          itemData,
          orders[0]?.review
        );
      } else if (type === "Dispensed") {
        handleCellChange(
          orders[0]?._id,
          `${itemName}-${key}`,
          value,
          "userdispensed",
          itemData
        );
      }
    }, 1000);
  };
  const searchLPDItem = async (type, name) => {
    const itemType= type==='dispensed'? 'dispense': type==='labs'?'lab':type
    try {
      const response = await axios.post("/search-lpd-item", { type:itemType, name, orderId:orders[0]._id }, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      
      if (!response.status=== 200) {
        throw new Error("Item not found or an error occurred");
      }
  
      debugger;
      const data = await response.data;
      
      return data.item; 
    } catch (error) {
      console.error("Error searching item:", error);
      throw error; 
    }
  };

  const onShortcutMenuClick = async (selectedShortcut) => {
    const editor = quillRef.current.getEditor();
    const selectedTemplate = shortcuts.find(
      (s) => s.shortcut === selectedShortcut
    );

    let itemDetail = {};
    if (selectedTemplate?.type !== 'custom') {
      console.log('Template details:', selectedTemplate);

      if (!selectedTemplate?.type || !selectedTemplate?.name) {
        console.error('Invalid template type or name:', selectedTemplate);
      } else {
        try {
          itemDetail = await searchLPDItem(selectedTemplate?.type, selectedTemplate.name);
          console.log('Item detail:', itemDetail);
        } catch (error) {
          console.error('Error fetching item detail:', error);
        }
      }
    }

    if (itemDetail) {
      if (selectedTemplate.type === 'procedure') {
        handleChange(
          itemDetail,
          itemDetail.procedure_name,
          "selectedField",
          itemDetail.dosages[0],
          "Procedures"
        )
        handleChange(
          itemDetail,
          itemDetail.procedure_name,
          "checked",
          true,
          "Procedures"
        );
      } else if (selectedTemplate.type === 'dispense') {
        handleChange(
          itemDetail,
          itemDetail.name,
          "selectedField",
          itemDetail.dosages[0],
          "Dispensed"
        )
        handleChange(
          itemDetail,
          itemDetail.name,
          "quantity",
           1,
           "Dispensed"
        )
      } else if (selectedTemplate.type === 'lab'){
        handleLabsCheckboxChange(itemDetail._id,true)
      }
    }

    if (selectedTemplate && editor) {
      let templateContent = selectedTemplate.templates[0].content;
      let itemData;

      if (selectedTemplate.type !== "custom") {
        const itemType = selectedTemplate.type === 'dispense'? 'dispensed' : selectedTemplate.type
        itemData = findItemData(itemType, selectedTemplate.name);
        templateContent = applyTemplate(
          selectedTemplate.type,
          itemData,
          templateContent
        );
      }
      
      const dotShortcutRegex = /\.\w+$/;
      const match = editor
        .getText()
        .substring(0, cursorPosition)
        .match(dotShortcutRegex);
      if (match && match.index !== undefined) {
        const startIndex = match.index;
        editor.deleteText(startIndex, match[0]?.length); // Remove the ".text"
        editor.clipboard.dangerouslyPasteHTML(startIndex, templateContent); // Insert the template
        setPlanNote(editor.root.innerHTML); // Update the editor content in the state
      }
    }

    setShowShortcutsMenu(false);
  };


  const formatVitalString = (vital) => {
    const vitalParts = [];
    if (vital.HR !== 0) vitalParts.push(`</br>HR: ${vital.HR}`);
    if (vital.BP !== "") vitalParts.push(`</br>BP: ${vital.BP}`);
    if (vital.T !== 0) vitalParts.push(`</br>T: ${vital.T}`);
    if (vital.W !== 0) vitalParts.push(`</br>W: ${vital.W}`);
    if (vital.RR !== 0) vitalParts.push(`</br>RR: ${vital.RR}`);
    if (vital.SPO2 !== 0) vitalParts.push(`</br>SPO2: ${vital.SPO2}`);

    return vitalParts?.length > 0
      ? `Time: ${vital.time}, ${vitalParts.join(", ")} </br></br>`
      : null;
  };
  const parseVitalInfo = (vitalString) => {
    const vitalInfo = {};
    const regex =
      /Time: (.*), BP: (.*), HR: (.*), RR: (.*), SPO2: (.*), T: (.*), W: (.*)/;
    const matches = vitalString.match(regex);

    if (matches) {
      vitalInfo.time = matches[1];
      vitalInfo.BP = matches[2] || "";
      vitalInfo.HR = matches[3] !== "null" ? matches[3] : "0";
      vitalInfo.RR = matches[4] !== "null" ? matches[4] : "0";
      vitalInfo.SPO2 = matches[5] !== "null" ? matches[5] : "0";
      vitalInfo.T = matches[6] !== "null" ? matches[6] : "0";
      vitalInfo.W = matches[7] !== "null" ? matches[7] : "0";
    }

    return vitalInfo;
  };

  const formatVitalStringItemsMenu = (vital) => {
    // Format the string to match the format of `selectedItem`
    return `Time: ${vital.time}, BP: ${vital.BP}, HR: ${vital.HR}, RR: ${vital.RR}, SPO2: ${vital.SPO2}, T: ${vital.T}, W: ${vital.W}`;
  };

  const onItemsMenuClick = async (selectedItem) => {
    const editor = quillRef.current.getEditor();
    let templateContent = "";
    
    let itemDetail = {};
    if (selectedCategory !== 'custom') {
      // console.log('Template details:', selectedTemplate);

      if (!selectedCategory || !selectedItem) {
        console.error('Invalid template type or name:', selectedCategory, selectedItem);
      } else {
        try {
          itemDetail = await searchLPDItem(selectedCategory, selectedItem);
          console.log('Item detail:', itemDetail);
        } catch (error) {
          console.error('Error fetching item detail:', error);
        }
      }
    }

    if (itemDetail) {
      if (selectedCategory === 'procedure') {
        handleChange(
          itemDetail,
          itemDetail.procedure_name,
          "selectedField",
          itemDetail.dosages[0],
          "Procedures"
        )
        handleChange(
          itemDetail,
          itemDetail.procedure_name,
          "checked",
          true,
          "Procedures"
        );
      } else if (selectedCategory === 'dispensed') {
        handleChange(
          itemDetail,
          itemDetail.name,
          "selectedField",
          itemDetail.dosages[0],
          "Dispensed"
        )
        handleChange(
          itemDetail,
          itemDetail.name,
          "quantity",
           1,
           "Dispensed"
        )
      } else if (selectedCategory === 'labs'){
        handleLabsCheckboxChange(itemDetail._id,true)
      }
    }
    if (selectedCategory === "custom") {
      templateContent = await fetchSpecificTemplate("custom", selectedItem);
      const currentCursorPos = cursorPosition;
      editor.deleteText(currentCursorPos - 2, 2); // Remove ".."
      editor.clipboard.dangerouslyPasteHTML(
        currentCursorPos - 2,
        `<h3>${templateContent}</h3>` || ""
      );
    } else {
      setPlanNote(editor.root.innerHTML);

      switch (selectedCategory) {
        case "procedure":
          const procedure = orders[0].userProcedures.find(
            (proc) => proc.procedure_name === selectedItem
          );
          templateContent = await fetchSpecificTemplate(
            "procedure",
            procedure.procedure_name
          );
          break;
        case "dispensed":
          const dispensedItem = orders[0].userdispensed.find(
            (item) => item.name === selectedItem
          );
          templateContent = await fetchSpecificTemplate(
            "dispense",
            dispensedItem.name
          );
          break;
        case "labs":
          const labTest = orders[0].userLabs.find(
            (lab) => lab.test_name === selectedItem
          );
          templateContent = await fetchSpecificTemplate(
            "lab",
            labTest.test_name
          );
          break;
        case "vitals":
          const vitalInfo = parseVitalInfo(selectedItem);
          const selectedVital = orders[0].vitals.find((vital) => {
            return (
              vital.time === vitalInfo.time &&
              vital.BP === vitalInfo.BP &&
              (vital.HR !== null ? vital.HR.toString() : "0") ===
              vitalInfo.HR &&
              (vital.RR !== null ? vital.RR.toString() : "0") ===
              vitalInfo.RR &&
              (vital.SPO2 !== null ? vital.SPO2.toString() : "0") ===
              vitalInfo.SPO2 &&
              (vital.T !== null ? vital.T.toString() : "0") === vitalInfo.T &&
              (vital.W !== null ? vital.W.toString() : "0") === vitalInfo.W
            );
          });
          if (selectedVital) {
            templateContent = await fetchSpecificTemplate(
              "vital",
              formatVitalStringItemsMenu(selectedVital)
            );
          }
          break;
        default:
          break;
      }

      if (templateContent) {
        let itemData;
        debugger
        switch (selectedCategory) {
          case "procedure":
            itemData = orders[0].userProcedures.find(
              (proc) => proc.procedure_name === selectedItem
            );
            break;
          case "dispensed":
            itemData = orders[0].userdispensed.find(
              (item) => item.name === selectedItem
            );
            break;
          case "labs":
            itemData = orders[0].userLabs.find(
              (lab) => lab.test_name === selectedItem
            );
            break;
          case "vitals":
            const vitalInfo = parseVitalInfo(selectedItem);
            itemData = orders[0].vitals.find((vital) => {
              return (
                vital.time === vitalInfo.time &&
                vital.BP === vitalInfo.BP &&
                (vital.HR !== null ? vital.HR.toString() : "0") ===
                vitalInfo.HR &&
                (vital.RR !== null ? vital.RR.toString() : "0") ===
                vitalInfo.RR &&
                (vital.SPO2 !== null ? vital.SPO2.toString() : "0") ===
                vitalInfo.SPO2 &&
                (vital.T !== null ? vital.T.toString() : "0") === vitalInfo.T &&
                (vital.W !== null ? vital.W.toString() : "0") === vitalInfo.W
              );
            });
            break;
          default:
            itemData = {};
            break;
        }
        const statement = applyTemplate(
          selectedCategory,
          itemData,
          templateContent
        );
        editor.deleteText(cursorPosition - 2, 2); // Remove ".."
        editor.clipboard.dangerouslyPasteHTML(cursorPosition - 2, statement); // Insert the statement
        setPlanNote(editor.root.innerHTML); // Update the editor content in the state
      }
    }
    setSearchText("");
    setPlanNote(editor.root.innerHTML);
    setShowItemsMenu(false);
  };


  const updateDropdownPosition = () => {
    const editor = quillRef.current.getEditor();
    const range = editor.getSelection(false);
    if (range && range?.length === 0) {
      const editorContainer = editor.container.getBoundingClientRect();
      const bounds = editor.getBounds(range.index);

      // Vertical adjustment: Add a small offset to align with ".."
      const scrollTop = editor.scrollingContainer.scrollTop;
      const topOffset = bounds.top - scrollTop + 150; // Adjust '5' as needed for alignment

      // Horizontal position remains the same
      const scrollLeft = editorContainer.left;
      const leftOffset = bounds.left + scrollLeft - 80;

      setDropdownPosition({
        top: topOffset,
        left: leftOffset,
      });
    }
  };

  const findItemData = (type, name) => {
    let itemData;

    switch (type) {
      case "procedure":
        itemData = orders[0].userProcedures.find(
          (proc) => proc.procedure_name === name
        );
        break;
      case "dispensed":
        itemData = orders[0].userdispensed.find(
          (item) => item.name === name
        );
        break;
      case "labs":
        itemData = orders[0].userLabs.find(
          (lab) => lab.test_name === name
        );
        break;
      case "vitals":
        itemData = orders[0].vitals.find((vital) => {
          // The condition here depends on how you identify a unique vital. You might need to adjust this.
          return formatVitalString(vital).includes(name);
        });
        break;
      default:
        itemData = {};
        break;
    }
    return itemData;
  };
  const handleShortcutEnterPress = async (query) => {
    let response;
    try {
      // Try to find an exact match
      response = await axios.get(
        `/api/templates/shortcut/exact-search/${query}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
    } catch (error) {
      // If exact match fails (404), try finding the closest match
      if (error.response && error.response.status === 404) {
        try {
          response = await axios.get(
            `/api/templates/shortcut/closest-search/${query}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            }
          );
        } catch (closestError) {
          console.error("Error fetching closest match shortcut:", closestError);
          return;
        }
      } else {
        console.error("Error fetching exact match shortcut:", error);
        return;
      }
    }

    // Continue with processing the response
    const editor = quillRef.current.getEditor();
    const dotShortcutRegex = /\.\w+$/;
    const match = editor
      .getText()
      .substring(0, cursorPosition)
      .match(dotShortcutRegex);

    if (response.data && match) {
      let templateContent = response.data.templates[0].content;
      let itemData;

      if (response.data.type !== "custom") {
        itemData = findItemData(response.data.type, response.data.name);
        templateContent = applyTemplate(
          response.data.type,
          itemData,
          templateContent
        );
      }

      const startIndex = cursorPosition - match[0]?.length;
      editor.deleteText(startIndex, match[0]?.length); // Remove the ".text"
      editor.clipboard.dangerouslyPasteHTML(startIndex, templateContent); // Insert the template
      setPlanNote(editor.root.innerHTML); // Update the editor content in the state
    }
  };
  const onChangePlanNote = (content, delta, source, editor) => {
    setPlanNote(content);

    const currentText = editor.getText();
    const cursorPos = editor.getSelection()?.index;

    // Check for '..' functionality
    if (
      currentText &&
      cursorPos &&
      currentText.substring(cursorPos - 2, cursorPos) === ".."
    ) {
      setShowMenu(true);
      setShowShortcutsMenu(false);
      setCursorPosition(cursorPos);
      updateDropdownPosition();
    } else {
      setShowMenu(false);
    }

    // Regular expression to find '.word'
    const dotShortcutRegex = /\.\w+$/;

    const matchDotShortcut = currentText
      .substring(0, cursorPos)
      .match(dotShortcutRegex);

    if (matchDotShortcut && source === "user") {
      if (delta.ops?.length === 2 && delta.ops[1].insert === "\n") {
        // User pressed Enter after '.text'
        const query = matchDotShortcut[0].substring(1); // Remove the dot and get the query
        handleShortcutEnterPress(query); // Handle the Enter key press for shortcut
        setShowShortcutsMenu(false);
        return; // Prevent further processing
      }

      // Handle single dot functionality for other cases
      const query = matchDotShortcut[0].substring(1); // Remove the dot and get the query
      fetchShortcuts(query); // Fetch shortcuts with the query
      setShowShortcutsMenu(true);
      setCursorPosition(cursorPos);
      updateDropdownPosition();
    } else {
      setShowShortcutsMenu(false);
    }
  };


  const formatVitals = () => {
    const formattedVitals = orders[0].vitals.map((vital, index) => {
      return `Time: ${vital.time}, BP: ${vital.BP}, HR: ${vital.HR}, RR: ${vital.RR}, SPO2: ${vital.SPO2}, T: ${vital.T}, W: ${vital.W}`;
    });
    return formattedVitals;
  };

  const onCustomTemplateClick = async (templateName) => {
    const templateContent = await fetchSpecificTemplate("custom", templateName);
    const editor = quillRef.current.getEditor();
    const currentCursorPos = cursorPosition;
    editor.deleteText(currentCursorPos - 2, 2); // Remove ".."
    editor.clipboard.dangerouslyPasteHTML(
      currentCursorPos - 2,
      templateContent || ""
    );
    setPlanNote(editor.root.innerHTML);
    setShowItemsMenu(false);
  };
  const onMenuClick = (e) => {
    const selectedOption = e.key;
    setSelectedCategory(selectedOption);

    if (selectedOption === "custom") {
      setShowItemsMenu(true); // Show items menu for custom templates
      setShowMenu(false);
    } else {
      let items = [];

      switch (selectedOption) {
        case "procedure":
          items = orders[0].userProcedures.map(
            (item) => item.procedure_name
          );
          break;
        case "dispensed":
          items = orders[0].userdispensed.map((item) => item.name);
          break;
        case "labs":
          items = orders[0].userLabs.map((item) => item.test_name);
          break;
        case "vitals":
          items = formatVitals();
          break;
        default:
          break;
      }

      setItemsForSelection(items);
      setShowItemsMenu(true);
      setShowMenu(false);
    }
  };
  const menu = (
    <Menu onClick={onMenuClick}>
      <Menu.Item key="procedure">Procedure</Menu.Item>
      <Menu.Item key="dispensed">Dispensed</Menu.Item>
      <Menu.Item key="labs">Labs</Menu.Item>
    </Menu>
  );

  const customTemplatesMenu = (
    <Menu>
      {customTemplates.map((template, index) => (
        <Menu.Item
          key={index}
          onClick={() => onCustomTemplateClick(template.name)}
        >
          {template.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const getFilteredItemsForSelection = () => {
    if (!searchText) return itemsForSelection;
    return itemsForSelection.filter((item) =>
      item.toLowerCase().includes(searchText.toLowerCase())
    );
  };
  const itemsMenu = (
    <Menu>
      <Menu.Item key="search" disabled>
        <input
          type="text"
          placeholder="Search..."
          value={searchText}
          onChange={handleSearchChange}
          style={{ width: "100%", padding: "5px", color: "black" }}
        />
      </Menu.Item>
      <div
        style={{
          maxHeight: "200px",
          overflowY: "auto",
          scrollbarColor: "blue lightblue",
        }}
      >
        {getFilteredItemsForSelection().map((item, index) => {
          return (
            <Menu.Item key={index} onClick={() => onItemsMenuClick(item)}>
              {item}
            </Menu.Item>
          );
        })}
      </div>
    </Menu>
  );

  function splitByLastDash(columnName) {
    // Find the index of the last dash
    const lastIndex = columnName.lastIndexOf("-");

    // If there's no dash, or the dash is the first character, return an error or handle it as needed
    if (lastIndex <= 0) {
      console.error(
        "No dash found, or the dash is at the start of the string"
      );
      return columnName;
    }

    // Split the string into two parts
    const itemName = columnName.substring(0, lastIndex);
    const field = columnName.substring(lastIndex + 1);

    return [itemName, field];
  }
 const itemsSortingFunction = (items, itemType) => {
      // Define the dynamic keys for each item type
      let filledCondition, emptyCondition;

      switch (itemType) {
        case "procedures":
          filledCondition = (item) =>
            (item.selectedField && item.selectedField !== "Empty") ||
            item.checked;
          emptyCondition = (item) =>
            (!item.selectedField || item.selectedField === "Empty") &&
            !item.checked;
          break;

        case "labs":
          filledCondition = (item) => item.checked;
          emptyCondition = (item) => !item.checked;
          break;

        case "dispensed":
          filledCondition = (item) =>
            (item.selectedField && item.selectedField !== "Empty") ||
            item.quantity > 0;
          emptyCondition = (item) =>
            (!item.selectedField || item.selectedField === "Empty") &&
            item.quantity === 0;
          break;

        case "supplements":
          filledCondition = (item) =>
            (item.selectedField && item.selectedField !== "Empty") ||
            item.checked;
          emptyCondition = (item) =>
            (!item.selectedField || item.selectedField === "Empty") &&
            !item.checked;
          break;

        default:
          return items; // If the item type is not recognized, return the original array.
      }

      const filledItems = items
        .filter(filledCondition)
        .sort((a, b) => a.originalOrder - b.originalOrder);

      const unfilledItems = items
        .filter(emptyCondition)
        .sort((a, b) => a.originalOrder - b.originalOrder);

      return [...filledItems, ...unfilledItems];
    };
  const containerRef = useRef(null);
  const adjustedLeft = Math.min(dropdownPosition.left, window.innerWidth - '400px'); // Ensures it stays within the screen bounds

  const handleCellChange = (
    patientId,
    columnName,
    newValue,
    category,
    itemData,
    review
  ) => {
    let payload;
    if (category === "vitals") {
      // Make sure newValue is an array and directly assign it to updates
      payload = {
        category: category,
        updates: newValue, // Ensure this is correctly formatted as an array of vitals
      };
    } else if (category === "patientLabs") {
      payload = {
        category: category,
        updates: {
          checked: newValue,
          type: "item_number",
          value: itemData.item_number,
        },
      };
    } else if (category === "newpatientLab") {
      payload = {
        category: 'patientLabs',
        newData: {
          checked: newValue,
          isNew: true,
          test_name: itemData?.test_name,
        },
      };
    } else {
      // Handling for other categories remains the same
      const identifierKey =
        category === "userProcedures" ? "procedure_name" : "name";
      let [itemName, field] = splitByLastDash(columnName);

      if (category === "patientInfo") {
        field = columnName;
      }

      payload = {
        category: category,
        identifier: {
          key: identifierKey,
          value: itemName,
        },
        updates: {
          [field]: newValue,
        },
        newData: itemData,
      };
    }

    // Send the update request using axios
    axios
      .put(
        `/patient/order/update/${patientId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      )
      .then((response) => {
        dataReset()
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
    <div className=" w-full h-[80%]">
      <div className='flex flex-col'>
        <div className='flex w-full justify-between'>
          <div className='flex items-center gap-3 '>
            <h1 className="text-2xl font-bold whitespace-nowrap mb-0">SOAP Note {customName && !editCustomName && `: ${customName}`}</h1>
            {noteId && editCustomName && <Input value={customName} onChange={(event) => setCustomName(event.target.value)} onBlur={handleUpdateCustomName} />}
            {!editCustomName && <IconButton onClick={() => setEditCustomName(true)}><EditFilled /></IconButton>}
          </div>
          {!orders.length > 0 && (
            <div>
              <Button
                className={`bg-[#111828] !py-5 !px-4 text-white !rounded-md !border-none !cursor-pointer !font-normal !text-sm !shadow-sm !transition-all !ease-in-out hover:!bg-[#3d3d3d] hover:!text-white hover:!border-none`}
                icon={<PlusOutlined />}
                iconPosition="start"
                onClick={() => createNewTreatment("Order")}
                loading={newOrderLoading}
              >
                New Order
              </Button>
            </div>
          )}

        </div>
        <div className='relative flex w-full items-center justify-between'>
          {
            <h1 className="text-lg font-semibold my-3">
              Chief Concern:
              {chiefConcern
                ? `${chiefConcernData.code} - ${chiefConcernData.name}`
                : storedChiefConcern
                  ? `${storedChiefConcern.code} - ${storedChiefConcern.name}`
                  : ""}
            </h1>
          }
          <div className='flex items-center gap-3'>
            <MessageIcon patient={patientProfile} />
            <Tooltip title="Print">
              <Print
                className="cursor-pointer"
                onClick={() => setPrintModalVisible(true)}
                style={{ fontSize: 28, cursor: 'pointer', marginRight: 5 }}
              />
            </Tooltip>
          </div>
        </div>

        <div className='grid grid-cols-2 gap-4 '>
          <div className="border h-80 bg-white p-4">
            <h2 className="font-bold text-lg mb-2">Subjective</h2>
            <div className='flex gap-3'>
              <h2 className="font-medium text-base mb-2">Subjective Note</h2>
              <a className="font-medium mb-2 text-blue-400 cursor-pointer">View Template</a>
              <a className="font-medium mb-2 text-blue-400 cursor-pointer">Import Past Encounter</a>
            </div>
            <ReactQuill
              theme="snow"
              value={subjective}
              onChange={setSubjective}
              onBlur={() => handleBlur()}
              style={{ height: 'calc(100% - 110px)' }}
            />
          </div>
          <div className="border h-80 bg-white p-4">
            <div className='w-full flex justify-between items-center'>
              <h2 className="font-bold text-lg mb-2">Objective</h2>
              {orders.length > 0 && <LPDVIconButtons
                treatment={orders[0]}
                setShowModal={setShowModal}
                setModalType={setModalType}
                setActiveTreatmentId={setActiveTreatmentId}
                setActiveTreatment={setActiveTreatment}
                fromSoapNote={true} iconButtons={["V"]}
              />
              }
            </div>
            <div className='flex gap-3'>
              <h2 className="font-medium text-base mb-2">Objective Note</h2>
              <a className="font-medium mb-2 text-blue-400 cursor-pointer">View Template</a>
              <a className="font-medium mb-2 text-blue-400 cursor-pointer">Import Past Encounter</a>
            </div>
            <ReactQuill
              theme="snow"
              value={objective}
              onChange={setObjective}
              onBlur={() => handleBlur()}
              style={{ height: 'calc(100% - 110px)' }}
            />
          </div>
        </div>
        <div className="border bg-white p-4">
          <div className={`relative grid gap-4 grid-cols-2`}>
            {/* Assessment and Plan (Always in the first row with two columns) */}
            <div className="border p-4 bg-white h-80 col-span-1">
              <h2 className="font-bold text-lg mb-2 whitespace-nowrap">Assessment</h2>
              <div className="flex gap-3">
                <h2 className="font-medium text-base mb-2 whitespace-nowrap">Assessment Note</h2>
                <div className="flex gap-2">
                  <p className="font-medium whitespace-nowrap mb-2 text-blue-400 cursor-pointer">
                    View Template
                  </p>
                  <p className="font-medium whitespace-nowrap mb-2 text-blue-400 cursor-pointer">
                    Import Past Encounter
                  </p>
                  <p
                    className="font-medium whitespace-nowrap mb-2 text-blue-400 cursor-pointer"
                    onClick={() => scrollToDiagnosisDiv()}
                  >
                    View Diagnosis
                  </p>
                  <p className="font-medium whitespace-nowrap mb-2 text-blue-400 cursor-pointer">
                    View S/I/As
                  </p>
                </div>
              </div>
              <ReactQuill
                theme="snow"
                value={assessment}
                onChange={setAssessment}
                onBlur={() => handleBlur()}
                style={{ height: 'calc(100% - 110px)' }}
              />
            </div>

            {/* Plan Note (Placed next to Assessment in the same row) */}

            <div className="relative border p-4 bg-white h-80 col-span-1">
              <div className="w-full flex justify-between items-center">
                <h2 className="font-bold text-lg mb-2">Plan</h2>
                {orders.length > 0 && <LPDVIconButtons
                  treatment={orders[0]}
                  setShowModal={setShowModal}
                  setModalType={setModalType}
                  setActiveTreatmentId={setActiveTreatmentId}
                  fromSoapNote={true}
                  setActiveTreatment={setActiveTreatment}
                  iconButtons={['L', 'P', 'D']}
                />}
              </div>
              <div className="flex gap-3 items-center mb-2">
                <h2 className="font-medium text-base">Plan Note</h2>
                <a className="font-medium text-blue-400 cursor-pointer">View Template</a>
                <a className="font-medium text-blue-400 cursor-pointer">Import Past Encounter</a>
                {orders.length > 0 && <Button onClick={resetPlanNotes}>Reset</Button>}
              </div>
              <ReactQuill
                theme="snow"
                value={planNote}
                onChange={onChangePlanNote}
                ref={quillRef}
                onBlur={() => handleBlur()}
                style={{ height: 'calc(100% - 110px)' }}
              />
              {showMenu && (
                <div
                  style={{
                    position: "absolute",
                    top: dropdownPosition.top,
                    left: adjustedLeft,
                    zIndex: 1000,
                  }}
                >
                  <Dropdown overlay={menu} visible={true} trigger={["click"]} placement='bottomLeft'>
                    <span></span>
                  </Dropdown>
                </div>
              )}
              {showItemsMenu && selectedCategory !== "custom" && (
                <div
                  style={{
                    position: "absolute",
                    top: dropdownPosition.top,
                    left: adjustedLeft,
                    zIndex: 1000,
                  }}
                >
                  <Dropdown
                    overlay={itemsMenu}
                    visible={true}
                    trigger={["click"]}
                    placement='bottomLeft'
                  >
                    <span></span>
                  </Dropdown>
                </div>
              )}
              {showItemsMenu && selectedCategory === "custom" && (
                <div
                  style={{
                    position: "absolute",
                    top: dropdownPosition.top,
                    left: adjustedLeft,
                    zIndex: 1000,
                  }}
                >
                  <Dropdown
                    overlay={customTemplatesMenu}
                    visible={true}
                    trigger={["click"]}
                    placement='bottomLeft'
                  >
                    <span></span>
                  </Dropdown>
                </div>
              )}
              {showShortcutsMenu && (
                <div
                  style={{
                    position: "absolute",
                    top: dropdownPosition.top,
                    left: adjustedLeft,
                    zIndex: 1000,
                  }}
                >
                  <Dropdown
                    overlay={shortcutsMenu}
                    visible={true}
                    trigger={["click"]}
                    placement='bottomLeft'
                  >
                    <span></span>
                  </Dropdown>
                </div>
              )}
            </div>
          </div>

        </div>



      </div>

      <div>
        <PrintModal isOpen={printModalVisible} onClose={closeModal} selectedPatient={patientProfile} soapNotes={soapNotes} formSoapNote={true} />
      </div>
      
        
      
    </div>
    <LPDPopup
    type={modalType}
    patientName={patientProfile["Full Name"]}
    // setAllTreatments={setOrders}
    activeTreatment={activeTreatment}
    showModal={showModal}
    onCloseModal={()=>{
      setShowModal(false)
      setModalType(null)
      setActiveTreatment({})
      dataReset()
    }}
    noteId={noteId}
    handleCreateSoapNote={handleBlur}
    setsoapNoteCreatedFromLPD={setsoapNoteCreatedFromLPD}
    setModalType={setModalType}
    handleCellChange={handleCellChange}
    ref={{containerRef,soapNoteCreatedRef}}
  // from="emr"
  />
  </>
  );
};

export default SOAPNotesSection;
