import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Checkbox,
  Modal,
  Form,
  Select,
  message,
  Button,
  Input,
  InputNumber,
} from "antd";
import { DataContext } from "../../context/DataContext";
import axios from "axios";

const { Option } = Select;

function AddCategory({ fetchCategories }) {
  const [isAddCategoryOpen, setIsAddCategoryOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();

  const showAddCategoryModal = () => {
    setIsAddCategoryOpen(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsAddCategoryOpen(false);
  };

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        "/add-category",
        values
      );
      message.success("Category added successfully");
      fetchCategories(); // Refresh categories list
      setIsAddCategoryOpen(false);
      form.resetFields();
    } catch (error) {
      message.error("Failed to add category");
      console.error("Error adding category:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Button
        className="mb-2 ml-2 float-right"
        type="primary"
        onClick={showAddCategoryModal}
        style={{ backgroundColor: "#1890ff", borderColor: "#1890ff" }}
      >
        Add Category
      </Button>
      <Modal
        title="Add Category"
        visible={isAddCategoryOpen}
        confirmLoading={isSubmitting}
        onCancel={handleCancel}
        footer={[
          <Button
            key="back"
            style={{
              color: "white",
              backgroundColor: "#1976d2",
              borderRadius: "5px",
              fontWeight: "bold",
            }}
            className="ml-3 py-1 px-10 w-full md:w-auto"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            style={{
              color: "white",
              backgroundColor: "#1976d2",
              borderRadius: "5px",
              fontWeight: "bold",
            }}
            className="ml-3 py-1 px-10 w-full md:w-auto"
            type="primary"
            loading={isSubmitting}
            form="categoryForm"
            htmlType="submit"
          >
            {isSubmitting ? "Adding..." : "Add"}
          </Button>,
        ]}
      >
        <Form id="categoryForm" form={form} onFinish={handleSubmit}>
          <Form.Item
            name="name"
            rules={[
              { required: true, message: "Please enter a category name" },
            ]}
          >
            <Input placeholder="Category Name" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

function AddSubCategory({ selectedCategory, categories, fetchSubCategories }) {
  const [isAddSubCategoryOpen, setIsAddSubCategoryOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();

  const showAddSubCategoryModal = () => {
    setIsAddSubCategoryOpen(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsAddSubCategoryOpen(false);
  };

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        "/add-subcategory",
        values
      );
      message.success("Subcategory added successfully");
      fetchSubCategories(values.category); // Refresh subcategories list
      setIsAddSubCategoryOpen(false);
      form.resetFields();
    } catch (error) {
      message.error("Failed to add subcategory");
      console.error("Error adding subcategory:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Button
        className="mb-2 ml-2 float-right"
        type="primary"
        onClick={showAddSubCategoryModal}
        style={{ backgroundColor: "#52c41a", borderColor: "#52c41a" }}
      >
        Add Subcategory
      </Button>
      <Modal
        title="Add Subcategory"
        visible={isAddSubCategoryOpen}
        confirmLoading={isSubmitting}
        onCancel={handleCancel}
        footer={[
          <Button
            style={{
              color: "white",
              backgroundColor: "#1976d2",
              borderRadius: "5px",
              fontWeight: "bold",
            }}
            className="py-1 px-10 w-full md:w-auto"
            key="back"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isSubmitting}
            style={{
              color: "white",
              backgroundColor: "#1976d2",
              borderRadius: "5px",
              fontWeight: "bold",
            }}
            className="ml-3 py-1 px-10 w-full md:w-auto"
            form="subcategoryForm"
            htmlType="submit"
          >
            {isSubmitting ? "Adding..." : "Add"}
          </Button>,
        ]}
      >
        <Form
          id="subcategoryForm"
          initialValues={{ category: selectedCategory }}
          form={form}
          onFinish={handleSubmit}
        >
          <Form.Item
            name="category"
            rules={[{ required: true, message: "Please select a category" }]}
          >
            <Select
              defaultValue={selectedCategory}
              placeholder="Select Category"
            >
              {categories.map((category) => {
                if (category._id === selectedCategory) {
                  return (
                    <Option key={category._id} value={category._id}>
                      {category.name}
                    </Option>
                  );
                } else {
                  return null;
                }
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="name"
            rules={[
              { required: true, message: "Please enter a subcategory name" },
            ]}
          >
            <Input placeholder="Subcategory Name" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

function DeleteSubcategoryButton({ categoryId, subcategoryId, isEnabled, onSuccess, onDeleted }) {
  const handleDelete = async () => {
    try {
      await axios.delete(`/delete-subcategory/${categoryId}/${subcategoryId}`);
      message.success('Subcategory deleted successfully');
      if (onSuccess) {
        onSuccess(categoryId);  // Call the onSuccess function passing the categoryId
      }
      if (onDeleted) {
        onDeleted(); // Reset subcategory selection
      }
    } catch (error) {
      message.error('Failed to delete subcategory');
      console.error(error);
    }
  };

  return (
    <button
      style={{
        color: "white",
        backgroundColor: "red",
        borderRadius: "5px",
        fontWeight: "bold",
      }}
      className="ml-3 py-1 px-10 w-full md:w-auto"
      onClick={handleDelete}
      disabled={!isEnabled}
    >
      Delete
    </button>
  );
}

function ManageTreatmentTemplates({ showToast, from, contextMenu }) {
  const {
    data: { labs = [], dispensed = [], procedures = [], supplements = [] },
  } = useContext(DataContext);
  const [dosages, setDosages] = useState({});
  const [quantities, setQuantities] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [dosageQuantities, setDosageQuantities] = useState({});
  const [selectedDispensed, setSelectedDispensed] = useState([]);
  const [selectedProcedures, setSelectedProcedures] = useState([]);
  const [selectedSupplements, setSelectedSupplements] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [form] = Form.useForm();
  const [isAddTemplateOpen, setIsAddTemplateOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const inputRefs = useRef({});
  const dispensedInputRefs = useRef({});

  const handleClickDispensed = (dispensedId, e) => {
    e.stopPropagation();
    setTimeout(() => {
      if (dispensedInputRefs.current[dispensedId]) {
        dispensedInputRefs.current[dispensedId].focus();
      }
    }, 0);
  };

  const handleClick = (supplementId, e) => {
    e.stopPropagation();
    setTimeout(() => {
      if (inputRefs.current[supplementId]) {
        inputRefs.current[supplementId].focus();
      }
    }, 0);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get("/categories");
      setCategories(response.data.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchSubCategories = async (categoryId) => {
    try {
      const response = await axios.get(
        `/subcategories/${categoryId}`
      );
      setSubCategories(response.data.data);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };

  const showAddTemplateModal = () => {
    setIsAddTemplateOpen(true);
  };

  const getNameById = (list, id) => {
    const item = list.find((i) => i._id === id);
    return item
      ? item.name ||
      item.procedure_name ||
      item.supplement_name ||
      item.test_name
      : id;
  };

  const handleSubmit = async (values) => {
    setIsSubmitting(true);

    try {
      const selectedData = {
        category: values.category,
        subCategory: values.subCategory,
        labs: Array.isArray(values?.labs)
          ? values?.labs?.map((labId) => getNameById(labs, labId))
          : [],
        dispensed: Array.isArray(values?.dispensed)
          ? values?.dispensed?.map((dispensedId) => ({
            name: getNameById(dispensed, dispensedId),
            dosages: (dosages[dispensedId] || []).map((dosage) => ({
              dosage,
              quantity: dosageQuantities[dosage] || 1,
            })),
          }))
          : [],
        procedures: Array.isArray(values?.procedures)
          ? values?.procedures?.map((procedureId) => ({
            name: getNameById(procedures, procedureId),
            dosages: dosages[procedureId] || [],
          }))
          : [],
        supplements: Array.isArray(values?.supplements)
          ? values?.supplements?.map((supplementId) => ({
            name: getNameById(supplements, supplementId),
            quantity: quantities[supplementId] || 1,
            dosages: (dosages[supplementId] || []).map((dosage) => ({
              dosage,
              quantity: dosageQuantities[dosage] || 1,
            })),
          }))
          : [],
      };



      let response;
      if (selectedTemplate?._id) {
        // Update existing template
        if (from === "tdv") {
          const url = `/makeTemplate/${contextMenu?.data?.key}`;
          axios
            .post(
              url,
              {},
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "sessionToken"
                  )}`,
                },
              }
            )
            .then(async (resp) => {

              const sdata = {
                category: values.category,
                subCategory: values.subCategory,
                ...resp.data,
              };
              response = await axios.put(
                `/treatment-template-edit/${selectedTemplate._id}`,
                sdata,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem(
                      "sessionToken"
                    )}`,
                  },
                }
              );
              setIsAddTemplateOpen(false);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          response = await axios.put(
            `/treatment-template-edit/${selectedTemplate._id}`,
            selectedData,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            }
          );
        }
      } else {
        if (from === "tdv") {
          const url = `/makeTemplate/${contextMenu?.data?.key}`;
          axios
            .post(
              url,
              {},
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "sessionToken"
                  )}`,
                },
              }
            )
            .then(async (resp) => {

              const ndata = {
                category: values.category,
                subCategory: values.subCategory,
                ...resp.data,
              };
              response = await axios.post(
                "/treatment-template-add",
                ndata,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem(
                      "sessionToken"
                    )}`,
                  },
                }
              );
              setIsAddTemplateOpen(false);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          response = await axios.post(
            "/treatment-template-add",
            selectedData,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            }
          );
        }
      }


      if (response.status === 201 || response.status === 200) {
        showToast(
          `Template ${selectedTemplate?._id ? "updated" : "added"
          } successfully`,
          "success"
        );
        setIsAddTemplateOpen(false);
        form.resetFields();
      }
    } catch (error) {
      // message.error(
      //   `Failed to ${selectedTemplate?._id ? "update" : "add"} template`
      // );
      console.error(
        `HereError ${selectedTemplate?._id ? "updating" : "adding"} template:`,
        error
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsAddTemplateOpen(false);
  };

  const handleDosageChange = (item, dosage, checked) => {
    setDosages((prevDosages) => {
      const updatedDosages = { ...prevDosages };
      if (checked) {
        if (!updatedDosages[item._id]) {
          updatedDosages[item._id] = [];
        }
        updatedDosages[item._id].push(dosage);
        // Set default quantity to 1 for each dosage
        setDosageQuantities((prevDosageQuantities) => ({
          ...prevDosageQuantities,
          [dosage]: 1,
        }));
      } else {
        if (updatedDosages[item._id]) {
          updatedDosages[item._id] = updatedDosages[item._id].filter(
            (d) => d !== dosage
          );
        }
        // Remove the dosage quantity if it is deselected
        if (updatedDosages[item._id].length === 0) {
          setDosageQuantities((prevDosageQuantities) => {
            const { [dosage]: _, ...rest } = prevDosageQuantities;
            return rest;
          });
        }
      }
      return updatedDosages;
    });
  };

  const handleQuantityChange = (itemId, value) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [itemId]: value,
    }));
  };

  const handleDosageQuantityChange = (dosage, value) => {
    setDosageQuantities((prevDosageQuantities) => ({
      ...prevDosageQuantities,
      [dosage]: value,
    }));
  };

  const renderCheckboxes = (item, from = "") => (
    <div className="checkbox-container" onClick={(e) => e.stopPropagation()}>
      {item?.dosages?.map((dosage, index) => (
        <div key={index} onClick={(e) => handleClickDispensed(item._id, e)}>
          <Checkbox
            onChange={(e) => {
              handleDosageChange(item, dosage, e.target.checked);
            }}
            checked={dosages[item._id]?.includes(dosage)}
            value={dosage}
          >
            {dosage}
          </Checkbox>
          {dosages[item._id] &&
            dosages[item._id].includes(dosage) &&
            from !== "procedures" && (
              <div>
                Quantity:
                <InputNumber
                  className="mt-2 ml-2"
                  min={1}
                  defaultValue={1}
                  value={dosageQuantities[dosage] || 1}
                  onChange={(value) =>
                    handleDosageQuantityChange(dosage, value)
                  }
                  ref={(el) => (dispensedInputRefs.current[item._id] = el)}
                />
              </div>
            )}
        </div>
      ))}
    </div>
  );

  const handleCategoryChange = (value) => {
    fetchSubCategories(value);
    form.resetFields(["subCategory"]);
  };

  const handleSubCategoryChange = async (value) => {
    setSelectedTemplate(null);
    form.resetFields(["labs", "dispensed", "procedures", "supplements"]);
    try {
      const response = await axios.get(
        `/treatment-templates?subcategory=${value}`
      );
      const template = response.data.data.find(
        (template) => template.subCategory._id === value
      );
      if (template) {
        setSelectedTemplate(template);
        // Populate form with existing template data
        form.setFieldsValue({
          labs: template.labs,
          dispensed: template.dispensed.map((item) => item.name),
          procedures: template.procedures.map((procedure) => procedure.name),
          supplements: template.supplements.map(
            (supplement) => supplement.name
          ),
        });
        const dosages = {};
        const quantities = {};
        const dosageQuantities = {};
        template.dispensed.forEach((item) => {
          dosages[item.name] = item.dosages.map((d) => d.dosage);
          item.dosages.forEach((d) => {
            dosageQuantities[d.dosage] = d.quantity;
          });
        });
        template.supplements.forEach((item) => {
          dosages[item.name] = item.dosages.map((d) => d.dosage);
          item.dosages.forEach((d) => {
            dosageQuantities[d.dosage] = d.quantity;
          });
          quantities[item.name] = item.quantity;
        });
        setDosages(dosages);
        setQuantities(quantities);
        setDosageQuantities(dosageQuantities);
      }
      // else {
      //   setSelectedTemplate(null);
      //   setDosages({});
      //   setQuantities({});
      //   setDosageQuantities({});
      //   form.resetFields();
      // }
    } catch (error) {
      console.error("Error fetching template:", error);
    }
  };

  return (
    <>
      {from === "tdv" ? (
        <button
          onClick={() => {
            showAddTemplateModal();
          }}
        >
          Make Template
        </button>
      ) : (
        <button
          className="btn-color-BorderOnly py-2 px-5 w-auto h-fit"
          onClick={showAddTemplateModal}
        >
          Manage Treatment Templates
        </button>
      )}
      <Modal
        title="Manage Treatment Templates"
        visible={isAddTemplateOpen}
        confirmLoading={isSubmitting}
        onCancel={handleCancel}
        cancelButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        okButtonProps={{
          style: { color: "white", backgroundColor: "#1976d2" },
        }}
        footer={[
          <button
            style={{
              color: "white",
              backgroundColor: "#1976d2",
              borderRadius: "5px",
              fontWeight: "bold",
            }}
            className="py-1 px-10 w-full md:w-auto"
            key="back"
            onClick={handleCancel}
          >
            Cancel
          </button>,
          form.getFieldValue("category") && form.getFieldValue("subCategory") && (
            <DeleteSubcategoryButton
              key="delete"
              categoryId={form.getFieldValue("category")}
              subcategoryId={form.getFieldValue("subCategory")}
              isEnabled={!!(form.getFieldValue("category") && form.getFieldValue("subCategory"))}
              onSuccess={() => fetchSubCategories(form.getFieldValue("category"))}
              onDeleted={() => form.setFieldsValue({ subCategory: undefined })}
              style={{ marginLeft: 8 }}
            />
          ),
          <button
            style={{
              color: "white",
              backgroundColor: "#1976d2",
              borderRadius: "5px",
              fontWeight: "bold",
            }}
            className="ml-3 py-1 px-10 w-full md:w-auto"
            key="submit"
            type="primary"
            loading={isSubmitting}
            form="templateForm"
            htmlType="submit"
          >
            {isSubmitting
              ? "Loading..."
              : selectedTemplate?._id
                ? "Update"
                : "Add"}
          </button>,
        ]}
        width={'600px'}
      >
        <AddCategory fetchCategories={fetchCategories} />
        <Form id="templateForm" form={form} onFinish={handleSubmit}>
          <Form.Item
            name="category"
            rules={[{ required: true, message: "Please select a category" }]}
          >
            <Select
              placeholder="Select Category"
              onChange={handleCategoryChange}
            >
              {categories.map((category) => (
                <Option key={category._id} value={category._id}>
                  {category.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {form.getFieldValue("category") && (
            <>
              <AddSubCategory
                selectedCategory={form.getFieldValue("category")}
                categories={categories}
                fetchSubCategories={fetchSubCategories}
              />
              <Form.Item
                name="subCategory"
                rules={[
                  { required: true, message: "Please select a subcategory" },
                ]}
              >
                <Select
                  placeholder="Select Subcategory"
                  onChange={handleSubCategoryChange}
                >
                  {subCategories.map((subCategory) => (
                    <Option key={subCategory._id} value={subCategory._id}>
                      {subCategory.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
          {form.getFieldValue("subCategory") && from !== "tdv" && (
            <>
              <Form.Item name="labs" label="Labs">
                <Select
                  mode="multiple"
                  filterOption={(input, option) =>
                    option?.children
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                  }
                  placeholder="Select labs"
                  allowClear
                >
                  {labs?.map((lab) => (
                    <Option key={lab._id} value={lab._id}>
                      {lab.test_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="dispensed" label="Dispensed">
                <Select
                  mode="multiple"
                  filterOption={(input, option) =>
                    option?.children[0]
                      ?.toLowerCase()
                      ?.indexOf(input?.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    setSelectedDispensed(value);
                  }}
                  placeholder="Select dispensed items"
                  allowClear
                >
                  {dispensed?.map((item) => (
                    <Option key={item._id} value={item._id}>
                      {item.name}
                      <span onClick={(e) => e.stopPropagation()}>
                        {selectedDispensed.includes(item._id) &&
                          item.dosages &&
                          item.dosages.length > 0 &&
                          renderCheckboxes(item)}
                      </span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="procedures" label="Procedures">
                <Select
                  filterOption={(input, option) =>
                    option?.children[0]
                      ?.toLowerCase()
                      ?.indexOf(input?.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    setSelectedProcedures(value);
                  }}
                  mode="multiple"
                  placeholder="Select procedures"
                  allowClear
                >
                  {procedures?.map((procedure) => (
                    <Option key={procedure._id} value={procedure._id}>
                      {procedure.procedure_name}
                      <span onClick={(e) => e.stopPropagation()}>
                        {selectedProcedures.includes(procedure._id) &&
                          procedure.dosages &&
                          procedure.dosages.length > 0 &&
                          renderCheckboxes(procedure, "procedures")}
                      </span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="supplements" label="Supplements">
                <Select
                  mode="multiple"
                  filterOption={(input, option) =>
                    option?.children[0]
                      ?.toLowerCase()
                      ?.indexOf(input?.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    setSelectedSupplements(value);
                  }}
                  placeholder="Select supplements"
                  allowClear
                >
                  {supplements?.map((supplement) => (
                    <Option key={supplement._id} value={supplement._id}>
                      {supplement.supplement_name}
                      {selectedSupplements.includes(supplement._id) && (
                        <div
                          onClick={(e) => handleClick(supplement._id, e)}
                          style={{ marginTop: 8 }}
                        >
                          {supplement.dosages &&
                            supplement.dosages.length > 0 && (
                              <div>{renderCheckboxes(supplement)}</div>
                            )}
                          <div>
                            Quantity:
                            <InputNumber
                              className="ml-2"
                              min={1}
                              defaultValue={1}
                              value={quantities[supplement._id] || 1}
                              onChange={(value) =>
                                handleQuantityChange(supplement._id, value)
                              }
                              ref={(el) =>
                                (inputRefs.current[supplement._id] = el)
                              }
                            />
                          </div>
                        </div>
                      )}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
}

export default ManageTreatmentTemplates;
