import React, { useState, useEffect } from "react";
import { Table, Select } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css"; // Make sure this path is correct
import AuditView from "../../Components/AuditView";
const { Option } = Select;
const RoomView = () => {
  const [treatments, setTreatments] = useState([]);
  const navigate = useNavigate();
  const [room, setRoom] = useState(localStorage.getItem("selectedRoom"));
  const [showAudit, setShowAudit] = useState(false);
  const [roomOptions, setRoomOptions] = useState([]);

  useEffect(() => {
    axios
      .get("/get-room-options", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          "Content-Type": "application/json", // Specify the content type of your request
        },
      }) // Replace with your actual API endpoint
      .then((response) => {
        setRoomOptions(response.data[0].name);
      })
      .catch((error) => {
        console.error("There was an error fetching the room options:", error);
        // Handle the error appropriately in your application
      });
  }, []);

  const fetchTreatments = async (selectedRoom) => {
    try {
      const response = await axios.get(
        `/ordered/today/${selectedRoom}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      setTreatments(response.data);
    } catch (error) {
      console.error("Error fetching treatments:", error);
      setTreatments([]);
    }
  };

  const handleRoomSelect = (selectedRoom) => {
    
    setRoom(selectedRoom);
    localStorage.setItem("selectedRoom", selectedRoom);
    setShowAudit(true);
    fetchTreatments(selectedRoom);
  };

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "patientName",
      key: "patientName",
      render: (text, record) => (
        <a
          href="/treatmentviewdisplay"
          onClick={(e) => {
            const patient = treatments.find(
              (treatment) => treatment.patientName === record.patientName
            );
            if (patient) {
              
              handlePatientClick(e, record.patientName, patient.PatientId);
            }
          }}
          style={{ color: "blue" }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Room",
      dataIndex: "room",
      key: "room",
    },
    {
      title: "Procedures",
      dataIndex: "procedures",
      key: "procedures",
      render: (procedures) => procedures.join(", "),
    },
    {
      title: "Dispensed Injections",
      dataIndex: "dispensed",
      key: "dispensed",
      render: (dispensed) => dispensed.join(", "),
    },
    {
      title: "Labs",
      dataIndex: "labs",
      key: "labs",
      render: (labs) => labs.join(", "),
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
    },
  ];

  const formatDataForTable = (data) => {
    return data.map((item) => {
      // Include userLabs where checked is true
      const labs = item.userLabs
        .filter((lab) => lab.checked)
        .map((lab) => lab.test_name);

      // Include userdispensed items with non-empty selectedField
      const dispensed = item.userdispensed
        .filter((d) => d.quantity > 0)
        .map((d) => `${d.name} (${d.selectedField}) x${d.quantity}`);

      // Include userProcedures where checked is true or selectedField is not an empty string
      const procedures = item.userProcedures
        .filter(
          (procedure) =>
            procedure.checked === true ||
            (procedure.selectedField && procedure.selectedField !== "" && !procedure.custom)
        )
        .map((procedure) => {
          if (procedure.selectedField && procedure.selectedField !== "") {
            return `${procedure.procedure_name} (${procedure.selectedField})`;
          }
          return procedure.procedure_name;
        });

      // Use the correct properties from the data structure
      return {
        key: item["_id"],
        patientName: item.patientName, // Assuming patient name isn't provided, so using PatientId
        room: item.room || "unassigned", // Provide a fallback if room is undefined
        procedures,
        dispensed,
        labs,
        note: item.note,
      };
    });
  };

  const handlePatientClick = (e, patientName, PatientId) => {
    e.preventDefault(); // Prevent default link behavior
    navigate("/treatmentviewdisplay", {
      state: { patientName, patientId: PatientId },
    });
  };
  const PatientButtonsGrid = ({ treatments }) => {
    return (
      <div className={styles.patientButtonsGrid}>
        <Select
          className={styles.roomsSelect}
          placeholder="Select a room"
          style={{ width: 325, height: 44 }}
          onChange={handleRoomSelect}
          value={room}
        >
          <Option value="unassigned">UnAssigned</Option>
          {roomOptions.map((option, index) => (
            <Option key={index} value={option}>
              {option}
            </Option>
          ))}
        </Select>
      </div>
    );
  };

  return (
    <>
      <div className={`${styles.roomViewHeader} mt-5`}>
        <div className="my-auto">
          <h3>Room View</h3>
        </div>
      </div>
      <div className={`${styles.tableContainer} mx-auto`}>
        {/* <PatientButtonsGrid /> */}
        <AuditView room={room} from="roomview" />

        {/* <Table
          columns={columns}
          dataSource={formatDataForTable(treatments)}
          pagination={false}
        /> */}
      </div>
    </>
  );
};

export default RoomView;
