import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TreatmentTab from '../TreatmentTab';

// This function will reorder the treatments array based on drag position
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const TreatmentTabsContainer = ({ treatments }) => {
  const [treatmentList, setTreatmentList] = useState(treatments);
  useEffect(()=>(
    setTreatmentList(treatments)
  ),[treatments])

  const onDragEnd = (result) => {
    // When dragging ends, we check if the item was dropped outside of the droppable area.
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;
    const reorderedTreatments = reorder(treatmentList, source.index, destination.index);

    setTreatmentList(reorderedTreatments);  // Update the state to reflect the new order
  };


  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="treatmentTabs" direction="vertical">
        {(provided) => (
          <div
            className="flex flex-col gap-2"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {treatmentList.map((treatment, index) => (
              <Draggable key={treatment._id} draggableId={treatment?._id} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,  // To apply necessary styling for dragging
                      ...provided.draggableProps.style,
                    }}
                  >
                    <TreatmentTab treatment={treatment} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default TreatmentTabsContainer;
