import React, { useState, useEffect, useContext, useRef } from "react";
import "../../customeCalender.css";
import EditNoteIcon from "@mui/icons-material/EditNote";
import dayjs from "dayjs";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import Round from "../../Assets/Round";
import ScheduleIcon from "@mui/icons-material/Schedule";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../../Components/SingleTreatmentView/labsModel.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { CircularProgress } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import smsIcon from "../../Assets/sms.png";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  Card,
  CardContent,
  FormControlLabel,
  Tooltip as MuiTooltip,
} from "@mui/material";
import {
  Button,
  Select,
  DatePicker,
  Input,
  Popconfirm,
  Badge,
  message,
  Spin,
} from "antd";
import {
  Modal as AntdModal,
  Form,
  Popover,
  Input as InputAnt,
  TimePicker,
} from "antd";
import "../../antTable.css";
import { TouchBackend } from "react-dnd-touch-backend";
import { createTransition } from "dnd-multi-backend";
import { MultiBackend } from "dnd-multi-backend";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TreatmentNotes from "../../Components/TreatmentNotes";
import {
  UpOutlined,
  DownOutlined,
  DeleteOutlined,
  SearchOutlined,
  CommentOutlined,
  LockOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd"; // or from your preferred UI library
import {
  Box,
  Typography,
  Grid,
  ListItem,
  ListItemText,
  Checkbox,
  Modal,
} from "@mui/material";
import axios from "axios";
import { MenuItem, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./FrontDesk.module.css"; // Make sure this path is correct
import { BarLoader, BounceLoader } from "react-spinners";
import moment from "moment-timezone";
import IconButton from "@mui/material/IconButton";
import ProcedureModal from "../../Components/ProcedureModal";
import DispenseModal from "../../Components/DispenseModal";
import VitalsModal from "../../Components/VitalsModal";
import PickUpModal from "../../Components/PickUpModal";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DataContext } from "../../context/DataContext";
import arrowRight from "../../Assets/arrowRight.png";
import leftRight from "../../Assets/LeftArrow.png";
import Messages from "../../Components/Messages";
import {
  dispensesSortingFunction,
  proceduresSortingFunction,
} from "../../utils";
import SupplementsModal from "../../Components/SupplementsModal";
import OrderDetailsModal from "../../Components/Updations/OrderDetailsModal";
import {
  CompareArrows,
  ContentCopyRounded,
  Edit,
  Refresh,
  ShoppingCartCheckout,
} from "@mui/icons-material";
import CustomToast from "../../Components/CustomToast";
import UpdatePatient from "../../Components/Messages/UpdatePatient";

const { Option } = Select;
const opts = {
  enableTouchEvents: true,
  enableMouseEvents: true,
};
// Modal style
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 750, // You can adjust the width as needed
  maxHeight: "80vh", // Adjust the max height as needed
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  "& .MuiTypography-h6": {
    // Targeting Typography with variant h6
    fontSize: "1rem", // Maintained decreased font size
  },
  "& .MuiListItemText-primary": {
    fontSize: "0.9rem", // Setting font size for primary text
    whiteSpace: "nowrap", // Prevent text wrapping
    overflow: "hidden", // Hide overflow
    textOverflow: "ellipsis", // Add ellipsis for overflowed text
  },
  "& .MuiCheckbox-root": {
    // Targeting Checkbox
    padding: "2px", // Reduced padding for a smaller checkbox
    "& svg": {
      // Targeting the SVG icon in checkbox
      fontSize: "1rem", // Reduced icon size
    },
  },
  "& .MuiGrid-container": {
    rowGap: 1, // Adjust row spacing if needed
  },
  "& .MuiGrid-item": {
    display: "flex", // Ensure the items in grid are flex containers
    alignItems: "center", // Align items vertically in the center
  },
};




const MessageIcon = ({ patient }) => {
  const [modalState, setModalState] = useState(false);

  const openModal = () => {
    setModalState(true);
  };

  const closeModal = () => {
    setModalState(false);
  };

  // Calculate unread messages count
  
 const unreadCount = patient?.messages?.filter(
  (msg) => msg.readStatus === false
).length;

console.log(unreadCount);

  return (
    <>
      <Badge count={unreadCount} overflowCount={99} offset={[-5, 5]}>
        <img
          src={smsIcon}
          alt="sms"
          style={{ width: "36px", height: "36px", background: "none", cursor: "pointer" }}
          onClick={openModal}
        />
      </Badge>
      {modalState && (
        <Messages patient={patient} onClose={closeModal} />
      )}
    </>
  );
};


const Row = ({
  isLocked,
  row,
  fetchData,
  selectedDate,
  handleReviewChange,
  allLabs,
  isTyping,
  setIsTyping,
  handleOpenSupplementsModal,
  handleOpenProcedureModal,
  handleOpenDispenseModal,
  handleOpenLabsModal,
  setSelectedTreatmentId,
  isSupModalClosed,
  setIsSupModalClosed,
  selectedFilters,
  PatientProfile,
  setIsComparisonModalVisible,
  isComparisonModalVisible,
  clickedOrderDetails,
  setClickedOrderDetails,
  setShopifyOrdersDetails,
  isLoading,
  setIsLoading,
  setCurrentPair,
}) => {
  const [supplementDialogOpen, setSupplementDialogOpen] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);
  const [pasteMenu, setPasteMenu] = useState(false);
  const [selectedSupplementIds, setSelectedSupplementIds] = useState(
    [row.userSupplements][0]
  );
  const [undoOrderId, setUndoOrderId] = useState(null);
  const [undoToken, setUndoToken] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [checkboxValues, setCheckboxValues] = useState({
    labs: false,
    dispensed: false,
    procedures: false,
    supplements: false,
  });
  const [noteInput, setShowNoteInput] = useState(false);
  const [isFinalChecked, setIsFinalChecked] = useState(row.review);
  const [showSupplementsModal, setShowSupplementsModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [supplements, setSupplements] = useState([]);
  const isNoteEditing = useRef(false);
  const [inputWidth, setInputWidth] = useState("100px");

  const inputRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isNoteEditing.current &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setShowNoteInput(false);
        isNoteEditing.current = false;
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleContextMenu = (event, record) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX,
      mouseY: event.clientY,
      data: record,
    });
  };

  function calculateTopPositionNCM(mouseY) {
    const menuHeight = 20;
    const windowHeight = window.innerHeight;
    const spaceBelowCursor = windowHeight - mouseY;

    if (spaceBelowCursor >= menuHeight) {
      return mouseY;
    } else {
      return mouseY - menuHeight;
    }
  }

  const handleClose = () => {
    setContextMenu(null);
    setPasteMenu(false);
    setCheckboxValues({
      labs: false,
      dispensed: false,
      procedures: false,
      supplements: false,
    });
  };

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        handleClose();
      })
      .catch((err) => {});
  };

  function extractDetails(resultString) {
    const details = {
      oldTreatment: null,
      hasProcedures: false,
      hasLabs: false,
      hasDispensed: false,
      hasSupplements: false,
    };

    // Extract Treatment
    const oldTreatmentMatch = resultString.match(/Treatment:\s*(.*)/);
    if (oldTreatmentMatch && oldTreatmentMatch[1]) {
      details.oldTreatment = oldTreatmentMatch[1];
    }

    // Check for Procedures
    details.hasProcedures = /Procedures:\s*(.*)/.test(resultString);

    // Check for Labs
    details.hasLabs = /Labs:\s*(.*)/.test(resultString);

    // Check for Dispensed
    details.hasDispensed = /Dispensed:\s*(.*)/.test(resultString);

    // Check for Supplements
    details.hasSupplements = /Supplements:\s*(.*)/.test(resultString);

    return details;
  }

  async function handlePasteUpdate(newTreatmentId, text, action) {
    //
    const normalizedText = text.replace(/\r\n/g, "\n").replace(/\n/g, "\r\n");

    const details = extractDetails(text);

    try {
      const response = await axios.put(
        `/paste-in-treatment`,
        {
          newTreatmentId,
          action,
          oldTreatmentId: details.oldTreatment,
          dataToUpdate: details,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      toast.success("Paste Successful");
      const undoToken = response.data.undoToken;
      const undoOrderId = response.data.orderId;
      setUndoOrderId(undoOrderId);
      setUndoToken(undoToken);
      handleClose();
      let tempDate = moment
        .tz(dayjs(selectedDate), "America/Phoenix")
        .format("YYYY-MM-DD");
      fetchData(tempDate);
    } catch (error) {
      toast.error(error);
      console.error(error);
    }
  }

  const handlePaste = (action) => {
    navigator.clipboard
      .readText()
      .then((text) => {
        handlePasteUpdate(contextMenu?.data._id, text, action);
      })
      .catch((err) => {});
  };

  const onCheckboxChange = (changes) => {
    setCheckboxValues({ ...checkboxValues, ...changes });
  };

  useEffect(() => {
    if (isSupModalClosed) {
      fetchData(moment(selectedDate).format("YYYY-MM-DD"));
      getShopifyOrders();
      setIsSupModalClosed(false);
    }
  }, [isSupModalClosed]);

  const handleFocus = () => {
    setInputWidth("auto");
    setIsTyping(true);
    isNoteEditing.current = true;
  };

  const handleBlur = (id, e) => {
    setInputWidth("100px");
    setIsTyping(false);
    handleNoteUpdate(id, e.target.value);
  };

  const handleNoteUpdate = async (key, newNote) => {
    const payload = {
      note: newNote,
    };

    // API call to update the note
    try {
      const response = await axios.put(
        `/shopify-note/${key}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      let tempDate = moment(selectedDate).format("YYYY-MM-DD");
      fetchData(tempDate);
      setShowNoteInput(false);
      isNoteEditing.current = false;
    } catch (error) {
      console.error("Error updating note:", error);
    }
  };
  const handleSupplementDialogOpen = () => {
    setSupplementDialogOpen(true);
  };

  const handleFinalCheckedChange = (event) => {
    const newCheckedValue = event.target.checked; // Get the updated value

    // Update local state immediately
    setIsFinalChecked(newCheckedValue);

    // Call the external handleReviewChange function
    handleReviewChange(row._id, newCheckedValue);
  };

  const handleSupplementDialogClose = () => {
    setSupplementDialogOpen(false);
  };

  useEffect(() => {
    // Assuming formattedDate is defined and available in this scope
    const resetTimer = isLocked ? 20000 : 15000;
    // Setting up the interval
    const interval = setInterval(() => {
      if (!isTyping) {
        let tempDate = moment(selectedDate).format("YYYY-MM-DD");

        fetchData(tempDate);
        getShopifyOrders();
      }
    }, 15000);

    // Clearing interval on component unmount or when formattedDate changes
    return () => clearInterval(interval);
  }, [selectedDate]); // Include formattedDate in the dependency array

  // Generate the text for labs, procedures, and dispensed items
  const labs = row.userLabs
    .filter((lab) => lab.checked)
    .map((lab) => ({ name: lab.test_name, custom: lab.custom }));

  const findDisplayNames = (labs, allLabs) => {
    return labs.map((lab) => {
      const foundLab = allLabs.find((allLab) => allLab.test_name === lab.name);
      let displayName =
        foundLab && foundLab.display_name ? foundLab.display_name : lab.name;

      // Append "( Custom)" if custom property is true
      if (lab.custom) {
        displayName += " (Custom)";
      }

      return displayName; // If display_name is not found, use test_name
    });
  };

  // Assuming you've already fetched allLabs and extracted labs as shown previously
  const displayNames = findDisplayNames(labs, allLabs);
  const labsText = displayNames.join(", ");
  const labsTextNew = displayNames;

  const procedureText =
    row.userProcedures
      .filter(
        (procedure) =>
          procedure.checked ||
          procedure.custom ||
          (procedure.selectedField !== "" && procedure.dosages?.length > 0)
      )
      .map(
        (procedure) =>
          `${procedure.procedure_name}${
            procedure.selectedField ? ` (${procedure.selectedField})` : ""
          }`
      )
      .join(", ") || "N/A";

  const procedureTextNew = row.userProcedures
    .filter(
      (procedure) =>
        procedure.checked ||
        procedure.custom ||
        (procedure.selectedField &&
          procedure.selectedField.trim() !== "" &&
          procedure.dosages?.length > 0)
    )
    .map(
      (procedure) =>
        `${procedure.procedure_name}${procedure.custom ? " (Custom)" : ""}${
          procedure.selectedField ? ` (${procedure.selectedField})` : ""
        }`
    );

  const dispensedText =
    row.userdispensed
      .filter((dispensed) => dispensed.selectedField !== "")
      .map(
        (dispensed) =>
          `${dispensed.name} (${dispensed.selectedField}, x${dispensed.quantity})`
      )
      .join(", ") || "N/A";

  const dispensedTextNew = row.userdispensed
    .filter((dispensed) => dispensed.selectedField !== "")
    .map(
      (dispensed) =>
        `${dispensed.name}${dispensed.custom ? " (Custom)" : ""} (${
          dispensed.selectedField
        }, x${dispensed.quantity})`
    );

  const supplementsText =
    row.userSupplements
      .filter((supplement) => supplement.name !== "" && supplement.quantity > 0)
      .map(
        (supplement) =>
          `${supplement.name} ${
            supplement?.selectedField ? `(${supplement?.selectedField})` : ""
          } (x${supplement.quantity})`
      )
      .join(", ") || "N/A";

  const supplementsLength = row.userSupplements.filter(
    (supplement) => supplement.name !== "" && supplement.quantity > 0
  ).length;

  const placeOrder = (types) => {
    fetch(`/shopify/placeorder/${row._id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        "Content-Type": "application/json",
        // Include any other headers as needed
      },
      body: JSON.stringify({ types }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        fetchData(moment(selectedDate).format("YYYY-MM-DD"));
        setOrder([]);
        getShopifyOrders();
        toast.success("Order placed successfully");
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(`Error placing order: ${error.message}`);
      });
  };
  //write function which uses each row_id and get their shopify orders in an array and use it in a useffect to get the shopify orders
  const deleteOrder = async (treatmentId) => {
    try {
      const response = await axios.delete(
        `/shopify-orders/${treatmentId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            // Include any other headers as needed
          },
        }
      );

      fetchData(moment(selectedDate).format("YYYY-MM-DD"));
      setOrder([]);
      getShopifyOrders();
      toast.success("Order deleted successfully");
    } catch (error) {
      console.error(error);

      toast.error(`Error deleting order: ${error.message}`);
    }
  };

  const [order, setOrder] = useState([]);

  const [shopifyOrders, setShopifyOrders] = useState([]);

  const [shopifyOrdersCollection, setShopifyOrdersCollection] = useState([]);

  const getShopifyTreatments = async (treatmentIds) => {
    try {
      const response = await axios.post(
        "/getshopifyorders",
        {
          ids: treatmentIds,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            // Include any other headers as needed
          },
        }
      );

      const orders = response.data;

      setShopifyOrders(orders);

      // Do something with the orders...
    } catch (error) {
      console.error(`Error fetching orders:`, error);
    }
  };

  const getShopifyOrders = async () => {
    try {
      const response = await fetch(
        `/shopify/getorders/${row._id}`,
        {
          method: "GET",

          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            // Include any other headers as needed
          },
        }
      );
      const data = await response.json();

      const treatmentIds = data
        .filter((order) => order.order_id !== null)
        .map((order) => order.order_id);

      getShopifyTreatments(treatmentIds);
      setOrder(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getShopifyOrdersCollection = async () => {
    try {
      const response = await axios.get(
        `/getShopifyOrders/${row._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            "Content-Type": "application/json",
            // Include any other headers as needed
          },
        }
      );
      const data = response.data;

      setShopifyOrdersCollection(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [otherSupplements, setOtherSupplements] = useState([]);
  useEffect(() => {
    fetch("/supplements-names")
      .then((response) => response.json())
      .then((data) => {
        const otherSupplements = data.filter(
          (supplement) => !supplements.some((item) => item.name === supplement)
        );
        setOtherSupplements(otherSupplements);
      });
  }, [supplements]);
  useEffect(() => {
    getShopifyOrders();
    getShopifyOrdersCollection();
  }, []);

  const handleSupplementCheckboxChange = (event, supplement) => {
    if (event.target.checked) {
      setSelectedSupplementIds((prev) => {
        if (prev.some((item) => item.name === supplement)) {
          // Supplement with the same name already exists, so return the previous state
          return prev;
        } else {
          // Supplement with the same name does not exist, so add it
          return [...prev, { name: supplement, quantity: 1 }];
        }
      });
    } else {
      setSelectedSupplementIds((prev) =>
        prev.filter((item) => item.name !== supplement)
      );
    }
  };

  const handleSupplementQuantityChange = (event, supplement) => {
    const quantity = event.target.value;
    setSelectedSupplementIds((prev) =>
      prev.map((item) =>
        item.name === supplement ? { ...item, quantity: quantity } : item
      )
    );
  };
  const handleSupplementSubmit = async () => {
    // Here you can do whatever you need with the selected supplements

    const url = `/addSupplementsOrder/${row._id}`;

    try {
      const response = await axios.post(
        url,
        {
          supplements: selectedSupplementIds,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      toast.success("Supplements added to order successfully");

      handleSupplementDialogClose();
    } catch (error) {
      console.error("Error:", error);
      toast.error(`Error adding supplements to order: ${error.message}`);
    }
    handleSupplementDialogClose();
  };
  // Assuming you have the orders data in a variable called order
  const dispensedOrder = order.filter((order) =>
    order.type.includes("dispensed")
  );
  const labOrder = order.filter((order) => order.type.includes("lab"));
  const procedureOrder = order.filter((order) =>
    order.type.includes("procedure")
  );

  const supplementsOrder = order.filter((order) =>
    order.type.includes("supplement")
  );

  const [openModal, setOpenModal] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    procedure: false,
    dispensed: false,
    lab: false,
  });

  const updateOrderType = async (orderId, type) => {
    try {
      const response = await axios.post(
        "/shopifyappointmentupdate",
        {
          id: orderId,
          type: type,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            // Include any other headers as needed
          },
        }
      );

      //toast success message
      toast.success("Appointment type " + type + " added to draft order");
    } catch (error) {
      console.error(`Error updating order type:`, error);
      toast.error(`Error updating appointment type: ${error.message}`);
    }
  };

  const updateOrder = async (orderId) => {
    try {
      const response = await axios.post(
        `/shopify/updateorder/${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            // Include any other headers as needed
          },
        }
      );

      //toast success message
      toast.success("Order updated successfully");
    } catch (error) {
      console.error(`Error updating order:`, error);
      toast.error(`Error updating order: ${error.message}`);
    }
  };
  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleCloseM = () => {
    setOpenModal(false);
  };

  const handleChange = (event) => {
    setSelectedOptions({
      ...selectedOptions,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Create an array of the selected options
    const selectedTypes = Object.keys(selectedOptions)
      .filter((option) => selectedOptions[option])
      .map((option) => option.toLowerCase());

    if (row.review !== true) {
      message.warning("This treatment is not finalized yet, so can't place a shopify order for it.")
    }
    else{
      placeOrder(selectedTypes);
    }
    handleCloseM();
  };

  const checkOrder = (text, order) =>
    text == "N/A" ||
    (text == "" && order.length === 0) ||
    (text !== "N/A" && order.length > 0);

  const allOrdersValid =
    (procedureText === ""
      ? checkOrder(procedureText, procedureOrder)
      : checkOrder(procedureText, procedureOrder)) &&
    (labsText === ""
      ? checkOrder(labsText, labOrder)
      : checkOrder(labsText, labOrder)) &&
    (dispensedText === ""
      ? checkOrder(dispensedText, dispensedOrder)
      : checkOrder(dispensedText, dispensedOrder));

  const allOrdersValidNew =
    procedureOrder.length > 0 &&
    labOrder.length > 0 &&
    dispensedOrder.length > 0;

  const partialOrdersValid =
    procedureOrder.length > 0 ||
    labOrder.length > 0 ||
    dispensedOrder.length > 0;

  const allAreNA =
    procedureText === "N/A" && labsText === "" && dispensedText === "N/A";

  const processingValid = partialOrdersValid || row.review === true;

  const meetsFilters = () => {
    // Convert the object into an array of [key, value] pairs
    const filtersArray = Object.entries(selectedFilters);

    // If no filters are selected, return true to include all rows
    if (!filtersArray.some(([filter, isSelected]) => isSelected)) {
      return true;
    }

    // Check each filter and return true if the row meets any selected filter
    return filtersArray.some(([filter, isSelected]) => {
      switch (filter) {
        case "waiting_review":
          return isSelected && !row.review;
        case "transaction_complete":
          return isSelected && allOrdersValid && row.review;
        case "partial_orders":
          return isSelected && partialOrdersValid && !allOrdersValid;
        case "processing":
          return isSelected && processingValid && !allOrdersValid;
        default:
          return false;
      }
    });
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "pickup":
        return styles.pickupRow;
      case "complete":
        return styles.completedRow;
      case "scheduled":
        return styles.scheduledRow;
      case "arrived":
        return styles.arrivedRow;
      default:
        return "";
    }
  };

  const handleOutsideClick = (event) => {
    if (!selectOpen) {
      handleClose();
    }
  };
  const fetchOrderDetails = async (details) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `/customer-orders/${details.customerId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setShopifyOrdersDetails({
          ...data,
          orderDates: data.orderDates.map((order) => ({
            ...order,
            created_at: moment(order.created_at).format("MM/DD/YYYY"),
            closed_at: moment(order.closed_at).format("MM/DD/YYYY"),
          })),
        });
      } else {
        message.error("Failed to fetch order details.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching order details:", error);
      message.error("Failed to fetch order details.");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [selectOpen]);

  const onCopyClick = (oldTreatment) => {
    const dispensedTextNewN = row.userdispensed.filter(
      (dispensed) => dispensed.selectedField !== ""
    );
    const supplementsTextN = row.userSupplements.filter(
      (supplement) => supplement.name !== "" && supplement.quantity > 0
    );

    let resultString = `Treatment: ${oldTreatment}\n`;
    // Check and append labs if selected
    if (checkboxValues.labs) {
      resultString += `Labs: ${labsTextNew.join(", ")}\n`;
    }

    // Check and append procedures if selected
    if (checkboxValues.procedures) {
      resultString += `Procedures: ${procedureTextNew.join(", ")}\n`;
    }

    // Check and append dispensed if selected
    if (checkboxValues.dispensed) {
      const namesArray = dispensedTextNewN.map((item) => item.name);
      resultString += `Dispensed: ${namesArray.join(", ")}\n`;
    }

    // Check and append supplements if selected
    if (checkboxValues.supplements) {
      const namesArray = supplementsTextN.map((item) => item.name);
      resultString += `Supplements: ${namesArray.join(", ")}\n`;
    }

    // Output or handle the constructed string

    handleCopy(resultString);
  };

  return meetsFilters() ? (
    <>
      {contextMenu && (
        <div
          className="fadeIn"
          style={{
            position: "fixed",
            top: calculateTopPositionNCM(contextMenu.mouseY),
            left: contextMenu.mouseX,
            backgroundColor: "white",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            zIndex: 1000,
            padding: "8px 0",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div style={{ padding: "8px 16px", cursor: "pointer" }}>
            {contextMenu?.data && (
              <>
                {pasteMenu ? (
                  <div
                    className="mt-[5px] relative group"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <button className="ml-[5px]">Paste</button>
                    <div
                      style={{ borderRadius: "10px" }}
                      className="absolute hidden w-[150px] top-[-10px] left-[50px] bg-white shadow-lg py-2 px-4 group-hover:block"
                    >
                      <button
                        onClick={() => handlePaste("modify")}
                        className="hover:text-white p-1 rounded-sm hover:bg-blue-500 w-full text-left"
                      >
                        Merge
                      </button>
                      <button
                        onClick={() => handlePaste("overwrite")}
                        className="hover:text-white p-1 rounded-sm hover:bg-blue-500 w-full text-left"
                      >
                        Overwrite
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    className="mt-[5px] hover:text-white hover:bg-blue-500 rounded-sm p-1"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <button
                      onClick={() => {
                        onCopyClick(contextMenu?.data?._id);
                      }}
                    >
                      Copy
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
      <TableRow
        onContextMenu={(event) => {
          event.stopPropagation();
          setPasteMenu(true);
          handleContextMenu(event, row);
        }}
        className={getStatusClass(row.status)}
        style={{
          backgroundColor: allAreNA
            ? "transparent" // Color when all are "N/A"
            : allOrdersValid
            ? "rgba(255, 255, 0, 0.5)" // 50% opacity yellow
            : row.status === "pickup"
            ? "#F6C4F8" // Specific color for 'pickup' status
            : row.review !== true
            ? "darkgrey" // Color when review is not true
            : "transparent", // Default color
        }}
        onClick={() => setOpen(!open)}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <Dialog open={openModal} onClose={handleCloseM}>
          <Box p={2}>
            <DialogTitle>Select Options</DialogTitle>
            <DialogContent>
              {procedureText !== "N/A" && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedOptions.procedure}
                      onChange={handleChange}
                      name="procedure"
                    />
                  }
                  label="Procedure"
                />
              )}

              {dispensedText !== "N/A" && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedOptions.dispensed}
                      onChange={handleChange}
                      name="dispensed"
                    />
                  }
                  label="Dispensed"
                />
              )}

              {labsText !== "" && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedOptions.labs}
                      onChange={handleChange}
                      name="lab"
                    />
                  }
                  label="Lab"
                />
              )}

              {supplementsText !== "N/A" && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedOptions.supplement}
                      onChange={handleChange}
                      name="Supplement"
                    />
                  }
                  label="Supplement"
                />
              )}
            </DialogContent>
            <DialogActions>
              <button
                className="btn-color py-1 px-10 text-xs md:text-sm w-full md:w-auto"
                onClick={handleClose}
                color="primary"
              >
                Cancel
              </button>
              <button
                className="btn-color py-1 px-10 text-xs md:text-sm w-full md:w-auto"
                onClick={handleSubmit}
                color="primary"
              >
                Submit
              </button>
            </DialogActions>
          </Box>
        </Dialog>
        <TableCell
          component="th"
          scope="row"
          sx={{ textAlign: "center !important" }}
        >
          
          {PatientProfile!==undefined ? <MessageIcon patient={PatientProfile} />:<CircularProgress size={'15px'} />}
        </TableCell>
        <TableCell component="th" scope="row" sx={{ textAlign: "center !important" }}>
          {row.patientName}
          {PatientProfile!==undefined ? <UpdatePatient patient={PatientProfile} showIcon={true} />:<CircularProgress size={'15px'} sx={{ml:3}}/>}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          sx={{ textAlign: "center !important" }}
        >
          <Tooltip
            title={
              <Typography
                sx={{ fontSize: 12 }}
                open={!isComparisonModalVisible}
              >
                {clickedOrderDetails?.orderId === row._id && isLoading
                  ? "Loading..."
                  : "Compare this treatment with shopify orders"}
              </Typography>
            }
          >
            <IconButton
              // loading={clickedOrderDetails?.orderId === row._id ? isLoading : false}
              onClick={() => {
                // setIsComparisonModalVisible(true)
                fetchOrderDetails({
                  customerId: row.PatientId,
                  orderId: row._id,
                });
                setClickedOrderDetails({
                  customerId: row.PatientId,
                  orderId: row._id,
                });
              }}
              size="medium"
              load
            >
              {clickedOrderDetails?.orderId === row._id && isLoading ? (
                <CircularProgress size={20} />
              ) : (
                <CompareArrows fontSize="medium" />
              )}
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell>
          <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
            <IconButton
              size="small"
              onClick={handleOpen}
              aria-label="procedure"
            >
              <MenuOpenIcon />
            </IconButton>

            <IconButton
              size="small"
              aria-label="procedure"
              sx={{
                width: 24,
                height: 24,
                fontSize: "0.9rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border:
                  procedureOrder.length && allOrdersValid > 0
                    ? "2px solid black"
                    : "none",
                backgroundColor:
                  procedureOrder.length > 0
                    ? "yellow"
                    : procedureText === "N/A"
                    ? "#add8e6"
                    : order
                    ? "lightgreen"
                    : "lightgrey",
              }}
              onContextMenu={(event) => {
                event.stopPropagation();

                onCheckboxChange({
                  procedures: true,
                });
                handleContextMenu(event, row);
              }}
              onClick={() => {
                setSelectedTreatmentId(row._id);
                handleOpenProcedureModal(row?._id, row);
              }}
            >
              P
            </IconButton>

            <IconButton
              size="small"
              aria-label="dispensed"
              sx={{
                width: 24,
                height: 24,
                fontSize: "0.9rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border:
                  dispensedOrder.length > 0 && allOrdersValid
                    ? "2px solid black"
                    : "none",
                backgroundColor:
                  dispensedOrder.length > 0
                    ? "yellow"
                    : dispensedText === "N/A"
                    ? "#add8e6"
                    : order
                    ? "lightgreen"
                    : "lightgrey",
              }}
              onContextMenu={(event) => {
                event.stopPropagation();

                onCheckboxChange({
                  dispensed: true,
                });
                handleContextMenu(event, row);
              }}
              onClick={() => {
                setSelectedTreatmentId(row._id);
                handleOpenDispenseModal(row?._id, row);
              }}
            >
              D
            </IconButton>

            <IconButton
              size="small"
              aria-label="lab"
              sx={{
                width: 24,
                height: 24,
                fontSize: "0.9rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border:
                  labOrder.length > 0 && allOrdersValid
                    ? "2px solid black"
                    : "none",
                backgroundColor:
                  labOrder.length > 0
                    ? "yellow"
                    : labsText === ""
                    ? "#add8e6"
                    : order
                    ? "lightgreen"
                    : "lightgrey",
              }}
              onContextMenu={(event) => {
                event.stopPropagation();

                onCheckboxChange({
                  labs: true,
                });
                handleContextMenu(event, row);
              }}
              onClick={() => {
                setSelectedTreatmentId(row._id);
                handleOpenLabsModal(row?._id, row);
              }}
            >
              L
            </IconButton>
            <IconButton
              size="small"
              onClick={() => {
                setSelectedTreatmentId(row._id);
                handleOpenSupplementsModal(row?._id, row);
              }}
              aria-label="lab"
              sx={{
                width: 24,
                height: 24,
                fontSize: "0.9rem",
                display: "flex",
                justifyContent: "center",
                border:
                  supplementsOrder.length > 0 && allOrdersValid
                    ? "2px solid black"
                    : "none",
                alignItems: "center",
                backgroundColor:
                  supplementsOrder.length > 0
                    ? "yellow"
                    : supplementsLength > 0
                    ? "lightgreen"
                    : "lightgrey",
              }}
              onContextMenu={(event) => {
                event.stopPropagation();

                onCheckboxChange({
                  supplements: true,
                });
                handleContextMenu(event, row);
              }}
            >
              S
            </IconButton>

            <Popconfirm
              title="Are you sure to delete this order?"
              onConfirm={() => deleteOrder(row._id)}
              okText="Yes"
              cancelText="No"
              okButtonProps={{
                className:
                  "btn-color py-1 px-10 text-xs md:text-sm w-full md:w-auto",
              }}
              cancelButtonProps={{
                className:
                  "btn-color py-1 px-10 text-xs md:text-sm w-full md:w-auto",
              }}
            >
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Popconfirm>
          </div>
          <Dialog
            open={supplementDialogOpen}
            onClose={handleSupplementDialogClose}
          >
            <DialogTitle>Select Supplements</DialogTitle>
            <DialogContent>
              <Typography variant="h6" gutterBottom>
                Recommended Supplements
              </Typography>
              {supplements && supplements.length > 0 ? (
                supplements.map((supplement, index) => (
                  <Card
                    key={index}
                    style={{ margin: "1rem 0", padding: "1rem" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <Checkbox
                        checked={selectedSupplementIds.some(
                          (item) => item.name === supplement
                        )}
                        onChange={(event) =>
                          handleSupplementCheckboxChange(event, supplement)
                        }
                      />
                      {supplement}
                      <TextField
                        label="Quantity"
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        value={
                          selectedSupplementIds.find(
                            (item) => item.name === supplement
                          )?.quantity || ""
                        }
                        onChange={(event) =>
                          handleSupplementQuantityChange(event, supplement)
                        }
                      />
                    </div>
                  </Card>
                ))
              ) : (
                <>
                  <p>No Recommended Supplements for the current patient.</p>
                  <p>Please select some from the treatment view.</p>
                </>
              )}

              <Typography
                variant="h6"
                gutterBottom
                style={{ marginTop: "2rem" }}
              >
                Other Supplements
              </Typography>
              {otherSupplements && otherSupplements.length > 0 ? (
                otherSupplements.map((supplement, index) => (
                  <Card
                    key={index}
                    style={{ margin: "1rem 0", padding: "1rem" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5rem",
                        justifyContent: "space-between",
                      }}
                    >
                      <Checkbox
                        checked={selectedSupplementIds.some(
                          (item) => item.name === supplement
                        )}
                        onChange={(event) =>
                          handleSupplementCheckboxChange(event, supplement)
                        }
                      />
                      {supplement}
                      <TextField
                        label="Quantity"
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        value={
                          selectedSupplementIds.find(
                            (item) => item.name === supplement
                          )?.quantity || ""
                        }
                        onChange={(event) =>
                          handleSupplementQuantityChange(event, supplement)
                        }
                      />
                    </div>
                  </Card>
                ))
              ) : (
                <p>No other supplements available.</p>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSupplementDialogClose}>Cancel</Button>
              <Button onClick={handleSupplementSubmit}>Submit</Button>
            </DialogActions>
          </Dialog>{" "}
        </TableCell>
        <TableCell component="th" scope="row">
          <Checkbox
            checked={isFinalChecked}
            onChange={handleFinalCheckedChange}
          />
        </TableCell>

        <TableCell>
          <div className="flex justify-center items-center">
            <Tooltip title={row.shopify_note} placement="top">
              <IconButton onClick={() => setShowNoteInput(!noteInput)}>
                <EditNoteIcon
                  style={{
                    fontSize: 28,
                    color: row.shopify_note ? "#FF5733" : "inherit",
                  }}
                />
              </IconButton>
            </Tooltip>
            {noteInput && (
              <Input
                ref={inputRef}
                defaultValue={row.shopify_note || ""}
                style={{ minWidth: "100px", width: inputWidth }}
                onFocus={handleFocus}
                onBlur={(e) => handleBlur(row._id, e)}
                disabled={row.review===true?true:false}
              />
            )}
          </div>
        </TableCell>
        <TableCell>
          {procedureTextNew.map((procedure, index) => {
            const isCustom = procedure.includes("(Custom)");
            return (
              <span
                key={index}
                style={{ color: isCustom ? "blue" : "inherit" }}
              >
                {procedure.replace(" (Custom)", "")}
                {index < procedureTextNew.length - 1 ? ", " : ""}
              </span>
            );
          })}
        </TableCell>
        <TableCell>
          <>
            {" "}
            {dispensedTextNew.length > 0 && (
              <span className=" font-bold">Dispensed:</span>
            )}{" "}
            <br />{" "}
            {dispensedTextNew.map((dispensed, index) => {
              const isCustom = dispensed.includes("(Custom)");
              return (
                <span
                  key={index}
                  style={{ color: isCustom ? "blue" : "inherit" }}
                >
                  {dispensed.replace(" (Custom)", "")}
                  {index < dispensedTextNew.length - 1 ? ", " : ""}
                </span>
              );
            })}
          </>
          {supplementsText === "N/A" ? (
            ""
          ) : (
            <p>
              {" "}
              <span className=" font-bold">Supplements:</span> <br />{" "}
              {supplementsText}
            </p>
          )}
        </TableCell>

        <TableCell>
          {" "}
          {labsTextNew.map((lab, index) => {
            const isCustom = lab.includes("(Custom)");
            return (
              <span
                key={index}
                style={{ color: isCustom ? "blue" : "inherit" }}
              >
                {lab.replace(" (Custom)", "")}
                {index < labsTextNew.length - 1 ? ", " : ""}
              </span>
            );
          })}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse
            in={open}
            timeout="auto"
            style={{ display: "flex", flexDirection: "column" }}
            unmountOnExit
          >
            {shopifyOrders.map((order, index) => (
              <>
                <Card
                  variant="outlined"
                  key={index}
                  style={{ marginBottom: "20px", width: "100%" }}
                >
                  <CardContent>
                    <Table>
                      <TableBody>
                        {/*
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Amount
                          </TableCell>
                          <TableCell>{order.total_price}</TableCell>
                        </TableRow>
                        */}
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Status
                          </TableCell>
                          <TableCell>{order.status}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Note
                          </TableCell>
                          <TableCell>{order.note}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell component="th" scope="row">
                            Appointment Type
                          </TableCell>
                          <TableCell>
                            {" "}
                            <button
                              variant="contained"
                              className="btn-color py-1 px-10 text-xs md:text-sm w-full md:w-auto"
                              color="primary"
                              onClick={() =>
                                updateOrderType(order.id, "Consultation")
                              }
                            >
                              Initial Consultation
                            </button>
                            <button
                              variant="contained"
                              className="btn-color py-1 px-10 text-xs md:text-sm w-full md:w-auto"
                              color="secondary"
                              onClick={() =>
                                updateOrderType(order.id, "Follow Up")
                              }
                            >
                              Follow Up
                            </button>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell component="th" scope="row">
                            Actions
                          </TableCell>
                          <TableCell>
                            {" "}
                            <button
                              variant="contained"
                              color="primary"
                              className="btn-color py-1 px-10 text-xs md:text-sm w-full md:w-auto"
                              onClick={() => {
                                updateOrder(order.id);
                              }}
                            >
                              Update Order
                            </button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </>
            ))}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  ) : null;
};

const FrontDesk = () => {
  const [scheduledCalendar, setScheduledCalendar] = useState();
  const [arrivedCalendar, setArrivedCalendar] = useState();
  const [pickupCalendar, setPickupCalendar] = useState();
  const interactionTimeoutRef = useRef(null);
  const [completedCalendar, setCompletedCalendar] = useState();
  const [isLocked, setIsLocked] = useState(false);
  const [patientData, setPatientData] = useState(null);
  const [recordForNotes, setRecordForNotes] = useState();
  const [allSupplements, setAllSupplements] = useState([]);
  const [availableSupplements, setAvailableSupplements] = useState([]);
  const [isComparisonModalVisible, setIsComparisonModalVisible] =
    useState(false);
  const [currentPair, setCurrentPair] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [orderPairs, setOrderPairs] = useState([]);
  const [shopifyOrdersDetails, setShopifyOrdersDetails] = useState(null);
  const [clickedOrderDetails, setClickedOrderDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editPatientModalloading, setEditPatientModalLoading] = useState(false);
  const [inactivePatientProfiles, setInactivePatientProfiles] = useState([]);
  const containerRef = useRef(null);
  const [itemLoader, setItemLoader] = useState([]); 
  useEffect(() => {
    console.log("Order Details", shopifyOrdersDetails);
  }, [shopifyOrdersDetails]);

  const handleCompareOrders = async (details) => {
    if (!details.customerId) {
      message.warning("Please select a patient.");
      return;
    }

    setActionLoading(true);
    try {
      const response = await axios.post(
        "/compare-shopify-orders-with-local-order",
        {
          customerId: details.customerId,
          orderId: details.orderId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      console.log("********* ", response);
      if (response.status === 200) {
        setIsComparisonModalVisible(true);
        const comparisonResults = response.data;
        setCurrentPair(comparisonResults); // Store order pairs in state
        message.success("Comparison complete");
        setIsLoading(false);
        setEditPatientModalLoading(false);
      } else {
        console.log("Error comparing orders:", response);
        message.error(response.data.message);
        setIsLoading(false);
        setEditPatientModalLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error comparing orders:", error);
      message.error(error.response.data.message);
      setEditPatientModalLoading(false);
    }
    setActionLoading(false);
  };

  // useEffect(() => {
  //   if (isComparisonModalVisible === true) {
  //     fetchOrderDetails(clickedOrderDetails)
  //   }
  // }, [clickedOrderDetails])
  useEffect(() => {
    handleCompareOrders(clickedOrderDetails);
  }, [shopifyOrdersDetails]);

  const calendarRef = useRef(null);
  const {
    data: {
      labs: contextLabs,
      dispensed: contextDispensed,
      procedures: contextProcedures,
    },
    isTyping,
    setIsTyping,
  } = useContext(DataContext);
  const [form] = Form.useForm();
  const [selectedFilters, setSelectedFilters] = useState({
    waiting_review: false,
    transaction_complete: false,
    processing: false,
    partial_orders: false,
  });
  const [selectedOrderTableFilters, setSelectedOrderTableFilters] = useState({
    waiting_review: false,
    transaction_complete: false,
    processing: false,
    partial_orders: false,
  });

  const handleCheckboxChangeFilters = (name) => (event) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [name]: event.target.checked,
    }));
  };

  const handleCheckboxChangeOrderTableFilters = (name) => (event) => {
    setSelectedOrderTableFilters((prevFilters) => ({
      ...prevFilters,
      [name]: event.target.checked,
    }));
  };

  const resetInteractionTimeout = () => {
    if (interactionTimeoutRef.current) {
      clearTimeout(interactionTimeoutRef.current);
    }
    const resetTimer = isLocked ? 7200000 : 180000;
    interactionTimeoutRef.current = setTimeout(() => {
      setSelectedFilters({
        waiting_review: false,
        transaction_complete: false,
        processing: false,
        partial_orders: false,
      });
    }, resetTimer);
  };

  useEffect(() => {
    // Function to reset timeout on interaction
    const handleUserInteraction = () => {
      resetInteractionTimeout();
    };

    // Add event listeners for various user interactions
    document.addEventListener("click", handleUserInteraction);
    document.addEventListener("keypress", handleUserInteraction);

    // Cleanup function to remove event listeners
    return () => {
      document.removeEventListener("click", handleUserInteraction);
      document.removeEventListener("keypress", handleUserInteraction);
      if (interactionTimeoutRef.current) {
        clearTimeout(interactionTimeoutRef.current); // Ensure to clear the timeout on component unmount
      }
    };
  }, [isLocked]);

  const filterOptions = [
    { key: "waiting_review", label: "Waiting Review" },
    { key: "transaction_complete", label: "Transaction Complete" },
    { key: "partial_orders", label: "Partial Orders" },
    { key: "processing", label: "Ready for processing" },
    // Add more options here if needed
  ];
  const [treatments, setTreatments] = useState([]);
  const [orders, setOrders] = useState([]);
  const [searchBarVisible, setSearchBarVisible] = useState(false);
  const [ivPrepByTreatment, setIvPrepByTreatment] = useState({});
  const [loader, setLoader] = useState(false);
  const [patientLoader, setPatientLoader] = useState(false);
  const [inactivePatients, setInactivePatients] = useState([]);
  const [patientID, setPatientID] = useState();
  const [customNote, setCustomNote] = useState();
  const [showInactivePatients, setShowInactivePatients] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState(undefined);
  const [activePatient, setActivePatient] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalPatientId, setModalPatientId] = useState(null);
  const [roomOptions, setRoomOptions] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [CalendarEvents, setCalendarEvents] = useState([]);
  const [formattedDate, setFormattedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [labsModalOpen, setLabsModalOpen] = useState(false);
  const [procedureModalOpen, setProcedureModalOpen] = useState(false);
  const [dispenseModalOpen, setDispenseModalOpen] = useState(false);
  const [vitalsModalOpen, setVitalsModalOpen] = useState(false);
  const [treatmentNoteModalOpen, setTreatmentNoteModalOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [orderRows, setOrderRows] = useState([]);
  const [currentPatientLabs, setCurrentPatientLabs] = useState({
    labs: [],
    orderId: null,
  });
  const [currentProcedures, setCurrentProcedures] = useState([]);
  const [currentSupplements, setCurrentSupplements] = useState([]);
  const [currentDispensedItems, setCurrentDispensedItems] = useState([]);
  const [currentVitalsItems, setCurrentVitalsItems] = useState([]);
  const [currentTreatment, setCurrentTreatment] = useState();

  const isTreatmentNoteEditing = useRef(false);

  const [currentPatientIdForModal, setCurrentPatientIdForModal] =
    useState(null);
  const [isRoomsListOpen, setIsRoomsListOpen] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [bulkAction, setBulkAction] = useState("");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [bulkActionDate, setBulkActionDate] = useState(null);

  const [showDuplicateOptions, setShowDuplicateOptions] = useState(false);
  const [duplicateActionDate, setDuplicateActionDate] = useState(null);

  const [hoveredColumn, setHoveredColumn] = useState(null);

  const [searchFilter, setSearchFilter] = useState("");
  const [orderTableSearchFilter, setOrderTableSearchFilter] = useState("");
  const [roomFilter, setRoomFilter] = useState("");
  const [assigneeFilter, setAssigneeFilter] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [allLabs, setAllLabs] = useState([]);
  const [clickedTreatmentData, setClickedTreatmentData] = useState({});

  useEffect(()=>{
    console.log("************ order rows ", orderRows)
  },[orderRows])


  const handlePickUp = async (patientId) => {
    // Prepare the data to be sent
    const data = {
      dispensed: [],
      supplements: [],
      patientId: patientId, // Assuming patientId is available in this scope
    };

    // Send the data to the endpoint

    const response = await fetch(
      `/schedule-pickup/${selectedDate}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
        body: JSON.stringify(data),
      }
    );

    if (response.ok) {
      toast.success("Pick up scheduled successfully!");
    } else {
      console.error("Error sending data:", response.statusText);
      toast.error("Error scheduling pick up!");
    }
  };

  const fetchCalendars = async (date) => {
    try {
      const response = await axios.get(
        "/google-calendars",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.data && response.data.length > 0) {
        const requestPromises = response.data.map((item) =>
          axios.get(
            `/calendar-events-all/${item._id}/${date}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            }
          )
        );

        // Use Promise.all to wait for all requests to resolve
        const results = await Promise.all(requestPromises);

        const calendarsWithCounts = response.data.map((item, index) => ({
          ...item,
          events: results[index]?.data?.events,
          eventsCount: results[index]?.data?.events?.length
            ? results[index]?.data?.events?.length
            : 0, // Add the events count to each calendar
        }));

        // Set state for each calendar based on its name
        calendarsWithCounts.forEach((calendar) => {
          if (calendar.name === "Scheduled") {
            setScheduledCalendar(calendar);
          } else if (calendar.name === "Complete") {
            setCompletedCalendar(calendar);
          } else if (calendar.name === "Pickup") {
            setPickupCalendar(calendar);
          } else if (calendar.name === "Arrived") {
            setArrivedCalendar(calendar);
          }
        });
      }
    } catch (error) {
      console.error("Error fetching calendars:", error);
    }
  };

  useEffect(() => {
    fetchCalendars(formattedDate);
  }, [formattedDate]);

  const getParentContainerStyle = (status) => {
    const count = treatments.filter((t) => t.status === status).length;
    let maxWidth = null;
    let minWidth = null;
    // let maxWidth = null;
    if (count === 0) {
      minWidth = "12%";
    } else if (count <= 7 && count > 0) {
      // width = "250px";
      // width = "auto";
      minWidth = "20%";
      maxWidth = "90%";
    } else if (count > 7) {
      // width = "620px";
      // width = "auto";
      minWidth = "20%";
      maxWidth = "70%";
    }
    return {
      width: "auto",
      minWidth: minWidth,
      maxWidth: maxWidth,
    };
  };
  const fetchallLabs = async () => {
    try {
      const response = await axios.get("/allLabs", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          "Content-Type": "application/json",
        },
      });
      setAllLabs(response.data.allLabs);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  useEffect(() => {
    fetchallLabs();
  }, []);

  const filterTreatmentsByStatus = (treatments, status) => {
    return treatments.filter((treatment) => treatment.status === status);
  };

  const toggleSearchBar = () => {
    setSearchBarVisible(!searchBarVisible);
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarRef]);

  useEffect(() => {
    if (selectedDate) {
      let tempDate = moment(selectedDate).format("YYYY-MM-DD");
      fetchData(tempDate);
      fetchOrdersData(tempDate);
    }
  }, [selectedDate]);

  const fetchData = (date) => {
    axios
      .get(`/ordered/frontdesk/${date}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Include any other headers as needed
        },
      })
      .then((response) => {
        setRows(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        toast.error(`Error fetching data: ${error.message}`);
        setRows([]);
      });
  };

  const fetchOrdersData = (date) => {
    axios
      .get(`/ordered-by-type/frontdesk/${date}/Order`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          // Include any other headers as needed
        },
      })
      .then((response) => {
        setOrderRows(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        toast.error(`Error fetching data: ${error.message}`);
        setOrderRows([]);
      });
  };

  const getCalendarCidByType = (type) => {
    switch (type) {
      case "scheduled":
        return scheduledCalendar ? scheduledCalendar.cid : null;
      case "arrived":
        return arrivedCalendar ? arrivedCalendar.cid : null;
      case "pickup":
        return pickupCalendar ? pickupCalendar.cid : null;
      case "complete":
        return completedCalendar ? completedCalendar.cid : null;
      default:
        return null;
    }
  };

  async function changeEventTime(
    eventId,
    status,
    time,
    note,
    timezone,
    patientName,
    selectedDate
  ) {
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    const cid = getCalendarCidByType(status);

    try {
      const response = await axios.post(
        `/change-event-time/${eventId}`,
        {
          newTime: time,
          patientName,
          note,
          timezone,
          cid,
          selectedDate: formattedDate,
        }
      );
      toast.success("Calendar event time changed");
    } catch (error) {
      toast.error(error);
      console.error(error);
    }
  }

  const navigate = useNavigate();
  // Function to determine the style of the containers
  const getContainerStyle = (status) => {
    const count = treatments.filter((t) => t.status === status).length;
    let width = "0px";
    // let maxWidth = null;

    if (count === 0) {
      width = "auto";
      // maxWidth = "18%";
      // width = "200px";
    } else if (count <= 7 && count > 0) {
      // width = "250px";
      width = "auto";
    } else if (count > 7) {
      // width = "620px";
      width = "auto";
    }
    return {
      display: "flex",
      flexDirection: "column", // stack items vertically
      alignItems: "flex-start", // align items to the start of the container
      justifyContent: "flex-start", // start items from the top
      flexWrap: "wrap",
      gap: "10px",
      height: "100%",
      overflowY: "auto",
      width: width,
      // maxWidth: maxWidth,
      fontSize: "11px",
    };
  };

  const [assigneeOptions, setassigneeOptions] = useState([]);
  useEffect(() => {
    axios
      .get("/get-Assignee-options", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          "Content-Type": "application/json", // Specify the content type of your request
        },
      }) // Replace with your actual API endpoint
      .then((response) => {
        setassigneeOptions(response.data[0].name);
      })
      .catch((error) => {
        console.error("There was an error fetching the room options:", error);
        // Handle the error appropriately in your application
      });
  }, []);
  const fetchEvents = async () => {
    const date = new Date().toISOString().split("T")[0]; // Get today's date
    const response = await axios.get(
      `/calendar-events/${date}`
    );
    setEvents(response.data);
  };

  useEffect(() => {
    setFormattedDate(moment().format("YYYY-MM-DD"));
    GetActivePatientProfiles();
    
    fetchEvents();
  }, []);
  const getCheckedLabsSummary = () => {
    const checkedLabs = currentPatientLabs.labs
      .filter((lab) => lab.checked)
      .map((lab) => lab.test_name)
      .join(", ");

    return checkedLabs;
  };

  // Assuming treatments is your array of treatment objects

  const fetchCalendarEvents = async () => {
    const treatmentCalendarIds = treatments
      .filter((treatment) => treatment._id != null)
      .map((treatment) => treatment._id);

    if (treatmentCalendarIds.length > 0) {
      try {
        const response = await axios.post(
          "/calendar-events-treatments",
          { treatmentCalendarIds }
        );

        setCalendarEvents(response.data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  // Call the function

  useEffect(() => {
    fetchRoomOptions();
    fetchTreatments(formattedDate);

    // fetchEvents();
  }, [formattedDate]);

  useEffect(() => {
    // Assuming formattedDate is defined and available in this scope
    const resetTimer = isLocked ? 20000 : 15000;
    // Setting up the interval
    const interval = setInterval(() => {
      if (!isTyping) {
        fetchTreatments(formattedDate);
        GetActivePatientProfiles();
      }
    }, 15000);

    // Clearing interval on component unmount or when formattedDate changes
    return () => clearInterval(interval);
  }, [formattedDate]); // Include formattedDate in the dependency array

  const HTML5toTouch = {
    backends: [
      {
        backend: HTML5Backend,
      },
      {
        backend: TouchBackend,
        options: { enableMouseEvents: true },
        preview: true,
        transition: createTransition("touchstart", (event) => {
          return !!event.touches;
        }),
      },
    ],
  };

  const handleDataReset = async (date) => {
    if (date) {
      // Check if 'date' is a moment object and format it
      const formatted = moment.isMoment(date)
        ? date.format("YYYY-MM-DD")
        : moment(date).format("YYYY-MM-DD");

      // Directly call the fetch functions
      await fetchTreatments(formatted);
    }
  };

  const formatDateToUTC = (date) => {
    return date.toISOString().split("T")[0];
  };

  const showConfirmationModal = (patientId, orderId) => {
    setModalPatientId(patientId);
    setIsModalVisible(true);
  };

  // This function will be called when the "Yes" button on the modal is clicked
  const handleCreateTreatment = async () => {
    if (!modalPatientId) {
      toast.error("Patient ID not found");
      return;
    }

    try {
      const response = await axios.post(
        `/patient/order/new/${modalPatientId}/Treatment`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Treatment created successfully");

        handleDataReset(selectedDate);
        navigate(`/treatmentviewdisplay/${modalPatientId}`);
      } else {
        toast.error("Failed to create treatment");
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          "An error occurred while creating the treatment"
      );
      console.error("Error creating treatment:", error);
    }

    setIsModalVisible(false); // Close the modal after handling the action
  };
  // New handler for the room selection
  const handleRoomSelect = (value) => {
    setSelectedRoom(value);
  };

  const handleCustomNoteSelect = (value) => {
    setCustomNote(value);
  };

  function isTouchDevice() {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }

  const createSchedule = async () => {
    if (!selectedPatientId || !formattedDate) {
      toast.error("Please select all fields");
      return;
    }

    try {
      const response = await axios.post(
        "/patient/order/new/scheduled",
        {
          PatientId: selectedPatientId,
          Scheduled_Date: formattedDate,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          type: "Treatment",
          status: "scheduled",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("Schedule created successfully");

        handleDataReset(selectedDate);
      } else {
        toast.error("Failed to create schedule");
      }
    } catch (error) {
      toast.error(
        error.response.data.message ||
          "An error occurred while creating the schedule"
      );
      console.error("Error creating schedule:", error);
    }
  };

  const fetchRoomOptions = async () => {
    //Get available rooms
    axios
      .get("/get-room-options", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          "Content-Type": "application/json", // Specify the content type of your request
        },
      }) // Replace with your actual API endpoint
      .then((response) => {
        setRoomOptions(response.data[0].name);
      })
      .catch((error) => {
        console.error("There was an error fetching the room options:", error);
        // Handle the error appropriately in your application
      });
  };
  const handlePatientSelect = (selectedValue) => {
    if (!selectedValue) {
      toast.error("Please select a patient");
    } else {
      setSelectedPatientId(selectedValue);
    }
  };

  const getInactivePatientProfiles = async () => {
    try {
      const response = await axios.get(
        `/patient-profiles/inActive-list`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (response.status === 200) {
        setInactivePatients(response.data);
      }
    } catch (error) {
      console.error("Error fetching inactive patients:", error);
    }
  };
  const GetActivePatientProfiles = async () => {
    try {
      setPatientLoader(true);
      const response = await axios.get(
        `/patient-profiles/active`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (response.status === 200) {
        setActivePatient(response.data);
        setPatientLoader(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
      }
      if (error.response && error.response.status === 409) {
      }
      console.error("Signup Error:", error);
    }
  };

  const fetchTreatments = async (dateString) => {
    try {
      setLoader(true);
      const formattedDateString = moment
        .tz(dateString, "America/Phoenix")
        .format("YYYY-MM-DD");

      const response = await axios.get(
        `/front-desk-ordered-by-type/${formattedDateString}/Both`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      const treatmentsWithIvPrep = response.data?.map((treatment) => ({
        ...treatment,
        ivPrepComplete: treatment.iv_prep === "complete", // Add iv_prep status
      }));
      setTreatments(treatmentsWithIvPrep);
      fetchCalendarEvents();
      setLoader(false);
    } catch (error) {
      setTreatments([]);
      setLoader(false);
      console.error("Error fetching treatments:", error);
    }
  };

  // Function to handle date changes
  const handleDateChange = (date) => {
    if (date) {
      setSelectedDate(date);
      const specificDate = dayjs(date);

      const startOfDay = specificDate.startOf("day");
      // setFormattedDate(date.toISOString().split('T')[0]);
      setFormattedDate(startOfDay.format("YYYY-MM-DD"));
      setTreatments([]);
      setLoader(true);

      fetchTreatments(startOfDay.format("YYYY-MM-DD"));
    }
  };

  const setDateToToday = () => {
    const startOfDay = dayjs().startOf("day");

    // Update the state variables with the start of today
    setSelectedDate(startOfDay.toDate());
    setFormattedDate(startOfDay.format("YYYY-MM-DD"));

    fetchTreatments(startOfDay.format("YYYY-MM-DD"));
  };

  // Function to navigate dates
  const navigateDate = (days) => {
    const newDate = moment(selectedDate).add(days, "days");
    setSelectedDate(newDate.toDate());
    setFormattedDate(newDate.format("YYYY-MM-DD"));

    // Clear the data
    setTreatments([]);
    setLoader(true);

    fetchTreatments(newDate.format("YYYY-MM-DD"));
  };

  const formatDateToYYYYMMDD = (date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };

  const onSelectAllChange = (e) => {
    if (e.target.checked) {
      const newSelectedRowKeys = treatments.map((t) => t._id); // Ensure 'treatments' is the correctly formatted data

      setSelectedRowKeys(newSelectedRowKeys);
    } else {
      setSelectedRowKeys([]);
    }
  };

  const statusColors = {
    scheduled: "rgba(78, 137, 174, 0.2)",
    arrived: "rgba(247, 108, 108, 0.2)",
    complete: "rgba(52, 168, 83, 0.2)",
    pickup: "rgba(128, 0, 128, 0.2)",
  };

  const handleReviewChange = async (orderId, isReviewed) => {
    try {
      await axios.put(
        `/patient/order/update-review/${orderId}`,
        { review: isReviewed },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      toast.success("Review status updated successfully");
      // Refetch data or update state as needed
      handleDataReset(formattedDate);
    } catch (error) {
      toast.error("Error updating review status");
      console.error("Error:", error);
    }
  };

  const formatDataForTable = (data) => {
    return data?.map((item) => {
      // Include userLabs where checked is true
      const labs = item.userLabs
        .filter((lab) => lab.checked)
        ?.map((lab) => lab.test_name);

      // Include userdispensed items with non-empty selectedField
      const dispensed2 = item.userdispensed
        .filter((d) => d.quantity > 0)
        ?.map((d) => `${d.name} (${d.selectedField}) x${d.quantity}`);

      const supplements = item.userSupplements.map(
        (s) => `${s.name} x${s.quantity}`
      );

      const dispensed = [...dispensed2, ...supplements];

      // Include userProcedures where checked is true or selectedField is not an empty string
      const procedures = item.userProcedures
        .filter(
          (procedure) =>
            procedure.checked === true ||
            (procedure.selectedField && procedure.selectedField !== "" && !procedure.custom)
        )
        ?.map((procedure) => {
          if (procedure.selectedField && procedure.selectedField !== "") {
            return `${procedure.procedure_name} (${procedure.selectedField})`;
          }
          return procedure.procedure_name;
        });

      // Filter vitals
      const vitals = item?.vitals?.some(
        (vital) =>
          vital.HR !== 0 ||
          vital.BP !== "" ||
          vital.T !== 0 ||
          vital.W !== 0 ||
          vital.RR !== 0 ||
          vital.SPO2 !== 0
      )
        ? item.vitals
        : [];
      
      // Use the correct properties from the data structure
      return {
        key: item["_id"],
        patientName: item.patientName, // Assuming patient name isn't provided, so using PatientId
        room: item.room || "unassigned", // Provide a fallback if room is undefined
        procedures,
        dispensed,
        usersupplements: item.usersupplements,
        labs,
        note: item.note || "",
        assignees: item.assignees || "",
        status: item.status,
        review: item.review,
        treatmentNotes: item.treatmentNotes,
        vitals,
      };
    });
  };

  const sortDataByName = (data) => {
    return data.sort((a, b) => {
      const lastNameA = a.patientName.split(" ").pop().toUpperCase();
      const lastNameB = b.patientName.split(" ").pop().toUpperCase();
      // Compare the last names
      if (lastNameA < lastNameB) {
        return -1;
      }
      if (lastNameA > lastNameB) {
        return 1;
      }

      // If last names are equal, compare the full names
      return a.patientName
        .toUpperCase()
        .localeCompare(b.patientName.toUpperCase());
    });
  };

  // Custom sorting function to move "pickup" items to the bottom
  const sortTreatmentsWithPickupLast = (data) => {
    const pickupItems = data.filter(
      (treatment) => treatment.status === "pickup"
    );
    const otherItems = data.filter(
      (treatment) => treatment.status !== "pickup"
    );
    return [...otherItems, ...pickupItems];
  };

  let sortedTreatments = sortTreatmentsWithPickupLast(
    formatDataForTable(treatments)
  );

  useEffect(() => {
    sortedTreatments = sortTreatmentsWithPickupLast(
      formatDataForTable(treatments)
    );

    treatments.forEach(async (treatment) => {
      const ivPrepData = await fetchIV_Prep(treatment._id);
      setIvPrepByTreatment((prev) => ({
        ...prev,
        [treatment._id]: ivPrepData,
      }));
    });
  }, [treatments]);

  const handlePatientClickTreatmentView = (e, patientId2) => {
    e.preventDefault(); // Prevent default link behavior
    navigate(`/treatmentviewdisplay/${patientId2}`);
  };

  const handlePatientClickAuditView = (e, patientId2, orderid) => {
    e.preventDefault(); // Prevent default link behavior

    navigate(`/auditview/${patientId2}/${orderid}`);
  };

  const handleCheckboxChange = async (e) => {
    const isChecked = e.target.checked;
    setShowInactivePatients(isChecked);

    if (isChecked && inactivePatients.length === 0) {
      await getInactivePatientProfiles();
    }
  };

  const deleteTreatment = async (treatmentId) => {
    try {
      const response = await axios.delete(
        `/patient/order/delete/${treatmentId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Treatment deleted successfully");
        // Refresh the treatments list

        handleDataReset(selectedDate);
      } else {
        toast.error("Failed to delete treatment");
      }
    } catch (error) {
      console.error("Error deleting treatment:", error);
      toast.error("Error deleting treatment");
    }
  };
  //Table functionalities

  const handleBulkActionChange = (value) => {
    setBulkAction(value || null);
    setShowDatePicker(value === "Date");
    setShowDuplicateOptions(value === "Duplicate");
  };

  // Function to execute the selected bulk action
  const executeBulkAction = async () => {
    if (bulkAction === "Date" && bulkActionDate) {
      try {
        const response = await axios.put(
          `/patient/order/bulk-update-date`,
          { treatmentIds: selectedRowKeys, newDate: bulkActionDate },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );

        if (response.status === 200) {
          toast.success("Treatments date updated successfully");
          handleDataReset(selectedDate);
          // Refresh your data here
        } else {
          toast.error("Failed to update treatments date");
        }
      } catch (error) {
        toast.error("Error updating treatments date");
        console.error("Error:", error);
      }
    } else if (bulkAction === "Duplicate" && duplicateActionDate) {
      try {
        const response = await axios.post(
          "/patient/order/bulk-duplicate",
          {
            treatmentIds: selectedRowKeys,
            newDate: duplicateActionDate,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );

        if (response.status === 200) {
          toast.success("Treatments duplicated successfully");
          handleDataReset(selectedDate);
          setSelectedRowKeys([]);
        } else {
          toast.error("Failed to duplicate treatments");
        }
      } catch (error) {
        toast.error("Error duplicating treatments");
        console.error("Error:", error);
      }
    } else if (bulkAction === "delete") {
      // Handle bulk delete action
      await handleBulkDelete();
    } else if (bulkAction === "review") {
      if (selectedRowKeys.length === 0) {
        toast.error("No treatments selected for review");
        return;
      }
      try {
        const response = await axios.put(
          "/patient/order/bulk-review",
          {
            treatmentIds: selectedRowKeys,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );

        if (response.status === 200) {
          toast.success("Treatments set to review successfully");
          handleDataReset(selectedDate);
          setSelectedRowKeys([]);
        } else {
          toast.error("Failed to review treatments");
        }
      } catch (error) {
        toast.error("Error reviewing treatments");
        console.error("Error reviewing treatments:", error);
      }
    } else {
      handleBulkUpdate(bulkAction);
    }
  };

  // Function to handle date selection for bulk actions
  const handleBulkActionDateChange = (date) => {
    setBulkActionDate(date ? date.toISOString().split("T")[0] : null);
  };

  const handleBulkActionDuplicateChange = (date) => {
    setDuplicateActionDate(date ? date.toISOString().split("T")[0] : null);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const handleBulkUpdate = async (newStatus) => {
    if (selectedRowKeys.length === 0) {
      toast.error("No treatments selected");
      return;
    }

    // Call the new backend API for bulk update
    try {
      const response = await axios.put(
        "/patient/order/bulk-update-status",
        {
          treatmentIds: selectedRowKeys,
          newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success(`Treatments updated to ${newStatus}`);
        handleDataReset(selectedDate);
        setSelectedRowKeys([]);
      } else {
        toast.error("Failed to update treatments");
      }
    } catch (error) {
      toast.error("Error updating treatments");
      console.error("Error updating treatments:", error);
    }
  };

  const handleBulkDelete = async () => {
    if (selectedRowKeys.length === 0) {
      toast.error("No treatments selected");
      return;
    }

    // Call the new backend API for bulk delete
    try {
      const response = await axios.delete(
        "/patient/order/bulk-delete",
        {
          data: { treatmentIds: selectedRowKeys },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Treatments deleted successfully");
        handleDataReset(selectedDate);
        setSelectedRowKeys([]);
      } else {
        toast.error("Failed to delete treatments");
      }
    } catch (error) {
      toast.error("Error deleting treatments");
      console.error("Error deleting treatments:", error);
    }
  };

  //REACT DRAG AND DROP FUNCTIONALITY

  const changeTreatmentStatus = async (treatmentId, newStatus) => {
    try {
      const response = await axios.put(
        `/treatments/updateStatus/${treatmentId}`,
        {
          status: newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Treatment status updated successfully");
        // You might want to fetch the updated list of treatments here
      } else {
        toast.error("Failed to update treatment status");
      }
    } catch (error) {
      toast.error("Error updating treatment status");
      console.error("Error updating treatment status:", error);
    }
  };

  const moveCard = (cardId, newStatus) => {
    // Change status of the treatment with matching cardId
    const updatedTreatments = treatments?.map((treatment) => {
      if (treatment._id === cardId) {
        return { ...treatment, status: newStatus };
      }
      return treatment;
    });

    setTreatments(updatedTreatments);

    // Persist the status change to the server
    changeTreatmentStatus(cardId, newStatus);
  };

  const ItemTypes = {
    CARD: "card",
    ROOM: "room",
  };

  const lightColors = [
    "#d1ecf1",
    "#d4edda",
    "#fff3cd",
    "#fce8b2",
    "#dcd3ff",
    "#f8d7da",
  ];

  const getRoomColor = (() => {
    const colorMap = {};
    let colorIndex = 0;

    return (roomName) => {
      if (!(roomName in colorMap)) {
        colorMap[roomName] = lightColors[colorIndex % lightColors.length];
        colorIndex++;
      }
      return colorMap[roomName];
    };
  })();

  const draggableCardStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "15px",
    margin: "5px 5px 5px 0",
    borderRadius: "20px",
    boxSizing: "border-box",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };

  const droppableAreaStyle = {
    // border: "2px solid #d3d3d3", // Light border
    borderRadius: "6px",
    padding: "10px 0px 0px 10px",
    display: "flex",
    flexWrap: "wrap",
    width: "100%", // Take 100% width of the parent container
    minHeight: "50px", // Set to 50px more than the card height
    marginBottom: "20px", // Margin bottom for spacing between areas
    justifyContent: "flex-start", // Align items to the start
    alignItems: "flex-start", // Align items to the start
    // backgroundColor: "#f9f9f9",    // Optional light background color
    // boxShadow: "rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(204, 219, 232) -3px -3px 6px 1px inset",
  };

  const fetchIV_Prep = async (treatmentID) => {
    try {
      const response = await axios.get(
        `/iv_prep/treatment/${treatmentID}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching IV preparation data:", error);
    }
  };

  const getCheckedLabs = (userLabs) => {
    return userLabs.filter((lab) => lab.checked);
  };
  const getDispensedItemsWithSelectedField = (userdispensed) => {
    return userdispensed.filter((item) => item.selectedField);
  };
  const getRelevantProcedures = (userProcedures) => {
    return userProcedures.filter(
      (procedure) => procedure.checked || procedure.selectedField
    );
  };

  const TooltipContent = ({ treatment }) => {
    const ivPrepData = ivPrepByTreatment[treatment._id] || {
      procedures: [],
      dispensed: [],
    };

    const getProcedureStyle = (name) => {
      const found = ivPrepData.procedures.find(
        (p) => p.name === name && p.comped
      );
      return found ? { color: "orange" } : {};
    };
    const getDispensedStyle = (name) => {
      const found = ivPrepData.dispensed.find(
        (p) => p.name === name && p.comped
      );
      return found ? { color: "orange" } : {};
    };

    const checkedLabs = getCheckedLabs(treatment.userLabs);
    const dispensedItems = getDispensedItemsWithSelectedField(
      treatment.userdispensed
    );
    const relevantProcedures = getRelevantProcedures(treatment.userProcedures);

    return (
      <div>
        {checkedLabs.length > 0 && (
          <div className="tooltipSection">
            <strong>Labs:</strong>
            {checkedLabs?.map((lab) => (
              <div key={lab._id} className="tooltipItem">
                {lab.test_name}
              </div>
            ))}
          </div>
        )}
        <br />
        {dispensedItems.length > 0 && (
          <div className="tooltipSection">
            <strong>Dispensed:</strong>
            {dispensedItems?.map((item) => (
              <div
                key={item._id}
                className="tooltipItem"
                style={getDispensedStyle(item.name)}
              >
                {item.name} - Quantity: {item.quantity} - Dosage:{" "}
                {item.selectedField}
              </div>
            ))}
          </div>
        )}
        <br />
        {relevantProcedures.length > 0 && (
          <div className="tooltipSection">
            <strong>Procedures:</strong>
            {relevantProcedures?.map((procedure) => (
              <div
                key={procedure._id}
                className="tooltipItem"
                style={getProcedureStyle(procedure.procedure_name)}
              >
                {procedure.procedure_name}
                {procedure.selectedField &&
                  ` - Selected: ${procedure.selectedField}`}
                {procedure.note && ` - Note: ${procedure.note}`}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const moveCardRoom = (room, treatment) => {
    handleCellChange(treatment?._id, "room", room.room, "patientInfo");
  };

  const DroppableAreaRoom = ({ treatment, startTime, moveCardRoom }) => {
    const [, drop] = useDrop({
      accept: ItemTypes.ROOM,
      hover: () => {
        // if (hoveredColumn !== status) {
        //   setHoveredColumn(status);
        // }
      },
      drop: (item, monitor) => {
        moveCardRoom(item, treatment);
        setHoveredColumn(null);
      },
    });

    return (
      <span ref={drop} className="flex justify-center items-center">
        {treatment.patientName}
        {treatment.status !== "pickup" && treatment.room
          ? ` - ${treatment.room}`
          : ""}
        {treatment.time ? ` - ${treatment.time}` : ""}
        {treatment.custom ? ` - ${treatment.custom}` : ""}
        {startTime ? ` - ${startTime}` : ""}
        {treatment.type === "pickup" && (
          <IconButton>
            <AddShoppingCartIcon
              style={{
                fontSize: "18px",
                color: treatment.status === "complete" ? "white" : "",
              }}
            />
          </IconButton>
        )}
      </span>
    );
  };

  const getCalendarIdByType = (type) => {
    switch (type) {
      case "scheduled":
        return scheduledCalendar ? scheduledCalendar._id : null;
      case "arrived":
        return arrivedCalendar ? arrivedCalendar._id : null;
      case "pickup":
        return pickupCalendar ? pickupCalendar._id : null;
      case "complete":
        return completedCalendar ? completedCalendar._id : null;
      default:
        return null;
    }
  };

  async function createWatch(eventId, treatmentId, calendarId, status) {
    const responseWatch = await axios.post(
      `/create-watch`,
      {
        eventId: eventId,
        treatmentId: treatmentId,
        calendarId: calendarId,
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        },
      }
    );
  }

  async function createEventWithTime(
    time,
    status,
    treatmentId,
    timezone,
    note,
    patientName,
    selectedDate
  ) {
    try {
      const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
      const cid = getCalendarCidByType(status);

      const response = await axios.post(
        `/create-new-event`,
        { time, patientName, timezone, note, cid, selectedDate: formattedDate }
      );
      const idc = getCalendarIdByType(status);
      createWatch(response.data.id, treatmentId, idc, status);
      toast.success("New event created for treatment");

      handleCellChange(treatmentId, "event", response.data.id, "patientInfo");
    } catch (error) {
      toast.error(error);
      console.error(error);
    }
  }

  const handleTimeChange = (
    time,
    treatmentId,
    eventId,
    status,
    patientName,
    timezone,
    note
  ) => {
    const timeString = time ? time.format("hh:mm a") : "";
    if (eventId && eventId !== "unassigned") {
      changeEventTime(
        eventId,
        status,
        timeString,
        note,
        timezone,
        patientName,
        selectedDate
      );
    }
    if (eventId === "unassigned") {
      createEventWithTime(
        timeString,
        status,
        treatmentId,
        timezone,
        note,
        patientName,
        selectedDate
      );
    }

    handleCellChange(treatmentId, "time", timeString, "patientInfo");

    // toggleCalendarVisibility(treatmentId);
  };

  const disabledHours = () => {
    const hours = [];
    for (let i = 0; i < 8; i++) {
      hours.push(i);
    }
    for (let i = 19; i < 24; i++) {
      hours.push(i);
    }
    return hours;
  };

  const DraggableCard = ({ treatment, moveCard, maxTabsPerRow }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
      type: ItemTypes.CARD,
      item: { id: treatment._id },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      canDrag: treatment.review === true ? false : true
    }));

    // Adjust the opacity when dragging
    const opacity = isDragging ? 0.5 : 1;
    const backgroundColor = getRoomColor(treatment.room);

    // Adjust the width dynamically based on maxTabsPerRow
    const cardStyle = {
      ...draggableCardStyle,
      width: `calc(${100 / maxTabsPerRow}% - 10px)`, // Adjust the calculation as needed
    };
    const event = CalendarEvents.find(
      (event) => event.treatmentId == treatment["_id"]
    );

    const startTime = event ? moment(event.startTime).format("HH:mm") : "";

    return (
      <div
        ref={drag}
        className={`${styles.draggableCardDisplay} md:w-1/5 lg:w-1/5`}
        style={{
          ...cardStyle,
          opacity,
          color: treatment.status === "complete" ? "white" : "",
          backgroundColor:
            treatment.status === "complete" ? "#228B22" : backgroundColor,
          cursor: treatment.review===true && 'not-allowed',
        }}
      >
        <Tooltip
          title={<TooltipContent treatment={treatment} />}
          placement="top"
        >
          <span
            className={`justify-self-center ${
              treatment.status === "pickup" ? "text-sm" : ""
            }`}
            onClick={(e) => {
              handlePatientClickAuditView(
                e,
                treatment.PatientId,
                treatment._id
              );
            }}
          >
            <DroppableAreaRoom
              treatment={treatment}
              startTime={startTime}
              moveCardRoom={moveCardRoom}
            />
          </span>
        </Tooltip>

        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{ zIndex: 1000, marginRight: "5px" }}
            key={treatment._id}
            onClick={(e) => {
              e.stopPropagation();
              setIsTyping(true);
            }}
          >
            <style jsx>{`
              .ant-picker-ok button {
                color: white;
                background-color: #3b82f6;
              }
            `}</style>
            <Popover
              content={
                <div>
                  <Form
                    form={form}
                    initialValues={{
                      note: "",
                    }}
                    onFinish={(values) => {
                      // Handle form submission here

                      const timezone =
                        Intl.DateTimeFormat().resolvedOptions().timeZone;

                      handleTimeChange(
                        values.time,
                        treatment._id,
                        treatment?.event,
                        treatment?.status,
                        treatment?.patientName,
                        timezone,
                        values.note
                      );
                      // toggleCalendarVisibility(null);
                    }}
                  >
                    <div className="flex">
                      <Form.Item
                        name="time"
                        rules={[
                          {
                            required: true,
                            message: "Please select a time!",
                          },
                        ]}
                      >
                        <TimePicker
                          use12Hours
                          format="h:mm a"
                          minuteStep={15}
                          allowClear={true}
                          onChange={(time) => {
                            form.setFieldsValue({ time });
                          }}
                          showNow={false}
                          disabledHours={disabledHours}
                          // disabledMinutes={disabledMinutes}
                        />
                      </Form.Item>

                      <Form.Item className="ml-[5px]">
                        <Button
                          style={{
                            color: "white",
                            backgroundColor: "#3B82F6",
                          }}
                          type="primary"
                          htmlType="submit"
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </div>
                    <Form.Item name="note">
                      <InputAnt placeholder="Enter note here" />
                    </Form.Item>
                  </Form>
                </div>
              }
              trigger="click"
              // open={visibleCalendarId === treatment._id}
              // onOpenChange={(open) => handleOpenChange(open, treatment)}
            >
              <ScheduleIcon
                onClick={() => setIsTyping(true)}
                style={{ fontSize: "18px", marginLeft: "8px" }}
              />
            </Popover>
          </div>
          <Popconfirm
            title="Are you sure to delete this treatment?"
            onConfirm={() => deleteTreatment(treatment._id)}
            okText="Yes"
            cancelText="No"
            okButtonProps={{
              className:
                "btn-color py-1 px-10 text-xs md:text-sm w-full md:w-auto",
            }}
            cancelButtonProps={{
              className:
                "btn-color py-1 px-10 text-xs md:text-sm w-full md:w-auto",
            }}
          >
            <DeleteOutlined style={{ fontSize: "16px" }} />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const DroppableArea = ({ status, treatments, moveCard }) => {
    const [, drop] = useDrop({
      accept: ItemTypes.CARD,
      hover: () => {
        if (hoveredColumn !== status) {
          setHoveredColumn(status);
        }
      },
      drop: (item, monitor) => {
        moveCard(item.id, status);
        setHoveredColumn(null);
      },
    });

    // Update style based on hover state
    const isHovered = hoveredColumn === status;
    const dynamicStyle = isHovered
      ? { backgroundColor: statusColors[status] }
      : {};

    const treatmentsInStatus = treatments.filter((t) => t.status === status);
    const treatmentCount = treatmentsInStatus.length;

    let maxTabsPerRow;
    if (treatmentCount <= 7) maxTabsPerRow = 1;
    else if (treatmentCount <= 14) maxTabsPerRow = 2;
    else maxTabsPerRow = 3;

    return (
      <div ref={drop} style={{ ...droppableAreaStyle, ...dynamicStyle }}>
        {treatments
          .filter((t) => t.status === status)
          .map((treatment) => (
            <DraggableCard
              key={treatment._id}
              treatment={treatment}
              moveCard={moveCard}
              maxTabsPerRow={maxTabsPerRow} // Pass maxTabsPerRow as a prop
            />
          ))}
        {treatments.filter((t) => t.status === status).length === 0 && (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              color: "#aaa",
              height: "100%",
              minHeight: "250px",
            }}
          >
            Drag cards here
          </div>
        )}
      </div>
    );
  };

  //LPDV FUNCTIONALITY

  const handleOpenLabsModal = async (orderId) => {
    try {
      const response = await axios.get(
        `/orderget/${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      setPatientId(response.data.PatientId);
      // Separate checked and unchecked labs
      const checkedLabs = response.data.userLabs.filter((lab) => lab.checked);
      const uncheckedLabs = response.data.userLabs.filter(
        (lab) => !lab.checked
      );

      // Combine them with checked labs first
      const sortedLabs = [...checkedLabs, ...uncheckedLabs];

      setCurrentPatientLabs({ labs: sortedLabs, orderId: orderId });
      setLabsModalOpen(true);
      setClickedTreatmentData(response.data);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
    }
  };

  const handleOpenProcedureModal = async (patientId) => {
    try {
      const response = await axios.get(
        `/orderget/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      const userProcedures = response.data.userProcedures;
      proceduresSortingFunction(contextProcedures, userProcedures);
      setCurrentProcedures(userProcedures);
      //
      setCurrentSupplements(response.data.userSupplements);
      setClickedTreatmentData(response.data);
      setProcedureModalOpen(true);
      setCurrentPatientIdForModal(patientId);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
    }
  };

  const handleOpenSupplementsModal = async (patientId, record) => {
    try {
      const userSupplementsResponse = await axios.get(
        `/userSupplements/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      const availableSupplementsResponse = await axios.get(
        "/supplements-names",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      const PatientData = activePatient.find(
        (patient) => patient["Full Name"] === record?.patientName
      );
      setPatientData(PatientData);
      setClickedTreatmentData(record);
      try {
        const response = await axios.get(
          "/getAllSupplements"
        );

        setAllSupplements(response.data);
        const updatedSupplements = userSupplementsResponse.data.map((supp) => {
          const matchedSupplement = response.data.find(
            (supplement) => supplement.supplement_name === supp.name
          );

          if (matchedSupplement) {
            return {
              ...supp,
              frequencyOptions:
                matchedSupplement.frequency[0] === "1 dosage twice a day"
                  ? [...matchedSupplement.frequency]
                  : ["1 dosage twice a day", ...matchedSupplement.frequency],
            };
          }
          return supp;
        });

        // setSupplements(updatedSupplements);
        // const sortedItems = sortItems([...updatedSupplements]);
        setCurrentSupplements(updatedSupplements);
      } catch (error) {
        console.error("Error fetching supplements:", error);
      }

      // const userSupplementsData = userSupplementsResponse.data;
      const availableSupplementsData = availableSupplementsResponse.data;
      setAvailableSupplements(availableSupplementsData);
      // setCurrentSupplements(userSupplementsData);
      setSupplementsPopup(true);
      setCurrentPatientIdForModal(patientId);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
    }
  };

  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredLabs = currentPatientLabs.labs.filter((lab) =>
    lab.test_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const halfLength = Math.ceil(filteredLabs.length / 2);

  // const firstColumnItems = filteredLabs.slice(0, halfLength);
  // const secondColumnItems = filteredLabs.slice(halfLength);

  const firstColumnItems = currentPatientLabs.labs
  .map((lab, index) => ({ ...lab, originalIndex: index }))
  .filter((_, index) => index % 2 === 0);

  const secondColumnItems = currentPatientLabs.labs
    .map((lab, index) => ({ ...lab, originalIndex: index }))
    .filter((_, index) => index % 2 !== 0);


  const handleOpenDispenseModal = async (patientId) => {
    try {
      const response = await axios.get(
        `/orderget/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      const userDispenses = response.data.userdispensed;
      dispensesSortingFunction(contextDispensed, userDispenses);
      setCurrentDispensedItems(userDispenses);
      setClickedTreatmentData(response.data);
      setDispenseModalOpen(true);
      setCurrentPatientIdForModal(patientId);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
      // Handle the error appropriately in your application
    }
  };

  const handleOpenVitalsModal = async (patientId) => {
    try {
      const response = await axios.get(
        `/orderget/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      setCurrentVitalsItems(response.data.vitals);
      setVitalsModalOpen(true);
      setCurrentPatientIdForModal(patientId);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
      // Handle the error appropriately in your application
    }
  };

  const handleOpenTreatmentNoteModal = async (treatmentNote) => {
    //treatmentNote is basically the treatment we want to send to treatment Note
    if (treatmentNote) {
      const selectedTreatment = treatments.find(
        (treatmentFind) => treatmentFind._id === treatmentNote.key
      );

      setCurrentTreatment({
        ...treatmentNote,
        custom: selectedTreatment.custom,
        date: selectedTreatment.date.split("T")[0],
        userLabs: selectedTreatment.userLabs,
        userProcedures: selectedTreatment.userProcedures,
        userdispensed: selectedTreatment.userdispensed,
        PatientId: selectedTreatment.PatientId,
        _id: selectedTreatment._id,
      });
      setTreatmentNoteModalOpen(true);
      isTreatmentNoteEditing.current = true;
    }
  };

  const handleCloseTreatmentNoteModal = () => {
    setCurrentTreatment(null);
    setTreatmentNoteModalOpen(false);
  };

  const setIsTreatmentNoteEditing = (isTreatmentNoteEditingVal) => {
    isTreatmentNoteEditing.current = isTreatmentNoteEditingVal;
  };

  function splitByLastDash(columnName) {
    // Find the index of the last dash
    const lastIndex = columnName.lastIndexOf("-");

    // If there's no dash, or the dash is the first character, return an error or handle it as needed
    if (lastIndex <= 0) {
      console.error("No dash found, or the dash is at the start of the string");
      return columnName;
    }

    // Split the string into two parts
    const itemName = columnName.substring(0, lastIndex);
    const field = columnName.substring(lastIndex + 1);

    return [itemName, field];
  }

  const handleCellChange = (patientId, columnName, newValue, category) => {
    let payload;

    if (category === "vitals") {
      // Make sure newValue is an array and directly assign it to updates
      payload = {
        category: category,
        updates: newValue, // Ensure this is correctly formatted as an array of vitals
      };
    } else {
      // Handling for other categories remains the same
      const identifierKey =
        category === "userProcedures" ? "procedure_name" : "name";
      let [itemName, field] = splitByLastDash(columnName);

      if (category === "patientInfo") {
        field = columnName;
      }

      payload = {
        category: category,
        identifier: {
          key: identifierKey,
          value: itemName,
        },
        updates: {
          [field]: newValue,
        },
      };
    }

    // Send the update request using axios
    axios
      .put(`/patient/order/update/${patientId}`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          "Content-Type": "application/json", // Specify the content type of your request
        },
      })
      .then((response) => {
        handleDataReset(selectedDate);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleCloseLabsModal = () => {
    setLabsModalOpen(false);
    setSearchTerm(""); // Clear the search term
  };

  const handleLabsCheckboxChange = (labId, checked) => {
    const scrollTop = containerRef.current.scrollTop;
    setCurrentPatientLabs((prevState) => {
      // Find the lab by ID to get its name
      const lab = prevState.labs.find((lab) => lab._id === labId);
      const labName = lab ? lab.test_name : "Unknown Lab";
      const labItemNumber = lab?.item_number;

      // {
      //   "category": "patientLabs",
      //
      // }

      // Construct the payload for the POST request
      const payload = {
        category: "patientLabs",
        updates: {
          checked: checked,
          value: labItemNumber ? `${labItemNumber}` : labName,
          type: labItemNumber ? "item_number" : "test_name",
        },
      };

      // Send the update to the backend using a POST request
      axios
        .put(
          `/patient/order/update/${currentPatientLabs.orderId}`,

          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        )
        .then((response) => {
          // Handle the successful update
          handleRefreshLabsAndTubes();
          handleDataReset(selectedDate);
        })
        .catch((error) => {
          // Handle any errors
          console.error("Update failed:", error);
        });

      // Update the labs state
      return {
        ...prevState,
        labs: prevState.labs?.map((lab) =>
          lab._id === labId ? { ...lab, checked: checked } : lab
        ),
      };
    });
    requestAnimationFrame(() => {
      containerRef.current.scrollTop = scrollTop;
    });
  };

  const [pickup, setPickup] = useState(false);

  const handlePickupOpen = () => {
    setPickup(true);
  };

  const handlePickupClose = () => {
    setPickup(false);
  };
  const [isSupModalClosed, setIsSupModalClosed] = useState(false);
  const [supplementsPopup, setSupplementsPopup] = useState(false);
  const [selectedTreatmentId, setSelectedTreatmentId] = useState();
  const handleSupplementsPopupOpen = () => {
    setSupplementsPopup(true);
  };

  const handleSupplementsPopupClose = () => {
    setSupplementsPopup(false);
  };

  //Search Functionality

  const handleSearchFilterChange = (e) => {
    setSearchFilter(e.target.value.toLowerCase());
  };

  const handleOrderTableSearchFilterChange = (e) => {
    setOrderTableSearchFilter(e.target.value.toLowerCase());
  };

  const filteredRows = rows.filter((item) => {
    const matchesSearch =
      !searchFilter ||
      item?.patientName?.toLowerCase()?.includes(searchFilter) ||
      item?.room?.toLowerCase()?.includes(searchFilter) ||
      item?.shopify_note?.toLowerCase()?.includes(searchFilter) ||
      (item?.assignees || [])
        .join(", ")
        ?.toLowerCase()
        ?.includes(searchFilter) ||
      item?.userSupplements?.some(
        (supplement) =>
          supplement.name.toLowerCase().includes(searchFilter) &&
          supplement.quantity > 0
      ) ||
      item?.userdispensed?.some(
        (dispensed) =>
          dispensed.name.toLowerCase().includes(searchFilter) &&
          dispensed.checked
      ) ||
      item?.userProcedures?.some(
        (procedure) =>
          procedure.procedure_name.toLowerCase().includes(searchFilter) &&
          procedure.checked
      ) ||
      item?.userLabs?.some(
        (lab) =>
          lab.test_name.toLowerCase().includes(searchFilter) && lab.checked
      );

    return matchesSearch;
  });

  const filteredOrderRows = orderRows.filter((item) => {
    const matchesSearch =
      !orderTableSearchFilter ||
      item?.patientName?.toLowerCase()?.includes(orderTableSearchFilter) ||
      item?.room?.toLowerCase()?.includes(orderTableSearchFilter) ||
      item?.shopify_note?.toLowerCase()?.includes(orderTableSearchFilter) ||
      (item?.assignees || [])
        .join(", ")
        ?.toLowerCase()
        ?.includes(orderTableSearchFilter) ||
      item?.userSupplements?.some(
        (supplement) =>
          supplement.name.toLowerCase().includes(orderTableSearchFilter) &&
          supplement.quantity > 0
      ) ||
      item?.userdispensed?.some(
        (dispensed) =>
          dispensed.name.toLowerCase().includes(orderTableSearchFilter) &&
          dispensed.checked
      ) ||
      item?.userProcedures?.some(
        (procedure) =>
          procedure.procedure_name.toLowerCase().includes(orderTableSearchFilter) &&
          procedure.checked
      ) ||
      item?.userLabs?.some(
        (lab) =>
          lab.test_name.toLowerCase().includes(orderTableSearchFilter) && lab.checked
      );

    return matchesSearch;
  });

  const statusPriorities = {
    scheduled: 1,
    arrived: 2,
    pickup: 3,
    complete: 4,
  };

  function sortTreatmentsByStatus(treatments) {
    return treatments.sort((a, b) => {
      // Compare by status first
      const statusComparison =
        statusPriorities[a.status] - statusPriorities[b.status];
      if (statusComparison !== 0) return statusComparison;

      // If statuses are the same, then compare by time
      return timeToMinutes(a.time) - timeToMinutes(b.time);
    });
  }
  function sortOrdersByStatus(treatments) {
    return treatments.sort((a, b) => {
      // Compare by status first
      const statusComparison =
        statusPriorities[a.status] - statusPriorities[b.status];
      if (statusComparison !== 0) return statusComparison;

      // If statuses are the same, then compare by time
      return timeToMinutes(a.time) - timeToMinutes(b.time);
    });
  }
  const timeToMinutes = (time) => {
    if (!time) return 0;
    const [hours, minutes, meridian] = time
      .match(/(\d+):(\d+)\s*(am|pm)/i)
      .slice(1);
    return (
      ((hours % 12) + (meridian.toLowerCase() === "pm" ? 12 : 0)) * 60 +
      parseInt(minutes)
    );
  };

  const sortedRows = sortTreatmentsByStatus(filteredRows);
  const sortedOrderRows = sortOrdersByStatus(filteredOrderRows);

  const handleRoomFilterChange = (value) => {
    setRoomFilter(value);
  };

  const handleAssigneeFilterChange = (value) => {
    setAssigneeFilter(value);
  };

  //Room Functionality

  const toggleRoomsList = () => {
    setIsRoomsListOpen(!isRoomsListOpen);
  };

  const groupPatientsByRoom = (treatments) => {
    const grouped = {};
    treatments.forEach((treatment) => {
      const room = treatment.room || "unassigned";
      const patientFirstName = treatment.patientName.split(" ")[0]; // Get the first name
      if (!grouped[room]) {
        grouped[room] = [patientFirstName];
      } else {
        grouped[room].push(patientFirstName);
      }
    });
    return grouped;
  };

  const patientsGroupedByRoom = groupPatientsByRoom(treatments);

  const FloatingRoomsList = ({ rooms, patientsGroupedByRoom }) => {
    //
    const [isRoomsListOpen, setIsRoomsListOpen] = useState(false);

    const toggleRoomsList = () => {
      setIsRoomsListOpen(!isRoomsListOpen);
    };

    return (
      <div className="border border-blue-500 rounded-md mt-[30px]">
        <div onClick={toggleRoomsList}>
          <div className="div"></div>
          <h4 className="bg-blue-500 md:p-0 font-bold text-xl text-white">
            <span className="p-3">Available Rooms</span>
          </h4>
          <hr className="border-blue-500" />
          <div className="p-1 grid grid-cols-2 mt-[10px]">
            {rooms?.map((room, index) => (
              <DraggableCardRoom
                room={room}
                patientsGroupedByRoom={patientsGroupedByRoom}
                index={index}
                maxTabsPerRow={3}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  const darkColors = [
    "#3cafc3",
    "#4bb464",
    "#e6b000",
    "#c59207",
    "#2e00e6",
    "#ad1f2a",
  ];

  const getRoomColorDark = (() => {
    const colorMap = {};
    let colorIndex = 0;

    return (roomName) => {
      if (!(roomName in colorMap)) {
        colorMap[roomName] = darkColors[colorIndex % darkColors.length];
        colorIndex++;
      }
      return colorMap[roomName];
    };
  })();

  const DraggableCardRoom = ({
    room,
    index,
    maxTabsPerRow,
    patientsGroupedByRoom,
  }) => {
    const hasPatients = Boolean(patientsGroupedByRoom[room]);
    const backgroundColor = hasPatients ? getRoomColor(room) : "transparent";
    const roundColor = hasPatients ? getRoomColorDark(room) : "#949494";
    const [{ isDragging }, drag] = useDrag(() => ({
      type: ItemTypes.ROOM,
      item: { room },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }));

    return (
      <div
        ref={drag}
        key={index}
        style={{ backgroundColor }}
        className="m-[5px] border-gray-300 shadow-md p-1 rounded-[5px] flex items-start"
      >
        <div className="flex items-center mr-2 mt-[6px]">
          <Round color={roundColor} />
        </div>
        <div className="flex-1 min-w-0">
          {room}
          {patientsGroupedByRoom[room] && " - "}
          {patientsGroupedByRoom[room] && (
            <>{patientsGroupedByRoom[room].join(", ")}</>
          )}
        </div>
      </div>
    );
  };

  //Status Key Filters
  const handleStatusFilter = (status) => {
    setSelectedStatus(status);
  };

  const isStatusSelected = (status) => {
    return selectedStatus === status;
  };

  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarPosition, setCalendarPosition] = useState({ top: 0, left: 0 });
  const iconRef = useRef(null);
  const handleIconClick = () => {
    if (iconRef.current) {
      const iconBounds = iconRef.current.getBoundingClientRect(); // Get position relative to the viewport
      // You can adjust 'top' and 'left' as needed, e.g., to place the calendar right below the icon
      setCalendarPosition({
        top: iconBounds.bottom + window.scrollY - 20,
        left: iconBounds.left + window.scrollX - 50,
      });
    }
    setShowCalendar(!showCalendar);
  };

  const [customItems, setCustomItems] = useState([]);
  const [shouldScroll, setShouldScroll] = useState(false); 
  useEffect(() => {
      if (shouldScroll) {
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
        setShouldScroll(false); 
      }
    }, [shouldScroll]); 
  const handleAddEmptyRowNew = () => {
    const newItem = {  test_name: "", checked: false, isNew: true, editItemName:true }
    // setCustomItems([
    //   ...customItems,
    //   { id: Date.now(), test_name: "", checked: false, isNew: true },
    // ]);
    setCurrentPatientLabs((prevState) => ({
      ...prevState,
      labs: [...prevState.labs, newItem],
    }));
    setShouldScroll(true); 
  };
  const handleInputChangeNew = (e, originalIndex, value) => {
    // const updatedItems = customItems.map((item) =>
    //   item.id === id ? { ...item, test_name: value } : item
    // );
    // setCustomItems(updatedItems);
    setCurrentPatientLabs((prev) => {
      const updatedLabs = [...prev.labs];
      updatedLabs[originalIndex].test_name = e.target.value;
      return { ...prev, labs: updatedLabs };
    });
  };
  const handleCheckboxChangeNew = (index, checked) => {
    const updatedLabs = [...currentPatientLabs.labs];
    updatedLabs[index] = { ...updatedLabs[index], checked , editItemName:false };
  
    let checkedLabs = [];
    let uncheckedLabs = [];      

      const item = updatedLabs[index];
    // Ensure the item exists
    if (item) {
      // Get the test_name from the item
      const { test_name, isNew } = item;
      // Now you have access to test_name and checked here

      if (checked) {
        const payload = {
          category: "patientLabs",
          newData: {
            test_name,
            checked,
            isNew,
          },
        };
        axios
          .put(
            `/patient/order/update/${currentPatientLabs.orderId}`,

            payload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              },
            }
          )
          .then((response) => {
            // Handle the successful update

            handleDataReset(selectedDate);            
            checkedLabs = response.data?.userLabs?.filter((lab) => lab.checked);
            uncheckedLabs = updatedLabs?.filter((lab) => !lab.checked);
            // Combine them with checked labs first
            const sortedLabs = [...checkedLabs, ...uncheckedLabs];
            setCurrentPatientLabs((prevState) => ({
              ...prevState,
              labs: [...sortedLabs],
            }));
          })
          .catch((error) => {
            // Handle any errors

            console.error("Update failed:", error);
          });
      }
    }
  };
  const handleDeleteLabItem = async (labItemId) => {
    setItemLoader([...itemLoader,{labItemId,show:true}])

    try {
      // Assuming `labItemId` is the ID of the lab item to be deleted
      const response = await axios.delete(
        `/delete/labitem/${clickedTreatmentData._id}/${labItemId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if(response.status===200 || response.status===201){
        const updatedLoader=itemLoader.filter(item => item?.labItemId!==labItemId)
        setItemLoader(updatedLoader)
        // Remove the deleted item from the customItems state
        let updatedCustomItems = customItems;
        if (customItems.some((item) => item.id === labItemId)) {
          updatedCustomItems = customItems.filter(
            (item) => item.id !== labItemId
          );
          setCustomItems(updatedCustomItems);
        }
        
        // Now update currentPatientLabs to reflect the deletion
        setCurrentPatientLabs((prevState) => {
          const updatedLabs = prevState.labs.filter(
            (lab) => lab._id !== labItemId
          );
          return { ...prevState, labs: updatedLabs };
        });
      }
    } catch (error) {
      console.error("Error deleting lab item:", error);
      // Optionally, handle the error (e.g., display an error message)
    }
  };

  const [tubesSummary, setTubesSummary] = useState({});
  const [labsModalLoader, setLabsModalLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastifyToast, setToastifyToast] = useState(null);
  const [patientId, setPatientId] = useState();
  const tubeColors = {
    "Gel-Barrier": "#C25C22",
    "Lavender Top (EDTA) Whole Blood": "#9900FF", // Assuming 'dark lavender' as a hex color
    "Yellow-top (ACD-A)": "#F1C232", // Assuming 'darker yellow' as a hex color
    "Futhan Collection Kit": "#C25C22",
    "Blue-top (sodium citrate)": "#68BDBD",
    "Lavender Top (EDTA) Serum Separator": "#674EA7", // Assuming 'dark lavender' as a hex color
    // Add other tube types and their colors here
  };

  const getPreviousDate = (dateString) => {
    const currentDate = new Date(dateString);
    const previousDate = new Date(currentDate);
    previousDate.setDate(previousDate.getDate() - 1);
    return previousDate.toISOString().split("T")[0];
  };
  const findPreviousTreatment = (treatments) => {
    let previousTreatment = null;
    let currentDate = new Date(selectedDate);
    let found = false;

    while (!found && currentDate) {
      currentDate = getPreviousDate(currentDate);
      previousTreatment = treatments.treatments.find((treatment) => {
        let treatmentDateString = new Date(treatment.date)
          .toISOString()
          .split("T")[0];

        return treatmentDateString === currentDate;
      });

      if (previousTreatment) {
        found = true;
      }
    }

    return previousTreatment;
  };
  const findCurrentTreatment = (treatments) => {
    let currentTreatment = null;
    let currentDate = new Date(selectedDate).toISOString().split("T")[0];
    let found = false;

    currentTreatment = treatments.treatments.find((treatment) => {
      let treatmentDateString = new Date(treatment.date)
        .toISOString()
        .split("T")[0];
      return treatmentDateString === currentDate;
    });

    if (currentTreatment) {
      found = true;
    }

    return currentTreatment;
  };

  const showToast = (variant, message) => {
    setToastifyToast({ variant, message });

    setTimeout(() => {
      setToastifyToast(null);
    }, 5000);
  };

  const handleRefreshLabsAndTubes = async () => {
    const scrollTop = containerRef.current.scrollTop;
    setLoading(true);
    try {
      // Assuming orderId is available in the component's state or props
      const orderId = currentPatientLabs.orderId;

      // Fetch new labs data
      const labsResponse = await axios.get(
        `/orderget/${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      // Function to sort labs if needed
      const sortLabs = (labs) => {
        // Your sorting logic here. Example:
        return labs.sort((a, b) =>
          a.checked === b.checked ? 0 : a.checked ? -1 : 1
        );
      };
      // Sort labs here if necessary
      const sortedLabs = sortLabs(labsResponse.data.userLabs);

      // Fetch new tubes summary
      const tubesResponse = await axios.get(
        `/getLabTubesInfo/${orderId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      // Update the state with the new data
      setCurrentPatientLabs((prevState) => {
        return {
          ...prevState,
          labs: sortedLabs,
          orderId: orderId,
        };
      });
      if (tubesResponse?.data?.TubesSummary) {
        setTubesSummary(tubesResponse?.data?.TubesSummary);
      }

      setLoading(false);
      setLabsModalLoader(false);
      requestAnimationFrame(() => {
        containerRef.current.scrollTop = scrollTop;
      });
    } catch (error) {
      setLoading(false);
      console.error("Error refreshing labs and tubes:", error);
    }
  };
  const handleCopyLabsFromPreviousTreatment = async () => {
    setLabsModalLoader(true);
    try {
      // Fetch previous treatments
      const previousTreatmentsResponse = await axios.get(
        `/api/patient/${patientId}/last-treatments`
      );

      if (previousTreatmentsResponse.data.totalCount <= 0) {
        setLabsModalLoader(false);
        showToast(
          "error",
          "There are no labs from the previous treatment to copy from"
        );
        return;
      }

      // Filter to keep only treatments of type "Treatment"
      const treatments = previousTreatmentsResponse.data.treatments.filter(
        (treatment) => treatment.type === "Treatment"
      );

      if (treatments.length <= 0) {
        setLabsModalLoader(false);
        showToast(
          "error",
          "There are no treatments of the required type to copy labs from"
        );
        return;
      }

      const treatment = findPreviousTreatment({ treatments: treatments });
      const currentTreatment = findCurrentTreatment({ treatments: treatments });

      if (currentTreatment.review == true) {
        showToast(
          "error",
          "The status of this treatment is reviewed, so labs can't be copied"
        );
        setLabsModalLoader(false);
        return;
      }
      const previousLabs = treatment.userLabs.filter(
        (previouslab) => previouslab.checked === true
      );
      if (previousLabs.length <= 0) {
        setLabsModalLoader(false);

        showToast(
          "error",
          "There are no labs from the previous treatment to copy from"
        );
        return;
      }

      const updatePromises = previousLabs.map((previouslab) => {
        const payload = {
          category: "patientLabs",
          updates: {
            checked: true,
            value: previouslab.item_number && `${previouslab.item_number}`,
            type: previouslab.item_number && "item_number",
          },
        };

        return axios.put(
          `/patient/order/update/${currentPatientLabs.orderId}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        );
      });

      Promise.all(updatePromises)
        .then((responses) => {
          const updatedLabs = responses
            .flatMap((response) => response.data.userLabs)
            .filter((lab) => lab.checked === true);

          handleRefreshLabsAndTubes();
          setLabsModalLoader(false);

          showToast("success", "Labs Copied Successfully");
        })
        .catch((error) => {
          setLabsModalLoader(false);

          showToast("error", "Failed to copy labs from previous treatment");
        });
    } catch (error) {
      showToast("error", "Error fetching previous treatments:");
      setLabsModalLoader(false);

      showToast("error", "Failed to fetch previous treatments");
    }
  };

  const placeOrder = (types) => {
    fetch(`/shopify/placeorder/${selectedTreatmentId}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ types }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        handleCloseLabsModal();
        toast.success("Order placed successfully");
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(`Error placing order: ${error.message}`);
      });
  };
  const getPatientProfiles = async () => {
    const inactivePatients = rows.filter(
      (item) => !activePatient.some((profile) => item.PatientId === profile['Customer ID'])
    );
    if (inactivePatients.length > 0) {
      try {
        const patientProfilePromises = inactivePatients.map((item) =>
          axios.get(`/patient-profiles/${item.PatientId}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          })
        );
        const responses = await Promise.all(patientProfilePromises);

        const inactivePatientProfilesData = responses
          .filter((response) => response.status === 200)
          .map((response) => response.data);
        setInactivePatientProfiles(inactivePatientProfilesData);
      } catch (error) {
        console.error("Error fetching inactive patients:", error);
      }
    } else {
      setInactivePatientProfiles([]);
    }
  };
  
  const previousActivePatientsRef = useRef();

  useEffect(() => {
    if (previousActivePatientsRef.current !== activePatient) {
      getPatientProfiles();
      previousActivePatientsRef.current = activePatient;
    }
  }, [activePatient]);  

  return (
    <>
      <div className={`${styles.todayViewContainer}`}>
        {/* Main Container */}
        <div className={`${styles.container}`}>
          {/* Day View Heading */}
          {/* <div className={`${styles.dayviewHeadinBlock}`}>
            <div className={`${styles.dayViewHeading}`}>Today View</div>
            <div className={`${styles.dateflexRooms}`}>
              <div className="ml-52 mt-1">
                <div className={`${styles.dateflexToday}`}>
                  <IconButton onClick={() => navigateDate(-1)}>
                    <img src={leftRight} alt="image" />
                  </IconButton>
                  <span className={`${styles.datetext}`}>
                    Date: {moment(selectedDate).format("dddd MMMM Do, YYYY")}
                  </span>
                  <IconButton onClick={() => navigateDate(1)}>
                    <img src={arrowRight} alt="image" />
                  </IconButton>
                </div>
              </div>
            </div>
            
          </div> */}
          {/* Header Section */}
          <div
            className={`${
              treatments.length === 0 ? "mt-[-90px]" : "mt-[-145px]"
            } ${styles.headerContainer}`}
          >
            {/* Column 1: Heading */}
            <div className={styles.headingContainer}>
              <div className={`${styles.dayViewHeading} justify-start`}>
                Front Desk View
              </div>
            </div>

            {/* Column 2: Date and Today Button */}
            <div className={styles.dateTodayContainer}>
              <div className={`${styles.dateDisplay}`}>
                <div className={`${styles.dateflexToday}`}>
                  <IconButton onClick={() => navigateDate(-1)}>
                    <img src={leftRight} alt="image" />
                  </IconButton>
                  <span className={`${styles.datetext}`}>
                    Date: {moment(selectedDate).format("dddd MMMM Do, YYYY")}
                  </span>
                  <IconButton onClick={() => navigateDate(1)}>
                    <img src={arrowRight} alt="image" />
                  </IconButton>
                </div>
              </div>
              <div>
                <button
                  className="btn-color py-1 px-10 text-xs md:text-sm w-full md:w-auto"
                  type="primary"
                  onClick={setDateToToday}
                >
                  Today
                </button>
                <span className="ml-[10px]">
                  {" "}
                  <Tooltip title={"Show Calendar"}>
                    <CalendarMonthIcon
                      ref={iconRef}
                      style={{
                        color: "#757575",
                        cursor: "pointer",

                        fontSize: 30,
                      }}
                      onClick={() => {
                        handleIconClick();
                        setShowCalendar(!showCalendar);
                      }}
                    />
                  </Tooltip>
                </span>

                {showCalendar && (
                  <div
                    ref={calendarRef}
                    style={{
                      position: "absolute",
                      top: `${calendarPosition.top}px`,
                      left: `${calendarPosition.left}px`,
                    }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Calendar
                      value={selectedDate}
                      onChange={handleDateChange}
                    />
                  </div>
                )}
              </div>
            </div>

            {/* Column 3: Calendar */}
            <div className={`${styles.headingContainer}`}>
              {/* <Calendar
                  value={selectedDate}
                  onChange={handleDateChange}
                /> */}
              <div className={`${styles.patientInfoAndCal} cursor-pointer`}>
                <div className="">
                  {/* Rooms Available dropdown */}
                  <DndProvider backend={MultiBackend} options={HTML5toTouch}>
                    <FloatingRoomsList
                      rooms={roomOptions}
                      patientsGroupedByRoom={patientsGroupedByRoom}
                    />
                  </DndProvider>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={`${styles.patientDropdown} flex items-center justify-between w-full`}>
              <div className="flex items-center gap-2 flex-1">
              <Select
                className={`${styles.patientDropdownInner} w-1/3 my-auto mr-4`}
                placeholder="Select a patient"
                onChange={handlePatientSelect}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    ?.indexOf(input?.toLowerCase()) >= 0
                }
                value={selectedPatientId}
              >
                {activePatient?.map((patient) => (
                  <Option key={patient._id} value={patient["Customer ID"]}>
                    {patient["Full Name"]}
                  </Option>
                ))}
                {showInactivePatients &&
                  inactivePatients?.map((patient) => (
                    <Option key={patient._id} value={patient["Customer ID"]}>
                      {patient["Full Name"]}
                    </Option>
                  ))}
              </Select>

              <div className={`${styles.inactivePatientsFlex}`}>
                {/* Inactive patients checkbox */}
                <div className={`${styles.InactiveCheckContainer}`}>
                  <input
                    type="checkbox"
                    id="inactiveCheckbox"
                    checked={showInactivePatients}
                    onChange={handleCheckboxChange}
                    className={`${styles.InactiveCheck} mr-1 mr-2`}
                  />
                  <label className={`${styles.inactivePatientsText}`}>
                    Show inactive patients
                  </label>
                </div>
                </div>
              </div>
            </div>
          </div>
          {/* Schedule Button */}
          <div className="flex flex-row gap-4">
            {/* Conditionally render Room Dropdown and Schedule Button */}
            {selectedPatientId && (
              <>
                <div className={`${styles.scheduleButton} mt-2 `}>
                  {/* Schedule Button */}
                  <Button
                    className="btn-color"
                    type="primary"
                    onClick={createSchedule}
                  >
                    Schedule
                  </Button>
                </div>
                <div className={`${styles.scheduleButton} mt-2`}>
                  {/* Schedule Button */}
                  <Button
                    className="btn-color"
                    type="primary"
                    onClick={() => handlePickUp(selectedPatientId)}
                  >
                    PickUp
                  </Button>

                  <PickUpModal
                    open={pickup}
                    onClose={() => handlePickupClose()}
                    patientId={selectedPatientId}
                    selectedDate={formattedDate}
                    patientName={
                      activePatient?.find(
                        (patient) => patient["Customer ID"] == selectedPatientId
                      )?.["Full Name"] || ""
                    }
                  />
                </div>
              </>
            )}
          </div>

          <div className={`${styles.todayViewMidSection}`}>
            {/* Container 1 starts */}
            {/* <div className={`${styles.patientInfoAndCal}`}> */}
            {/* <div className={`${styles.calendarContainer} end-0`}>
                <Calendar value={selectedDate} onChange={handleDateChange} />
              </div> */}
            {/* Rooms Available dropdown */}
            {/* <div className="py-5">
                <FloatingRoomsList rooms={roomOptions} />
              </div> */}
            {/* </div> */}
            {/* Container 1 ends */}
            {/* Container 2 starts */}
            <div className={`${styles.dragableCardsSection}`}>
              <DndProvider backend={MultiBackend} options={HTML5toTouch}>
                <div className={`${styles.droppableContainer}`}>
                  <div style={getParentContainerStyle("scheduled")}>
                    <div className={`${styles.scheduledOuterDiv}`}>
                      <div className={`${styles.scheduledInnerDiv}`}>
                        Scheduled
                      </div>
                    </div>
                    {/* Scheduled Container */}
                    <div
                      className={`${styles.dragableCardsContainerScroll}`}
                      style={getContainerStyle("scheduled")}
                    >
                      <DroppableArea
                        status="scheduled"
                        treatments={treatments}
                        moveCard={moveCard}
                      />
                    </div>
                  </div>
                  <div style={getParentContainerStyle("arrived")}>
                    <div className={`${styles.arrivedOuterDiv}`}>
                      <div className={`${styles.scheduledInnerDiv}`}>
                        Arrived
                      </div>
                    </div>
                    <div
                      className={`${styles.dragableCardsContainerScroll}`}
                      style={getContainerStyle("arrived")}
                    >
                      <DroppableArea
                        status="arrived"
                        treatments={treatments}
                        moveCard={moveCard}
                      />
                    </div>
                  </div>

                  <div style={getParentContainerStyle("pickup")}>
                    <div className={`${styles.pickupOuterDiv}`}>
                      <div className={`${styles.scheduledInnerDiv}`}>
                        PickUp
                      </div>
                    </div>
                    <div
                      className={`${styles.dragableCardsContainerScroll}`}
                      style={getContainerStyle("pickup")}
                    >
                      <DroppableArea
                        status="pickup"
                        treatments={treatments}
                        moveCard={moveCard}
                      />
                    </div>
                  </div>
                  <div style={getParentContainerStyle("complete")}>
                    <div className={`${styles.completedOuterDiv}`}>
                      <div className={`${styles.scheduledInnerDiv}`}>
                        Completed
                      </div>
                    </div>
                    <div
                      className={`${styles.dragableCardsContainerScroll}`}
                      style={getContainerStyle("complete")}
                    >
                      <DroppableArea
                        status="complete"
                        treatments={treatments}
                        moveCard={moveCard}
                      />
                    </div>
                  </div>
                </div>
              </DndProvider>
            </div>
          </div>

          {/* Container 2 ends */}
          <div className={`${styles.tableContainer} mx-2 mt-2`}>
          <p className="text-lg font-semibold">Treatments</p>
            <div className="flex justify-center items-center">
              <Input
                prefix={<SearchOutlined />}
                className="ml-0.5 font-type"
                placeholder="Search"
                style={{
                  width: "50%",
                  height: "38.65px",
                  borderRadius: "5px",
                  backgroundColor: "#F7F7F7",
                  border: "none",
                }}
                onChange={handleSearchFilterChange}
              />
              <div className="flex w-auto flex-wrap flex-grow md:justify-center justify-start">
                {filterOptions.map((option) => (
                  <div
                    className="filter-options"
                    key={option.key}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "5px",
                      padding: "2px 9px 2px 2px",
                      border: selectedFilters[option.key]
                        ? "1px solid #1C82F5"
                        : "none",
                      margin: "2px",
                      cursor: "pointer",
                    }}
                  >
                    <Checkbox
                      checked={selectedFilters[option.key]}
                      onChange={handleCheckboxChangeFilters(option.key)}
                      name={option.key}
                    />
                    <span>{option.label}</span>
                  </div>
                ))}

                {/* Lock icon hidden */}
                <div
                  className="filter-options"
                  key="lock"
                  style={{
                    display: "none",
                    // display: "flex",
                    alignItems: "center",
                    border: isLocked ? "1px solid #1C82F5" : "none",
                    borderRadius: "5px",
                    padding: "2px 9px 2px 2px",
                    margin: "2px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsLocked(!isLocked)}
                >
                  <LockOutlined
                    style={{
                      color: isLocked ? "#1C82F5" : "black",
                      marginRight: "5px",
                    }}
                  />
                  <span className={`${styles.checkboxtabletext}`}>Lock</span>
                </div>
              </div>
            </div>
            <TableContainer
              component={Paper}
              className={`${styles.tableContainer}`}
            >
              <Table aria-label="collapsible table">
                <TableHead className={styles.tableHead}>
                  <TableRow>
                    <TableCell />

                    <TableCell style={{ width: "100px" }}>
                      Conversation
                    </TableCell>
                    <TableCell>Patient Name</TableCell>
                    <TableCell style={{ width: "100px" }}>Comparison</TableCell>
                    <TableCell>Actions</TableCell>
                    <TableCell>Final</TableCell>

                    {/* <TableCell>Note</TableCell> */}
                    <TableCell>S/Note</TableCell>
                    <TableCell>Procedure</TableCell>
                    <TableCell>Medications</TableCell>
                    <TableCell style={{ minWidth: "0", maxWidth: "150px" }}>
                      Labs
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activePatient ? (
                    activePatient.length > 0 ? (
                      <>
                        {sortedRows.length > 0 ? (
                          sortedRows.map((row) => {
                            // const patientProfile =
                            //   activePatient.find(
                            //     (patient) => patient["Customer ID"] === row.PatientId
                            //   ) ||
                            //   inactivePatientProfiles?.find(
                            //     (patient) => patient["Customer ID"] === row.PatientId
                            //   );
                          
                            // Only render the Row if a valid patient profile exists
                            // if (patientProfile) {
                              return (
                                <Row
                                  key={row._id}
                                  isLocked={isLocked}
                                  row={row}
                                  isTyping={isTyping}
                                  fetchData={fetchData}
                                  selectedDate={selectedDate}
                                  handleReviewChange={handleReviewChange}
                                  allLabs={allLabs}
                                  setIsTyping={setIsTyping}
                                  setIsComparisonModalVisible={setIsComparisonModalVisible}
                                  isComparisonModalVisible={isComparisonModalVisible}
                                  clickedOrderDetails={clickedOrderDetails}
                                  setClickedOrderDetails={setClickedOrderDetails}
                                  handleOpenSupplementsModal={handleOpenSupplementsModal}
                                  handleOpenProcedureModal={handleOpenProcedureModal}
                                  handleOpenDispenseModal={handleOpenDispenseModal}
                                  handleOpenLabsModal={handleOpenLabsModal}
                                  setSelectedTreatmentId={setSelectedTreatmentId}
                                  setShopifyOrdersDetails={setShopifyOrdersDetails}
                                  isLoading={isLoading}
                                  setIsLoading={setIsLoading}
                                  isSupModalClosed={isSupModalClosed}
                                  setIsSupModalClosed={setIsSupModalClosed}
                                  selectedFilters={selectedFilters}
                                  setCurrentPair={setCurrentPair}
                                  // PatientProfile={patientProfile}  
                                  PatientProfile={  activePatient.find(
                                    (patient) => patient["Customer ID"] === row.PatientId
                                  ) ||
                                  inactivePatientProfiles?.find(
                                    (patient) => patient["Customer ID"] === row.PatientId
                                  )}  
                                />
                              );
                            // } else {
                            //   // Optionally, handle cases where the profile is not found
                            //   return (
                            //     <div key={row._id}>
                            //       <p>Loading patient profile for {row.PatientId}...</p>
                            //     </div>
                            //   );
                            // }
                          })
                          
                        ) : (
                          <TableRow>
                            <TableCell colSpan={7}>
                              No treatments for today
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          <div
                            style={{
                              marginLeft: "63%",

                              marginTop: "15px",
                            }}
                          >
                            <CircularProgress />
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div
              style={{
                marginLeft: "47%",
                marginRight: "50%",
                marginTop: "15px",
              }}
            >
              {loader && <BounceLoader color="#000" />}
            </div>
          </div>

          {/* Orders Table */}
          <div className={`${styles.tableContainer} mx-2 mt-2`}>
            <p className="text-lg font-semibold">Orders</p>
            <div className="flex justify-center items-center">
              <Input
                prefix={<SearchOutlined />}
                className="ml-0.5 font-type"
                placeholder="Search"
                style={{
                  width: "50%",
                  height: "38.65px",
                  borderRadius: "5px",
                  backgroundColor: "#F7F7F7",
                  border: "none",
                }}
                onChange={handleOrderTableSearchFilterChange}
              />
              <div className="flex w-auto flex-wrap flex-grow md:justify-center justify-start">
                {filterOptions.map((option) => (
                  <div
                    className="filter-options"
                    key={option.key}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "5px",
                      padding: "2px 9px 2px 2px",
                      border: selectedOrderTableFilters[option.key]
                        ? "1px solid #1C82F5"
                        : "none",
                      margin: "2px",
                      cursor: "pointer",
                    }}
                  >
                    <Checkbox
                      checked={selectedOrderTableFilters[option.key]}
                      onChange={handleCheckboxChangeOrderTableFilters(option.key)}
                      name={option.key}
                    />
                    <span>{option.label}</span>
                  </div>
                ))}

                {/* Lock icon hidden */}
                <div
                  className="filter-options"
                  key="lock"
                  style={{
                    display: "none",
                    // display: "flex",
                    alignItems: "center",
                    border: isLocked ? "1px solid #1C82F5" : "none",
                    borderRadius: "5px",
                    padding: "2px 9px 2px 2px",
                    margin: "2px",
                    cursor: "pointer",
                  }}
                  onClick={() => setIsLocked(!isLocked)}
                >
                  <LockOutlined
                    style={{
                      color: isLocked ? "#1C82F5" : "black",
                      marginRight: "5px",
                    }}
                  />
                  <span className={`${styles.checkboxtabletext}`}>Lock</span>
                </div>
              </div>
            </div>
            <TableContainer
              component={Paper}
              className={`${styles.tableContainer}`}
            >
              <Table aria-label="collapsible table">
                <TableHead className={styles.tableHead}>
                  <TableRow>
                    <TableCell />

                    <TableCell style={{ width: "100px" }}>
                      Conversation
                    </TableCell>
                    <TableCell>Patient Name</TableCell>
                    <TableCell style={{ width: "100px" }}>Comparison</TableCell>
                    <TableCell>Actions</TableCell>
                    <TableCell>Final</TableCell>

                    {/* <TableCell>Note</TableCell> */}
                    <TableCell>S/Note</TableCell>
                    <TableCell>Procedure</TableCell>
                    <TableCell>Medications</TableCell>
                    <TableCell style={{ minWidth: "0", maxWidth: "150px" }}>
                      Labs
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activePatient ? (
                    activePatient.length > 0 ? (
                      <>
                        {sortedOrderRows.length > 0 ? (
                          sortedOrderRows.map((row) => {
                            // const patientProfile =
                            //   activePatient.find(
                            //     (patient) => patient["Customer ID"] === row.PatientId
                            //   ) ||
                            //   inactivePatientProfiles?.find(
                            //     (patient) => patient["Customer ID"] === row.PatientId
                            //   );
                          
                            // Only render the Row if a valid patient profile exists
                            // if (patientProfile) {
                              return (
                                <Row
                                  key={row._id}
                                  isLocked={isLocked}
                                  row={row}
                                  isTyping={isTyping}
                                  fetchData={fetchOrdersData}
                                  selectedDate={selectedDate}
                                  handleReviewChange={handleReviewChange}
                                  allLabs={allLabs}
                                  setIsTyping={setIsTyping}
                                  setIsComparisonModalVisible={setIsComparisonModalVisible}
                                  isComparisonModalVisible={isComparisonModalVisible}
                                  clickedOrderDetails={clickedOrderDetails}
                                  setClickedOrderDetails={setClickedOrderDetails}
                                  handleOpenSupplementsModal={handleOpenSupplementsModal}
                                  handleOpenProcedureModal={handleOpenProcedureModal}
                                  handleOpenDispenseModal={handleOpenDispenseModal}
                                  handleOpenLabsModal={handleOpenLabsModal}
                                  setSelectedTreatmentId={setSelectedTreatmentId}
                                  setShopifyOrdersDetails={setShopifyOrdersDetails}
                                  isLoading={isLoading}
                                  setIsLoading={setIsLoading}
                                  isSupModalClosed={isSupModalClosed}
                                  setIsSupModalClosed={setIsSupModalClosed}
                                  selectedFilters={selectedOrderTableFilters}
                                  setCurrentPair={setCurrentPair}
                                  // PatientProfile={patientProfile}  
                                  PatientProfile={  activePatient.find(
                                    (patient) => patient["Customer ID"] === row.PatientId
                                  ) ||
                                  inactivePatientProfiles?.find(
                                    (patient) => patient["Customer ID"] === row.PatientId
                                  )}  
                                />
                              );
                            // } else {
                            //   // Optionally, handle cases where the profile is not found
                            //   return (
                            //     <div key={row._id}>
                            //       <p>Loading patient profile for {row.PatientId}...</p>
                            //     </div>
                            //   );
                            // }
                          })
                          
                        ) : (
                          <TableRow>
                            <TableCell colSpan={7}>
                              No orders for today
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          <div
                            style={{
                              marginLeft: "63%",

                              marginTop: "15px",
                            }}
                          >
                            <CircularProgress />
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div
              style={{
                marginLeft: "47%",
                marginRight: "50%",
                marginTop: "15px",
              }}
            >
              {loader && <BounceLoader color="#000" />}
            </div>
          </div>

          {/* Container 3 ends */}
          <ProcedureModal
            open={procedureModalOpen}
            procedures={currentProcedures}
            onClose={() => setProcedureModalOpen(false)}
            patientId={currentPatientIdForModal}
            handleCellChange={handleCellChange}
            treatmentId={selectedTreatmentId}
            clickedTreatmentData={clickedTreatmentData}
            from="fdv"
          />

          <SupplementsModal
            open={supplementsPopup}
            onClose={() => {
              setIsSupModalClosed(true);
              setSupplementsPopup(false);
            }}
            supplements={currentSupplements}
            patientId={currentPatientIdForModal}
            handleCellChange={handleCellChange}
            from="fdv"
            treatmentId={selectedTreatmentId}
            patientData={patientData}
            handleOpenTreatmentNoteModal={handleOpenTreatmentNoteModal}
            recordForNotes={recordForNotes}
            selectedDate={selectedDate}
            allSupplements={allSupplements}
            clickedTreatmentData={clickedTreatmentData}
            toastifyToast={toast}
          />

          <DispenseModal
            open={dispenseModalOpen}
            dispensedItems={currentDispensedItems}
            onClose={() => setDispenseModalOpen(false)}
            patientId={currentPatientIdForModal}
            handleCellChange={handleCellChange}
            treatmentId={selectedTreatmentId}
            clickedTreatmentData={clickedTreatmentData}
            from="fdv"
          />

          <VitalsModal
            open={vitalsModalOpen}
            VitalsItems={currentVitalsItems}
            onClose={() => setVitalsModalOpen(false)}
            patientId={currentPatientIdForModal}
            handleCellChange={handleCellChange}
          />
          <Dialog
            open={labsModalOpen}
            onClose={handleCloseLabsModal}
            aria-labelledby="labs-modal-title"
            maxWidth="lg"
            fullWidth
          >
            <Box
              sx={{
                padding: "24px",
              }}
            >
              {toast && (
                <CustomToast
                  message={toastifyToast?.message}
                  variant={toastifyToast?.variant}
                />
              )}
              <div>
                <div className="flex justify-between mb-[16px]">
                  {" "}
                  <span className="labstitleStyling">
                    Patient Labs
                    <MuiTooltip
                      title={
                        <h1 className="text-sm">
                          Copy Labs From Previous Treatment
                        </h1>
                      }
                      placement="right"
                    >
                      <IconButton
                        onClick={() => {
                          if (clickedTreatmentData.review) {
                            return message.warning(
                              "This treatment is finalized so can't make any changes in it"
                            );
                          } else {
                            handleCopyLabsFromPreviousTreatment();
                          }
                        }}
                        sx={{ ml: "10px" }}
                      >
                        <ContentCopyRounded sx={{ fontSize: 30 }} />
                      </IconButton>
                    </MuiTooltip>
                  </span>{" "}
                  <div className="flex items-center gap-2">
                    <IconButton onClick={handleRefreshLabsAndTubes}>
                      <Refresh
                        style={{
                          fontSize: 30,
                          animation: loading
                            ? "spin 0.5s linear infinite"
                            : "none",
                        }}
                      />
                    </IconButton>

                    <IconButton title="Send to Shopify">
                      <ShoppingCartCheckout
                        onClick={() => {
                          if (!clickedTreatmentData.review) {
                            message.warning("This treatment is not finalized yet, so can't place a shopify order for it.")
                          }
                          else{
                          const modal = AntdModal.confirm({
                            title: "Confirmation",
                            content:
                              "Are you sure you want to place this order?",
                            okText: "Place Order",
                            cancelText: "Cancel", // Add the cancel button
                            onOk: () => {
                              modal.destroy();
                              placeOrder(["lab"]);
                            }, // Wrap in an arrow function
                            onCancel: () => modal.destroy(), // Handle cancel button click
                            okButtonProps: {
                              style: {
                                backgroundColor: "#1976d2",
                                borderColor: "#1976d2",
                                color: "white",
                                fontWeight: "bold",
                              },
                            },
                          });
                          }
                        }}
                        style={{
                          fontSize: 28,
                        }}
                      />
                    </IconButton>
                  </div>
                </div>
              </div>
              {labsModalLoader && (
                <div className="loader-backdrop">
                  <BounceLoader color="#000" />
                </div>
              )}

              <div>
                <div className={`${styles.activeStylingD} mb-[16px]`}>
                  {currentPatientLabs?.patientName}
                </div>
                <div className="flex">
                  <div className="flex">
                    {Object.entries(tubesSummary).map(
                      ([tubeType, quantity]) => (
                        <div key={tubeType} className="p-1 rounded">
                          <span
                            className="text-white rounded p-1"
                            style={{
                              backgroundColor: tubeColors[tubeType] || "black",
                            }}
                          >
                            {tubeType} ({quantity})
                          </span>
                        </div>
                      )
                    )}
                  </div>
                </div>

                {/* Search bar */}
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Search labs..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  sx={{ marginBottom: "16px" }}
                />
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: "bold" }} // optional, for emphasis
                >
                  Active Labs: {getCheckedLabsSummary()}
                </Typography>
                {/* Labs list */}

                <div className="outer-labs-container">
                  <div className="inner-labs-container py-3" ref={containerRef}>
                    <div className="w-1/2 space-y-3">
                      {firstColumnItems.map((lab) => {
                        const loader = itemLoader.find(
                          (item) => {
                      
                           if( item.labItemId === lab._id && item.show){
                            return true
                           }
                           return false
                          }
                        );
                        return(
                        lab?.editItemName ? (
                          <ListItem key={lab._id} className="flex items-center gap-1">
                            <input
                              value={lab.test_name}
                              onChange={(e) =>
                                handleInputChangeNew(e, lab?.originalIndex)
                              }
                              placeholder="Custom lab"
                              className="w-full dispensedTextInputHere"
                            />
                            <Checkbox
                              checked={lab.checked}
                              onChange={(e) =>
                                handleCheckboxChangeNew(lab?.originalIndex, e.target.checked)
                              }
                            />
                          </ListItem>)
                          :
                          (<ListItem key={lab._id}>
                          <ListItemText
                            primary={`${lab.test_name} ${lab.item_number ? ` - (${lab.item_number})` : ''}`}
                          />
                          <Checkbox
                            checked={lab.checked}
                            onChange={(e) => {
                              if (clickedTreatmentData.review) {
                                return message.warning(
                                  "This treatment is finalized so can't make any changes in it"
                                );
                              } else {
                                handleLabsCheckboxChange(
                                  lab._id,
                                  e.target.checked
                                );
                              }
                            }}
                          />
                          {(lab.custom || lab.isNew) && (
                            loader
                            ?
                            <Spin indicator={<LoadingOutlined spin />} size="small" />

                            : <IconButton
                              onClick={() => handleDeleteLabItem(lab._id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </ListItem>)
                      )})}
                    </div>
                    <div className="w-1/2 space-y-3">
                      {secondColumnItems.map((lab) => {
                        const loader = itemLoader.find(
                          (item) => {
                      
                           if( item.labItemId === lab._id && item.show){
                            return true
                           }
                           return false
                          }
                        );
                        return(
                        lab?.editItemName ? (
                          <ListItem key={lab._id} className="flex items-center gap-1">
                            <input
                              value={lab.test_name}
                              onChange={(e) =>
                                handleInputChangeNew(e, lab?.originalIndex)
                              }
                              placeholder="Custom lab"
                              className="w-full dispensedTextInputHere"
                            />
                            <Checkbox
                              checked={lab.checked}
                              onChange={(e) =>
                                handleCheckboxChangeNew(lab?.originalIndex, e.target.checked)
                              }
                            />
                          </ListItem>)
                          :
                          (<ListItem key={lab._id}>
                            <ListItemText
                              primary={`${lab.test_name} ${lab.item_number ? ` - (${lab.item_number})` : ''}`}
                            />
                            <Checkbox
                              checked={lab.checked}
                              onChange={(e) => {
                                if (clickedTreatmentData.review) {
                                  return message.warning(
                                    "This treatment is finalized so can't make any changes in it"
                                  );
                                } else {
                                  handleLabsCheckboxChange(
                                    lab._id,
                                    e.target.checked
                                  );
                                }
                              }}
                            />
                            {(lab.custom || lab.isNew) && (
                              loader
                              ?
                              <Spin indicator={<LoadingOutlined spin />} size="small" />
  
                              : <IconButton
                                onClick={() => handleDeleteLabItem(lab._id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </ListItem>)
                      )})}
                    </div>
                  </div>
                </div>
                {/* {customItems.map((item) => (
                  <div key={item.id} className="w-[50%]">
                    <ListItem>
                      <input
                        className="dispensedTextInputHere"
                        value={item.test_name}
                        onChange={(e) =>
                          handleInputChangeNew(item.id, e.target.value)
                        }
                        placeholder="Custom item"
                      />
                      <Checkbox
                        checked={item.checked}
                        onChange={(e) =>
                          handleCheckboxChangeNew(item.id, e.target.checked)
                        }
                      />
                    </ListItem>
                  </div>
                ))} */}
                <button
                  className="btn-color py-2 mt-5 px-14 text-xs md:text-sm w-full md:w-auto"
                  onClick={() => {
                    if (clickedTreatmentData.review) {
                      return message.warning(
                        "This treatment is finalized so can't make any changes in it"
                      );
                    } else {
                      handleAddEmptyRowNew();
                    }
                  }}
                >
                  Add Custom Item
                </button>
              </div>
            </Box>
          </Dialog>
          <AntdModal
            title="Treatment Note"
            visible={treatmentNoteModalOpen} // Changed to use the state variable for treatment notes
            onCancel={handleCloseTreatmentNoteModal} // Changed to the corresponding close handler function for treatment notes
            footer={null}
            width="80vw" // Set the width to 80% of the viewport width
            styles={{ maxHeight: "70vh", overflowY: "scroll" }}
          >
            <TreatmentNotes
              treatment={currentTreatment}
              isTreatmentNoteEditing={isTreatmentNoteEditing.current}
              setIsTreatmentNoteEditing={setIsTreatmentNoteEditing}
            />
          </AntdModal>
          {isComparisonModalVisible && (
            <OrderDetailsModal
              visible={isComparisonModalVisible}
              onClose={() => {
                setIsComparisonModalVisible(false);
              }}
              shopifyOrder={currentPair?.shopifyOrder}
              treatmentOrder={[currentPair?.treatmentOrder]}
              setActionLoading={setActionLoading} // Pass setActionLoading to control loading state
              onCompare={handleCompareOrders}
              clickedOrderDetails={clickedOrderDetails}
              setCurrentPair={setCurrentPair}
              loading={editPatientModalloading}
              setLoading={setEditPatientModalLoading}
              fromFDV={true}
            />
            // <AntdModal title={'Order Comparison'} visible={isComparisonModalVisible} closable={true} onCancel={()} />
          )}
          <ToastContainer />
        </div>
      </div>
    </>
  );
};

export default FrontDesk;;
