import { LoadingOutlined } from "@ant-design/icons";
import {
  AddCircleRounded,
  RemoveCircleRounded
} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import NotesIcon from "@mui/icons-material/Notes";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import { message, Spin, TimePicker } from "antd";
import axios from "axios";
import moment from "moment";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { BounceLoader } from "react-spinners";
import CustomToast from "../CustomToast";
import { dispensedItemsArray } from "../DispenseModal/dispensedItems";
import TreatmentNotes from "../TreatmentNotes";
import styles from "./LPDPopupModal.module.css";

const LPDPopup = React.memo(forwardRef(({
    // open,
    // procedures,
    // onClose,
    // patientId,
    // patientIdForPreviousTreatment,
    // handleCellChange,
    // handleOpenLabsModal,
    // handleOpenDispenseModal,
    // recordForNotes,
    // selectedDate,
    // clickedTreatmentData,
    // from,
    // treatmentId,
    allTreatments,
    // setAllTreatments,
    activeTreatment,
    type,
    setModalType,
    showModal,
    // setShowModal,
    onCloseModal,
    patientName,
    handleCellChange,
    handleCreateSoapNote,
    noteId,
    setsoapNoteCreatedFromLPD,
    from=""
},{containerRef,soapNoteCreatedRef}) => {
  const [treatment, setTreatment] = useState({});  
  useEffect(() => {
    if (activeTreatment) {
      setTreatment(activeTreatment);
    }
  }, [activeTreatment]);
    const [updatedProcedures, setUpdatedProcedures] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [vitals, setVitals] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [modalLoader, setModalLoader] = useState(false);
    const [toast, setToast] = useState(null);
    
    const [isAddingCustomItem, setIsAddingCustomItem] = useState(false);
    const [itemLoader, setItemLoader] = useState([]); 
    
    useEffect(()=>{
      if(showModal){
        let updatedDispensedItems=[];
        if(type==='Dispensed'){
          updatedDispensedItems =  treatment?.userdispensed.map((item, index) => ({
            ...item,
            showInput: false,
            isFilled: !!item.selectedField,
            originalOrder: index,
            checked: !item.isNew && item.quantity > 0 ? true : false
          }));
        }
      const updatedFilteredItems =
      type === "Labs"
        ? treatment?.userLabs?.filter((lab) =>
            lab.test_name.toLowerCase().includes(searchTerm)
          )
        : type === "Procedures"
        ? treatment?.userProcedures?.filter((procedure) =>
            procedure.procedure_name.toLowerCase().includes(searchTerm)
          )
        : type === "Dispensed"
        ? updatedDispensedItems?.filter((dispense) =>
            dispense.name.toLowerCase().includes(searchTerm)
          )
        : "";
        if(type==='Procedures'){
          const sortedProcedures= proceduresSortingFunction([...updatedFilteredItems])
          setFilteredItems(sortedProcedures)
        }
        if(type==='Dispensed'){
          const sortedDispensedItems= dispensedItemsSortingFunction([...updatedDispensedItems])
          setFilteredItems(sortedDispensedItems)
        }
        if(type==='Labs'){
          const sortedLabs= sortLabs([...updatedFilteredItems])
          const updatedSortedLabs = sortedLabs.map(lab => ({
            ...lab,
            checked: lab.checked ?? false
          }))
          setFilteredItems(updatedSortedLabs)
        }
        if(type==='Vitals'){
          const vitals= [...treatment?.vitals]
          setVitals(vitals);
        }
        }
    },[searchTerm,treatment])
    const toastCount = useRef(0);
    const isTreatmentNoteEditing = useRef(false);

    const setIsTreatmentNoteEditing = (isTreatmentNoteEditingVal) => {
      isTreatmentNoteEditing.current = isTreatmentNoteEditingVal;
    };
    
    const handleItemNameChange = (value, index, type) => {
      const typeMapping = {
        procedure: { key: 'userProcedures', name: 'procedure_name' },
        dispensed: { key: 'userdispensed', name: 'name' },
        labs: { key: 'userLabs', name: 'test_name' },
      };
    
      // Check if the type is valid
      if (!typeMapping[type]) return;
    
      setTreatment((prevState) => {
        // Get the correct key and name based on the type
        const { key, name } = typeMapping[type];
    
        // Clone the previous state array
        const updatedItems = [...(prevState[key] || [])];
    
        // Only update the relevant item's name
        updatedItems[index] = {
          ...updatedItems[index],
          [name]: value, // Dynamically update the field
        };
    
        // Return the updated treatment state
        return {
          ...prevState,
          [key]: updatedItems,
        };
      });
    };
    
    const dispensedItemsSortingFunction = (items) => {
      const filledItems = items.filter((item) => item.checked).sort((a, b) => a.originalOrder - b.originalOrder);
      const unfilledItems = items.filter((item) => !item.checked).sort((a, b) => a.originalOrder - b.originalOrder);
      return [...filledItems, ...unfilledItems];
    };

    const sortLabs = (labs) => {
      // Your sorting logic here. Example:
        return labs.sort((a, b) =>
          a.checked === b.checked ? 0 : a.checked ? -1 : 1
        );
    };

    const handleSelectedFieldChange = (value, index, type) => {
      const typeMapping = {
        procedure: 'userProcedures',
        dispensed: 'userdispensed',
        labs: 'userLabs',
      };
    
      // Check if the type is valid
      if (!typeMapping[type]) return;
    
      setTreatment((prevState) => {
        // Get the correct key based on the type
        const key = typeMapping[type];
    
        // Clone the previous state array
        const updatedItems = [...(prevState[key] || [])];
    
        // Only update the relevant item's selectedField
        updatedItems[index] = {
          ...updatedItems[index],
          selectedField: value, // Dynamically update the selectedField
        };
    
        // Return the updated treatment state
        return {
          ...prevState,
          [key]: updatedItems,
        };
      });
    };
    
    
    
    const handleCustomLabCheckboxChange = (index, checked) => {
    
      const updatedLabs = sortLabs([...treatment.userLabs]);
      updatedLabs[index] = { ...updatedLabs[index], checked, editItemName:false };
      
      const item = updatedLabs[index];
      
        if (item) {    
          handleCellChange(
            treatment._id,
            ``,
            checked,
            "newpatientLab",
            item,
            treatment.review
          );
          setTreatment({...treatment, userLabs:[...updatedLabs]})
      }
    };

    const handleLabsCheckboxChange = (labId, checked) => {
      const scrollTop = containerRef.current.scrollTop;
      const updatedLabs = treatment?.userLabs?.map((lab) => {
        if (lab._id === labId) {
          return { ...lab, checked: checked };
        }
        return lab;
      });
      
      const lab = treatment?.userLabs?.find((lab) => lab._id === labId);
      
      handleCellChange(
        treatment._id,
        ``,
        checked,
        "patientLabs",
        lab,
        treatment.review
      );
      
      // Now set the state with the updated labs array
      setTreatment({...treatment, userLabs:[...updatedLabs]})
      requestAnimationFrame(() => {
        containerRef.current.scrollTop = scrollTop;
      });
    };
    
    const handleChange = (itemData, itemName, key, value) => {
      console.log(
        "********* handle change triggered",
        itemData,
        itemName,
        key,
        value
      );
      const scrollTop = containerRef.current.scrollTop;
      let newProcedures = [...treatment?.userProcedures];
      let newLabs = [...treatment?.userLabs];
      let newDispensed = [...treatment?.userdispensed];
      let sortedItems = [];
      if (type === "Procedures") {
        newProcedures = treatment?.userProcedures.map((procedure) => {
          if (procedure.procedure_name === itemName && key !== "dosages") {
            let updatedProcedure = { ...procedure, [key]: value };
        if (key === "checked" && (itemData.isNew=== true || itemData.custom===true)) {
          let updatedProcedureData = { ...itemData, editItemName: false, checked:value };
          itemData = updatedProcedureData;
          handleCellChange(
            treatment._id,
            `${itemName}-selectedField`,
            itemData.selectedField,
            "userProcedures",
            itemData,
          );
        }
        else if (key==="checked" && (!itemData.isNew && !itemData.isCustom)){
          let updatedProcedureData = { ...itemData, editItemName: false, checked:value };
          itemData = updatedProcedureData;

          handleCellChange(
            treatment._id,
            `${itemName}-selectedField`,
            value?itemData.dosages[0]:"",
            "userProcedures",
            itemData,
          );
        }
        
        if (key === "n" ) {
          updatedProcedure.checked = true;
          updatedProcedure.isNew= false
          updatedProcedure.custom= true
        }
        if ((key === "selectedField" && (value === "Empty" || value === "" ) && (!itemData?.isNew && !itemData?.custom) )) {
          // Remove the procedure from active procedures
          updatedProcedure = {
            ...updatedProcedure,
            selectedField: "",
            checked: false,
          }; // Uncheck the checkbox
          handleCellChange(
            treatment._id,
            `${itemName}-checked`,
            false,
            "userProcedures",
            itemData,
          );
        } else if (key === "selectedField" && value !== "" && (!itemData.isNew && !itemData.custom)) {
          // Mark the procedure as checked when a dosage is selected
          updatedProcedure.checked = true;
          handleChange(
            procedure,
            procedure.procedure_name,
            "checked",
            true
          );
        }
            if(from!=='treatmentView'){
            if ( !noteId && !soapNoteCreatedRef.current &&(updatedProcedure.checked || updatedProcedure.selectedField)) {
              console.log(" lpd pop up called")
              handleCreateSoapNote();
              setsoapNoteCreatedFromLPD(true);
              soapNoteCreatedRef.current = true;

            }}
            return updatedProcedure;
          }
          return procedure;
        });
      } else if (type === "Dispensed") {
        newDispensed = treatment?.userdispensed.map((item) => {
          if (item.name === itemName) {
            let isFilled = key === "selectedField" ? !!value : item.isFilled;
            let updatedItem = { ...item, [key]: value, isFilled };
        if(key === "quantity" && itemData.isNew && value){
          let updatedProcedureData = { ...itemData, editItemName: false }; 
          itemData = updatedProcedureData; 
        }
        if (key === "n" ) {
          updatedItem.checked = value ? true : false;
          updatedItem.isNew= false
          updatedItem.custom= true
        }

            if (key === "quantity") {
              updatedItem.quantity = value
              updatedItem.checked = value > 0 ? true : false
              if (itemData?.isNew === true || item?.custom === true) {
                updatedItem.selectedField = itemData.selectedField
                handleCellChange(
                  treatment._id,
                  `${itemName}-selectedField`,
                  itemData.selectedField,
                  "userdispensed",
                  itemData
                )
              }
              else {
                updatedItem.selectedField = value > 0 ? itemData.selectedField ? itemData.selectedField : itemData.dosages[0] : ""
                handleCellChange(
                  treatment._id,
                  `${itemName}-selectedField`,
                  value > 0 ? itemData.selectedField ? itemData.selectedField : itemData.dosages[0] : "",
                  "userdispensed",
                  itemData
                );
              }
            }
            if (key === "selectedField" && (!itemData.isNew || !itemData.custom)) {
              updatedItem.quantity = value ? itemData.quantity > 0 ? itemData.quantity : 1 : 0;
              updatedItem.checked = value ? true : false;
              if (itemData?.editItemName !== false || (itemData.custom && itemData?.custom !== true)) {
                handleCellChange(
                  treatment._id,
                  `${itemName}-quantity`,
                  updatedItem.quantity,
                  "userdispensed",
                  itemData
                );
              }
            }
            return updatedItem;
          }
          return item;
        });
      }

      let updatedNewProcedures={};
      let updatedNewDispensed={};
      if (type === 'Procedures') {
        updatedNewProcedures = newProcedures.map((item) => {
          if (item.editItemName) {
            return { ...item, editItemName: false }
          }
          else {
            return item
          }
        })
      }
      if (type === 'Dispensed') {
        updatedNewDispensed = newDispensed.map((item) => {
          if (item.editItemName) {
            return { ...item, editItemName: false }
          }
          else {
            return item
          }
        })
      }
      // Sort procedures based on selectedField value and checked status
      sortedItems =
        type === "Labs"
          ? itemsSortingFunction(newLabs, "labs")
          : type === "Procedures"
          ? itemsSortingFunction(updatedNewProcedures, "procedures")
          : type === "Dispensed"
          ? itemsSortingFunction(updatedNewDispensed, "dispensed")
          : null;
      // setUpdatedProcedures(sortedProcedures);      
      let updatedTreatment={}
      if (type === "Labs") {
        updatedTreatment = { ...treatment, userLabs: [...sortedItems] };
      } else if (type === "Procedures") {
        updatedTreatment = { ...treatment, userProcedures: [...sortedItems] };
      } else if (type === "Dispensed") {
        updatedTreatment = { ...treatment, userdispensed: [...sortedItems] };
      }
        setTreatment(updatedTreatment);
      requestAnimationFrame(() => {
        containerRef.current.scrollTop = scrollTop;
      });

      setTimeout(() => {
        if (type === "Procedures") {
          handleCellChange(
            treatment?._id,
            `${itemName}-${key}`,
            value,
            "userProcedures",
            itemData,
            treatment?.review
          );
        } else if (type === "Dispensed") {
          handleCellChange(
            treatment?._id,
            `${itemName}-${key}`,
            value,
            "userdispensed",
            itemData
          );
        }
      }, 1000);
    };



    const handleTimeChange = (index, time) => {
      handleChange(index, "time", time ? time.toISOString() : "");
    };
    const proceduresSortingFunction = (procedures) => {
      const filledProcedures = procedures
        ?.filter(
          (procedure) => procedure.checked
        )
        .sort((a, b) => a.originalOrder - b.originalOrder);

      const unfilledProcedures = procedures
        ?.filter(
          (procedure) => !procedure.checked
        )
        .sort((a, b) => a.originalOrder - b.originalOrder);

      return [...filledProcedures, ...unfilledProcedures];
    };

    const itemsSortingFunction = (items, itemType) => {
      // Define the dynamic keys for each item type
      let filledCondition, emptyCondition;

      switch (itemType) {
        case "procedures":
          filledCondition = (item) =>
            (item.checked && item.checked !== false)
          emptyCondition = (item) =>
            (!item.checked|| item.checked === false)
          break;

        case "labs":
          filledCondition = (item) => item.checked;
          emptyCondition = (item) => !item.checked;
          break;

        case "dispensed":
          filledCondition = (item) =>
            (item.selectedField && item.selectedField !== "Empty") ||
            item.quantity > 0;
          emptyCondition = (item) =>
            (!item.selectedField || item.selectedField === "Empty") &&
            item.quantity === 0;
          break;

        case "supplements":
          filledCondition = (item) =>
            (item.selectedField && item.selectedField !== "Empty") ||
            item.checked;
          emptyCondition = (item) =>
            (!item.selectedField || item.selectedField === "Empty") &&
            !item.checked;
          break;

        default:
          return items; // If the item type is not recognized, return the original array.
      }

      const filledItems = items
        ?.filter(filledCondition)
        .sort((a, b) => a.originalOrder - b.originalOrder);

      const unfilledItems = items
        ?.filter(emptyCondition)
        .sort((a, b) => a.originalOrder - b.originalOrder);

      return [...filledItems, ...unfilledItems];
    };

    const handleUpdateVitals = () => {
      const defaultVital = {
        HR: 0,
        BP: "",
        T: 0,
        W: 0,
        RR: 0,
        SPO2: 0,
        time: moment.tz("America/Phoenix").toDate(),
      };

      const validatedItems = vitals?.map((item) => {
        return {
          HR: item.HR || defaultVital.HR,
          BP: item.BP || defaultVital.BP,
          T: item.T || defaultVital.T,
          W: item.W || defaultVital.W,
          RR: item.RR || defaultVital.RR,
          SPO2: item.SPO2 || defaultVital.SPO2,
          time: item.time || defaultVital.time,
        };
      });

      handleCellChange(treatment?._id, "vitals", validatedItems, "vitals");
      onClose();
    };
    const handleVitalsChange = (index, key, value) => {
      const newItems = treatment?.vitals?.map((item, i) => {
        if (i === index) {
          return { ...item, [key]: value };
        }
        return item;
      });
      // setAllTreatments(newItems);

      setTreatment({...treatment, vitals:[...newItems]})
      
        // setAllTreatments((prevState) =>
        //     prevState?.map((order) => {
        //       if (order._id === treatment._id) {
        //         if (type === "Vitals") {
        //           return { ...order, vitals: [...newItems] };
        //         }
        //       }
        //       return order;
        //     })
        //   );
    
    };

    const toggleNoteInputRow = (itemName) => {
      if (type === "Procedures") {
        const newProcedures = treatment.userProcedures?.map((item) => {
          if (item.procedure_name === itemName) {
            return { ...item, showNoteInputRow: !item.showNoteInputRow };
          }
          return item;
        });
        
        
          const updatedTreatment= { ...treatment, userProcedures: [...newProcedures] }
            setTreatment(updatedTreatment)
      } else if (type === "Dispensed") {
        const newDispensed = treatment.userdispensed?.map((item) => {
          if (item.name === itemName) {
            return { ...item, showNoteInputRow: !item.showNoteInputRow };
          }
          return item;
        });
        
          const updatedTreatment= { ...treatment, userdispensed: [...newDispensed] }
            setTreatment(updatedTreatment)
            
        
      }
    };

    useEffect(() => {
        if (isAddingCustomItem && containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
            setIsAddingCustomItem(false);
      }
    }, [treatment,isAddingCustomItem]);
    
    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value.toLowerCase());
    };

    const handleAddEmptyRow = () => {
      if (type === "Procedures" || type === "Dispensed" || type === "Labs") {
        setIsAddingCustomItem(true);

        let newItem = {};
        if (type === "Procedures") {
          newItem = {
            procedure_name: "",
            selectedField: "",
            note: "",
            checked:false,
            isNew: true,
            editItemName: true,
          };
          
         
          const updatedTreatment={
            ...treatment,
            userProcedures: [...treatment.userProcedures, newItem],
          }
            setTreatment(updatedTreatment);
        
        } else if (type === "Dispensed") {
          newItem = {
            name: "",
            selectedField: "",
            quantity: 1,
            isNew: true,
            checked:false,
            editItemName: true,
          };
          
          const updatedTreatment={
            ...treatment,
            userdispensed: [...treatment.userdispensed, newItem],
          };
            setTreatment(updatedTreatment)
        
        } else if (type === "Labs"){
           newItem = {  test_name: "", checked: false, isNew: true, editItemName:true }
           const updatedTreatment={
            ...treatment,
            userLabs: [...treatment.userLabs, newItem],
          };
            setTreatment(updatedTreatment)
        }
      }
    };

    const getActiveProceduresSummary = () => {
        return updatedProcedures
            ?.filter(
                (procedure) => procedure.checked 
            )
            ?.map(
                (procedure) =>
                    `${procedure.procedure_name} (${procedure.selectedField || ""})`
            )
            .join(", ");
    };

    const getCheckedLabsSummary = () => {
        const checkedLabs = treatment.userLabs
            ?.filter((lab) => lab.checked)
            ?.map((lab) => lab.test_name)
            .join(", ");

        return checkedLabs;
    };

    const getActiveDispensedItemsSummary = () => {
        const activeItems = treatment.userdispensed?.filter((item) => item.isFilled);
        return activeItems
            ?.map(
                (item) =>
                    `${item.name} (${item.selectedField || "N/A"}) x${item.quantity}`
            )
            .join(", ");
    };
    const handleDeleteItem = async (item) => {
      setItemLoader([...itemLoader,{itemId:item._id,show:true}])
      let url="";
      if(type==='Procedures'){
        url=`/delete/procedureitem/${treatment._id}/${item._id}`
      }
      if(type==='Dispensed'){
        url=`/delete/dispenseditem/${treatment._id}/${item._id}`
      }
      if(type==='Labs'){
        url=`/delete/labitem/${treatment._id}/${item._id}`
      }
        try {

            const response = await axios.delete(url,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.status === 200 || response.status === 201) {
              const updatedLoader=itemLoader.filter(item => item?.itemId!==item._id)
              setItemLoader(updatedLoader)
            }
            // Successfully deleted item, you can now remove it from your UI or refetch the procedures list
            if(type==='Procedures'){
              const newItems = treatment?.userProcedures?.filter(
                  (procedure) => procedure._id !== item._id
              );
              setTreatment({...treatment,userProcedures:[...newItems]});
            }
            if(type==='Dispensed'){
              const newItems = treatment?.userdispensed?.filter(
                (dispensed) => dispensed._id !== item._id
              );
              setTreatment({ ...treatment, userdispensed: [...newItems] });
            }
            if(type==='Labs'){
              const newItems = treatment?.userLabs?.filter(
                (labItem) => labItem._id !== item._id
              );
              setTreatment({ ...treatment, userLabs: [...newItems] });
            }
            
        } catch (error) {
            console.error("Error deleting procedure item:", error);
            const updatedLoader=itemLoader.filter(item => item?.itemId!==item._id)
            setItemLoader(updatedLoader)
            // Handle error (e.g., show an error message)
        }
    };

    // const handleNext = () => {
    //     handleOpenDispenseModal(patientId, recordForNotes);

    //     onClose();
    // };

    // const handlePrevious = () => {
    //     handleOpenLabsModal(patientId, recordForNotes);

    //     onClose();
    // };

    const [itemCounters, setItemCounters] = useState({});
    const handleAddItem = (itemName) => {
        const newCounter = (itemCounters[itemName] || 0) + 1;
        const newItemName = `${itemName} ${newCounter}`;

        const selectedItemIndex = treatment.userdispensed.findIndex(
            (item) => item.name === itemName
        );

        if (selectedItemIndex !== -1) {
            const newItem = {
                ...treatment.userdispensed[selectedItemIndex], // Copy the selected item
                name: newItemName, // Update the name with the counter
                selectedField: "", // Reset other fields as needed
                quantity: 0,
                isNew: true,
            };

            // Insert the new item below the selected item
            const newUpdatedItems = [
                ...treatment.userdispensed.slice(0, selectedItemIndex + 1), // Items before the selected item
                newItem, // The new item
                ...treatment.userdispensed.slice(selectedItemIndex + 1), // Items after the selected item
            ];
            
              const updatedTreatment= { ...treatment, userdispensed: [...newUpdatedItems] }
                setTreatment(updatedTreatment)
            
        }
    };
    const handleRemoveItem = (item) => {
        const newUpdatedItems = treatment.userdispensed?.filter(
            (data) => data.name !== item.name
        );

        if (item && item._id) {
            // If the item has an ID, it means it's a saved item, so we need to make a delete request
            axios
                .delete(`/delete/dispenseditem/${item._id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {

                    // Decrement the counter for the corresponding item type
                    const itemName = item.name.split(" ")[0]; // Extracting the item name without counter
                    setItemCounters((prevCounters) => {
                        const newCounters = { ...prevCounters };
                        if (newCounters[itemName]) {
                            newCounters[itemName]--;
                        }
                        return newCounters;
                    });
                    // setUpdatedItems(newUpdatedItems);
                    
                    const updatedTreatment= { ...treatment, userdispensed: [...newUpdatedItems] }
                        setTreatment(updatedTreatment)
                    
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        } else {
            // If the item doesn't have an ID, it means it's a newly added item, so just update the state
            // setUpdatedItems(newUpdatedItems);
            
                const updatedTreatment= { ...treatment, userdispensed: [...newUpdatedItems] }
                setTreatment(updatedTreatment)
            
        }
    };
    // Function to find the previous treatment based on a given date
    const findPreviousTreatment = (treatments) => {
        let previousTreatment = null;
        let currentDate = new Date(treatment.date);
        let found = false;

        while (!found && currentDate) {
            currentDate = getPreviousDate(currentDate);
            previousTreatment = treatments.treatments.find((treatment) => {
                let treatmentDateString = new Date(treatment.date)
                    .toISOString()
                    .split("T")[0];
                return treatmentDateString === currentDate;
            });

            if (previousTreatment) {
                found = true; // Found treatment on previous date
            }
        }

        return previousTreatment;
    };


    const findCurrentTreatment = (treatments) => {
        let currentTreatment = null;
        let currentDate = new Date(treatment.date).toISOString().split("T")[0];
        let found = false;

        currentTreatment = treatments.treatments.find((treatment) => {
            let treatmentDateString = new Date(treatment.date)
                .toISOString()
                .split("T")[0];
            return treatmentDateString === currentDate;
        });

        if (currentTreatment) {
            found = true;
        }

        return currentTreatment;
    };
    const getPreviousDate = (dateString) => {
        const currentDate = new Date(dateString);
        const previousDate = new Date(currentDate);
        previousDate.setDate(previousDate.getDate() - 1);
        return previousDate.toISOString().split("T")[0];
    };
    const showToast = (variant, message) => {
        setToast({ variant, message });

        setTimeout(() => {
            setToast(null);
        }, 5000);
    };

    const addNewVitalsRow = () => {
        const newVital = {
            BP: "",
            HR: 0,
            RR: 0,
            SPO2: 0,
            T: 0,
            W: 0,
            time: moment.tz("America/Phoenix").toDate(),
        };

        // setUpdatedItems([...treatment.vitals, newVital]);
        const updatedItems= [...vitals, newVital];
        
        setTreatment({...treatment, vitals:[...updatedItems]})
            
            // setAllTreatments((prevState) =>
            //     prevState?.map((order) => {
            //         if (order._id === treatment._id) {                        
            //             return { ...order, vitals: [...treatment.vitals, newVital] };
            //         } else {
            //             return order; 
            //         }
            //     })
            // );
        
    };

    const handleRefreshLabs = async () => {
        // setLabsModalLoader(true);
        try {
            // Fetch new labs data
            const labsResponse = await axios.get(
                `/orderget/${treatment._id}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
                    },
                }
            );
            // Function to sort labs if needed
            const sortLabs = (labs) => {
                // Your sorting logic here. Example:
                return labs.sort((a, b) =>
                    a.checked === b.checked ? 0 : a.checked ? -1 : 1
                );
            };
            // Sort labs here if necessary
            const sortedLabs = sortLabs(labsResponse.data.userLabs);

            // setCurrentPatientLabs({ labs: sortedLabs, orderId: orderId });
            
                const updatedTreatment= { ...treatment, userLabs: [...sortedLabs] }
                setTreatment(updatedTreatment)
        } catch (error) {
            // setLoading(false);
            console.error("Error refreshing labs and tubes:", error);
        }
    };
    
    

    const onClose = () => {
        onCloseModal()
        setModalType(null)
        
    }
    const placeOrder = (types) => {
        fetch(`/shopify/placeorder/${treatment._id}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ types }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                onClose();
                toast.success("Order placed successfully");
            })
            .catch((error) => {
                console.error("Error:", error);
                toast.error(`Error placing order: ${error.message}`);
            });
    };

    return (
        <Dialog
            open={showModal}
            onClose={() => {
                onClose();
                setSearchTerm("");
                if(type==='Vitals'){
                    handleUpdateVitals()
                }
            }}
            maxWidth={type === "Notes" ? false : "md"}  // Use false for custom width, "md" for predefined maxWidth
            PaperProps={{
                style: {
                    width: type === "Notes" ? '80vw' : false,  // Custom width for "Notes", default for others
                    padding: type === "Notes" ? '20px' : 'auto'
                }
            }}
            fullWidth
        >
            <div className="flex justify-between items-center">
                <DialogTitle>
                    <span className={styles["proctitleStyling"]}>
                        {/* <IconButton onClick={handlePrevious}>
                            <ChevronLeft />
                        </IconButton> */}
                        {type === "Labs" ? "Update Labs" : type === "Procedures" ? "Update Procedures" : type === "Dispensed" ? "Update Dispensed" : type === "Notes" ? "Treatment Notes" : type === "Vitals" ? "Update Vitals Items" : null}
                        {/* <IconButton onClick={handleNext}>
                            <ChevronRight />
                        </IconButton> */}
                    </span>
                </DialogTitle>


            </div>
            {type === "Vitals" &&
                <div className="flex items-center justify-between">
                    <div className="activeStylingD w-fit">{patientName}</div>
                    <div className="flex w-full justify-end">
                        <button className="newVitalBtn" onClick={addNewVitalsRow}>
                            Add New Vitals
                        </button>
                    </div>
                </div>}
            {(type === "Labs" || (type === "Procedures" || type === "Dispensed")) &&
                <>
                    {modalLoader && (
                        <div className="loader-backdrop">
                            <BounceLoader color="#000" />
                        </div>
                    )}

                    <div className="px-5">
                        {toast && (
                            <CustomToast
                                message={toast.message}
                                variant={toast.variant}
                                customStyles={{ bottom: "40px" }}
                            />
                        )}
                        <div className="activeStylingD">{treatment?.userProcedures?.patientName}</div>
                        <div>
                            <input
                                placeholder={type === "Labs" ? "Search Labs" : type === "Procedures" ? "Search Procedures" : type === "Dispensed" ? "Search Dispensed" : null}
                                className="procsearchInput"
                                fullWidth
                                margin="normal"
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className="procactiveStyling">
                            {type === "Labs" ? (
                                <>
                                    <strong>Active Labs:</strong> {getCheckedLabsSummary()}
                                </>
                            ) : type === "Procedures" ? (
                                <>
                                    <strong>Active Procedures:</strong> {getActiveProceduresSummary()}
                                </>
                            ) : type === "Dispensed" ? (
                                <>
                                    <strong>Active Dispensed Items:</strong> {getActiveDispensedItemsSummary()}
                                </>
                            ) : null}

                        </div>
                        <div className="outer-procedures-container">

                            <div className="procedures-container inner-procedures-container" ref={containerRef} >
                                {
                                    filteredItems?.length>0 && filteredItems?.map((item,index) => {
                                      const loader = itemLoader.find(
                                        (itemLoader) => {
                                    
                                         if( itemLoader.itemId === item._id && itemLoader.show){
                                          return true
                                         }
                                         return false
                                        }
                                      );
                                        return (
                                            <div key={index} className="procedure-row flex">
                                                {/* Item Name Section */}
                                                <div className="procedure-name w-3/5">

                                              
                                              {type === 'Procedures' && <div className="flex items-center gap-6">
                                                {(!item.editItemName || item.custom === false) && <p>{item.procedure_name}</p>}
                                                {type === "Procedures" && (item.editItemName || item.custom) && (
                                                  <>
                                                    {item.editItemName && <div className="flex items-center gap-6">
                                                      <input
                                                        className="dispensedTextInput"
                                                        type="text"
                                                        value={item.procedure_name || ""}
                                                        placeholder="Name"
                                                        onChange={(event) => handleItemNameChange(event.target.value, index, "procedure")}
                                                        fullWidth
                                                      />
                                                      <input
                                                        className="dispensedTextInput"
                                                        type="text"
                                                        placeholder="Dosage"
                                                        value={item?.selectedField || ""}
                                                        onChange={(event) => handleSelectedFieldChange(event.target.value, index, 'procedure')}
                                                        fullWidth
                                                      />
                                                    </div>}
                                                    {item.procedure_name && (item.editItemName === false || item.custom) && <input
                                                      className="dispensedTextInput"
                                                      type="text"
                                                      placeholder="Dosage"
                                                      value={item?.selectedField || ""}
                                                      onBlur={(e) => {
                                                        if ((treatment && treatment.review)) {
                                                          return message.warning("This treatment is finalized so can't make any changes in it")
                                                        }
                                                        else {
                                                          if (!item?.editItemName) {
                                                            handleChange(
                                                              item,
                                                              item.procedure_name,
                                                              "selectedField",
                                                              e.target.value
                                                            )
                                                          }
                                                        }
                                                      }}
                                                      onChange={(event) => handleSelectedFieldChange(event.target.value, index, 'procedure')}
                                                      fullWidth
                                                    />
                                                    }
                                                  </>
                                                )}
                                              </div>}
                                              {type === 'Dispensed' && <div className="flex items-center gap-6">
                                                {(!item.editItemName || item.custom === false) && <p>{item.name}</p>}
                                                {type === "Dispensed" && (item.editItemName || item.custom) && (
                                                  <>
                                                    {item.editItemName && <div className="flex items-center gap-6">
                                                      <input
                                                        className="dispensedTextInput"
                                                        type="text"
                                                        value={item.name || ""}
                                                        placeholder="Name"
                                                        onChange={(event) => handleItemNameChange(event.target.value, index, "dispensed")}
                                                        fullWidth
                                                      />
                                                      <input
                                                        className="dispensedTextInput"
                                                        type="text"
                                                        placeholder="Dosage"
                                                        value={item?.selectedField || ""}
                                                        onChange={(event) => handleSelectedFieldChange(event.target.value, index, 'dispensed')}
                                                        fullWidth
                                                      />
                                                    </div>}
                                                    {item.name && (item.editItemName === false || item.custom) && <input
                                                      className="dispensedTextInput"
                                                      type="text"
                                                      placeholder="Dosage"
                                                      value={item?.selectedField || ""}
                                                      onBlur={(e) => {
                                                        if ((treatment && treatment.review)) {
                                                          return message.warning("This treatment is finalized so can't make any changes in it")
                                                        }
                                                        else {
                                                          if (!item?.editItemName) {
                                                            handleChange(
                                                              item,
                                                              item.procedure_name,
                                                              "selectedField",
                                                              e.target.value
                                                            )
                                                          }
                                                        }
                                                      }}
                                                      onChange={(event) => handleSelectedFieldChange(event.target.value, index, 'dispensed')}
                                                      fullWidth
                                                    />
                                                    }
                                                  </>
                                                )}
                                              </div>}
                                              {type === 'Labs' && <div className="flex items-center gap-6">
                                                {(!item.editItemName || item.custom === false) && <p>{item.test_name}</p>}
                                                {type === "Labs" && (item.editItemName || item.custom) && (
                                                  <>
                                                    {item.editItemName && <div className="flex items-center gap-6">
                                                      <input
                                                        className="dispensedTextInput"
                                                        type="text"
                                                        value={item.test_name || ""}
                                                        placeholder="Name"
                                                        onChange={(event) => handleItemNameChange(event.target.value, index, "labs")}
                                                        fullWidth
                                                      />
                                                    </div>}
                                                  </>
                                                )}
                                              </div>}
                                              

                                                </div>
                                                {/* Checkbox Section */}
                                                <div className="procedure-checkbox w-1/5 flex justify-center items-center">

                                                    {("checked" in item)  && (

                                                        <Checkbox
                                                            checked={item.checked}
                                                            onChange={(e) => {
                                                                if (treatment?.review ) {
                                                                    return message.warning("This treatment is finalized so can't make any changes in it")
                                                              }
                                                              else {
                                                                if (type === 'Procedures') {
                                                                  if ((treatment && treatment?.review)) {
                                                                    return message.warning("This treatment is finalized so can't make any changes in it")
                                                                  }
                                                                  else {
                                                                    if (e.target.checked) {
                                                                      if (item.isNew) {
                                                                        handleChange(
                                                                          item,
                                                                          item.procedure_name,
                                                                          "n",
                                                                          item.procedure_name,
                                                                        );
                                                                      }
                                                                      else {
                                                                        handleChange(
                                                                          item,
                                                                          item.procedure_name,
                                                                          "checked",
                                                                          e.target.checked
                                                                        );
                                                                      }
                                                                    }
                                                                    else {
                                                                      handleChange(
                                                                        item,
                                                                        item.procedure_name,
                                                                        "checked",
                                                                        e.target.checked
                                                                      );
                                                                    }
                                                                  }
                                                                }
                                                                else if (type === 'Dispensed') {
                                                                  if (treatment && treatment.review) {
                                                                    return message.warning("This treatment is finalized so can't make any changes in it")
                                                                  }
                                                                  else {
                                                                    if ((item.isNew || item.custom) && (!item.selectedField || !item.name)) {
                                                                      message.warning(`Please add ${!item.name ? 'name value' : ""} ${(!item.name && !item.selectedField) ? 'and dosage value' : !item.selectedField ? 'dosage value' : ""} in order to check the item`)
                                                                    }
                                                                    else {
                                                                      if (item.isNew) {
                                                                        handleChange(
                                                                          item,
                                                                          item.name,
                                                                          "n",
                                                                          item.name
                                                                        )
                                                                      }
                                                                      else {
                                                                        handleChange(
                                                                          item,
                                                                          item.name,
                                                                          "quantity",
                                                                          e.target.checked ? 1 : 0
                                                                        )
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                                else if (type === "Labs") {
                                                                  if (treatment && treatment.review) {
                                                                    return message.warning("This treatment is finalized so can't make any changes in it")
                                                                  }
                                                                  else {
                                                                    if ((item.isNew || item.custom) && ( !item.test_name)) {
                                                                      message.warning(`Please add ${!item.name ? 'name value' : ""} in order to check the item`)
                                                                    }
                                                                    else {
                                                                      if (item.isNew) {
                                                                        handleCustomLabCheckboxChange(index, e.target.checked)
                                                                      }
                                                                      else {
                                                                        handleLabsCheckboxChange(
                                                                          item._id,
                                                                          e.target.checked
                                                                        );
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                            }
                                                        // disabled={("selectedField" in procedure)}
                                                        />
                                                    )}
                                                </div>
                                                {/* Custom Item Section */}
                                                <div className="procedure-details w-1/5 flex justify-start items-center">
                                                    {item.custom && (type === "Labs" || type === "Procedures") && (
                                                       loader
                                                       ?
                                                       <Spin indicator={<LoadingOutlined spin />} size="small" />
                                                       :
                                                        <IconButton
                                                            onClick={() => handleDeleteItem(item)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )}
                                                    {/* Dosages Section */}
                                                    {item.dosages && item.dosages.length > 0 && !item.editItemName &&  (
                                                        <div className="procedure-dosage my-auto">
                                                            <Select
                                                                className="dosageSelect"
                                                                value={item.selectedField ? item.selectedField : item.checked ? item.dosages[0] : ""}
                                                                onChange={(e) => {
                                                                    if (treatment?.review ) {
                                                                        return message.warning("This treatment is finalized so can't make any changes in it")
                                                                    }
                                                                    else {
                                                                        if (type === 'Procedures') {
                                                                            handleChange(
                                                                                item,
                                                                                item.procedure_name,
                                                                                "selectedField",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                        else if (type === 'Dispensed') {
                                                                            handleChange(
                                                                                item,
                                                                                item.name,
                                                                                "selectedField",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    }
                                                                }
                                                                }
                                                                fullWidth
                                                            >
                                                                <MenuItem key="empty-option" value="">
                                                                    Empty
                                                                </MenuItem>
                                                                {item.dosages?.map((dosage, idx) => (
                                                                    <MenuItem key={idx} value={dosage}>
                                                                        {dosage}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    )}
                                                    {/* Custom Dosage Section */}
                                                    {"customDosage" in item && (
                                                        <div className="procedure-note my-auto pr-2">
                                                            <input
                                                                className="note-input"
                                                                value={item.customDosage || ""}
                                                                onChange={(e) => {
                                                                    if (treatment?.review ) {
                                                                        return message.warning("This treatment is finalized so can't make any changes in it")
                                                                    } else {
                                                                        handleChange(
                                                                            item,
                                                                            item.procedure_name,
                                                                            "customDosage",
                                                                            e.target.value
                                                                        )
                                                                    }
                                                                }
                                                                }
                                                                fullWidth
                                                                placeholder="Dosage"
                                                            />
                                                        </div>
                                                    )}
                                                    {/* Note Section */}
                                                    {"note" in item && (
                                                        <div className="procedure-note my-auto pr-2">
                                                            {item.showNoteInputRow ||
                                                                (item.note && item.note.trim() !== "") ? (
                                                                <input
                                                                    className="note-input"
                                                                    value={item.note || ""}
                                                                    onChange={(e) => {
                                                                        if (treatment?.review ) {
                                                                            return message.warning("This treatment is finalized so can't make any changes in it")
                                                                        } else {
                                                                            handleChange(
                                                                                item,
                                                                                item.procedure_name,
                                                                                "note",
                                                                                e.target.value
                                                                            )
                                                                        }
                                                                    }
                                                                    }
                                                                    fullWidth
                                                                />
                                                            ) : (
                                                                <IconButton
                                                                    onClick={() => {
                                                                        if (type === "Procedures") {
                                                                            toggleNoteInputRow(item.procedure_name)
                                                                        }
                                                                        else if (type === "Dispensed") (
                                                                            toggleNoteInputRow(item.name)
                                                                        )
                                                                    }
                                                                    }
                                                                >
                                                                    <NotesIcon />
                                                                </IconButton>
                                                            )}
                                                        </div>
                                                    )}

                                                    {type === "Dispensed" && <div className="divTableCell contentFitthreeC">
                                                        <input
                                                            className="dispensedNumberInput"
                                                            type="number"
                                                            value={item.quantity || 0}
                                                            onChange={(e) => {
                                                                if (treatment?.review) {
                                                                    return message.warning("This treatment is finalized so can't make any changes in it")
                                                                } else {
                                                                    handleChange(item, item.name, "quantity", e.target.value)
                                                                }
                                                            }
                                                            }
                                                            fullWidth
                                                        />
                                                    </div>}
                                                    {!item.isNew && type === "Dispensed" ? ( // Only render the button if the item is not new
                                                        !dispensedItemsArray.includes(item.name) ? ( // Check if the item name is not in the specified list
                                                          loader ?
                                                          <Spin indicator={<LoadingOutlined spin />} size="small" />
                                                          :
                                                            <IconButton onClick={() => handleDeleteItem(item)}>
                                                                <RemoveCircleRounded className="text-red-500" />
                                                            </IconButton>
                                                        ) : (
                                                            <IconButton onClick={() => {
                                                                if (treatment?.review) {
                                                                    return message.warning("This treatment is finalized so can't make any changes in it")
                                                                } else { handleAddItem(item.name) }
                                                            }}>
                                                                <AddCircleRounded className="text-green-500" />
                                                            </IconButton>
                                                        )
                                                    ) : (
                                                        <div className="w-[30px]"></div>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })
                                }


                            </div>
                        </div>
                        {(type === "Procedures" || type === "Dispensed" || type === "Labs") && <DialogActions>
                         {!treatment?.review && <button
                                className="btn-color py-2 px-14 text-xs md:text-sm w-full md:w-auto"
                                onClick={handleAddEmptyRow}
                            >
                                Add Custom Item
                            </button>}
                            <button
                                className="btn-color py-2 px-14 text-xs md:text-sm w-full md:w-auto"
                                onClick={onClose}
                            >
                                Finish
                            </button>
                        </DialogActions>}
                        
                        
                    </div>
                    
                </>
            }
            {type === "Notes" && <TreatmentNotes
                treatment={treatment}
                isTreatmentNoteEditing={isTreatmentNoteEditing.current}
                setIsTreatmentNoteEditing={setIsTreatmentNoteEditing}
            />}
            {type === 'Vitals' && <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow className="rowStyling">
                            <TableCell>BP</TableCell>
                            <TableCell>HR</TableCell>
                            <TableCell>RR</TableCell>
                            <TableCell>SPO2</TableCell>
                            <TableCell>T</TableCell>
                            <TableCell>W</TableCell>
                            <TableCell>Time</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {vitals.length>0 && vitals?.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <input
                                        className="vitalsInput"
                                        type="text"
                                        value={item.BP}
                                        onChange={(e) =>
                                            handleVitalsChange(index, "BP", e.target.value)
                                        }
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <input
                                        className="vitalsInput"
                                        type="number"
                                        value={item.HR}
                                        onChange={(e) =>
                                            handleVitalsChange(index, "HR", e.target.value)
                                        }
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <input
                                        className="vitalsInput"
                                        type="number"
                                        value={item.RR}
                                        onChange={(e) =>
                                            handleVitalsChange(index, "RR", e.target.value)
                                        }
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <input
                                        className="vitalsInput"
                                        type="number"
                                        value={item.SPO2}
                                        onChange={(e) =>
                                            handleVitalsChange(index, "SPO2", e.target.value)
                                        }
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <input
                                        className="vitalsInput"
                                        type="number"
                                        value={item.T}
                                        onChange={(e) => handleVitalsChange(index, "T", e.target.value)}
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <input
                                        className="vitalsInput"
                                        type="number"
                                        value={item.W}
                                        onChange={(e) => handleVitalsChange(index, "W", e.target.value)}
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <TimePicker
                                        className="datetimeStling"
                                        use12Hours
                                        format="h:mm a"
                                        value={item.time ? moment(item.time) : null}
                                        onChange={(time) => handleTimeChange(index, time)}
                                        fullWidth
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>}
            {type === "Vitals" && <DialogActions>
                            <button className="btn-color py-2 px-14" onClick={()=>handleUpdateVitals()}>
                            Finish
                            </button>
                        </DialogActions>}
        </Dialog >
        // <>
        //     {type && <p>{type}</p>}
        //     {treatment && <p>{JSON.stringify(treatment)}</p>}
        // </>
    );
}));

export default LPDPopup;
