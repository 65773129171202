import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { Checkbox, Col, Form, Input, Radio, Row, Space } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import Paragraph from 'antd/es/typography/Paragraph'
import Title from 'antd/lib/typography/Title'
import React, { useState } from 'react'

const HealthQuestionsForm = ({form,handleNext,handlePrev}) => {

    const [hasWeightProblems, setHasWeightProblems] = useState(false);
    const [hasAllergies, setHasAllergies] = useState(false);
    const yesNoOptions = ["Yes", "No"];

    const alcoholConsumptionOptions = [
        "Daily",
        "Weekly",
        "Monthly",
        "Occasionally",
        "Never",
      ];

      
  const familyHistoryOptions = [
    "Asthma",
    "Autoimmune disease",
    "Cancer",
    "Heart disease",
    "Diabetes",
    "High blood pressure",
    "Mental illness",
    "Stroke",
    "Other",
  ];

  const weightProblemsOptions = [
    "30% over your recommended weight",
    "Anorexia",
    "Binge eating",
    "Eat to gain weight",
    "Eat to lose weight",
    "Bulimia",
    "Unable to gain weight",
    "Rapid/unexplained weight loss",
    "None",
    "Other",
  ];

      const handleWeightProblemsChange = (checkedValues) => {
          setHasWeightProblems(checkedValues.includes("Other"));
          const hasOther = checkedValues.includes("Other");
      
          if (hasOther) {
            form.setFieldsValue({
              medicationsSupplements: {
                weightProblems: ["Other"],
              },
            });
          }
        };

    const handleAllergiesChange = (e) => {
        setHasAllergies(e.target.value === "Yes");
      };
  return (
    <>
           <div className="bg-white p-6 rounded-2xl w-full">
           <Space direction="vertical" size="large" style={{ width: '100%' }}>

          <Form
            form={form}
            layout="vertical"
            initialValues={{ alcoholConsumption: 'never' }}
          >
            <Row gutter={16}>
              {/* Column 1 */}
              <Col xs={24} sm={12}>
                <Title level={4}>Family History</Title>
                <Paragraph>
                  Has anyone in your immediate family (blood relative) ever had any of the following diseases or conditions?
                </Paragraph>
                <Form.Item name="familyHistory">
                  <Checkbox.Group>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 16 }}>
                      {familyHistoryOptions.map((condition) => (
                        <Checkbox key={condition} value={condition}>
                          {condition}
                        </Checkbox>
                      ))}
                    </div>
                  </Checkbox.Group>
                </Form.Item>

                <Title level={4}>Allergies</Title>
                <Form.Item
                  name="allergies"
                  label="Do you have any allergies to medications, foods, or environmental allergens?"
                >
                  <Radio.Group>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                    <Radio value="notSure">Not Sure</Radio>
                  </Radio.Group>
                </Form.Item>

                <Title level={4}>Medications / Supplements</Title>
                <Form.Item
                  name="takingMedications"
                  label="Are you currently taking any medications or supplements?"
                >
                  <Radio.Group>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  name="weightChange"
                  label="Has your weight changed in the last year? If so how?"
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="weightIssues"
                  label="Have you ever had problems with your weight? Please describe the problem:"
                >
                  <Checkbox.Group>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 16 }}>
                      {weightProblemsOptions.map((issue) => (
                        <Checkbox key={issue} value={issue}>
                          {issue}
                        </Checkbox>
                      ))}
                    </div>
                  </Checkbox.Group>
                </Form.Item>
              </Col>

              {/* Column 2 */}
              <Col xs={24} sm={12}>
                <Row gutter={16}>
                  <Col xs={12}>
                    <Form.Item name="weight" label="Weight">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item name="height" label="Height">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item name="diet" label="Please describe your diet">
                  <TextArea rows={4} />
                </Form.Item>

                <Form.Item name="exercise" label="Please describe any regular exercise">
                  <TextArea rows={4} />
                </Form.Item>

                <Form.Item
                  name="stress"
                  label="Please describe your current level of stress and any ways you use to manage your stress"
                >
                  <TextArea rows={4} />
                </Form.Item>

                <Form.Item name="alcoholConsumption" label="How often do you consume alcohol?">
                  <Radio.Group>
                    <Radio value="daily">Daily</Radio>
                    <Radio value="weekly">Weekly</Radio>
                    <Radio value="monthly">Monthly</Radio>
                    <Radio value="occasionally">Occasionally</Radio>
                    <Radio value="never">Never</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  name="smoked"
                  label="Have you ever smoked cigarettes, cigars, marijuana or a pipe?"
                >
                  <Radio.Group>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  name="illegalDrugs"
                  label="Do you use or do you have history of using illegal drugs?"
                >
                  <Radio.Group>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item name="vaccinated" label="Have you ever been vaccinated?">
                  <Radio.Group>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  name="vaccineReaction"
                  label="Have you ever had a reaction to a vaccine?"
                >
                  <Radio.Group>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item name="surgery" label="Have you ever had surgery?">
                  <Radio.Group>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>

            <div className="flex justify-between">
              <IconButton onClick={handlePrev}>
                <NavigateBefore />
              </IconButton>
              <IconButton>
                <NavigateNext onClick={handleNext} />
              </IconButton>
            </div>
          </Form>

            </Space>

              </div>
    </>
  )
}

export default HealthQuestionsForm