import { DeleteFilled, SendOutlined } from "@ant-design/icons";
import { Delete } from "@mui/icons-material";
import { colors, IconButton, Typography } from "@mui/material";
import { blue, red } from "@mui/material/colors";
import { Button, DatePicker, Input, Modal, Popover } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";

const { TextArea } = Input;
const MultipleTxNotes = ({
  selectedPatientDetails,
  setSelectedPatientDetails,
  selectedDateFromView,
  from=""
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [noteValue, setNoteValue] = useState("");
  const [todayDate, setTodayDate] = useState("");
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [date, setDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState(null);
  const previousPatientDetailsRef = useRef({ "Cusomer ID": null });

  const onDateChange = (date, dateString) => {
    setDate(date);
    setFormattedDate(moment(dateString).format("YYYY-MM-DD"));
  };

  const handleVisibleChange = (visible) => {
    if (!isAnotherDateModalVisible && !isTodayDateModalVisible) {
      setPopoverVisible(visible);
    }
  };
  const handleUpdateNote = async (noteId, noteValue, date) => {
    const payload = {
      note: noteValue,
    };
    try {
      const response = await axios.put(
        `/patient/${selectedPatientDetails["Customer ID"]}/note/${noteId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        setSelectedPatientDetails(response.data.updatedPatientProfile);
        setSelectedDate(date);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdateNoteFromTreatment = async (treatmentId, noteValue, date) => {
  
    const payload = {
      note: noteValue,
    };
    try {
      const response = await axios.put(
        `/treatment/note/${treatmentId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdateGeneralNote = async (noteValue) => {
    const payload = {
      generalNote: noteValue,
    };
    try {
      const response = await axios.put(
        `/updateGeneralNote/${selectedPatientDetails["Customer ID"]}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        setSelectedPatientDetails(response.data.patient);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDeleteNote = async (noteId, date) => {
    
    try {
      const response = await axios.delete(
        `/patient/${selectedPatientDetails["Customer ID"]}/note/${noteId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        setSelectedPatientDetails(response.data.updatedPatientProfile);        
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const findNotesForDate = (date) => {
    const notes = selectedPatientDetails?.MultipleTxNotes?.filter(
      (item) => item?.date?.split("T")[0] === date
    );

    if (!notes || notes.length === 0) {
      return [];
    }
    return notes;
  };

  const [isTodayDateModalVisible, setIsTodayDateModalVisible] = useState(false);
  const [isAnotherDateModalVisible, setIsAnotherDateModalVisible] =
    useState(false);

  const showTodayDateModal = () => {
    setIsTodayDateModalVisible(true);
    setPopoverVisible(false);
  };
  const showAnotherDateModal = () => {
    setIsAnotherDateModalVisible(true);
    setPopoverVisible(false);
  };

  const handleCancelTodayDateModal = () => {
    setIsTodayDateModalVisible(false);
    setNoteValue(null);
  };
  const handleCancelAnotherDateModal = () => {
    setIsAnotherDateModalVisible(false);
    setDate(null);
    setNoteValue(null);
  };
  const content = (
    <div className="flex flex-col items-center gap-2">
      <Button
        type="primary"
        className="btn-color  py-2 px-3 text-xs md:text-sm w-full "
        onClick={() => showTodayDateModal()}
      >
        New note for today
      </Button>
      <Button
        type="primary"
        className="btn-color  py-2 px-3 text-xs md:text-sm w-full"
        onClick={() => showAnotherDateModal()}
      >
        New note for another date
      </Button>
    </div>
  );

  const handleNoteValueChange = (event) => {
    setNoteValue(event.target.value);
  };

  const handleDeleteDateNotes = (noteId) => {
    try {
      const response = axios
        .delete(
          `/patient/${selectedPatientDetails["Customer ID"]}/note/${noteId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          setSelectedPatientDetails(response.data.updatedPatientProfile);
        });
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  useEffect(() => {
    setTodayDate(moment(selectedDateFromView).format("YYYY-MM-DD"));
    setSelectedDate(moment(selectedDateFromView).format("YYYY-MM-DD"));
  }, []);

  useEffect(() => {
    if (
      selectedPatientDetails["Customer ID"] !==
      previousPatientDetailsRef.current["Customer ID"]
    ) {
      previousPatientDetailsRef.current = selectedPatientDetails;
      setSelectedDate(moment(selectedDateFromView).format("YYYY-MM-DD"));
    }
    console.log("******** selected Patient Detail", selectedPatientDetails)
  }, [selectedPatientDetails]);

  const handleAddNote = (date) => {
    const payload = {
      note: noteValue,
      date: date,
    };
    try {
      const response = axios
        .post(
          `/patient/${selectedPatientDetails["Customer ID"]}/addNote`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          setSelectedPatientDetails(response.data.patient);
          setSelectedDate(date.split("T")[0]);
          handleCancelTodayDateModal();
          handleCancelAnotherDateModal();
        });
    } catch (error) {
      console.error("Error: ", error);
    }
  };
  return (
    <>
      <div className={`flex flex-row justify-between ${from==='emr'?'w-full':'w-[55%]'} h-[310px] shadow-md px-2 py-2 rounded-sm`}>
        <div className="flex-1 px-3">
          <div className="flex justify-between">
            <p className="text-xl font-bold">Tx Notes</p>
            {
              <Popover
                content={content}
                trigger="click"
                visible={popoverVisible}
                onVisibleChange={handleVisibleChange}
              >
                <Button
                  type="primary"
                  className="btn-color  py-2 px-3 text-xs md:text-sm w-auto"
                >
                  Add
                </Button>
              </Popover>
            }
          </div>
          <div className="mt-2 mb-4">
            <p className="text-base font-semibold">General Note</p>
            <TextArea
              type="text"
              key={selectedPatientDetails._id}
              className="rounded-md bg-gray-100 placeholder:text-gray-400  placeholder:text-sm"
              placeholder="Please enter general note"
              defaultValue={selectedPatientDetails.generalNote || ""}
              style={{ height: "100px" }}
              autoSize={{ minRows: 1, maxRows: 3 }}
              onBlur={(event) => {
                handleUpdateGeneralNote(event.target.value)
              }}
            />
          </div>
          <div className="max-h-[120px] overflow-auto">
            {
              selectedPatientDetails?.MultipleTxNotes?.length > 0 &&
              selectedPatientDetails.MultipleTxNotes
              .slice()
              .sort((a, b) => new Date(b.date) - new Date(a.date)) .map((Noteitem) => {
              return(
              <div className="mt-1 flex flex-col ">
                <div className="flex flex-col  gap-2  mt-2 pr-2">
                  {findNotesForDate(Noteitem.date?.split("T")[0])[0]?.value.map(
                    (item, index) => (
                  <div className="relative">
                        <TextArea
                          key={item._id}
                          type="text"
                          placeholder="please enter note"
                          className="rounded-md bg-gray-100"
                          defaultValue={item.note}
                          style={{ height: "100px" }}
                          autoSize={{ minRows: 1, maxRows: 3 }}
                          onBlur={(event) => {
                            if (event.target.value.trim() !== item.note) {
                              if(!item.treatment){
                              handleUpdateNote(
                                item._id,
                                event.target.value,
                                selectedDate,
                                );
                              }
                              else {
                                handleUpdateNoteFromTreatment(
                                item.treatment,
                                event.target.value,
                                item.date,
                                );
                                handleUpdateNote(
                                  item._id,
                                  event.target.value,
                                  selectedDate,
                                  );
                              }

                            }
                          }}
                        />
                        <div className="flex gap-2 items-center justify-between absolute bottom-1 right-1"  style={{ marginBottom: '-5px' }} >
                        <Typography
                          sx={{
                            fontSize: 14,
                            color: "darkgray",
                            marginRight: '8px',
                          }}
                        >
                           {moment.tz(item.updatedAt, "America/Phoenix").format("hh:mm A")} |{" "}
                           {moment.utc(Noteitem.date).format("MM-DD-YYYY")}
                        </Typography>
                          <IconButton sx={{ color: colors.red[400], fontSize: 20 }} onClick={() => {
                            handleDeleteNote(
                                item._id,
                                selectedDate
                              );
                        }}>
                          <DeleteFilled />
                          </IconButton>
                          </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            )    
            })
            }
          </div>
          
          {/* {selectedDate &&
            findNotesForDate(selectedDate?.split("T")[0])[0]?.value.length >
              0 && (
              <div className="mt-1 flex flex-col max-h-[120px] overflow-auto:">
                <p className="text-base font-semibold ">
                  Date: {moment.utc(selectedDate).format("MM-DD-YYYY")}
                </p>
                <div className="flex flex-col overflow-y-auto gap-2 h-[150px] mt-2 pr-2">
                  {findNotesForDate(selectedDate?.split("T")[0])[0]?.value.map(
                    (item, index) => (
                      <div className="relative">
                        <TextArea
                          key={item._id}
                          type="text"
                          placeholder="please enter note"
                          className="rounded-md bg-gray-100"
                          defaultValue={item.note}
                          style={{ height: "100px" }}
                          autoSize={{ minRows: 3, maxRows: 3 }}
                          onBlur={(event) => {
                            if (event.target.value.trim() !== item.note) {
                              handleUpdateNote(
                                item._id,
                                event.target.value,
                                selectedDate
                              );
                            }
                          }}
                        />
                        <Typography
                          sx={{
                            position: "absolute",
                            bottom: 5,
                            right: 10,
                            fontSize: 14,

                            color: "darkgray",
                          }}
                        >
                          {moment
                            .tz(item.updatedAt, "America/Phoenix")
                            .format("hh:mm A")}
                        </Typography>
                      </div>
                    )
                  )}
                </div>
              </div>
            )} */}

          {/* {!findNotesForDate(selectedDate?.split("T")[0])[0]?.value.length >
            0 && (
            <div style={{ position: "relative", marginTop: 4 }}>
              <TextArea
                type="text"
                placeholder="Please enter tx note for today"
                className="rounded-md bg-gray-100 placeholder:text-gray-400  placeholder:text-sm"
                value={noteValue}
                onChange={handleNoteValueChange}
                style={{ height: "100px" }}
                autoSize={{ minRows: 3, maxRows: 3 }}
              />
              <IconButton
                onClick={() => handleAddNote(todayDate)}
                style={{
                  position: "absolute",
                  bottom: "8px",
                  right: "8px",
                  background: blue["500"],
                }}
              >
                <SendOutlined
                  style={{ color: "white", fontSize: 15, padding: 2 }}
                />
              </IconButton>
            </div>
          )} */}
        </div>
        {/* Dates Column Section Hidden */}
        {/* {selectedPatientDetails?.MultipleTxNotes?.length > 0 && (
          <div className="flex flex-col items-start py-2 px-3 w-auto h-[230px] border-l-gray-300 gap-1 border-l-2 overflow-y-auto">
            {" "}
            {selectedPatientDetails?.MultipleTxNotes?.length > 0 &&
              selectedPatientDetails.MultipleTxNotes.slice()
                .sort((a, b) => new Date(b.date) - new Date(a.date))
                .map((item) => {
                  return (
                    <div
                      className={`px-2 py-1 flex gap-2 hover:bg-blue-200 rounded-md ${
                        item?.date?.split("T")[0] === selectedDate
                          ? "bg-blue-400 text-white hover:bg-blue-400"
                          : ""
                      }`}
                    >
                      <button
                        className={`text-md font-semibold `}
                        onClick={() =>
                          setSelectedDate(item?.date?.split("T")[0])
                        }
                      >
                        {moment.utc(item.date).format("MM-DD-YYYY")}
                      </button>
                      <IconButton
                        onClick={() => handleDeleteDateNotes(item?._id)}
                      >
                        <Delete
                          sx={
                            item?.date?.split("T")[0] === selectedDate
                              ? { color: "white" }
                              : { color: red["300"] }
                          }
                        />
                      </IconButton>
                    </div>
                  );
                })}
          </div>
        )} */}
      </div>
      <Modal
        title="Add Note for today"
        open={isTodayDateModalVisible}
        onCancel={handleCancelTodayDateModal}
        footer={[
          <Button key="back" onClick={handleCancelTodayDateModal}>
            Cancel
          </Button>,
          <Button
            key="submit"
            onClick={() => handleAddNote(todayDate)}
            className={`bg-blue-500 mr-2 mb-2 text-white hover:bg-blue-600 hover:text-white`}
          >
            Add Note
          </Button>,
        ]}
      >
        <TextArea
          style={{
            height: "120px",
          }}
          placeholder="Please enter note"
          value={noteValue}
          onChange={handleNoteValueChange}
        />
      </Modal>
      <Modal
        title="Add Note for another today"
        open={isAnotherDateModalVisible}
        onCancel={handleCancelAnotherDateModal}
        footer={[
          <Button key="back" onClick={handleCancelAnotherDateModal}>
            Cancel
          </Button>,
          <Button
            key="submit"
            onClick={() => handleAddNote(formattedDate)}
            className={`bg-blue-500 mr-2 mb-2 text-white hover:bg-blue-600 hover:text-white`}
          >
            Add Note
          </Button>,
        ]}
      >
        <div className="flex flex-col gap-3">
          <DatePicker
            style={{ width: "100%" }}
            value={date}
            onChange={onDateChange}
            format={"MM-DD-YYYY"}
          />
          <TextArea
            placeholder="Please enter note"
            value={noteValue}
            onChange={handleNoteValueChange}
            style={{
              height: "120px",
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default MultipleTxNotes;
