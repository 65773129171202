import { Button, Col, Modal } from 'antd';
import React from 'react';

const PatientFormComparisonModal = ({ current, incoming, visible, onClose }) => {
    const RenderComparison = ({ currentObj, incomingObj }) => {
        const keys = new Set([...Object.keys(currentObj), ...Object.keys(incomingObj)]);

        return Array.from(keys).map((key) => {
            const currentValue = currentObj[key];
            const incomingValue = incomingObj[key];

            let currentStyle = {};
            let incomingStyle = {};

            if (currentValue !== incomingValue) {
                currentStyle = { color: 'red' };
                incomingStyle = { color: 'green' };
            }

            return (
                <div key={key} className='flex flex-col shadow-lg rounded-md px-4 py-6 mb-6'>
                    <strong className='capitalize text-base'>{key}:</strong>
                    <div className='flex items-center'>
                        <Col span={12} className='m-0'>
                            <div style={currentStyle} className='text-base'><strong>Current:</strong> {currentValue}</div>
                        </Col>
                        <Col span={12} offset={1} className='m-0'>
                            <div style={incomingStyle} className='text-base'><strong>Incoming:</strong> {incomingValue}</div>
                        </Col>
                    </div>
                </div>
            );
        });
    };

    return (
        <Modal
            title="Comparison Modal"
            visible={visible}
            onCancel={onClose}
            footer={[
                <Button key="close" onClick={onClose}>
                    Close
                </Button>,
                <Button key="approve" className='!bg-green-700 !text-white' onClick={onClose}>
                    Approve
                </Button>
            ]}
            width={800}
            className='gap-5'
        >
            <h3>Comparison of Current vs Incoming</h3>
            <RenderComparison currentObj={current} incomingObj={incoming} />
        </Modal>
    );
};

export default PatientFormComparisonModal;