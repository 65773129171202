import React, { useEffect, useRef, useState } from 'react';
import { Modal, Checkbox, Button } from 'antd';
import axios from 'axios';
import moment from 'moment';
import jsPDF from 'jspdf';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

const PrintModal = ({ isOpen, onClose, selectedPatient, encounters = [], soapNotes = {}, formSoapNote = false }) => {
    const [checkboxes, setCheckboxes] = useState({
        subjective: false,
        objective: false,
        assessment: false,
        plan: false,
        healthConcerns: false,
        diagnoses: false,
        familyHealthHistory: false,
        pastMedicalHistory: false,
        allergies: false,
        medications: false,
        implantableDevices: false,
        goals: false
    });
    const [emrData, setEMRData] = useState({})
    const [selectedEncounters, setSelectedEncounters] = useState({});
    const [separatePageSections, setSeparatePageSections] = useState([]); // Sections to be printed on a separate page
    const [openPreview, setOpenPreview] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);
    const pdfDocRef = useRef(null);

    const handleDownloadPDF = () => {
        if (pdfDocRef.current) {
            pdfDocRef.current.save("EMR_Data.pdf");
        }
    };

    const fetchEMRData = async () => {
        try {
            const response = await axios.get(`/emr/${selectedPatient._id}`)
            if (response.data) {
                setEMRData(response.data)
            }
            else {
                setEMRData({})
            }
        } catch (error) {
            console.error('Error fetching EMR data', error);
        }
    }

    useEffect(() => {
        if (selectedPatient) {
            fetchEMRData();
        }
    }, [selectedPatient])

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setCheckboxes((prev) => ({
            ...prev,
            [name]: checked
        }));

        // If checkbox is unchecked, also uncheck the separate page checkbox for that section
        if (!checked) {
            setSeparatePageSections((prev) => prev.filter((item) => item !== name));
        }
    };

    const handleSeparatePageChange = (e, sectionKey) => {
        if (!selectedEncounters[sectionKey] && !checkboxes[sectionKey]) return;
        const { checked } = e.target;
        setSeparatePageSections((prev) =>
            checked
                ? [...prev, sectionKey] 
                : prev.filter((item) => item !== sectionKey)
        );
    };

    const formatAllergies = (allergies) => {
        const { drug, food, environmental, medications } = allergies || {};
        let content = '';

        if (drug) content += `Drug: ${drug}\n`;
        if (food) content += `Food: ${food}\n`;
        if (environmental) content += `Environmental: ${environmental}\n`;
        if (medications) content += `Medications: ${medications}\n`;

        return content;
    };
    // Helper function to format and add Past Medical History data to the content
    const formatPastMedicalHistory = (pastMedicalHistory) => {
        const { majorEvents, ongoingMedicalProblems, preventiveCare, socialHistory, nutritionHistory, developmentHistory } = pastMedicalHistory || {};
        let content = '';

        if (majorEvents) content += `Major Events: ${majorEvents}\n`;
        if (ongoingMedicalProblems) content += `Ongoing Medical Problems: ${ongoingMedicalProblems}\n`;
        if (preventiveCare) content += `Preventive Care: ${preventiveCare}\n`;
        if (socialHistory) content += `Social History: ${socialHistory}\n`;
        if (nutritionHistory) content += `Nutrition History: ${nutritionHistory}\n`;
        if (developmentHistory) content += `Development History: ${developmentHistory}\n`;

        return content;
    };
    const handlePrint = () => {
        const doc = new jsPDF();
        let y = 10;
        pdfDocRef.current = doc;
        const addPageBreak = () => {
            doc.addPage();
            y = 10;
        };

        const formatField = (title, data) => {
            return `${title}: ${data}\n\n`;
        };

        const formatArrayField = (title, data) => {
            if (!data || !Array.isArray(data) || data.length === 0) return "";
            return `${title}:\n${data
                .map(
                    (item) => `- ${item.supplementName} (${item.frequency})`
                )
                .join("\n")}\n\n`;
        };
        const formatEncounterContent = (encounter) => {
            console.log("****encounter in func", encounter)
            return `
    SOAP Note for ${moment(encounter.createdAt.split('T')[0]).format('MM-DD-YYYY')}:
    - Subjective: ${encounter.subjective}
    - Objective: ${encounter.objective}
    - Assessment: ${encounter.assessment}
    - Plan: ${encounter.plan.note || encounter.plan}
    `;
        };

        const sections = [
            { name: "subjective", label: "Subjective", content: soapNotes.subjective },
            { name: "objective", label: "Objective", content: soapNotes.objective },
            { name: "assessment", label: "Assessment", content: soapNotes.assessment },
            { name: "plan", label: "Plan", content: soapNotes.plan },
            { name: "healthConcerns", label: "Health Concerns", content: emrData.healthConcerns },
            { name: "diagnoses", label: "Diagnoses", content: emrData.diagnoses },
            { name: "familyHealthHistory", label: "Family Health History", content: emrData.familyHealthHistory },
            { name: "implantableDevice", label: "Implantable Device", content: emrData.implantableDevice },
            { name: "goals", label: "Goals", content: emrData.goals }
        ];

        sections.forEach((section) => {
            if (checkboxes[section.name] && !separatePageSections.includes(section.name)) {
                doc.text(formatField(section.label, section.content), 10, y);
                y += 20;
            }
        });

        const separateSections = [
            { name: "subjective", label: "Subjective", content: soapNotes.subjective },
            { name: "objective", label: "Objective", content: soapNotes.objective },
            { name: "assessment", label: "Assessment", content: soapNotes.assessment },
            { name: "plan", label: "Plan", content: soapNotes.plan },
            { name: "healthConcerns", label: "Health Concerns", content: emrData.healthConcerns },
            { name: "diagnoses", label: "Diagnoses", content: emrData.diagnoses },
            { name: "familyHealthHistory", label: "Family Health History", content: emrData.familyHealthHistory },
            { name: "implantableDevice", label: "Implantable Device", content: emrData.implantableDevice },
            { name: "goals", label: "Goals", content: emrData.goals },
            { name: "pastMedicalHistory", label: "Past Medical History", content: formatPastMedicalHistory(emrData.pastMedicalHistory) },
            { name: "allergies", label: "Allergies", content: formatAllergies(emrData.allergies) },
            { name: "medications", label: "Medications", content: emrData.medications }
        ];

        separateSections.forEach((section) => {
            if (checkboxes[section.name] && separatePageSections.includes(section.name)) {
                addPageBreak();
                doc.text(formatField(section.label, section.content), 10, y);
                y += 20;
            }
        });

        const filteredEncounters = encounters.filter(encounter =>
            selectedEncounters.hasOwnProperty(encounter._id)
        );

        filteredEncounters.forEach((encounter) => {
            const isSeparatePage = separatePageSections.includes(encounter._id);

            if (isSeparatePage) addPageBreak();
            const encounterContent = formatEncounterContent(encounter);
            doc.text(encounterContent, 10, y);
            y += 50;
            if (isSeparatePage) addPageBreak();
        });

        const pdfOutput = doc.output('blob');
        const objectUrl = URL.createObjectURL(pdfOutput);
        setPdfUrl(objectUrl);
        setOpenPreview(true);
    };


    const handlePrintAll = () => {
        setCheckboxes((prev) => Object.fromEntries(Object.keys(prev).map(key => [key, true])));
        handleCheckAllEncounters(true);
    };

    useEffect(() => {
        if (Object.values(checkboxes).every(value => value === true)) {
            handlePrint();
        }
    }, [checkboxes]);

    const handleEncounterCheckboxChange = (id) => {
        setSelectedEncounters((prev) => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    const handleCheckAllEncounters = (isChecked) => {
        const allEncounters = {};
        encounters.forEach((encounter) => {
            allEncounters[encounter.createdAt] = isChecked;
        });
        setSelectedEncounters(allEncounters);
    };
    return (
        <>
            <Modal
                title="Select Fields to Print"
                visible={isOpen}
                onCancel={onClose}
                footer={[
                    <Button key="close" onClick={onClose}>
                        Close
                    </Button>,
                    <Button key="print" className='!bg-[#111828] hover:!bg-[#3d3d3d] hover:!text-white text-white border-none' onClick={handlePrint}>
                        Print
                    </Button>,
                    <Button key="printAll" className='!bg-[#111828] hover:!bg-[#3d3d3d] hover:!text-white text-white border-none' onClick={handlePrintAll}>
                        Print All
                    </Button>,
                ]}
            >
                <div className=" relative w-full flex flex-row items-start justify-stretch gap-4 h-auto">
                    {/* Left Section */}
                    <div className="flex flex-col justify-center gap-4 w-3/6">
                        <div className="flex flex-col gap-3">
                            <div className="flex flex-col items-start gap-3">
                                <Checkbox
                                    name="healthConcerns"
                                    checked={checkboxes.healthConcerns}
                                    onChange={handleCheckboxChange}
                                >
                                    Health Concerns
                                </Checkbox>                                
                                {checkboxes["healthConcerns"] && <Checkbox
                                    style={{ marginLeft: '10px' }}
                                    disabled={!checkboxes["healthConcerns"]}
                                    checked={separatePageSections.includes("healthConcerns")}
                                    onChange={(e) => handleSeparatePageChange(e, "healthConcerns")}
                                >
                                    Print on Separate Page
                                </Checkbox>}
                                <Checkbox
                                    name="diagnoses"
                                    checked={checkboxes.diagnoses}
                                    onChange={handleCheckboxChange}
                                >
                                    Diagnoses
                                </Checkbox>
                                {checkboxes["diagnoses"] && <Checkbox
                                    style={{ marginLeft: '10px' }}
                                    disabled={!checkboxes["diagnoses"]}
                                    checked={separatePageSections.includes("diagnoses")}
                                    onChange={(e) => handleSeparatePageChange(e, "diagnoses")}
                                >
                                    Print on Separate Page
                                </Checkbox>}
                                <Checkbox
                                    name="familyHealthHistory"
                                    checked={checkboxes.familyHealthHistory}
                                    onChange={handleCheckboxChange}
                                >
                                    Family Health History
                                </Checkbox>                                
                                {checkboxes["familyHealthHistory"] && <Checkbox
                                    style={{ marginLeft: '10px' }}
                                    disabled={!checkboxes["familyHealthHistory"]}
                                    checked={separatePageSections.includes("familyHealthHistory")}
                                    onChange={(e) => handleSeparatePageChange(e, "familyHealthHistory")}
                                >
                                    Print on Separate Page
                                </Checkbox>}
                            </div>
                            <Checkbox
                                name="pastMedicalHistory"
                                checked={checkboxes.pastMedicalHistory}
                                onChange={handleCheckboxChange}
                            >
                                Past Medical History
                            </Checkbox>
                            {checkboxes["pastMedicalHistory"] && <Checkbox
                                style={{ marginLeft: '10px' }}
                                disabled={!checkboxes["pastMedicalHistory"]}
                                checked={separatePageSections.includes("pastMedicalHistory")}
                                onChange={(e) => handleSeparatePageChange(e, "pastMedicalHistory")}
                            >
                                Print on Separate Page
                            </Checkbox>}
                            <Checkbox
                                name="allergies"
                                checked={checkboxes.allergies}
                                onChange={handleCheckboxChange}
                            >
                                Allergies
                            </Checkbox>                            
                            {checkboxes["allergies"] && <Checkbox
                                style={{ marginLeft: '10px' }}
                                disabled={!checkboxes["allergies"]}
                                checked={separatePageSections.includes("allergies")}
                                onChange={(e) => handleSeparatePageChange(e, "allergies")}
                            >
                                Print on Separate Page
                            </Checkbox>}

                            <Checkbox
                                name="medications"
                                checked={checkboxes.medications}
                                onChange={handleCheckboxChange}
                            >
                                Medications
                            </Checkbox>
                            {checkboxes["medications"] && <Checkbox
                                style={{ marginLeft: '10px' }}
                                disabled={!checkboxes["medications"]}
                                checked={separatePageSections.includes("medications")}
                                onChange={(e) => handleSeparatePageChange(e, "medications")}
                            >
                                Print on Separate Page
                            </Checkbox>}
                            <Checkbox
                                name="implantableDevices"
                                checked={checkboxes.implantableDevices}
                                onChange={handleCheckboxChange}
                            >
                                Implantable Devices
                            </Checkbox>
                            { }
                            {checkboxes["implantableDevices"] && <Checkbox
                                style={{ marginLeft: '10px' }}
                                disabled={!checkboxes["implantableDevices"]}
                                checked={separatePageSections.includes("implantableDevices")}
                                onChange={(e) => handleSeparatePageChange(e, "implantableDevices")}
                            >
                                Print on Separate Page
                            </Checkbox>}
                            <Checkbox
                                name="goals"
                                checked={checkboxes.goals}
                                onChange={handleCheckboxChange}
                            >
                                Goals
                            </Checkbox>
                            {checkboxes["goals"] && <Checkbox
                                style={{ marginLeft: '10px' }}
                                disabled={!checkboxes["goals"]}
                                checked={separatePageSections.includes("goals")}
                                onChange={(e) => handleSeparatePageChange(e, "goals")}
                            >
                                Print on Separate Page
                            </Checkbox>}
                        </div>
                    </div>

                    {/* Center Divider */}
                    <div className="w-[1px] bg-gray-500 h-auto"></div>
                    {/* Encounters Section */}
                    {encounters.length > 0 && <div className="flex flex-col items-start gap-3">
                        <strong>Encounters:</strong>
                        {encounters.map((encounter) => (
                            <>
                                <Checkbox
                                    key={encounter._id}
                                    checked={selectedEncounters[encounter._id] || false}
                                    onChange={() => handleEncounterCheckboxChange(encounter._id)}
                                >
                                    {moment(encounter.createdAt.split('T')[0]).format('MM-DD-YYYY')}
                                </Checkbox>
                                {selectedEncounters[encounter._id] && (
                                    <Checkbox
                                        style={{ marginLeft: '10px' }}
                                        checked={separatePageSections.includes(encounter._id)}
                                        onChange={(e) => handleSeparatePageChange(e, encounter._id)}
                                    >
                                        Print on Separate Page
                                    </Checkbox>
                                )}
                            </>
                        ))}
                    </div>}
                    {/* Right Section */}
                    {formSoapNote && <div className="flex flex-col items-start gap-3 w-3/6">
                        <Checkbox
                            name="subjective"
                            checked={checkboxes.subjective}
                            onChange={handleCheckboxChange}
                        >
                            Subjective
                        </Checkbox>
                        {checkboxes["subjective"] && <Checkbox
                            style={{ marginLeft: '10px' }}
                            disabled={!checkboxes["subjective"]}
                            checked={separatePageSections.includes("subjective")}
                            onChange={(e) => handleSeparatePageChange(e, "subjective")}
                        >
                            Print on Separate Page
                        </Checkbox>}
                        <Checkbox
                            name="objective"
                            checked={checkboxes.objective}
                            onChange={handleCheckboxChange}
                        >
                            Objective
                        </Checkbox>
                        {checkboxes["objective"] && <Checkbox
                            style={{ marginLeft: '10px' }}
                            disabled={!checkboxes["objective"]}
                            checked={separatePageSections.includes("objective")}
                            onChange={(e) => handleSeparatePageChange(e, "objective")}
                        >
                            Print on Separate Page
                        </Checkbox>}
                        <Checkbox
                            name="assessment"
                            checked={checkboxes.assessment}
                            onChange={handleCheckboxChange}
                        >
                            Assessment
                        </Checkbox>
                        {checkboxes["assessment"] && <Checkbox
                            style={{ marginLeft: '10px' }}
                            disabled={!checkboxes["assessment"]}
                            checked={separatePageSections.includes("assessment")}
                            onChange={(e) => handleSeparatePageChange(e, "assessment")}
                        >
                            Print on Separate Page
                        </Checkbox>}
                        <Checkbox
                            name="plan"
                            checked={checkboxes.plan}
                            onChange={handleCheckboxChange}
                        >
                            Plan
                        </Checkbox>
                        {checkboxes["plan"] && <Checkbox
                            style={{ marginLeft: '10px' }}
                            disabled={!checkboxes["plan"]}
                            checked={separatePageSections.includes("plan")}
                            onChange={(e) => handleSeparatePageChange(e, "plan")}
                        >
                            Print on Separate Page
                        </Checkbox>}
                    </div>}
                </div>

            </Modal>
            <div>
                <Dialog
                    open={openPreview}
                    onClose={() => {
                        setOpenPreview(false);
                    }}
                    maxWidth="lg"
                    fullWidth
                    PaperProps={{
                        style: { height: "100%", padding: "10px 20px" },
                    }}
                >
                    <DialogTitle>
                        PDF Preview
                        <button
                            className="py-2 px-4 text-sm bg-blue-500 hover:bg-gray-600 text-white rounded-md focus:outline-none"
                            onClick={handleDownloadPDF}
                            style={{ float: "right" }}
                        >
                            Download PDF
                        </button>
                    </DialogTitle>
                    <DialogContent
                        dividers
                        style={{ height: "calc(100% - 64px)" }}
                    >
                        {pdfUrl && (
                            <iframe
                                src={pdfUrl}
                                width="100%"
                                height="100%"
                                title="PDF Preview"
                                style={{ border: "none" }}
                            />
                        )}
                    </DialogContent>
                </Dialog>
            </div>
        </>

    );
};

export default PrintModal;
