import React, { useEffect, useState } from "react";
import { List, Button, Tooltip, Row, Col } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import IconButton from "@mui/material/IconButton";
import styles from "./styles.module.css";
import moment from "moment";

const PreviousTreatmentsDisplay = ({
  loadedTreatments,
  handleSelectTreatment,
  navigateToTreatment,
  currentLoadedCount,
  totalTreatmentsCount,
  isAllLoaded,
  loadMoreTreatments,
  hideAllTreatments,
  setCheckedLabs,
  setPreviousTreatmentId,
  onCopyClick,
  checkboxValues,
  setCheckboxValues,
  contextMenu,
  setContextMenu,
  newContextMenu,
  setNewContextMenu,
  handleClose,
  handlePaste,
  previousTreatmentId,
  handleOpenCopyConfirmationDialog,
  // setUndoOrderId={setUndoOrderId}
  // setUndoToken={setUndoToken}
  undoOrderId,
  undoToken,
  handleUndo,
  pastedTreatmentId,
  previouslyCheckedValues,
  setPasteAction,
  setCopyKey,
  showContextMenu,
  setShowContextMenu,
  from
}) => {
  const [processedTreatments, setProcessedTreatments] = useState([]);

  // Processing the loaded treatments
  useEffect(() => {
    if (loadedTreatments && loadedTreatments.length > 0) {
      const newProcessedTreatments = loadedTreatments?.map((treatment) => ({
        ...treatment,
        formattedDate: formatDate(treatment.date),
        processedData: formatDataForTable(treatment),
        id: treatment._id,
      }));
      setProcessedTreatments(newProcessedTreatments);

    }
  }, [loadedTreatments]);

  const formatDataForTable = (data) => {
    // Include userLabs where checked is true
    const labs = data?.userLabs
      ?.filter((lab) => lab.checked)
      ?.map((lab) => lab.test_name);

    // Include userdispensed items with non-empty selectedField
    const dispensed = data?.userdispensed
      ?.filter((d) => d.quantity > 0)
      ?.map((d) => `${d.name} (${d.selectedField}) x${d.quantity}`);

    // Include userProcedures where checked is true or selectedField is not an empty string
    const procedures = data.userProcedures
      ?.filter(
        (procedure) =>
          procedure.checked === true ||
          (procedure.selectedField && procedure.selectedField !== "" && !procedure.custom)
      )
      ?.map((procedure) => {
        if (procedure.selectedField && procedure.selectedField !== "") {
          return `${procedure.procedure_name} (${procedure.selectedField})`;
        }
        return procedure.procedure_name;
      });

    const vitals = data?.vitals?.some(
      (vital) =>
        vital.HR !== 0 ||
        vital.BP !== "" ||
        vital.T !== 0 ||
        vital.W !== 0 ||
        vital.RR !== 0 ||
        vital.SPO2 !== 0
    )
      ? data.vitals
      : [];

    const formattedVitals = vitals?.map(formatVitalString)?.filter(Boolean);

    return {
      labs: labs,
      dispensed: dispensed,
      procedures: procedures,
      vitals: formattedVitals,
    };
  };

  const formatVitalString = (vital) => {
    const vitalParts = [];
    if (vital.HR !== 0) vitalParts.push(`</br>HR: ${vital.HR}`);
    if (vital.BP !== "") vitalParts.push(`</br>BP: ${vital.BP}`);
    if (vital.T !== 0) vitalParts.push(`</br>T: ${vital.T}`);
    if (vital.W !== 0) vitalParts.push(`</br>W: ${vital.W}`);
    if (vital.RR !== 0) vitalParts.push(`</br>RR: ${vital.RR}`);
    if (vital.SPO2 !== 0) vitalParts.push(`</br>SPO2: ${vital.SPO2}`);

    return vitalParts?.length > 0
      ? `Time: ${vital.time}, ${vitalParts.join(", ")} </br></br>`
      : null;
  };

  const getCheckedLabs = (userLabs) => {
    return userLabs.filter((lab) => lab.checked);
  };
  const getDispensedItemsWithSelectedField = (userdispensed) => {
    return userdispensed.filter((item) => item.selectedField);
  };
  const getRelevantProcedures = (userProcedures) => {
    return userProcedures.filter(
      (procedure) => procedure.checked || procedure.selectedField
    );
  };

  const TooltipContentL = ({ treatment }) => {
    const checkedLabs = getCheckedLabs(treatment.userLabs);

    return (
      <div>
        {checkedLabs.length > 0 && (
          <div className="tooltipSection">
            <strong>Labs:</strong>
            {checkedLabs?.map((lab) => (
              <div key={lab._id} className="tooltipItem">
                {lab.test_name}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const TooltipContentP = ({ treatment }) => {
    const relevantProcedures = getRelevantProcedures(treatment.userProcedures);

    return (
      <div>
        {relevantProcedures.length > 0 && (
          <div className="tooltipSection">
            <strong>Procedures:</strong>
            {relevantProcedures?.map((procedure) => (
              <div key={procedure._id} className="tooltipItem">
                {procedure.procedure_name}
                {procedure.selectedField &&
                  ` - Selected: ${procedure.selectedField}`}
                {procedure.note && ` - Note: ${procedure.note}`}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const TooltipContentD = ({ treatment }) => {
    const dispensedItems = getDispensedItemsWithSelectedField(
      treatment.userdispensed
    );

    return (
      <div>
        {dispensedItems.length > 0 && (
          <div className="tooltipSection">
            <strong>Dispensed:</strong>
            {dispensedItems?.map((item) => (
              <div key={item._id} className="tooltipItem">
                {item.name} - Quantity: {item.quantity} - Dosage:{" "}
                {item.selectedField}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    // getUTCMonth() returns 0-11, adding 1 to get 1-12
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    // Format: MM/DD/YYYY
    return `${month.toString().padStart(2, "0")}/${day
      .toString()
      .padStart(2, "0")}/${year}`;
  };

  const handleCopy = (treatment) => {
    setPreviousTreatmentId(treatment._id);
    onCopyClick(treatment);
    handleClose();
  };
  function calculateTopPositionNCM(mouseY) {
    const menuHeight = newContextMenu ? 20 : 20;
    const windowHeight = window.innerHeight;
    const spaceBelowCursor = windowHeight - mouseY;

    if (spaceBelowCursor >= menuHeight) {
      return mouseY;
    } else {
      return mouseY - menuHeight;
    }
  }
  const onCheckboxChange = (changes) => {
    setCheckboxValues({ ...changes });
  };
  const handleContextMenu = (event, treatmentId) => {
    const matchedTreatment = loadedTreatments.find(treatment => treatment._id === treatmentId)
    console.warn(
      "******** record from treatment view",
      "event : ",
      event,
      "record : ",
      matchedTreatment
    );
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX,
      mouseY: event.clientY,
      data: matchedTreatment,
    });
  };
  const [selectOpen, setSelectOpen] = useState(false);

  const handleOutsideClick = (event) => {
    if (!selectOpen) {
      handleClose();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [selectOpen]);
  return (
    <div className={styles.treatmentsDisplay}>
      <h3 className={styles.treatmentsDisplayHeading}>
        <strong>Previous Treatments</strong>
      </h3>
      <List
        dataSource={processedTreatments}
        className={styles.treatmentsDisplayBox}
        renderItem={(item) => (
          <List.Item
            className={`${styles.listItem} ${item.type === "Treatment" &&
              item.treatmentNotes.includes("Notes by:")
              ? styles.withTreatmentNotes
              : item.type === "Order"
                ? styles.withOrderedNotes
                : ""
              }`}
            onContextMenu={(event) => {
              event.stopPropagation();
              setNewContextMenu(true);
              onCheckboxChange({
                labs: false,
                dispensed: false,
                procedures: false,
                supplements: false,
              });
              // setCopyKey("Labs");
              // setCheckedLabs(item?.processedData?.labs);
              handleContextMenu(event, item?._id);
            }}
          >
            <Row
              className={styles.importRow}
              justify="space-between"
              align="middle"
            >
              <Col
                span={10}
                onClick={() => navigateToTreatment(item._id)}
                className={styles.details}
              >
                <div> {moment.utc(item.date).format("MM/DD/YYYY")}</div>
                <div>{item.custom ? item.custom : null}</div>
              </Col>

              <Col span={12}>
                <div className="buttonsarray">
                  {item?.processedData?.labs?.length > 0 && (
                    <Tooltip title={<TooltipContentL treatment={item} />}>
                      <IconButton
                        size="small"
                        aria-label="labs"
                        onContextMenu={(event) => {
                          event.stopPropagation();
                          setNewContextMenu(true);
                          onCheckboxChange({
                            labs: true,
                            dispensed: false,
                            procedures: false,
                            supplements: false,
                          });
                          setCopyKey("Labs");
                          setCheckedLabs(item?.processedData?.labs);
                          handleContextMenu(event, item?._id);
                        }}
                        sx={{
                          width: 24,
                          height: 24,
                          fontSize: "0.9rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#b9f6ca",
                          margin: "0 1px",
                          color: "black",
                        }}
                      >
                        L
                      </IconButton>
                    </Tooltip>
                  )}

                  {item?.processedData?.procedures?.length > 0 && (
                    <Tooltip title={<TooltipContentP treatment={item} />}>
                      <IconButton
                        size="small"
                        aria-label="procedures"
                        onContextMenu={(event) => {
                          event.stopPropagation();
                          setNewContextMenu(true);
                          onCheckboxChange({
                            labs: false,
                            dispensed: false,
                            procedures: true,
                            supplements: false,
                          });
                          setCopyKey("Procedures");
                          handleContextMenu(event, item?._id);
                        }}
                        sx={{
                          width: 24,
                          height: 24,
                          fontSize: "0.9rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#b9f6ca",
                          margin: "0 1px",
                          color: "black",
                        }}
                      >
                        P
                      </IconButton>
                    </Tooltip>
                  )}

                  {item?.processedData?.dispensed?.length > 0 && (
                    <Tooltip title={<TooltipContentD treatment={item} />}>
                      <IconButton
                        size="small"
                        aria-label="dispense"
                        onContextMenu={(event) => {
                          event.stopPropagation();
                          setNewContextMenu(true);
                          onCheckboxChange({
                            labs: false,
                            dispensed: true,
                            procedures: false,
                            supplements: false,
                          });
                          setCopyKey("Dispensed");
                          handleContextMenu(event, item?._id);
                        }}
                        sx={{
                          width: 24,
                          height: 24,
                          fontSize: "0.9rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "#b9f6ca",
                          margin: "0 1px",
                          color: "black",
                        }}
                      >
                        D
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </Col>

              <Col span={2}>
                <CopyOutlined
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelectTreatment(item._id);
                  }}
                />
              </Col>
              {contextMenu?.data && showContextMenu && (
                <div
                  className="fadeIn"
                  style={{
                    position: "fixed",
                    top: contextMenu.mouseY - 275,
                    left: contextMenu.mouseX - 190,
                    backgroundColor: "white",
                    borderRadius: "10px",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                    zIndex: 1000,
                    padding: "8px 0",
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div style={{ cursor: "pointer" }}>
                    {newContextMenu && (
                      <>
                        {Object.values(checkboxValues).some(value => value === true) &&
                          <div
                            className="mt-[5px] mx-2 py-[8px] px-[16px] hover:text-white hover:bg-blue-500 rounded-sm p-1 active:bg-blue-800 transition-colors duration-300 ease-in-out"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <button
                              onClick={() => {
                                handleCopy(contextMenu?.data);
                              }}
                              className="px-4"
                            >
                              Copy
                            </button>
                          </div>}
                        {previousTreatmentId !== contextMenu?.data._id &&
                          previousTreatmentId !== null &&
                          Object.values(previouslyCheckedValues).some(value => value === true) && (
                            <div
                              className="mt-[5px] mx-2 py-[8px] px-[16px] hover:text-white hover:bg-blue-500 rounded-sm p-1 relative group active:bg-blue-800 transition-colors duration-300 ease-in-out"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <button className="px-4 hover:text-white hover:bg-blue-500 rounded-sm ">
                                Paste
                              </button>
                              <div
                                style={{ borderRadius: "10px" }}
                                className="absolute hidden w-[150px] top-[-10px] left-[-140px] bg-white shadow-lg py-2 px-4 group-hover:block"
                              >
                                <button
                                  onClick={() => {
                                    setPasteAction("modify");
                                    setShowContextMenu(false);
                                    handleOpenCopyConfirmationDialog();
                                  }}
                                  className="text-black hover:text-white p-1 rounded-sm hover:bg-blue-500 w-full text-left active:bg-blue-800 transition-colors duration-300 ease-in-out"
                                >
                                  Merge
                                </button>
                                <button
                                  onClick={() => {
                                    setPasteAction("overwrite");
                                    setShowContextMenu(false);
                                    handleOpenCopyConfirmationDialog();
                                  }}
                                  className="text-black hover:text-white p-1 rounded-sm hover:bg-blue-500 w-full text-left active:bg-blue-800 transition-colors duration-300 ease-in-out"
                                >
                                  Overwrite
                                </button>
                              </div>
                            </div>
                          )}
                        {pastedTreatmentId === contextMenu?.data._id &&
                          JSON.stringify(checkboxValues) === JSON.stringify(previouslyCheckedValues) && (
                            <div
                              className="mt-[5px] hover:text-white hover:bg-blue-500 rounded-sm p-1 text-center active:bg-blue-800 transition-colors duration-300 ease-in-out"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <button
                                className={`${!undoOrderId && !undoToken ? "cursor-not-allowed" : ""}`}
                                disabled={!undoOrderId && !undoToken}
                                onClick={() => handleUndo(undoOrderId, undoToken)}
                              >
                                Undo
                              </button>
                            </div>
                          )}
                      </>
                    )}

                  </div>
                </div>
              )}
            </Row>

          </List.Item>
        )}
      />
      {currentLoadedCount < totalTreatmentsCount && (!isAllLoaded && from!=='emr') && (
        <Button onClick={loadMoreTreatments}>Load More</Button>
      )}
      {(isAllLoaded && from!=='emr') && currentLoadedCount > 10 && (
        <Button onClick={hideAllTreatments}>Hide All</Button>
      )}
    </div>
  );
};

export default PreviousTreatmentsDisplay;
