import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { Checkbox, Col, Form, Radio, Row, Slider } from 'antd'
import Title from 'antd/lib/typography/Title'
import React from 'react'

const HealthQuestionsForm = ({form,handleNext,handlePrev}) => {

    const yesNoOptions = ["Yes", "No"];

    const symptomsOptions = [
        "Fever/Chills",
        "Unexplained change in weight",
        "Fatigue/Malaise/Generalized weakness",
        "Headaches/Migraines",
        "Dizziness",
        "Sinus Pain/Pressure/Discharge",
        "Excessive snoring",
        "Wheezing/Chronic Cough",
        "Shortness of breath",
        "Chest pain, pressure or tightness",
        "Swelling of hands/feet/ankles",
        "Nausea/Vomiting",
        "Abdominal pain",
        "Heartburn",
        "Constipation or diarrhea",
        "Stiffness/Pain in joints/muscles",
        "Joint swelling",
        "Bleeding/Easy bruising",
        "Excessive urination",
        "Excessive thirst/hunger",
        "Hot flashes",
        "Painful/Bloody urination",
        "Difficulty urinating/Night-time urination",
        "Urinary incontinence (leakage)",
        "Sexual Difficulties/Painful intercourse",
        "Rash",
        "Anxiety/Panic Attacks",
        "Concentration Difficulty",
        "Feelings of Guilt",
        "Insomnia/Problems with Sleep",
        "Loss of energy",
        "Thoughts of harming self or others",
      ];

  return (
    <>
         <div className="bg-white p-6 rounded-2xl">
         <Form
  form={form}
  layout="vertical"
  initialValues={{ alcoholConsumption: 'never' }}
>
  <Row gutter={16}>
    <Col xs={24} md={12}>
      <Title level={4}>Head, Ears, Eyes, Nose, Throat</Title>
      <Form.Item
        name={["healthQuestions", "headEarNoseMouthEyesThroatProblems"]}
        label="Do you have or have you ever had eye, ear, nose, mouth or throat problems?"
      >
        <Radio.Group>
          {yesNoOptions.map((option, index) => (
            <Radio key={index} value={option}>
              {option}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Title level={4}>Heart</Title>
      <Form.Item
        name={["healthQuestions", "heartProblems"]}
        label="Do you have or have you ever had cardiovascular problems (e.g., chest pain, heart attack, heart valve problems, high blood pressure, leg cramps)?"
      >
        <Radio.Group>
          {yesNoOptions.map((option, index) => (
            <Radio key={index} value={option}>
              {option}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
    </Col>

    <Col xs={24} md={12}>
      <Title level={4}>Lungs</Title>
      <Form.Item
        name={["healthQuestions", "lungsProblems"]}
        label="Do you have or have you ever had lung problems (e.g., asthma, bronchitis, shortness of breath, pneumonia)?"
      >
        <Radio.Group>
          {yesNoOptions.map((option, index) => (
            <Radio key={index} value={option}>
              {option}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Title level={4}>Gastrointestinal</Title>
      <Form.Item
        name={["healthQuestions", "gastrointestinalProblems"]}
        label="Do you have or have you ever had gastrointestinal problems (e.g., blood in your stool, cramping, hepatitis, liver disease, pancreas problems)?"
      >
        <Radio.Group>
          {yesNoOptions.map((option, index) => (
            <Radio key={index} value={option}>
              {option}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
    </Col>
  </Row>

  <Row gutter={16}>
    <Col xs={24}>
      <Title level={4}>Symptoms Checklist</Title>
      <Form.Item
        name={["symptomsChecklist", "symptoms"]}
        label="Check if you have ever experienced any of the following symptoms:"
      >
        <Checkbox.Group style={{width:'100%'}}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)", 
              columnGap: "16px", 
              rowGap: "8px", 
              width: "100%",
            }}
          >
            {symptomsOptions.map((symptom, index) => (
              <Checkbox key={index} value={symptom}>
                {symptom}
              </Checkbox>
            ))}
          </div>
        </Checkbox.Group>
      </Form.Item>
    </Col>
  </Row>

  <Row gutter={16}>
    <Col xs={24}>
      <Form.Item
        name={["symptomsChecklist", "generalHealthRating"]}
        label="How healthy do you feel in general?"
      >
        <Slider
          min={0}
          max={10}
          marks={{ 0: "0", 5: "5", 10: "10" }}
          defaultValue={5}
        />
      </Form.Item>
    </Col>
  </Row>

  <div className="flex justify-between">
    <IconButton onClick={handlePrev}>
      <NavigateBefore />
    </IconButton>
    <IconButton onClick={handleNext}>
      <NavigateNext />
    </IconButton>
  </div>
</Form>


              </div>
    </>
  )
}

export default HealthQuestionsForm 