import React, { useState, useEffect, useContext, useRef, useCallback, useMemo } from "react";
import "../../customeCalender.css";
import dayjs from "dayjs";
import RefreshIcon from "@mui/icons-material/Refresh";
import "react-calendar/dist/Calendar.css";
import DeleteIcon from "@mui/icons-material/Delete";
import "../../Components/SingleTreatmentView/labsModel.css";
import "./todayView.css";
import IPADTodayView from '../IPADPages/TodayView'
import {
  ContentCopyRounded, East,
} from "@mui/icons-material";
import { ChevronLeft, ChevronRight } from "lucide-react"
import {
  Button, Table, Select, Input, Checkbox as AntdCheckbox, Popconfirm, message,
  Row, Card, Col, Badge, Space, Modal, Form, TimePicker, Dropdown, Menu as AntdMenu,
  DatePicker,
  Tooltip,
  Spin
} from "antd";
import { Modal as AntdModal } from "antd";
import "../../antTable.css";
import Dialog from "@mui/material/Dialog";
import SupplementsModalPopup from "../../Components/SupplementsModal";
import TreatmentNotes from "../../Components/TreatmentNotes";
import {  LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { CircularProgress, Tooltip as MuiTooltip } from "@mui/material";
import { Box, Typography, ListItem, ListItemText, Checkbox } from "@mui/material";
import axios from "axios";
import { Menu, MenuItem, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast as toastifyToast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./styles.module.css"; // Make sure this path is correct
import { BounceLoader, ClipLoader } from "react-spinners";
import moment from "moment-timezone";
import IconButton from "@mui/material/IconButton";
import ProcedureModal from "../../Components/ProcedureModal";
import DispenseModal from "../../Components/DispenseModal";
import VitalsModal from "../../Components/VitalsModal";
import ScheduleTreatmentModal from "../../Components/ScheduleTreatmentModal";
import MergeTemplatesModal from "../../Components/MergeTemplatesModal";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DataContext } from "../../context/DataContext";
import { blue } from "@mui/material/colors";
import { dispensesSortingFunction, proceduresSortingFunction } from "../../utils";
import EventsModal from "../../Components/EventsModal";
import CustomToast from "../../Components/CustomToast";
import styled from 'styled-components';
import {  EditOutlined, ClockCircleOutlined } from '@ant-design/icons';
// import ConversationSection from "../../Components/ConversationSection";
import ManageTreatmentTemplates from "../../Components/Updations/ManageTreatmentTemplates";
import TreatmentTemplates from "../TreatmentViewDisplay/TreatmentTemplates";
import TasksTab from "../../Components/TasksTab";
import { Clock, Trash2,  DoorClosed } from 'lucide-react';
import Messages from "../../Components/Messages";
import smsIcon from "../../Assets/sms.png";
import Header from "../../Components/IPADComponents/Header";



const { Option } = Select;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    background-color: white !important;
    color: black !important;
  }

  .ant-select-selection-item {
    color: black !important;
  }
`;


// Timezone utilities
const PHX_TIMEZONE = 'America/Phoenix';

// Get current date in Phoenix timezone
const getPhoenixDate = () => {
  const date = new Date();
  return new Date(date.toLocaleString('en-US', { timeZone: PHX_TIMEZONE }));
};

// Your existing code
const api = axios.create({
  baseURL: '',
});


// Format date for API calls (YYYY-MM-DD)
const formatDateForAPI = (date) => {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
};

// Format date for display
const formatDisplayDate = (date) => {
  return date.toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });
};



// Status card component
const TreatmentCardStatus = ({
  treatment,
  startTime,
  ivPrepByTreatment,
  eventDetailsList,
  deleteTreatment,
  handleEventTimeChange,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'TREATMENT',
    item: { id: treatment._id, treatment },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const navigate = useNavigate();

  // Memoized calculation for eventDetail
  const eventDetail = useMemo(
    () =>
      eventDetailsList.find(
        (detail) => detail.treatmentId === treatment._id
      ),
    [eventDetailsList, treatment._id]
  );

  const { time, note } = useMemo(() => {
    if (eventDetail && eventDetail.eventData) {
      return {
        time: dayjs(eventDetail.eventData.start.dateTime),
        note: eventDetail.eventData.description || '',
      };
    }
    return { time: '', note: '' };
  }, [eventDetail]);

  const normalizedStatus = useMemo(
    () => treatment.status?.toLowerCase(),
    [treatment.status]
  );

  const statusColors = useMemo(
    () => ({
      scheduled: '#89cff0', // Blue
      arrived: '#ffd590', // Orange
      pickup: '#f6c4f8', // Pink
      complete: '#00a36c', // Green
    }),
    []
  );

  const cardColor = useMemo(
    () => statusColors[normalizedStatus] || '#f0f0f0',
    [statusColors, normalizedStatus]
  );

  const textColor = useMemo(
    () => (normalizedStatus === 'complete' ? '#ffffff' : '#000000'),
    [normalizedStatus]
  );

  return (
    <Tooltip
      title={
        <TooltipPatientContent
          treatment={treatment}
          ivPrepByTreatment={ivPrepByTreatment}
        />
      }
      placement="top"
    >
      <div
  ref={drag}
  style={{
    opacity: isDragging ? 0.5 : 1,
    cursor: 'move',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '5px 5px 0px 5px',
    background: cardColor,
    color: textColor,
    borderRadius: '8px',
    marginBottom: '0px',
    fontSize: '12px',
    overflow: 'hidden',
    width: '100%',
    boxSizing: 'border-box',
  }}
  onClick={() =>
    navigate(`/auditview/${treatment.PatientId}/${treatment._id}`)
  }
      >
        {/* First Row: Name */}
        <div>
          {treatment.patientName}
        </div>
        {/* Second Row: Name */}
        <div>
          {treatment.assignees.length > 0 &&
            <p className={`text-xs ${treatment.status==='complete'?'text-white':'text-gray-600'}`}>
              {` (${treatment.assignees.map(
                (assignee, index) =>
                  `${index !== 0 ? ` ${assignee}` : `${assignee}`}`
              )})`}
            </p>
          }

        </div>

  {/* Third Row: Room, Time, and Actions */}
  <div
    className="flex justify-between items-center"
    style={{ width: '100%', marginTop: '-5px' }}
  >
    <div className="flex items-center">
      {/* Room Icon and Text */}
      {treatment.room && (
        <div className="flex items-center" style={{ marginRight: '10px' }}>
          <DoorClosed size={16} style={{ marginRight: '5px' }} />
          <span
            style={{
              color: normalizedStatus === 'complete' ? '#ffffff' : '#353935',
            }}
          >
            {treatment.room}
          </span>
        </div>
      )}

      {/* Time Icon and Text */}
      {(treatment.time || startTime) && (
        <div className="flex items-center">
          <Clock size={16} style={{ marginRight: '5px' }} />
          <span>
            {treatment.time ? ` ${treatment.time}` : ''}
            {startTime ? ` ${startTime}` : ''}
          </span>
        </div>
      )}
    </div>

    {/* Actions */}
    <div
      className="flex flex-row items-center"
      onClick={(e) => e.stopPropagation()}
    >
      <TimeModal
        time={time}
        note={note}
        status={normalizedStatus}
        submitFunction={(time, note) => {
          handleEventTimeChange(
            time,
            treatment._id,
            treatment?.event,
            treatment?.status,
            treatment?.patientName,
            note
          );
        }}
      />
      <div className="flex justify-center items-center">
        <Popconfirm
          title={`Are you sure to delete ${treatment.patientName}'s treatment?`}
          onConfirm={() => deleteTreatment(treatment._id)}
          okText="Yes"
          cancelText="No"
          okButtonProps={{
            className:
              'btn-color py-1 px-10 text-xs md:text-sm w-full md:w-auto',
          }}
          cancelButtonProps={{
            className:
              'btn-color py-1 px-10 text-xs md:text-sm w-full md:w-auto',
          }}
        >
          <Trash2 size={16} style={{ fontSize: '16px' }} />
        </Popconfirm>
      </div>
    </div>
  </div>
</div>

    </Tooltip>
  );
};

const StatusColumn = ({
  status,
  treatments,
  calendarEvents,
  moveCardStatus,
  calendar,
  ignoredEvents,
  fetchEventsNew,
  isLoading,
  ivPrepByTreatment,
  eventDetailsList,
  deleteTreatment,
  handleEventTimeChange,
}) => {
  const [, drop] = useDrop({
    accept: "TREATMENT",
    drop: (item) => {
      moveCardStatus(item.id, status);
    },
  });

  // Memoized badge count
  const badgeCount = useMemo(() => {
    if (!calendar?.events) return 0;
    return (
      calendar.events.filter(
        (event) =>
          !ignoredEvents?.some(
            (ignoredEvent) => ignoredEvent.eventId === event.id
          ) &&
          !treatments?.some(
            (treatment) =>
              treatment.status === status && treatment.event === event.id
          )
      )?.length || 0
    );
  }, [calendar?.events, ignoredEvents, treatments, status]);

  // Memoized treatment cards
  const treatmentCards = useMemo(() => {
    return treatments.map((treatment) => {
      const event = calendarEvents.find(
        (event) => event.treatmentId === treatment["_id"]
      );
      const startTime = event
        ? moment(event.startTime).format("HH:mm")
        : "";
      return (
        <TreatmentCardStatus
          key={treatment._id}
          treatment={treatment}
          startTime={startTime}
          ivPrepByTreatment={ivPrepByTreatment}
          eventDetailsList={eventDetailsList}
          deleteTreatment={deleteTreatment}
          handleEventTimeChange={handleEventTimeChange}
        />
      );
    });
  }, [
    treatments,
    calendarEvents,
    ivPrepByTreatment,
    eventDetailsList,
    deleteTreatment,
    handleEventTimeChange,
  ]);

  return (
    <Col
      xs={24}
      md={12}
      lg={6}
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "stretch",
      }}
    >
      <Card
        ref={(node) => {
          drop(node);
        }}
        style={{
          flex: 1,
          margin: "0px",
          overflow: "auto",
        }}
        bodyStyle={{
          padding: "10px 10px 5px 10px",
        }}
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 8px",
            }}
          >
            {status.charAt(0).toUpperCase() + status.slice(1)}
            {calendar && (
              <Badge
                className="cursor-pointer"
                title={"Events"}
                count={badgeCount}
                onClick={() => fetchEventsNew(calendar, status)}
              />
            )}
          </div>
        }
      >
        {!isLoading ? (
          <div
            className="grid md:grid-cols-2 gap-2"
            style={{
              padding: "4px",
            }}
          >
            {treatmentCards}
          </div>
        ) : (
          <div className="flex justify-center items-center h-32">
            <ClipLoader color="#000000" loading={isLoading} size={25} />
          </div>
        )}
      </Card>
    </Col>
  );
};



const TreatmentCardRoom = ({
  treatment,
  startTime,
  moveCardRoom,
  ivPrepByTreatment,
  eventDetailsList,
  deleteTreatment,
  handleEventTimeChange,
  variant = 'vertical',
  disabled = false
}) => {
  // Memoize the dragged item data to prevent unnecessary recalculations
  const dragItem = useMemo(() => ({
    id: treatment._id,
    treatment,
    room: treatment.room,
    status: treatment.status
  }), [treatment]);

  const [{ isDragging }, drag] = useDrag({
    type: 'ROOM_TREATMENT',
    item: dragItem,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag: !disabled,
  });

  // Memoize room colors to prevent recalculations
  const roomColors = useMemo(() => ({
    "Community 1": "bg-[#ffd590]",
    "Community 2": "bg-[#ffd590]",
    "Room 1": "bg-[#ffd590]",
    "Room 2": "bg-[#ffd590]",
    "Room 3": "bg-[#ffd590]",
    "Room 4": "bg-[#ffd590]",
    "Education Room": "bg-[#ffd590]",
  }), []);

  const rooms = useMemo(() => Object.keys(roomColors), [roomColors]);
  const items = useMemo(() => [
    ...rooms.map((room) => ({ label: room, key: room })),
    { label: "Pickup", key: "pickup" },
    { label: "Unassign", key: "unassigned" },
    { label: "Set To Completed", key: "complete" }
  ], [rooms]);

  // const navigate = useNavigate();
  const eventDetail = useMemo(() => eventDetailsList.find((detail) => detail.treatmentId === treatment._id), [eventDetailsList, treatment._id]);

  let time = "";
  let note = "";

  if (eventDetail && eventDetail.eventData) {
    time = dayjs(eventDetail.eventData.start.dateTime);
    note = eventDetail.eventData.description || "";
  }

  const ContextMenu = useMemo(() => {
    // Filter items based on the treatment status
    let filteredItems = items;
  
    if (treatment.status === 'pickup') {
      // Only show "Set to Completed" when the status is "pickup"
      filteredItems = items.filter((item) => item.key === 'complete');
    }
  
    return (
      <AntdMenu>
        <span className="text-xs font-semibold">Move to</span>
        {filteredItems.map((item) => (
          <AntdMenu.Item key={item.key} onClick={() => item.key === 'complete' || item.key === 'pickup' ? moveCardRoom(treatment._id, item.key, true) : moveCardRoom(treatment._id, item.key)}>
            {item.label}
          </AntdMenu.Item>
        ))}
      </AntdMenu>
    );
  }, [items, moveCardRoom, treatment.status, treatment._id]);
  

  const cardColor = useMemo(() => {
    if (treatment.status === 'complete') {
      return treatment.room ? 'bg-[#00a36c] text-white' : 'bg-[#00a36c] text-white'; // Green for complete status
    } else if (treatment.status === 'pickup') {
      return 'bg-[#f6c4f8]'; // Pink for pickup status
    } else if (roomColors[treatment.room]) {
      return roomColors[treatment.room]; // Default color for assigned rooms
    } else if (treatment.status === 'arrived') {
      return 'bg-[#ffd590]'; // Yellow for arrived status
    } else if (treatment.status === 'scheduled') {
      return 'bg-[#89cff0]'; // Blue for scheduled status
    }
    return ''; // Default color if no match
  }, [treatment, roomColors]);

  const paddingStyle = useMemo(() => {
    return treatment.status === 'scheduled' || treatment.status === 'arrived'
      ? 'p-[3px_5px_0px_5px]' // Padding for 'scheduled' and 'arrived'
      : 'p-[3px_4px_0px_4px]'; // Padding for the rest
  }, [treatment.status]);

  const roomPaddingStyle = useMemo(() => {
    const isRoomAssigned = rooms.includes(treatment.room);
    return isRoomAssigned ? 'p-[3px_4px_0px_4px]' : paddingStyle;
  }, [treatment.room, rooms, paddingStyle]);

  return (
    <Tooltip
      title={<TooltipPatientContent treatment={treatment} ivPrepByTreatment={ivPrepByTreatment} />}
      placement="top"
    >
      <Dropdown overlay={ContextMenu} trigger={['contextMenu']}>
        <div
          ref={drag}
          className={`flex flex-col rounded-lg ${cardColor} ${!disabled && 'cursor-move'} ${isDragging ? 'opacity-50' : 'opacity-100'} ${variant === 'horizontal' ? 'mr-3 flex-shrink-0' : 'mb-2'} ${roomPaddingStyle}`} // Apply dynamic padding based on room and status
        >
          {/* First Row: Name and Time */}
          <div className="flex justify-between items-center mb-0">
            <span className="text-sm">{treatment.patientName}</span>
            {startTime && <span className="text-xs text-gray-500">{startTime}</span>}
          </div>

          {/* Second Row: Room, Time, and Icons */}
          <div className="flex justify-between items-center mb-0" style={{ marginTop: '-5px' }}>
            <div className="flex items-center text-xs">
              {/* Room Icon and Text */}
              {treatment.room && (
                <div className="flex items-center mr-2">
                  <DoorClosed size={16} style={{ marginRight: '5px' }} />
                  <span
                    style={{
                      color: treatment.status === "complete" ? '#ffffff' : '#353935',
                    }}
                  >
                    {treatment.room}
                  </span>
                </div>
              )}

              {/* Time Icon and Text */}
              {(treatment.time || startTime) && (
                <div className="flex items-center">
                  <Clock size={16} style={{ marginRight: '5px' }} />
                  <span>
                    {treatment.time ? ` ${treatment.time}` : ''}
                    {startTime ? ` ${startTime}` : ''}
                  </span>
                </div>
              )}
            </div>

            {/* Actions (Time Modal and Delete) */}
            <div className="flex items-center">
              <TimeModal
                time={time}
                note={note}
                submitFunction={(time, note) => {
                  handleEventTimeChange(time, treatment._id, treatment?.event, treatment?.status, treatment?.patientName, note);
                }}
              />
              <div className="flex justify-center items-center">
                <Popconfirm
                  title={`Are you sure to delete ${treatment.patientName}'s treatment?`}
                  onConfirm={() => deleteTreatment(treatment._id)}
                  okText="Yes"
                  cancelText="No"
                  okButtonProps={{
                    className: "btn-color py-1 px-10 text-xs md:text-sm w-full md:w-auto",
                  }}
                  cancelButtonProps={{
                    className: "btn-color py-1 px-10 text-xs md:text-sm w-full md:w-auto",
                  }}
                >
                  <Trash2 size={16} style={{ fontSize: '16px' }} />
                </Popconfirm>
              </div>
            </div>
          </div>
        </div>
      </Dropdown>
    </Tooltip>
  );
};



const RoomColumnUnassigned = ({
  room,
  treatments,
  ivPrepByTreatment,
  moveCardRoom,
  isLoading,
  eventDetailsList,
  deleteTreatment,
  handleEventTimeChange,
  height = 'auto',
  fullHeight = false
}) => {
  // Memoize the unique assignees to avoid unnecessary recalculations
  const unique_assignees = useMemo(() => {
    return [...new Set(treatments.map(treatment => treatment.assignees).flat())];
  }, [treatments]);

  const [, drop] = useDrop({
    accept: 'ROOM_TREATMENT',
    drop: (item) => {
      if (item.room !== 'unassigned') {
        moveCardRoom(item.id, 'unassigned');
      }

      if (item.status !== room) {
        moveCardRoom(item.id, room, true);
      }
    },
  });

  return (
    <div className="w-full p-2" ref={drop} style={{ display: 'flex', flexDirection: 'column', height: 'auto' }}>
      <Card
        style={{
          flex: 1, // Ensure this stretches to the max height of the column
          overflow: 'auto',
        }}
        bodyStyle={{
          padding: '10px 10px 3px 10px', // Reduce the padding inside the card body
        }}
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {room.charAt(0).toUpperCase() + room.slice(1)}
          </div>
        }
      >
        <div ref={drop} className="h-full" style={{ minHeight: 'auto' }}>
          <div className="overflow-auto w-full flex flex-wrap gap-[10px]">
            {!isLoading ? (
              treatments.map((treatment) => (
                <TreatmentCardRoom
                  key={treatment._id}
                  treatment={treatment}
                  disabled={false}
                  ivPrepByTreatment={ivPrepByTreatment}
                  moveCardRoom={moveCardRoom}
                  eventDetailsList={eventDetailsList}
                  deleteTreatment={deleteTreatment}
                  handleEventTimeChange={handleEventTimeChange}
                />
              ))
            ) : (
              <div className="flex justify-center items-center h-32 w-full">
                <ClipLoader color="#000000" loading={isLoading} size={15} />
              </div>
            )}
          </div>
        </div>
      </Card>
      <div className="flex justify-center items-center mt-2 bg-white" style={{ borderRadius: '8px', padding: '2px' }}>
        {unique_assignees.length > 0 ? (
          <span className="text-xs">{unique_assignees.join(', ')}</span>
        ) : (
          <span className="text-xs text-red-400">No Assignees</span>
        )}
      </div>
    </div>
  );
};


// Room column component
const RoomColumn = ({
  room,
  treatments,
  ivPrepByTreatment,
  moveCardRoom,
  isLoading,
  eventDetailsList,
  deleteTreatment,
  handleEventTimeChange,
}) => {
  const isPickup = room === "Pickup";
  const isComplete = room === "Complete";

  const [, drop] = useDrop({
    accept: 'ROOM_TREATMENT',
    drop: (item) => {
      if (isPickup) {
        moveCardRoom(item.id, 'pickup', true);
      } else if (isComplete) {
        moveCardRoom(item.id, 'complete', true);
      } else {
        moveCardRoom(item.id, room);
      }
    },
  });

  // Memoize the unique assignees to avoid recalculating on every render
  const unique_assignees = useMemo(() => {
    return [...new Set(treatments.map(treatment => treatment.assignees).flat())];
  }, [treatments]);

  // Memoize the sorted treatments list to avoid unnecessary sorting on every render
  const sortedTreatments = useMemo(() => {
    return treatments.sort((a, b) => a.status === 'complete' ? 1 : -1);
  }, [treatments]);

  return (
    <div className="w-full p-2" style={{ display: 'flex', flexDirection: 'column', height: 'auto' }} ref={drop}>
      <Card
        style={{
          flex: 1, // Ensures the card stretches to the full height
          overflow: 'auto',
        }}
        bodyStyle={{
          padding: "10px 10px 3px 10px", // Reduce the padding inside the card body
        }}
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {room}
          </div>
        }
      >
        <div ref={drop} className="h-full">
          <div className="max-h">
            {!isLoading ? (
              sortedTreatments.map((treatment) => (
                <TreatmentCardRoom
                  key={treatment._id}
                  treatment={treatment}
                  disabled={isPickup } // Disable for both Pickup and Complete
                  ivPrepByTreatment={ivPrepByTreatment}
                  moveCardRoom={moveCardRoom}
                  eventDetailsList={eventDetailsList}
                  deleteTreatment={deleteTreatment}
                  handleEventTimeChange={handleEventTimeChange}
                />
              ))
            ) : (
              <div className="flex justify-center items-center h-32">
                <ClipLoader color="#000000" loading={isLoading} size={15} />
              </div>
            )}
          </div>
        </div>
      </Card>
      <div className="flex justify-center items-center mt-2 bg-white" style={{ borderRadius: '8px', padding: '2px' }}>
        {unique_assignees.length > 0 ? (
          <span className="text-xs">{unique_assignees.join(", ")}</span>
        ) : (
          <span className="text-xs text-red-400">No Assignees</span>
        )}
      </div>
    </div>
  );
};


const TimeModal = ({ time, note, submitFunction,status }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    // Update form values when props change
    form.setFieldsValue({
      time: time ? dayjs(time) : null,
      note: note || ''
    });
  }, [time, note, form]);

  const disabledHours = () => {
    const hours = [];
    for (let i = 0; i < 8; i++) {
      hours.push(i);
    }
    for (let i = 19; i < 24; i++) {
      hours.push(i);
    }
    return hours;
  };

  const handleSubmit = (values) => {

    submitFunction(values.time, values.note);
    setIsModalOpen(false);
  };


  return (
    <>
      <Button
        icon={<ClockCircleOutlined />}
        type="text"
        onClick={(e) => {
          e.stopPropagation();

          setIsModalOpen(true);
        }}
        style={{
          color: status === 'complete' ? '#FFFFFF' : 'inherit',
          backgroundColor: status === 'complete' ? '#00a36c' : 'inherit',
        }}

      />

      <Modal
        title="Set Time and Note"
        open={isModalOpen}
        onCancel={(e) => {
          e.stopPropagation();
          setIsModalOpen(false);

        }}
        footer={null}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          initialValues={{
            time: time ? dayjs(time) : null,
            note: note || ''
          }}
        >
          <Form.Item
            name="time"
            label="Time"
            rules={[{ required: true, message: 'Please select time' }]}
          >
            <TimePicker
              format="hh:mm A"
              minuteStep={15}
              disabledHours={disabledHours}
              hideDisabledOptions
              use12Hours
              style={{ width: '100%' }}
              needConfirm={false}
              showNow={false}
            />
          </Form.Item>

          <Form.Item
            name="note"
            label="Note"
           
          >
            <Input.TextArea />
          </Form.Item>

          <div className="flex justify-end items-center">
            <Form.Item>

              <Button className="bg-white text-black px-8" htmlType="submit">Save</Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};

const EditRoomModal = ({ record, roomOptions, assigneeOptions, onCellChange }) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectRoomOptions = roomOptions.map((room) => ({ label: room, value: room }));
  const selectAssigneeOptions = assigneeOptions.map((assignee) => ({ label: assignee, value: assignee }));
  selectRoomOptions.unshift({ label: "Unassigned", value: "unassigned" });

  return (
    <>
      <Button
        icon={<EditOutlined />}
        type="text"
        onClick={() => setIsModalOpen(true)}
      />
      <Modal
        title="Edit Room & Assignees"
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalOpen(false)}>
            Done
          </Button>,
        ]}
      >
        <div className="space-y-4">
          <div>
            <label className="block mb-2">Room</label>
            <Select
              defaultValue={record.room || null}
              onChange={(value) => onCellChange(record.key, "room", value, "patientInfo")}
              options={selectRoomOptions}
              style={{ width: '100%' }}
            />
          </div>
          <div>
            <label className="block mb-2">Assignees</label>
            <Select
              mode="multiple"
              defaultValue={record.assignees || []}
              onChange={(value) => onCellChange(record.key, "assignees", value, "patientInfo")}
              options={selectAssigneeOptions}
              style={{ width: '100%' }}

            />
          </div>
        </div>
      </Modal>
    </>
  );
};

// const ConversationsModal = ({ patientIDs, setUnreadMessagesCount }) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);



//   return (
//     <>
//       <Button className="bg-white text-black" onClick={() => setIsModalOpen((prev) => !prev)}>Recent Conversations</Button>

//       <Modal
//         title="Recent Conversations"
//         open={isModalOpen}
//         onCancel={() => setIsModalOpen(false)}
//         footer={null}
//         width={'900px'}
//       >
//         <div className="flex justify-center items-center">
//           <ConversationSection
//             key={patientIDs.join(",")}
//             patientIds={patientIDs}
//             showUnreadMessages={true}
//             setUnreadMes
//             sagesCount={setUnreadMessagesCount}
//           />
//         </div>

//       </Modal>
//     </>
//   );
// };


// test 










const TasksModal = () => {
  const [visibleTaskTabPoppup, setVisibleTaskTabPopup] = useState(false);
  const [overdueTasks, setOverDueTasks] = useState(0);
  const closeTaskTabPopup=()=> setVisibleTaskTabPopup(false);

  return (
    <>
      <Button className="bg-white text-black" onClick={()=>setVisibleTaskTabPopup(true)}> {overdueTasks > 0 ? `${overdueTasks} Overdue Tasks` : 'Tasks Tab'}</Button>
      
      <AntdModal
        open={visibleTaskTabPoppup}
        onClose={closeTaskTabPopup}
        closable={false}
        maskClosable={true}
        forceRender
        title={<p className="text-2xl">Tasks</p>}
        onCancel={closeTaskTabPopup}
        footer={[
          <Button key="close" onClick={closeTaskTabPopup}>
            Close
          </Button>,
        ]}
      >
        <TasksTab type={'popup'} setOverDueTasks={setOverDueTasks} />
      </AntdModal>
    </>
  );
};

const TooltipPatientContent = ({ ivPrepByTreatment, treatment }) => {
  const ivPrepData = ivPrepByTreatment[treatment._id] || {
    procedures: [],
    dispensed: [],
  };

  const checkedLabs = treatment.userLabs.filter((lab) => lab.checked);
  const dispensedItems = treatment.userdispensed.filter((item) => item.selectedField)
  const relevantProcedures = treatment.userProcedures.filter((procedure) => procedure.checked || procedure.selectedField);

  return (
    <div onClick={() => { }}>
      {checkedLabs.length > 0 && (
        <div className="tooltipSection">
          <strong>Labs:</strong>
          {checkedLabs?.map((lab) => (
            <div key={lab._id} className="tooltipItem">
              {lab.test_name}
            </div>
          ))}
        </div>
      )}
      {dispensedItems.length > 0 && (
        <div className="tooltipSection">
          <br />
          <strong>Dispensed:</strong>
          {dispensedItems?.map((item) => (
            <div
              key={item._id}
              className="tooltipItem"
              style={

                ivPrepData.dispensed.find(
                  (p) => p.name === item.name && p.comped
                ) ? { color: "orange" } : {}

              }
            >
              {item.name} - Quantity: {item.quantity} - Dosage:{" "}
              {item.selectedField}
            </div>
          ))}
        </div>
      )}
      {relevantProcedures.length > 0 && (
        <div className="tooltipSection">
          <br />
          <strong>Procedures:</strong>
          {relevantProcedures?.map((procedure) => (
            <div
              key={procedure._id}
              className="tooltipItem"
              style={
                ivPrepData.procedures.find(
                  (p) => p.name === procedure.procedure_name && p.comped
                ) ? { color: "orange" } : {}
              }
            >
              {procedure.procedure_name}
              {procedure.selectedField &&
                ` - Selected: ${procedure.selectedField}`}
              {procedure.note && ` - Note: ${procedure.note}`}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};


const SelectPatientModal = ({ visible, patients, onSelect, onClose, treatment }) => {

  return (
    <Modal
      title="Select Patient"
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <div className="space-y-4">
        <Select
          showSearch
          placeholder="Select a patient"
          optionFilterProp="children"
          value={treatment ? treatment.patientId : null}
          onChange={
            (value) => {
              onSelect(value, treatment.key);
            }}
          style={{ width: '100%' }}
        >
          {patients.map((patient) => (
            <Option key={patient._id} value={patient['Customer ID']}>
              {patient['Full Name']}
            </Option>
          ))}
        </Select>
      </div>
    </Modal>
  );
};


const SelectDateModal = ({ visible, onSelect, onClose, treatment }) => {
  // Convert the date string to dayjs object
  const currentDate = treatment ? dayjs(treatment.date) : dayjs();

  return (
    <Modal
      title="Select Date"
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <div className="space-y-4">
        <DatePicker
          value={currentDate}
          onChange={(date, dateString) => {
            onSelect([treatment.key], dateString);
            onClose();
          }}
          style={{ width: '100%' }}
        />
      </div>
    </Modal>
  );
};


const TodayView = () => {
  const {
    data: {
      // labs: contextLabs,
      dispensed: contextDispensed,
      procedures: contextProcedures,
    },
    isTyping,
    setIsTyping,
  } = useContext(DataContext);
  const [currentDate, setCurrentDate] = useState(getPhoenixDate());
  const [treatments, setTreatments] = useState([]);
  const [filteredTreatments, setFilteredTreatments] = useState([]);
  const [ivPrepByTreatment, setIvPrepByTreatment] = useState({});
  const [newLabsMap, setNewLabsMap] = useState({});
  const [allLabs, setAllLabs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [patientLoader, setPatientLoader] = useState(false);
  const [activePatients, setActivePatients] = useState([]);
  const [inActivePatients, setInActivePatients] = useState([]);
  const [showInactivePatients, setShowInactivePatients] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [byRoom, setByRoom] = useState(false);
  const [searchTermLabs, setSearchTermLabs] = useState('');
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const statuses = ['scheduled', 'arrived', 'pickup', 'complete']
  const rooms = [
    "Complete","Pickup", "Community 1", "Community 2", "Room 1", "Room 2", "Room 3", "Room 4", "Education Room"
  ]
  const formattedDateForAPI = useMemo(() => formatDateForAPI(currentDate), [currentDate]);
  const displayDate = useMemo(() => formatDisplayDate(currentDate), [currentDate]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [eventDetailsList, setEventDetailsList] = useState([]);
  const [scheduledCalendar, setScheduledCalendar] = useState(null);
  const [arrivedCalendar, setArrivedCalendar] = useState(null);
  const [cancelledCalendar, setCancelledCalendar] = useState(null);
  const [noShowCalendar, setNoShowCalendar] = useState(null);
  const [pickupCalendar, setPickupCalendar] = useState(null);
  const [completedCalendar, setCompletedCalendar] = useState(null);
  const [isEventModalVisible, setIsEventModalVisible] = useState(false);
  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
  const [allEvents, setAllEvents] = useState([]);
  const [eventsNew, setEventsNew] = useState([]);
  const [ignoredEvents, setIgnoredEvents] = useState([]);
  const [currentCalendar, setCurrentCalendar] = useState(null);
  const [status, setStatus] = useState(null);
  const [toast, setToast] = useState(null);
  const [isCreateScheduleLoading, setIsCreateScheduleLoading] = useState(false);
  const [isCreatePickupLoading, setIsCreatePickupLoading] = useState(false);
  const [isFetchingEvents, setIsFetchingEvents] = useState(false);
  const [patientIDs, setPatientIDs] = useState([]);
  // const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const [recordForNotes, setRecordForNotes] = useState();
  const [currentPatientIdForModal, setCurrentPatientIdForModal] = useState(null);
  const [patientId, setPatientId] = useState();
  const [tubesSummary, setTubesSummary] = useState({});
  const [currentPatientLabs, setCurrentPatientLabs] = useState({
    labs: [],
    orderId: null,
  });
  const [labsModalOpen, setLabsModalOpen] = useState(false);
  const [selectRedrawnLabs, setSelectRedrawnLabs] = useState(false);
  const [clickedTreatmentData, setClickedTreatmentData] = useState();
  const [currentProcedures, setCurrentProcedures] = useState([]);
  const [procedureModalOpen, setProcedureModalOpen] = useState(false);
  const [currentDispensedItems, setCurrentDispensedItems] = useState([]);
  const [dispenseModalOpen, setDispenseModalOpen] = useState(false);
  const [currentTreatment, setCurrentTreatment] = useState();
  const [treatmentNoteModalOpen, setTreatmentNoteModalOpen] = useState(false);
  const isTreatmentNoteEditing = useRef(false);
  const [patientData, setPatientData] = useState(null);
  const [allSupplements, setAllSupplements] = useState([]);
  const [currentSupplements, setCurrentSupplements] = useState([]);
  const [availableSupplements, setAvailableSupplements] = useState([]);
  const [supplementsPopup, setSupplementsPopup] = useState(false);
  const [currentVitalsItems, setCurrentVitalsItems] = useState([]);
  const [vitalsModalOpen, setVitalsModalOpen] = useState(false);
  const isNoteEditing = useRef(false);
  const [inputWidth, setInputWidth] = useState("100px");

  const [labsModalLoader, setLabsModalLoader] = useState(false);
  const [isLabsRefreshLoading, setIsLabsRefreshLoading] = useState(false);
  const containerRef = useRef(null);
  const [customItems, setCustomItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [roomOptions, setRoomOptions] = useState([]);
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [bulkAction, setBulkAction] = useState("");
  // const [showBulkDatePicker, setShowBulkDatePicker] = useState(false);
  const [bulkActionDate, setBulkActionDate] = useState(null);
  const [isMergeTemplatesModalVisible, setIsMergeTemplatesModalVisible] = useState(false);
  const [tableContextMenuCurrentRecord, setTableContextMenuCurrentRecord] = useState(null);
  const [isSelectPatientModalOpen, setIsSelectPatientModalOpen] = useState(false);
  const [selectPatientAction, setSelectPatientAction] = useState(null);
  const [isSelectDateModalOpen, setIsSelectDateModalOpen] = useState(false);
  const [selectDataAction, setSelectDataAction] = useState(null);
  const [template, setTemplate] = useState(null);
  const navigate = useNavigate();
  const [visibleCalendarId, setVisibleCalendarId] = useState(null);
  const [modals, setModals] = useState({}); 
  const [checkboxValues, setCheckboxValues] = useState({
      labs: false,
      dispensed: false,
      procedures: false,
      supplements: false,
    });
    const [shouldScroll, setShouldScroll] = useState(false); 
    const [itemLoader, setItemLoader] = useState([]); 

  const tubeColors = {
    "Gel-Barrier": "#C25C22",
    "Lavender Top (EDTA) Whole Blood": "#9900FF",
    "Yellow-top (ACD-A)": "#F1C232",
    "Futhan Collection Kit": "#C25C22",
    "Blue-top (sodium citrate)": "#68BDBD",
    "Lavender Top (EDTA) Serum Separator": "#674EA7",
  };

  const filteredLabs = currentPatientLabs.labs.filter((lab) =>
    lab.test_name.toLowerCase().includes(searchTermLabs.toLowerCase())
  );

  const halfLength = Math.ceil(filteredLabs.length / 2);

  // const firstColumnItems = filteredLabs.slice(0, halfLength);
  // const secondColumnItems = filteredLabs.slice(halfLength);
  const firstColumnItems = currentPatientLabs.labs
  .map((lab, index) => ({ ...lab, originalIndex: index }))
  .filter((_, index) => index % 2 === 0);

const secondColumnItems = currentPatientLabs.labs
  .map((lab, index) => ({ ...lab, originalIndex: index }))
  .filter((_, index) => index % 2 !== 0);


  const handleNoteHover = (e) => {
    const newWidth = Math.max(100, Math.min(e.target.value.length * 8, 300)); // 100px min, 300px max
    setInputWidth(newWidth);
  };
  const handleNoteFocus = (e) => {
    const newWidth = Math.max(100, Math.min(e.target.value.length * 8, 300)); // 100px min, 300px max
      setInputWidth(newWidth);
    setIsTyping(true); // Track that the user is typing
    isNoteEditing.current = true; // Indicate editing mode
  };

  const handleNoteBlur = (e,note,recordKey) => {
    const newWidth = Math.max(100, Math.min(e.target.value.length * 8, 300)); // 100px min, 300px max
      setInputWidth(newWidth);
    setIsTyping(false); // Stop tracking typing
    if (note !== e.target.value) {
      handleNoteUpdate(recordKey, e.target.value); // Update note if changed
    }
  };
  const handleAddNoteToMultipleTxNotes = (data, event) => {
    const payload = {
      note: event.target.value,
      date: data.date,
    };
    try {
      const response = axios
        .post(
          `/patient/${data.patientId}/note?fromTreatment=true&treatmentId=${data.key}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
              "Content-Type": "application/json",
            },
          }
        )
    } catch (error) {
      console.error("Error: ", error);
    }
  };


  // Format date for input (YYYY-MM-DD)
  const formattedDateForInput = useMemo(() => {
    return formatDateForAPI(currentDate); // reuse API format as it's the same format needed for input
  }, [currentDate]);

  // Memoize the API call function
  const getTreatments = async (loading = true) => {


    if (!loading) {

      if (isLoading) {
        return;
      }
    }

    else {
      setIsLoading(true);
    }

    try {
      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const calendars = await fetchCalendars(formattedDateForAPI);
      const response = await api.get(`/ordered/${formattedDateForAPI}`, {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });

      // Initialize empty arrays for both states
      let newTreatments = [];
      let newFilteredTreatments = [];


      if (response.data && response.data.length > 0) {
        const treatmentsWithIvPrep = response.data?.map((treatment) => ({
          ...treatment,
          ivPrepComplete: treatment.iv_prep === 'complete',
        })) ?? [];

        treatmentsWithIvPrep.forEach(async (treatment) => {
          const ivPrepData = await fetchIVPrepData(treatment._id);
          setIvPrepByTreatment((prev) => ({
            ...prev,
            [treatment._id]: ivPrepData,
          }));
        });

        newTreatments = treatmentsWithIvPrep;
        newFilteredTreatments = await formatDataForTable(treatmentsWithIvPrep);
      }


      if (newTreatments.length > 0) {
        await fetchAllEventDetails(newTreatments, calendars);
      }

      // Always set both states, even if empty
      setTreatments(newTreatments);
      setFilteredTreatments(newFilteredTreatments);
      setPatientIDs(newTreatments.map((item) => item.PatientId))



    } catch (error) {
      // On error, clear both states
      setTreatments([]);
      setFilteredTreatments([]);
      setPatientIDs([]);
      console.error('Error fetching treatments:', error);
    } finally {
      if (loading) {
        setIsLoading(false);
      }
      fetchCalendarEvents();
    }
  }

  // Fetch all labs
  const fetchallLabs = async () => {
    try {

      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.get("/allLabs", {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
          "Content-Type": "application/json",
        },
      });

      setAllLabs(response.data.allLabs);
      return response.data.allLabs;
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  // Fetch IV preparation data for a treatment
  const fetchIVPrepData = async (treatmentID) => {
    try {

      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.get(
        `/iv_prep/treatment/${treatmentID}`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching IV preparation data:", error);
    }
  };

  // Format data for table
  const fetchDataForAllLabs = async (labs, userLabs) => {
    try {
      const allLabs = await fetchallLabs();

      // Map each testName to its display name in the allLabs array
      const displayNames = labs.map((testName) => {
        // Find the lab with the matching test_name in the allLabs array

        const lab = allLabs.find((lab) => lab.test_name === testName);

        const displayName =
          lab && lab.display_name ? lab.display_name : testName;
        const userLab = userLabs.find((lab) => lab.test_name === testName);
        const customIndicator = userLab && userLab.custom ? " (Custom)" : "";

        return displayName + customIndicator;
      });

      // Filter out any null values
      const validDisplayNames = displayNames.filter((name) => name !== null);

      return validDisplayNames;
    } catch (error) {
      console.error("Error fetching lab data:", error);
      return [];
    }
  };

  const setNewLabsForSet = (setId, labs) => {
    setNewLabsMap((prevState) => ({
      ...prevState,
      [setId]: labs,
    }));
  };

  const formatDataForTable = async (data) => {
    const formattedData = await Promise.all(data?.map(async (item) => {
      // Include userLabs where checked is true
      const labs = item.userLabs
        .filter((lab) => lab.checked)
        ?.map((lab) => lab.test_name);

      if (labs.length > 0) {
        const setId = item._id;
        const currentLabs = newLabsMap[setId] || [];

        if (labs.length !== currentLabs.length) {
          const labsResp = await fetchDataForAllLabs(labs, item.userLabs);
          if (JSON.stringify(labsResp) !== JSON.stringify(currentLabs)) {
            if (labsResp.length > 0) {
              setNewLabsForSet(setId, labsResp);
              // Update local reference to newLabsMap
              newLabsMap[setId] = labsResp;
            }
          }
        }
      }

      // Include userdispensed items with non-empty selectedField
      const dispensed2 = item.userdispensed
        .filter((d) => d.quantity > 0)
        ?.map((d) => {
          let dispensedName = d.name;
          if (d.custom) {
            dispensedName += " (Custom)"; // Append "(Custom)" if the dispensed item is custom
          }
          return `${dispensedName} (${d.selectedField}) x${d.quantity}`;
        });

      const supplements = item.userSupplements
        .filter((s) => s.quantity > 0)
        .map(
          (s) =>
            `${s.name} ${s?.selectedField ? `(${s?.selectedField})` : ""} x${s.quantity
            } SUP ${s.custom ? "(Custom)" : ""}`
        );

      const dispensed = [...dispensed2, ...supplements];

      // Include userProcedures where checked is true or selectedField is not an empty string
      const procedures = item.userProcedures
        .filter(
          (procedure) =>
            procedure.checked === true ||
            (procedure.selectedField && procedure.selectedField !== "" && !procedure.custom)
        )
        ?.map((procedure) => {
          // Check if the procedure has a custom flag
          const isCustom = procedure.custom === true;
          let procedureName = procedure.procedure_name;

          if (procedure.selectedField && procedure.selectedField !== "") {
            procedureName += ` (${procedure.selectedField})`;
          }

          if (isCustom) {
            procedureName += " (Custom)";
          }

          return procedureName;
        });

      // Filter vitals
      const vitals = item?.vitals?.some(
        (vital) =>
          vital.HR !== 0 ||
          vital.BP !== "" ||
          vital.T !== 0 ||
          vital.W !== 0 ||
          vital.RR !== 0 ||
          vital.SPO2 !== 0
      )
        ? item.vitals
        : [];

      const supplementsNew = item.userSupplements.map((s) => `${s.name}`);

      // Use the correct properties from the data structure
      return {
        key: item["_id"],
        patientName: item.patientName, // Assuming patient name isn't provided, so using PatientId
        patientId: item.PatientId,
        room: item.room || "unassigned", // Provide a fallback if room is undefined
        date: item.date,
        procedures,
        time: item.time,
        event: item.event,
        dispensed,
        usersupplements: supplementsNew,
        labs: labs.length === 0 ? [] : newLabsMap[item._id] || [],
        note: item.note || "",
        assignees: item.assignees || "",
        status: item.status,
        review: item.review,
        treatmentNotes: item.treatmentNotes,
        vitals,
        messages:item.messages,
      };
    }));
    return formattedData;
  };

  // Navigate date by days
  const navigateDate = useCallback((days) => {
    setCurrentDate((prev) => {
      const newDate = new Date(prev);
      newDate.setDate(newDate.getDate() + days);
      return newDate;
    });
  }, []);

  // Handle date input change
  const handleDateChange = useCallback((e) => {
    if (e.target.value) {
      const newDate = new Date(e.target.value);
      // Add 12 hours to avoid timezone issues
      newDate.setHours(12, 0, 0, 0);
      setCurrentDate(newDate);
    }
  }, []);

  // Reset to today's date (in Phoenix timezone)
  const resetToToday = useCallback(() => {
    setCurrentDate(getPhoenixDate());
  }, []);

  // Get active patients
  const getActivePatients = async () => {

    setPatientLoader(true);

    try {

      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.get(`/patient-profiles/active`, {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });

      if (response.status === 200) {
        setActivePatients(response.data);
      }
    } catch (error) {
      console.error("Error fetching active patients:", error);
    }

    setPatientLoader(false);
  };

  // Get inactive patients
  const getInactivePatients = async () => {

    setPatientLoader(true);
    try {
      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.get(`/patient-profiles/inActive-list`, {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });

      if (response.status === 200) {
        setInActivePatients(response.data);
      }
    } catch (error) {
      console.error("Error fetching inactive patients:", error);
    }

    setPatientLoader(false);
  };

  // Drag and Drop Change Status
  const changeTreatmentStatus = async (treatmentId, newStatus) => {
    try {

      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.put(
        `/treatments/updateStatus/${treatmentId}`,
        {
          status: newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );

      if (response.status === 200) {
        toastifyToast.success("Treatment status updated successfully");

        const updatedTreatments = treatments.map((treatment) => {
          if (treatment._id === response.data._id) {
            return { ...treatment, status: response.data.status, room: response.data.room };
          } else {
            return treatment;
          }
        })

        setTreatments(updatedTreatments);
        setFilteredTreatments(await formatDataForTable(updatedTreatments));
      } else {
        toastifyToast.error("Failed to update treatment status");
      }
    } catch (error) {
      toastifyToast.error("Error updating treatment status");
      console.error("Error updating treatment status:", error);
    }
  };

  const moveCardStatus = async (cardId, newStatus) => {
    // Change status of the treatment with matching cardId
    const updatedTreatments = treatments?.map((treatment) => {
      if (treatment._id === cardId) {
        return { ...treatment, status: newStatus };
      }
      return treatment;
    });

    setTreatments(updatedTreatments);
    setFilteredTreatments(await formatDataForTable(updatedTreatments));

    // Persist the status change to the server
    changeTreatmentStatus(cardId, newStatus);

  };

  // Drag and Drop change Room
  // Update the changeTreatmentRoom function to accept and handle status updates
const changeTreatmentRoom = async (treatmentId, newRoom, newStatus) => {
  try {
    const sessionToken = localStorage.getItem('sessionToken');
    if (!sessionToken) {
      throw new Error('No session token found');
    }

    if (newRoom.toLowerCase() === "pickup" && newRoom.toLowerCase() === "complete") {
      toastifyToast.error("Cannot assign treatment to Pickup as Room");
      return;
    }

    const response = await api.put(
      `/patient/order/update/${treatmentId}?fromPrepView=true`,
      {
        category: 'patientInfo',
        identifier: { key: "name", value: 'r' },
        updates: { room: newRoom, ...(newStatus && { status: newStatus }) }, // Add status update if provided
      },
      {
        headers: { Authorization: `Bearer ${sessionToken}` },
      }
    );

    if (response.status === 200) {
      toastifyToast.success("Treatment Room updated successfully");

      const updatedTreatments = treatments.map((treatment) => {
        if (treatment._id === response.data._id) {
          return {
            ...treatment,
            room: response.data.room,
            status: response.data.status,
          };
        }
        return treatment;
      });

      setTreatments(updatedTreatments);
      setFilteredTreatments(await formatDataForTable(updatedTreatments));
    } else {
      toastifyToast.error("Failed to update treatment room");
    }
  } catch (error) {
    toastifyToast.error("Error updating treatment room");
    console.error("Error updating treatment room:", error);
  }
};
const moveCardRoom = async (treatmentId, newRoom, status = false) => {
  // Find the treatment being updated
  const treatmentToUpdate = treatments?.find((treatment) => treatment._id === treatmentId);

  if (!treatmentToUpdate) return; // Exit if treatment is not found

  // Check if the new room is "unassigned"
  if (newRoom.toLowerCase() === "unassigned") {
    // If the current room is already "unassigned" and status is "scheduled," do nothing
    if (treatmentToUpdate.room.toLowerCase() === "unassigned" && treatmentToUpdate.status.toLowerCase() === "scheduled") {
      return; // No updates needed
    }

    // If the status is "complete" or "pickup," only change the room to "unassigned"
    if (["complete", "pickup"].includes(treatmentToUpdate.status.toLowerCase())) {
      const updatedTreatments = treatments?.map((treatment) => {
        if (treatment._id === treatmentId) {
          return { ...treatment, room: newRoom }; // Update only the room
        }
        return treatment;
      });

      setTreatments(updatedTreatments);
      changeTreatmentRoom(treatmentId, newRoom, treatmentToUpdate.status); // Keep the same status
      return; // Exit after handling this case
    }

    // For other statuses, set the room to "unassigned" and status to "arrived"
    const updatedTreatments = treatments?.map((treatment) => {
      if (treatment._id === treatmentId) {
        return { ...treatment, room: newRoom, status: "arrived" };
      }
      return treatment;
    });

    setTreatments(updatedTreatments);
    changeTreatmentRoom(treatmentId, newRoom, "arrived");
  } else {
    // If the room is not "unassigned," proceed with normal handling
    if (status) {
      moveCardStatus(treatmentId, newRoom); // Handle status change
    } else {
      const updatedTreatments = treatments?.map((treatment) => {
        if (treatment._id === treatmentId) {
          return { ...treatment, room: newRoom, status: "arrived" }; // Update room and status to "arrived"
        }
        return treatment;
      });

      setTreatments(updatedTreatments);
      changeTreatmentRoom(treatmentId, newRoom, "arrived");
    }
  }
};

  
// Handle status filter
const handleStatusFilter = (status) => {
  if (selectedStatuses.includes(status)) {
    setSelectedStatuses(selectedStatuses.filter((s) => s !== status));
  } else {
    setSelectedStatuses([...selectedStatuses, status]);
  }
};

// Apply status filter
const applyStatusFilter = async () => {
  const tempFilteredTreatments = await formatDataForTable(treatments);
  const newSearchFilteredTreatments = filterTreatments(tempFilteredTreatments,"Search")
  const newFilteredTreatments = filterTreatments(newSearchFilteredTreatments,"Status")
  setFilteredTreatments(newFilteredTreatments);
};

// Ensure the filter is applied whenever selectedStatuses changes
useEffect(() => {
  applyStatusFilter();  
}, [selectedStatuses]);
  

  const filterTreatments = (treatments, type) => {
    if (type === 'Status') {
      const newStatusFilteredTreatments = treatments.filter((item) => {
        const matchesStatus =
          selectedStatuses.length > 0 ? selectedStatuses.includes(item.status) : true;
        return matchesStatus;
      });
      return newStatusFilteredTreatments;
    }
    else if (type === 'Search') {
      const searchTermLower = searchTerm.trim().toLowerCase();
      const newSearchFilteredTreatments = treatments.filter((item) => {
        const matchesSearch = searchTermLower
          ? (item.patientName || '').toLowerCase().includes(searchTermLower) ||
          (item.procedures || []).join(", ").toLowerCase().includes(searchTermLower) ||
          (item.dispensed || []).join(", ").toLowerCase().includes(searchTermLower) ||
          (item.labs || []).join(", ").toLowerCase().includes(searchTermLower) ||
          (item.room || '').toLowerCase().includes(searchTermLower) ||
          (item.assignees || []).join(", ").toLowerCase().includes(searchTermLower)
          : true;

        return matchesSearch;
      });
      return newSearchFilteredTreatments;
    }
  }

  // Handle Search Filter
  const applySearchFilter = async () => {
    const tempStatusFilteredTreatments = await formatDataForTable(treatments);
    const newStatusFilteredTreatments= filterTreatments(tempStatusFilteredTreatments,"Status")
    const newFilteredTreatments = filterTreatments(newStatusFilteredTreatments,"Search")
    setFilteredTreatments(newFilteredTreatments);
  }


  const onSelectAllChange = (e) => {
    if (e.target.checked) {
      const newSelectedRowKeys = treatments.map((t) => t._id); // Ensure 'treatments' is the correctly formatted data
      setSelectedRowKeys(newSelectedRowKeys);
    } else {
      setSelectedRowKeys([]);
    }
  };

  // Handle delete treatment
  const deleteTreatment = async (treatmentId) => {
    try {

      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.delete(
        `/patient/order/delete/${treatmentId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );


      if (response.status === 200) {
        toastifyToast.success("Treatment deleted successfully");
        const filteredTreatments = treatments.filter(
          (treatment) => treatment._id !== treatmentId
        );
        setTreatments(filteredTreatments);
        setFilteredTreatments(await formatDataForTable(filteredTreatments));
      } else {
        toastifyToast.error("Failed to delete treatment");
      }
    } catch (error) {
      console.error("Error deleting treatment:", error);
      toastifyToast.error("Error deleting treatment");
    }
  };

  // Fetch Calendars
  const fetchCalendars = async (date) => {

    try {

      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.get(
        "/google-calendars",
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );

      if (response.data && response.data.length > 0) {
        const requestPromises = response.data.map(async (item) =>
          await api.get(
            `/calendar-events-all/${item._id}/${date}`,
            {
              headers: {
                Authorization: `Bearer ${sessionToken}`,
              },
            }
          )
        );

        // Use Promise.all to wait for all requests to resolve
        const results = await Promise.all(requestPromises);

        const calendarsWithCounts = response.data.map((item, index) => ({
          ...item,
          events: results[index]?.data?.events,
          eventsCount: results[index]?.data?.events?.length
            ? results[index]?.data?.events?.length
            : 0, // Add the events count to each calendar
        }));

        calendarsWithCounts.forEach((calendar) => {
          if (calendar.name === "Scheduled") {
            setScheduledCalendar(calendar);
          } else if (calendar.name === "Complete") {
            setCompletedCalendar(calendar);
          } else if (calendar.name === "Pickup") {
            setPickupCalendar(calendar);
          } else if (calendar.name === "Arrived") {
            setArrivedCalendar(calendar);
          } else if (calendar.name === "noshow") {
            setNoShowCalendar(calendar);
          } else if (calendar.name === "cancelled") {
            setCancelledCalendar(calendar);
          }
        });

        // Set state for each calendar based on its name
        return calendarsWithCounts;

      }
      return [];

    } catch (error) {
      console.error("Error fetching calendars:", error);
      return [];
    }
  };

  const fetchCalendarEvents = async () => {
    const treatmentCalendarIds = treatments
      .filter((treatment) => treatment._id != null)
      .map((treatment) => treatment._id);



    if (treatmentCalendarIds.length > 0) {
      try {
        const response = await api.post(
          "/calendar-events-treatments",
          { treatmentCalendarIds }
        );

        setCalendarEvents(response.data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getCalendarByType = (type) => {

    switch (type) {
      case "scheduled":
        return scheduledCalendar ? scheduledCalendar : null;
      case "arrived":
        return arrivedCalendar ? arrivedCalendar : null;
      case "pickup":
        return pickupCalendar ? pickupCalendar : null;
      case "complete":
        return completedCalendar ? completedCalendar : null;
      default:
        return null;
    }
  };

  const getCalendarCIDByType = (type) => {

    const calendar = getCalendarByType(type);
    return calendar ? calendar.cid : null;
  };

  const getCalendarIDByType = (type) => {

    const calendar = getCalendarByType(type);
    return calendar ? calendar._id : null;
  };

  // Fetch All Event Details
  const fetchAllEventDetails = async (treatments, calendars) => {

    const eventsToFetch = treatments
      .filter(
        (treatment) => treatment.event && treatment.event !== "unassigned"
      )
      .map((treatment) => {
        const cid = calendars.find((calendar) => calendar.name.toLowerCase() === treatment.status)?.cid;
        if (!cid) {
          console.error(`No CID found for status: ${treatment.status}`);
        }
        return {
          eventId: treatment.event,
          cid: cid,
          treatmentId: treatment._id,
        };
      });


    if (eventsToFetch.length === 0) {
      setEventDetailsList([]);
      return;
    }

    try {

      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.post(
        "/calendar-events/batch",
        { events: eventsToFetch },
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      setEventDetailsList(response.data);
    } catch (error) {
      console.error("Failed to fetch event details:", error);
    }
  };

  const fetchEventsNew = async (calendar, status, eventDate) => {

    setIsFetchingEvents(true);

    setCurrentCalendar(calendar._id);
    try {

      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.get(
        `/calendar-events-all/${calendar._id}/${eventDate ?? formattedDateForAPI
        }`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      const ignoredEvents = await api.get(
        `/ignored-calendar-events`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      setIgnoredEvents(ignoredEvents.data);
      setEventsNew(response.data.events || []);
      fetchAllEvents();
      setIsEventModalVisible(true);
      setStatus(status);
    } catch (error) {
      console.error("Failed to fetch events:", error);
    }

    setIsFetchingEvents(false);
  };

  const fetchAllEvents = async () => {
    try {

      const sessionToken = localStorage.getItem('sessionToken');
      const response = await api.get(
        `/all-calendar-events/${formatDateForAPI}`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      setAllEvents(response.data.events);
    } catch (error) {
      console.error("Failed to fetch events:", error);
    }
  };

  // Change Event Time

  async function changeEventTime(eventId, status, time, note, timezone, patientName) {
    const calendar = getCalendarByType(status);

    const cid = calendar ? calendar.cid : null;

    try {
      const response = await api.post(
        `/change-event-time/${eventId}`,
        {
          newTime: time,
          patientName,
          note,
          timezone,
          cid,
          selectedDate: formattedDateForAPI,
        }
      );
      toastifyToast.success("Calendar event time changed");
      await fetchAllEventDetails(treatments, [scheduledCalendar, arrivedCalendar, cancelledCalendar, noShowCalendar, pickupCalendar, completedCalendar]);
    } catch (error) {
      toastifyToast.error(error);
      console.error(error);
    }
  }

  async function createEventWithTime(time, status, treatmentId, timezone, note, patientName) {
    try {
      const calendar = getCalendarByType(status);
      const cid = calendar ? calendar.cid : null;

      const response = await api.post(
        `/create-new-event`,
        { time, patientName, timezone, note, cid, selectedDate: formattedDateForAPI },
      );
      const idc = calendar ? calendar.cid : null;
      createWatch(response.data.id, treatmentId, idc, status);
      toastifyToast.success("New event created for treatment");
      await fetchAllEventDetails(treatments, [scheduledCalendar, arrivedCalendar, cancelledCalendar, noShowCalendar, pickupCalendar, completedCalendar]);
    } catch (error) {
      toastifyToast.error(error);
      console.error(error);
    }
  }

  // updated handleEventTimeChange

  const toggleCalendarVisibility = (treatmentId) => {
    setVisibleCalendarId((prevId) =>
      prevId === treatmentId ? null : treatmentId
    );
  };

  const handleEventTimeChange = (time, treatmentId, eventId, status, patientName, note) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get timezone
    const timeString = time ? time.format("hh:mm a") : "";
  
    if (eventId && eventId !== "unassigned") {
      changeEventTime(eventId, status, timeString, note, timezone, patientName); // Update event time
    }
    
    if (eventId === "unassigned") {
      createEventWithTime(timeString, status, treatmentId, timezone, note, patientName); // Create event with time
    }
  
    handleCellChange(treatmentId, "time", timeString, "patientInfo"); // Update treatment time in UI
    toggleCalendarVisibility(treatmentId); // Toggle calendar visibility
  };
  

  // Fetch all labs on component mount
  // useEffect(() => {

  // }, []);

  // Fetch active patients on component mount
  useEffect(() => {
    fetchallLabs();
    getActivePatients();
  }, []);

  // Fetch inactive patients when showInactivePatients changes
  useEffect(() => {

    if (showInactivePatients) {
      getInactivePatients();
    } else {
      setInActivePatients([]);
    }

  }, [showInactivePatients]);

  // Apply search filter when search term changes
  useEffect(() => {

    applySearchFilter();

  }, [searchTerm,treatments]);

  // Apply status filter when selected statuses change
  useEffect(() => {

    applyStatusFilter();

  }, [selectedStatuses,treatments]);

  // Change Cell
  const splitByLastDash = (columnName) => {
    // Find the index of the last dash
    const lastIndex = columnName.lastIndexOf("-");

    // If there's no dash, or the dash is the first character, return an error or handle it as needed
    if (lastIndex <= 0) {
      console.error("No dash found, or the dash is at the start of the string");
      return columnName;
    }

    // Split the string into two parts
    const itemName = columnName.substring(0, lastIndex);
    const field = columnName.substring(lastIndex + 1);

    return [itemName, field];
  }

  const handleCellChange = (patientId, columnName, newValue, category, newData) => {
    let payload;

    if (category === "vitals") {
      // Make sure newValue is an array and directly assign it to updates
      payload = {
        category: category,
        updates: newValue, // Ensure this is correctly formatted as an array of vitals
      };
    } else {
      // Handling for other categories remains the same
      const identifierKey =
        category === "userProcedures" ? "procedure_name" : "name";
      let [itemName, field] = splitByLastDash(columnName);

      if (category === "patientInfo") {
        field = columnName;
      }

      payload = {
        category: category,
        identifier: {
          key: identifierKey,
          value: itemName,
        },
        updates: {
          [field]: newValue,
        },
        newData,
      };
    }

    const sessionToken = localStorage.getItem('sessionToken');
    if (!sessionToken) {
      throw new Error('No session token found');
    }

    // Send the update request using axios
    api.put(`/patient/order/update/${patientId}?fromTodayView=true`, payload, {
      headers: {
        Authorization: `Bearer ${sessionToken}`,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {

        const updatedTreatments = treatments.map((treatment) => {
          if (treatment._id === response.data._id) {
            const updatedSupplements = response.data.userSupplements.map((supp) => {
              const matchedSupplement = treatment.userSupplements.find(
                (supplement) => supplement.supplement_name === supp.name
              );
    
              if (matchedSupplement) {
                return {
                  ...supp,
                  frequencyOptions:
                    matchedSupplement.frequency[0] === "1 dosage twice a day"
                      ? [...matchedSupplement.frequency]
                      : ["1 dosage twice a day", ...matchedSupplement.frequency],
                };
              }
              return supp;
            });
            setCurrentSupplements(updatedSupplements)
            return { ...response.data };
          } else {
            return treatment;
          }
        })

        const clickedUpdatedTreatment= updatedTreatments.find(treatment => treatment._id===clickedTreatmentData._id)
        if(clickedUpdatedTreatment){
          setCurrentProcedures(clickedUpdatedTreatment.userProcedures)
          setCurrentDispensedItems(clickedUpdatedTreatment.userdispensed)
        }
        setTreatments(updatedTreatments);
        setFilteredTreatments(await formatDataForTable(updatedTreatments));

      })
      .catch((error) => {
        toastifyToast.error("Error updating the treatment");
        console.error("Error:", error);
      });
  };

  // Create Watch
  async function createWatch(eventId, treatmentId, calendarId, status) {

    const sessionToken = localStorage.getItem('sessionToken');
    if (!sessionToken) {
      throw new Error('No session token found');
    }

    const responseWatch = await api.post(
      `/create-watch`,
      {
        eventId: eventId,
        treatmentId: treatmentId,
        calendarId: calendarId,
        status,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      }
    );
  }

  // Create Schedule for a patient
  const createSchedule = async () => {

    if (!selectedPatient || !formattedDateForAPI) {
      toastifyToast.error("Please select all fields");
      return;
    }

    setIsCreateScheduleLoading(true);
    try {

      const sessionToken = localStorage.getItem('sessionToken');
      if (!sessionToken) {
        throw new Error('No session token found');
      }
      const response = await api.post(
        "/patient/order/new/scheduled",
        {
          PatientId: selectedPatient,
          Scheduled_Date: formattedDateForAPI,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          type: "Treatment",
          status: "scheduled",
        },
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        toastifyToast.success("Schedule created successfully");
        const newTreatments = [
          ...treatments,
          { ...response.data, patientName: activePatients.find((p) => p["Customer ID"] === selectedPatient)["Full Name"] },
        ];

        setTreatments(newTreatments);
        setFilteredTreatments(await formatDataForTable(newTreatments));

        setSelectedPatient(null);
      } else {
        toastifyToast.error("Failed to create schedule");
      }
    } catch (error) {
      toastifyToast.error(
        error.response.data.message ||
        "An error occurred while creating the schedule"
      );
      console.error("Error creating schedule:", error);
    }

    setIsCreateScheduleLoading(false);
  };

  // Create PickUp for a patient
  const createPickup = async () => {

    setIsCreatePickupLoading(true);

    const sessionToken = localStorage.getItem('sessionToken');
    if (!sessionToken) {
      throw new Error('No session token found');
    }

    const response = await api.post(
      `/schedule-pickup/${formattedDateForAPI}`,
      {
        dispensed: [],
        supplements: [],
        patientId: selectedPatient, // Assuming patientId is available in this scope
      },
      {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      }
    );


    if (response.status === 200 || response.status === 201) {
      setSelectedPatient(null);
      toastifyToast.success("Pick up scheduled successfully!");
      const newTreatments = [
        ...treatments,
        { ...response.data, patientName: activePatients.find((p) => p["Customer ID"] === selectedPatient)["Full Name"] },
      ];

      setTreatments(newTreatments);
      setFilteredTreatments(await formatDataForTable(newTreatments));

    } else {
      console.error("Error sending data:", response.statusText);
      toastifyToast.error("Error scheduling pick up!");
    }

    setIsCreatePickupLoading(false);
  };

  // Fetch treatments when date changes
  useEffect(() => {

    getTreatments();


  }, [formattedDateForAPI]);


  // Call Get Treatments after Every 15 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if(!isTyping){
        getTreatments(false);
      }
    }, 15000);
    return () => clearInterval(interval);
  }, [formattedDateForAPI,isTyping]);


  // Context Menu
  const ContextMenu = ({ onEdit, label }) => (
    <AntdMenu items={[
      {
        key: 'edit',
        label: label || 'Edit',
        onClick: onEdit,
      }
    ]} />
  );

  const ContextMenuTable = () => {
  const record = tableContextMenuCurrentRecord;

  if (!record) {
    return <></>;
  }    
    const items = [
      ...(copiedData ? [
        {
          key: 'paste',
          label: 'Paste',
          click: () => { },
          children: [
            {
              key: 'paste_merge',
              label: 'Merge',
              click: () => {
                handlePaste(record.key, "modify");
              }
            },
            {
              key: 'paste_overwrite',
              label: 'Overwrite',
              click: () => {
                handlePaste(record.key, "overwrite");
              }
            },
          ],
        }
      ] : []),
      ...(undoOrderId ? [
        {
          key: 'undo',
          label: 'Undo',
          click: () => handleUndo(undoOrderId, undoToken)
        }
      ] : []),
      
      {
        key: 'copy_to',
        label: 'Copy To',
        click: () => { },
        children: [
          {
            key: 'copy_to_add_patient',
            label: 'Add Patient',
            click: () => {
              // setTableContextMenuCurrentRecord(record);
              setSelectPatientAction('copy_to_add_patient');
              setIsSelectPatientModalOpen(true);
            }
          },
          {
            key: 'copy_to_scheduled_patient',
            label: 'Scheduled Patient',
            click: () => {
              // setTableContextMenuCurrentRecord(record);
              setSelectPatientAction('copy_to_scheduled_patient');
              setIsSelectPatientModalOpen(true);
            },
          },
        ],
      },
      
      
      {
        key: 'scheduling',
        label: 'Scheduling',
        click: () => { },
        children: [
          {
            key: 'set_to_scheduled',
            label: 'Set To Scheduled',
            click: () => changeTreatmentStatus(record.key, "scheduled"),
          },
          {
            key: 'set_to_arrived',
            label: 'Set To Arrived',
            click: () => changeTreatmentStatus(record.key, "arrived"),
          },
          {
            key: 'set_to_completed',
            label: 'Set To Completed',
            click: () => changeTreatmentStatus(record.key, "complete"),
          },
          {
            key: 'change_date',
            label: 'Change Date',
            click: () => {
              // setTableContextMenuCurrentRecord(record);
              setSelectDataAction('date');
              setIsSelectDateModalOpen(true)
            }
          },
          {
            key: 'duplicate_to_another_date',
            label: 'Duplicate to Another Date',
            click: () => {
              // setTableContextMenuCurrentRecord(record);
              setSelectDataAction('duplicate');
              setIsSelectDateModalOpen(true)
            }
          },
          {
            key: 'move_to_no_show',
            label: 'Move to No Show',
            click: () => { }
    
          },
          {
            key: 'move_to_cancel',
            label: 'Move to Cancel',
            click: () => { }
    
          },
        ],
      },
      {
        key: 'edit_patient',
        label: 'Edit Patient',
        click: () => { },
        children: [
          {
            key: 'final_review_complete',
            label: 'Final Review Complete',
            click: () => handleReviewChange(record.key, true)
          },
          
          {
            key: 'delete_selected',
            label: 'Delete Selected',
            click: () => deleteTreatment(record.key)
          },
          {
            key: 'change_patient',
            label: 'Change Patient',
            click: () => {
              setSelectPatientAction('change_patient');
              // setTableContextMenuCurrentRecord(record);
              setIsSelectPatientModalOpen(true);
            }
          },
        ],
      },
      {
        key: 'templates',
        label: 'Templates',
        click: () => { },
        children: [
          {
            key: 'make_template',
            label: 'Make template',
            click: () => { }
    
          },
          {
            key: 'copy_from_template',
            label: 'Copy From template',
            click: () => { }
    
          },
          {
            key: 'merge_templates',
            label: 'Merge Templates',
            click: () => {
              // setTableContextMenuCurrentRecord(record);
              setIsMergeTemplatesModalVisible(true);
            }
          },
        ],
      }

    ];

    
    return (
      <AntdMenu>
        <span className="text-xs font-semibold">Action</span>
        {items.map((item) => {
          return (

            (item.key === 'copy_to' || item.key === 'paste' || item.key === 'scheduling' || item.key === 'templates' || item.key === 'edit_patient') ?
              <AntdMenu.SubMenu key={item.key} title={item.label}>
                {item.children.map((child) => {
                  return <AntdMenu.Item key={child.key} onClick={child.click}>
                    {child.label}
                  </AntdMenu.Item>
                })}
              </AntdMenu.SubMenu>
              :
              item.key === 'make_template' ?
                <AntdMenu.Item key={item.key}>
                  <ManageTreatmentTemplates
                    showToast={(message, type) => toast(message, { type })}
                    from="tdv"
                    contextMenu={{ data: { key: record ? record.key : null } }}
                  />
                </AntdMenu.Item>
                :
                item.key === 'copy_from_template' ?
                  <AntdMenu.Item key={item.key}>
                    <TreatmentTemplates
                      setTemplate={setTemplate}
                      patient={record ? record.patientId : null}
                      from="tdv"
                      contextMenu={{
                        data: record ? {
                          key: record.key,
                          PatientId: record.patientId,
                          review: record.review,
                        } :
                          {
                            key: null,
                            PatientId: null,
                            review: false,
                          }
                      }}
                      activePatient={activePatients}
                      treatments={treatments}
                      setTreatments={setTreatments}
                    />
                  </AntdMenu.Item>
                  :
                  <AntdMenu.Item key={item.key} onClick={item.click}>
                    {item.label}
                  </AntdMenu.Item>
          )
        })}



      </AntdMenu>
    );
  }

  // LPDN/S/V Modals

  const handleNextLabs = () => {
    handleOpenProcedureModal(currentPatientIdForModal, recordForNotes);

    setLabsModalOpen(false);
  };

  const getPreviousDate = (dateString) => {
    const currentDate = new Date(dateString);
    const previousDate = new Date(currentDate);
    previousDate.setDate(previousDate.getDate() - 1);
    return previousDate.toISOString().split("T")[0];
  };

  const findPreviousTreatment = (treatments) => {
    let previousTreatment = null;
    let currentDate = new Date(formattedDateForAPI);
    let found = false;

    while (!found && currentDate) {
      currentDate = getPreviousDate(currentDate);
      previousTreatment = treatments.treatments.find((treatment) => {
        let treatmentDateString = new Date(treatment.date)
          .toISOString()
          .split("T")[0];

        return treatmentDateString === currentDate;
      });

      if (previousTreatment) {
        found = true;
      }
    }

    return previousTreatment;
  };
  const findCurrentTreatment = (treatments) => {
    let currentTreatment = null;
    let currentDate = new Date(formattedDateForAPI).toISOString().split("T")[0];
    let found = false;

    currentTreatment = treatments.treatments.find((treatment) => {
      let treatmentDateString = new Date(treatment.date)
        .toISOString()
        .split("T")[0];
      return treatmentDateString === currentDate;
    });

    if (currentTreatment) {
      found = true;
    }

    return currentTreatment;
  };

  const handleRefreshLabsAndTubes = async () => {
    const scrollTop = containerRef.current.scrollTop;
    setIsLabsRefreshLoading(true);
    try {
      // Assuming orderId is available in the component's state or props
      const orderId = currentPatientLabs.orderId;

      const sessionToken = localStorage.getItem('sessionToken');

      if (!sessionToken) {
        throw new Error('No session token found');
      }

      // Fetch new labs data
      const labsResponse = await api.get(
        `/orderget/${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      // Function to sort labs if needed
      const sortLabs = (labs) => {
        // Your sorting logic here. Example:
        if (!selectRedrawnLabs) {
          return labs.sort((a, b) =>
            a.checked === b.checked ? 0 : a.checked ? -1 : 1
          );
        }
        if (selectRedrawnLabs) {
          return labs.sort((a, b) =>
            a.redrawn === b.redrawn ? 0 : a.redrawn ? -1 : 1
          );
        }
      };
      // Sort labs here if necessary
      const sortedLabs = sortLabs(labsResponse.data.userLabs);

      // Fetch new tubes summary
      const tubesResponse = await api.get(
        `/getLabTubesInfo/${orderId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );

      // Update the state with the new data
      setCurrentPatientLabs((prevState) => {
        return {
          ...prevState,
          labs: sortedLabs,
          orderId: orderId,
        };
      });
      if (tubesResponse?.data?.TubesSummary) {
        setTubesSummary(tubesResponse?.data?.TubesSummary);
      }

      setLabsModalLoader(false);
      requestAnimationFrame(() => {
        containerRef.current.scrollTop = scrollTop;
      });
    } catch (error) {
      console.error("Error refreshing labs and tubes:", error);
    }
    setIsLabsRefreshLoading(false);

  };

  const handleDeleteLabItem = async (labItemId) => {
    setItemLoader([...itemLoader,{labItemId,show:true}])
    try {

      const sessionToken = localStorage.getItem('sessionToken');

      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.delete(
        `/delete/labitem/${clickedTreatmentData._id}/${labItemId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        const updatedLoader=itemLoader.filter(item => item?.labItemId!==labItemId)
        setItemLoader(updatedLoader)
        // Remove the deleted item from the customItems state
        let updatedCustomItems = customItems;
        if (customItems.some((item) => item.id === labItemId)) {
          updatedCustomItems = customItems.filter(
            (item) => item.id !== labItemId
          );
          setCustomItems(updatedCustomItems);
        }

        // Now update currentPatientLabs to reflect the deletion
        setCurrentPatientLabs((prevState) => {
          const updatedLabs = prevState.labs.filter(
            (lab) => lab._id !== labItemId
          );
          return { ...prevState, labs: updatedLabs };
        });
      }
      
    } catch (error) {
      console.error("Error deleting lab item:", error);
      // Optionally, handle the error (e.g., display an error message)
    }
  };

  const getCheckedLabsSummary = () => {
    const checkedLabs = currentPatientLabs.labs
      .filter((lab) => lab.checked)
      .map((lab) => lab.test_name)
      .join(", ");

    return checkedLabs;
  };

  const getCheckedRedrawnLabsSummary = () => {
    const checkedRedrawnLabs = currentPatientLabs.labs
      .filter((lab) => lab.redrawn)
      .map((lab) => lab.test_name)
      .join(", ");

    return checkedRedrawnLabs;
  };

  const handleLabsCheckboxChange = (labId, checked) => {

    const sessionToken = localStorage.getItem('sessionToken');

    if (!sessionToken) {
      throw new Error('No session token found');
    }

    const scrollTop = containerRef.current.scrollTop;
    setCurrentPatientLabs((prevState) => {
      // Find the lab by ID to get its name
      const lab = prevState.labs.find((lab) => lab._id === labId);
      const labName = lab ? lab.test_name : "Unknown Lab";
      const labItemNumber = lab?.item_number;

      let url = ``;
      let payload = {};
      if (!selectRedrawnLabs) {
        url = `/patient/order/update/${currentPatientLabs.orderId}`;
        payload = {
          category: "patientLabs",
          updates: {
            checked: checked,
            value: labItemNumber ? `${labItemNumber}` : labName,
            type: labItemNumber ? "item_number" : "test_name",
          },
        };
      }

      if (selectRedrawnLabs) {
        url = `/patient/order/${currentPatientLabs.orderId}/lab/${labItemNumber}`;
        payload = {
          redrawn: checked,
        };
      }


      api
        .put(url, payload, {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        })
        .then(async (response) => {
          // Handle the successful update

          const updatedTreatments = treatments.map((treatment) => {
            if (treatment._id === response.data._id) {
              return { ...response.data };
            } else {
              return treatment;
            }
          })

          setTreatments(updatedTreatments);
          setFilteredTreatments(await formatDataForTable(updatedTreatments));

          handleRefreshLabsAndTubes();
          // handleDataReset(selectedDate);
        })
        .catch((error) => {
          // Handle any errors
          console.error("Update failed:", error);
        });

      // Update the labs state
      if (!selectRedrawnLabs) {
        return {
          ...prevState,
          labs: prevState.labs?.map((lab) =>
            lab._id === labId ? { ...lab, checked: checked } : lab
          ),
        };
      }
      if (selectRedrawnLabs) {
        return {
          ...prevState,
          labs: prevState.labs?.map((lab) =>
            lab._id === labId ? { ...lab, redrawn: checked } : lab
          ),
        };
      }
    });
    requestAnimationFrame(() => {
      containerRef.current.scrollTop = scrollTop;
    });
  };

  const handleCopyLabsFromPreviousTreatment = async () => {
    setLabsModalLoader(true);
    try {

      const sessionToken = localStorage.getItem('sessionToken');

      if (!sessionToken) {
        throw new Error('No session token found');
      }

      // Fetch previous treatments
      const previousTreatmentsResponse = await api.get(
        `/api/patient/${patientId}/last-treatments`
      );

      if (previousTreatmentsResponse.data.totalCount <= 0) {
        setLabsModalLoader(false);
        toastifyToast.error(
          "There are no labs from the previous treatment to copy from"
        );
        return;
      }

      // Filter to keep only treatments of type "Treatment"
      const treatments = previousTreatmentsResponse.data.treatments.filter(
        (treatment) => treatment.type === "Treatment"
      );

      if (treatments.length <= 0) {
        setLabsModalLoader(false);
        toastifyToast.error(
          "There are no treatments of the required type to copy labs from"
        );
        return;
      }

      const treatment = findPreviousTreatment({ treatments: treatments });
      const currentTreatment = findCurrentTreatment({ treatments: treatments });

      if (currentTreatment.review == true) {
        toastifyToast.error(
          "The status of this treatment is reviewed, so labs can't be copied"
        );
        setLabsModalLoader(false);
        return;
      }
      const previousLabs = treatment.userLabs.filter(
        (previouslab) => previouslab.checked === true
      );
      if (previousLabs.length <= 0) {
        setLabsModalLoader(false);

        toastifyToast.error(
          "There are no labs from the previous treatment to copy from"
        );
        return;
      }

      const updatePromises = previousLabs.map((previouslab) => {
        const payload = {
          category: "patientLabs",
          updates: {
            checked: true,
            value: previouslab.item_number && `${previouslab.item_number}`,
            type: previouslab.item_number && "item_number",
          },
        };

        return api.put(
          `/patient/order/update/${currentPatientLabs.orderId}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${sessionToken}`,
            },
          }
        );
      });

      Promise.all(updatePromises)
        .then((responses) => {
          const updatedLabs = responses
            .flatMap((response) => response.data.userLabs)
            .filter((lab) => lab.checked === true);

          handleRefreshLabsAndTubes();

          toastifyToast.success("Labs Copied Successfully");
        })
        .catch((error) => {

          toastifyToast.error("Failed to copy labs from previous treatment");
        });
    } catch (error) {
      toastifyToast.error("Error fetching previous treatments:");


      toastifyToast.error("Failed to fetch previous treatments");
    }

    setLabsModalLoader(false);
  };

  const handleInputChangeNew = (e,originalIndex, value) => {
    // const updatedItems = currentPatientLabs.map((item) =>
    //   item.id === id ? { ...item, test_name: value } : item
    // );
    // setCurrentPatientLabs(updatedItems);
    
      setCurrentPatientLabs((prev) => {
        const updatedLabs = [...prev.labs];
        updatedLabs[originalIndex].test_name = e.target.value;
        return { ...prev, labs: updatedLabs };
      });
    
    
    
    
  };

  useEffect(() => {
    if (shouldScroll) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
      setShouldScroll(false); 
    }
  }, [shouldScroll]); 

  
  useEffect(() => {
    console.log("************* updated labs",currentPatientLabs)
  }, [currentPatientLabs]); 

  
  const handleAddEmptyRowNew = () => {
    const newItem = {  test_name: "", checked: false, isNew: true, editItemName:true }
    // setCustomItems([
    //   ...customItems,
    //   { id: Date.now(), test_name: "", checked: false, isNew: true },
    // ]);
    setCurrentPatientLabs((prevState) => ({
      ...prevState,
      labs: [...prevState.labs, newItem],
    }));
    
    setShouldScroll(true); 
  };

  const handleSearchChangeLabs = (event) => {
    setSearchTermLabs(event.target.value);
  };

  const handleCheckboxChangeNew = (index, checked) => {
    
    const updatedLabs = [...currentPatientLabs.labs];
    updatedLabs[index] = { ...updatedLabs[index], checked, editItemName:false };
  
    let checkedLabs = [];
      let uncheckedLabs = [];

      const item = updatedLabs[index];

      if (item) {
        const { test_name, isNew } = item;

        const payload = {
          category: "patientLabs",
          newData: {
            test_name,
            checked,
            isNew,
          },
        };
  
      axios
        .put(
          `/patient/order/update/${currentPatientLabs.orderId}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
            },
          }
        )
        .then((response) => {
          console.log("Update successful:", response.data);
          if (!selectRedrawnLabs) {
            checkedLabs = response.data?.userLabs?.filter((lab) => lab.checked);
            uncheckedLabs = updatedLabs?.filter((lab) => !lab.checked);
          }
          if (selectRedrawnLabs) {
            checkedLabs = response.data?.userLabs?.filter((lab) => lab.redrawn);
            uncheckedLabs = updatedLabs?.filter((lab) => !lab.redrawn);
          }
    
          // Combine them with checked labs first
          const sortedLabs = [...checkedLabs, ...uncheckedLabs];
          setCurrentPatientLabs((prevState) => ({
            ...prevState,
            labs: [...sortedLabs],
          }));
    
        })
        .catch((error) => {
          console.error("Update failed:", error);
        });
    }
  };

  const handleOpenLabsModal = async (orderId, record) => {
    setRecordForNotes(record);
    setCurrentPatientIdForModal(orderId);
    try {

      const sessionToken = localStorage.getItem('sessionToken');

      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.get(
        `/orderget/${orderId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      setPatientId(response.data.PatientId);
      // Separate checked and unchecked labs
      let checkedLabs = [];
      let uncheckedLabs = [];
      if (!selectRedrawnLabs) {
        checkedLabs = response.data.userLabs.filter((lab) => lab.checked);
        uncheckedLabs = response.data.userLabs.filter((lab) => !lab.checked);
      }
      if (selectRedrawnLabs) {
        checkedLabs = response.data.userLabs.filter((lab) => lab.redrawn);
        uncheckedLabs = response.data.userLabs.filter((lab) => !lab.redrawn);
      }

      const responseTubes = await api.get(
        `/getLabTubesInfo/${orderId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );

      if (responseTubes?.data?.TubesSummary) {
        setTubesSummary(responseTubes?.data?.TubesSummary);
      }

      // Combine them with checked labs first
      const sortedLabs = [...checkedLabs, ...uncheckedLabs];
      setCurrentPatientLabs({
        labs: sortedLabs,
        orderId: orderId,
        patientName: response.data.patientName,
      });
      setLabsModalOpen(true);
      setClickedTreatmentData(response.data);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
    }
  };

  const handleOpenProcedureModal = async (patientId, record) => {
    setRecordForNotes(record);
    try {

      const sessionToken = localStorage.getItem('sessionToken');

      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.get(
        `/orderget/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      setPatientId(response.data.PatientId);
      const userProcedures = response.data.userProcedures;
      userProcedures.patientName = response.data.patientName;
      proceduresSortingFunction(contextProcedures, userProcedures);
      setCurrentProcedures(userProcedures);
      setClickedTreatmentData(response.data);
      setProcedureModalOpen(true);
      setCurrentPatientIdForModal(patientId);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
    }
  };

  const handleOpenDispenseModal = async (patientId, record) => {
    setRecordForNotes(record);
    try {

      const sessionToken = localStorage.getItem('sessionToken');

      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.get(
        `/orderget/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      setPatientId(response.data.PatientId);
      const userDispenses = response.data.userdispensed;

      userDispenses.patientName = response.data.patientName;
      dispensesSortingFunction(contextDispensed, userDispenses);
      setCurrentDispensedItems(userDispenses);
      setDispenseModalOpen(true);
      setClickedTreatmentData(response.data);
      setCurrentPatientIdForModal(patientId);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
      // Handle the error appropriately in your application
    }
  };

  const handleNextNotes = () => {
    if (currentTreatment.status === "pickup") {
      handleOpenSupplementsModal(currentPatientIdForModal, recordForNotes);
    } else {
      handleOpenVitalsModal(currentPatientIdForModal, recordForNotes);
    }

    setTreatmentNoteModalOpen(false);
  };

  const handlePreviousNotes = () => {
    handleOpenDispenseModal(currentPatientIdForModal, recordForNotes);

    setTreatmentNoteModalOpen(false);
  };

  const setIsTreatmentNoteEditing = (isTreatmentNoteEditingVal) => {
    isTreatmentNoteEditing.current = isTreatmentNoteEditingVal;
  };

  const handleOpenTreatmentNoteModal = async (treatmentNote) => {
    setCurrentPatientIdForModal(treatmentNote.key);
    //treatmentNote is basically the treatment we want to send to treatment Note
    if (treatmentNote) {
      const selectedTreatment = treatments.find(
        (treatmentFind) => treatmentFind._id === treatmentNote.key
      );

      setCurrentTreatment({
        ...treatmentNote,
        custom: selectedTreatment.custom,
        date: selectedTreatment.date.split("T")[0],
        userLabs: selectedTreatment.userLabs,
        userProcedures: selectedTreatment.userProcedures,
        userdispensed: selectedTreatment.userdispensed,
        PatientId: selectedTreatment.PatientId,
        _id: selectedTreatment._id,
      });
      setTreatmentNoteModalOpen(true);
      isTreatmentNoteEditing.current = true;
    }
  };

  const handleOpenSupplementsModal = async (patientId, record) => {
    setRecordForNotes(record);
    try {

      const sessionToken = localStorage.getItem('sessionToken');

      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const treatmentDataResponse = await api.get(
        `/orderget/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      const userSupplementsResponse = await api.get(
        `/userSupplements/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      const availableSupplementsResponse = await api.get(
        "/supplements-names",
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      const PatientData = activePatients.find(
        (patient) => patient["Full Name"] === record?.patientName
      );

      setPatientData(PatientData);
      setClickedTreatmentData(treatmentDataResponse.data);
      try {
        const response = await api.get(
          "/getAllSupplements"
        );

        setAllSupplements(response.data);
        const updatedSupplements = userSupplementsResponse.data.map((supp) => {
          const matchedSupplement = response.data.find(
            (supplement) => supplement.supplement_name === supp.name
          );

          if (matchedSupplement) {
            return {
              ...supp,
              frequencyOptions:
                matchedSupplement.frequency[0] === "1 dosage twice a day"
                  ? [...matchedSupplement.frequency]
                  : ["1 dosage twice a day", ...matchedSupplement.frequency],
            };
          }
          return supp;
        });

        // setSupplements(updatedSupplements);
        // const sortedItems = sortItems([...updatedSupplements]);
        setCurrentSupplements(updatedSupplements);
      } catch (error) {
        console.error("Error fetching supplements:", error);
      }

      const userSupplementsData = userSupplementsResponse.data;
      const availableSupplementsData = availableSupplementsResponse.data;

      // setCurrentSupplements(userSupplementsData);
      setAvailableSupplements(availableSupplementsData);
      setCurrentPatientIdForModal(patientId);

      setSupplementsPopup(true);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
    }
  };

  const handleOpenVitalsModal = async (patientId, record) => {
    setRecordForNotes(record);

    try {

      const sessionToken = localStorage.getItem('sessionToken');

      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.get(
        `/orderget/${patientId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      setPatientId(response.data.PatientId);

      response.data.vitals.patientName = response.data.patientName;
      setCurrentVitalsItems(response.data.vitals);
      setVitalsModalOpen(true);
      setCurrentPatientIdForModal(patientId);
    } catch (error) {
      console.error("There was an error fetching the order:", error);
      // Handle the error appropriately in your application
    }
  };

  const handleReviewChange = async (orderId, isReviewed) => {
    try {

      const sessionToken = localStorage.getItem('sessionToken');

      if (!sessionToken) {
        throw new Error('No session token found');
      }

      const response = await api.put(
        `/patient/order/update-review/${orderId}`,
        { review: isReviewed },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        toastifyToast.success("Review status updated successfully");
        const newTreatments =
          treatments.map((treatment) => {
            if (treatment._id === response.data._id) {
              return { ...treatment, review: response.data.review };
            } else {
              return treatment;
            }
          })

        setTreatments(newTreatments);
        setFilteredTreatments(await formatDataForTable(newTreatments));

        // Refetch data or update state as needed
      }
    } catch (error) {
      toastifyToast.error("Error updating review status");
      console.error("Error:", error);
    }
  };
  
  const handleNoteUpdate = async (key, newNote) => {
    // Construct the payload
    const payload = {
      note: newNote,
    };

    const sessionToken = localStorage.getItem('sessionToken');

    if (!sessionToken) {
      throw new Error('No session token found');
    }

    try {
      const response = await api.put(
        `/patient/order/update-note/${key}?fromTodayView=true`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );
      ;
      isNoteEditing.current = false;
    } catch (error) {
      console.error("Error updating note:", error);
    }
  };

  // const handleNoteFocus = () => {
  //   setInputWidth("auto");
  //   setIsTyping(true);
  //   isNoteEditing.current = true;
  // };

  // const handleNoteBlur = (e, recordKey) => {
  //   setInputWidth("100px");
  //   setIsTyping(false);
  //   handleNoteUpdate(recordKey, e.target.value);
  // };
  // Fetch Room Options
  const fetchRoomOptions = async () => {

    const sessionToken = localStorage.getItem('sessionToken');
    if (!sessionToken) {
      throw new Error('No session token found');
    }
    try {
      const response = await api.get("/get-room-options", {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      })

      setRoomOptions(response.data[0].name);
    }
    catch (error) {
      console.error("There was an error fetching the room options:", error);
    };
  };

  // Fetch Assignee Options
  const fetchAssigneeOptions = async () => {

    const sessionToken = localStorage.getItem('sessionToken');
    if (!sessionToken) {
      throw new Error('No session token found');
    }

    try {
      const response = await api.get("/get-Assignee-options", {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      })
      setAssigneeOptions(response.data[0].name);
    }
    catch (error) {
      console.error("There was an error fetching the room options:", error);
    }
  };

  useEffect(() => {
    fetchRoomOptions();
    fetchAssigneeOptions();
  }, []);

  // Execute Bulk Actions

  const handleBulkUpdateDate = async (treatmentIds, newDate) => {

    const sessionToken = localStorage.getItem('sessionToken');

    if (!sessionToken) {
      throw new Error('No session token found');
    }

    try {
      const response = await api.put('/patient/order/bulk-update-date', {
        treatmentIds,
        newDate,
      }, {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });

      if (response.status === 200) {
        toastifyToast.success("Treatments date updated successfully");
        const date = new Date(newDate);
        const tzFormattedDate = date.toISOString();
        const restructuringTreatments = treatments.map((treatment) => {
          if (treatmentIds.includes(treatment._id)) {
            return { ...treatment, date: tzFormattedDate };
          }
          return treatment;
        });

        const filteredTreatments = restructuringTreatments.filter(
          (treatment) => treatment.date !== tzFormattedDate
        );
        setTreatments(filteredTreatments);
        setFilteredTreatments(await formatDataForTable(filteredTreatments));
      } else {
        toastifyToast.error("Failed to update treatment date");
      }
    } catch (error) {
      toastifyToast.error("Error updating treatment date");
      console.error("Error:", error);
    }
  };

  const handleBulkDuplicate = async (treatmentIds, newDate) => {

    const sessionToken = localStorage.getItem('sessionToken');

    if (!sessionToken) {
      throw new Error('No session token found');
    }

    try {
      const response = await api.post('/patient/order/bulk-duplicate', {
        treatmentIds,
        newDate,
      }, {
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });

      if (response.status === 200) {
        toastifyToast.success("Treatments duplicated successfully");
        const filteredTreatments = treatments.filter((treatment) =>
          treatmentIds.includes(treatment._id)
        );

        const patientIdToNameMap = filteredTreatments.reduce((map, treatment) => {
          map[treatment.PatientId] = treatment.patientName;
          return map;
        }, {});

        const updatedResponse = response.data.treatment.map((responseItem) => {
          const patientName = patientIdToNameMap[responseItem.PatientId];
          return {
            ...responseItem,
            patientName: patientName || null,
          };
        });

        setTreatments([...treatments, ...updatedResponse]);
        setFilteredTreatments(await formatDataForTable([...treatments, ...updatedResponse]));
      } else {
        toastifyToast.error("Failed to duplicate treatments");
      }
    } catch (error) {
      toastifyToast.error("Error duplicating treatments");
      console.error("Error:", error);
    }
  };


  const handleBulkUpdate = async (newStatus) => {
    if (selectedRowKeys.length === 0) {
      toastifyToast.error("No treatments selected");
      return;
    }

    const sessionToken = localStorage.getItem('sessionToken');
    if (!sessionToken) {
      throw new Error('No session token found');
    }

    // Call the new backend API for bulk update
    try {
      const response = await api.put(
        "/patient/order/bulk-update-status",
        {
          treatmentIds: selectedRowKeys,
          newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );

      if (response.status === 200) {
        toastifyToast.success(`Treatments updated to ${newStatus}`);

        const filteredTreatments = treatments.map((treatment) => {
          if (selectedRowKeys.includes(treatment._id)) {
            return { ...treatment, status: newStatus };
          }
          return treatment;
        });
        setTreatments(filteredTreatments);
        setFilteredTreatments(await formatDataForTable(filteredTreatments));
        setSelectedRowKeys([]);
      } else {
        toastifyToast.error("Failed to update treatments");
      }
    } catch (error) {
      toastifyToast.error("Error updating treatments");
      console.error("Error updating treatments:", error);
    }
  };

  const handleBulkDelete = async () => {
    if (selectedRowKeys.length === 0) {
      toastifyToast.error("No treatments selected");
      return;
    }

    const sessionToken = localStorage.getItem('sessionToken');
    if (!sessionToken) {
      throw new Error('No session token found');
    }

    // Call the new backend API for bulk delete
    try {
      const response = await api.delete(
        "/patient/order/bulk-delete",
        {
          data: { treatmentIds: selectedRowKeys },
          headers: {
            Authorization: `Bearer ${sessionToken}`,
          },
        }
      );

      if (response.status === 200) {
        toastifyToast.success("Treatments deleted successfully");
        const filteredTreatments = treatments.filter(
          (treatment) => !selectedRowKeys.includes(treatment._id)
        );
        setTreatments(filteredTreatments);
        setFilteredTreatments(await formatDataForTable(filteredTreatments));
        setSelectedRowKeys([]);
      } else {
        toastifyToast.error("Failed to delete treatments");
      }
    } catch (error) {
      toastifyToast.error("Error deleting treatments");
      console.error("Error deleting treatments:", error);
    }
  };

  const executeBulkAction = async () => {

    const sessionToken = localStorage.getItem('sessionToken');
    if (!sessionToken) {
      throw new Error('No session token found');
    }

    if (bulkAction === "Date" && bulkActionDate) {

      handleBulkUpdateDate(selectedRowKeys, bulkActionDate);
      setSelectedRowKeys([]);

    } else if (bulkAction === "Duplicate" && bulkActionDate) {
      handleBulkDuplicate(selectedRowKeys, bulkActionDate);
      setSelectedRowKeys([]);

    } else if (bulkAction === "delete") {
      // Handle bulk delete action
      await handleBulkDelete();
    } else if (bulkAction === "review") {
      if (selectedRowKeys.length === 0) {
        toastifyToast.error("No treatments selected for review");
        return;
      }
      try {
        const response = await api.put(
          "/patient/order/bulk-review",
          {
            treatmentIds: selectedRowKeys,
          },
          {
            headers: {
              Authorization: `Bearer ${sessionToken}`,
            },
          }
        );

        if (response.status === 200) {
          toastifyToast.success("Treatments set to review successfully");
          const updatedTreatments = treatments.map((treatment) => {
            if (selectedRowKeys.includes(treatment._id)) {
              return { ...treatment, review: true };
            }
            return treatment;
          });
          setTreatments(updatedTreatments);
          setFilteredTreatments(await formatDataForTable(updatedTreatments));
          setSelectedRowKeys([]);
        } else {
          toastifyToast.error("Failed to review treatments");
        }
      } catch (error) {
        toastifyToast.error("Error reviewing treatments");
        console.error("Error reviewing treatments:", error);
      }
    } else {
      handleBulkUpdate(bulkAction);
    }

    setBulkAction("");
  };


  const changeScheduledPatient = (selectedValue, treatmentId) => {
    const selectedPatient = [...activePatients, ...inActivePatients].find(
      (patient) => patient["Customer ID"] === selectedValue
    );

    // setSelectedPatientName(selectedPatientNameFound);
    if (!selectedPatient) {
      toastifyToast.error("Please select a patient");
    } else {

      handleCellChange(
        treatmentId,
        "PatientId",
        selectedPatient["Customer ID"],
        "patientInfo"
      );
      setIsSelectPatientModalOpen(false);
    }
  };

  const copyTreatmentToNewPatient = async (patientId, treatmentId) => {

    const sessionToken = localStorage.getItem('sessionToken');

    if (!sessionToken) {
      throw new Error('No session token found');
    }


    try {
      const response = await api.post(
        "/patient/copy",
        {
          patientId,
          treatmentId
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        toastifyToast.success("Copied successfully");
        const selectedPatient = [...activePatients, ...inActivePatients].filter(
          (item) => item["Customer ID"] === patientId
        );
        const filteredTreatments = [
          ...treatments,
          {
            ...response.data.newTreatment,
            patientName: selectedPatient[0]["Full Name"],
          },
        ];
        setTreatments(filteredTreatments);
        setFilteredTreatments(await formatDataForTable(filteredTreatments));
        setIsSelectPatientModalOpen(false);
      } else {
        toastifyToast.error("Failed to create schedule");
      }
    } catch (error) {
      toastifyToast.error(
        error.response.data.message || "An error occurred while copying"
      );
      console.error("Error creating copy:", error);
    }
  };

  const copyTreatmentToScheduledPatient = async (patientId, treatmentId) => {

    const sessionToken = localStorage.getItem('sessionToken');

    if (!sessionToken) {
      throw new Error('No session token found');
    }

    try {

      const modifyTreatmentId = treatments.find(
        (treatment) => treatment.PatientId === patientId
      )?._id;

      if (!modifyTreatmentId) {
        return;
      }

      const response = await api.post(
        "/todaypatientcopy",
        {
          patientId,
          treatmentId: treatmentId,
          treatmentIdToModify: modifyTreatmentId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        toastifyToast.success("Copied successfully");
        const selectedPatient = [...activePatients, ...inActivePatients].filter(
          (item) => item["Customer ID"] === patientId
        );
        const filteredTreatments = treatments.map((treatment) => {
          if (treatment._id === response.data.newTreatmentData._id) {
            return {
              ...response.data.newTreatmentData,
              patientName: selectedPatient[0]["Full Name"],
            };
          }
          return treatment;
        });
        setTreatments(filteredTreatments);
        setFilteredTreatments(await formatDataForTable(filteredTreatments));
        setIsSelectPatientModalOpen(false);
      } else {
        toastifyToast.error("Failed to create schedule");
      }
    } catch (error) {
      toastifyToast.error(
        error.response.data.message || "An error occurred while copying"
      );
      console.error("Error creating copy:", error);
    }
  };

  const onCheckboxChange = (changes) => {
    setCheckboxValues({ ...checkboxValues, ...changes });
  };

  const openModal = (patientId) => {
    setModals((prev) => ({ ...prev, [patientId]: true }));
  };

  const closeModal = (patientId) => {
    setModals((prev) => ({ ...prev, [patientId]: false }));
  };

  const onCopyClick = (oldTreatment) => {
    setCopiedData(true);
    let resultString = `Treatment: ${oldTreatment.key}\n`;
    // Check and append labs if selected
    if (checkboxValues.labs) {
      resultString += `Labs: ${oldTreatment.labs.join(", ")}\n`;
    }

    // Check and append procedures if selected
    if (checkboxValues.procedures) {
      resultString += `Procedures: ${oldTreatment.procedures.join(
        ", "
      )}\n`;
    }

    // Check and append dispensed if selected
    if (checkboxValues.dispensed) {
      const dispensedItems = oldTreatment.dispensed.filter(
        (item) => !item.includes("SUP")
      );
      resultString += `Dispensed: ${dispensedItems.join(", ")}\n`;
    }

    // Check and append supplements if selected
    if (checkboxValues.supplements) {
      const supplements = oldTreatment.dispensed
        .filter((item) => item.includes("SUP"))
        .map((item) => item.replace("SUP", "").trim());
      resultString += `Supplements: ${supplements.join(", ")}\n`;
    }

    // Output or handle the constructed string

    handleCopy(resultString);
  };

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // handleClose();
      })
      .catch((err) => {});
  };

  const [pastedText, setPastedText] = useState("");
  const [copiedData, setCopiedData] = useState("");
  const [undoOrderId, setUndoOrderId] = useState(null);
  const [undoToken, setUndoToken] = useState(null);
  const handlePaste = (key,action) => {
    navigator.clipboard
      .readText()
      .then((text) => {
        setPastedText(text);

        handlePasteUpdate(key, text, action);
      })
      .catch((err) => {});
  };

  function extractDetails(resultString) {
    const details = {
      oldTreatment: null,
      hasProcedures: false,
      hasLabs: false,
      hasDispensed: false,
      hasSupplements: false,
    };

    // Extract Treatment
    const oldTreatmentMatch = resultString.match(/Treatment:\s*(.*)/);
    if (oldTreatmentMatch && oldTreatmentMatch[1]) {
      details.oldTreatment = oldTreatmentMatch[1];
    }

    // Check for Procedures
    details.hasProcedures = /Procedures:\s*(.*)/.test(resultString);

    // Check for Labs
    details.hasLabs = /Labs:\s*(.*)/.test(resultString);

    // Check for Dispensed
    details.hasDispensed = /Dispensed:\s*(.*)/.test(resultString);

    // Check for Supplements
    details.hasSupplements = /Supplements:\s*(.*)/.test(resultString);

    return details;
  }

  async function handlePasteUpdate(newTreatmentId, text, action) {
    //
    const normalizedText = text.replace(/\r\n/g, "\n").replace(/\n/g, "\r\n");

    const details = extractDetails(text);

    try {
      const response = await axios.put(
        `/paste-in-treatment`,
        {
          newTreatmentId,
          action,
          oldTreatmentId: details.oldTreatment,
          dataToUpdate: details,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );
      toastifyToast.success("Paste Successful");
      const undoToken = response.data.undoToken;
      const undoOrderId = response.data.orderId;
      setUndoOrderId(undoOrderId);
      setUndoToken(undoToken);
      getTreatments(false);      
    } catch (error) {
      toastifyToast.error(error);
      console.error(error);
    }
  }

  async function handleUndo(undoOrderId, undoToken) {
    try {
      await axios.put(
        `/undo-paste/${undoOrderId}`,
        { undoToken },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("sessionToken")}`,
          },
        }
      );

      toastifyToast.success("Undo Successful");
      setUndoOrderId(null);
      setUndoToken(null);
      getTreatments(false)
    } catch (error) {
      toastifyToast.error(error.message || "Error undoing changes");
      console.error(error);
    }
  }
  const getPatientProfile = (record) => {
    return (
      activePatients.find((patient) => patient["Customer ID"] === record.patientId) ||
      inActivePatients?.find((patient) => patient["Customer ID"] === record.patientId)
    );
  };

  // const dropdownRef = useRef(false);
  const [dropdownVisible,setDropdownVisible] = useState(false);

  // useEffect(()=>{
  //   console.log("************* dropdown ref", dropdownRef.current)
  // },[dropdownRef.current])
  // Table columns
  function isIpad() {
    const { innerWidth } = window;
    const userAgent = navigator.userAgent;
  
    // Check if the device is an iPad based on screen width and user agent
    return (
      (innerWidth <= 1024 || /iPad|Macintosh/.test(userAgent) || 'ontouchend' in document)
    );
  }
  
  const [isIpadDevice, setIsIpadDevice] = useState(false);

  useEffect(() => {
    setIsIpadDevice(isIpad());
    const handleResize = () => {
      setIsIpadDevice(isIpad());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(()=>{
    console.log("*************** is ipad device", isIpadDevice)
  },[isIpadDevice])
  const columns = [
    {
      title: (
        <Checkbox
          indeterminate={
            selectedRowKeys.length > 0 &&
            selectedRowKeys.length < treatments.length
          }
          onChange={onSelectAllChange}
          checked={
            selectedRowKeys.length === treatments.length &&
            treatments.length > 0
          }
          className="p-2"
        />
      ),
      dataIndex: "key",
      key: "select",
      render: (text, record) => (
        <Checkbox
          className="p-2"
          checked={selectedRowKeys.includes(record.key)}
          onChange={(e) => {
            const checked = e.target.checked;
            setSelectedRowKeys((prev) =>
              checked
                ? [...prev, record.key]
                : prev.filter((key) => key !== record.key)
            );
          }}
        />
      ),
      onCell: (record) => ({
        style: {
          backgroundColor: selectedRowKeys.includes(record.key)
            ? "#f7f7f7"
            : "inherit",
        },
      }),
    },
    {
      title: "Messages",
      key: "messages",
      render: (text, record) => {
        const patientProfile = getPatientProfile(record);
        return (
          <div className="w-full flex items-start p-2">
          <RenderMessages
            patientProfile={patientProfile}
          />
          </div>
        );
      },
    },
    {
      title: "Patient Name",
      dataIndex: "patientName",
      key: "patientName",
      render: (text, record) => (

        <div style={{ display: "flex", alignItems: "center" }}>
          <p className="text-lg font-medium p-2">{text}</p>
          <IconButton
            aria-label="more"
            aria-controls={`menu-${record.patientId}`}
            aria-haspopup="true"
            sx={{
              color: blue[700],
              ":hover": {
                color: blue[400],
              },
            }}
            onClick={(e) => setMenuAnchor({ id: record.patientId, element: e.currentTarget })}
          >
            <East sx={{ fontSize: 18 }} />
          </IconButton>
          <Menu
            id={`menu-${record.patientId}`}
            anchorEl={menuAnchor && menuAnchor.id === record.patientId ? menuAnchor.element : null}
            keepMounted
            open={menuAnchor && menuAnchor.id === record.patientId}
            onClose={() => setMenuAnchor(null)}
            sx={{
              "& .MuiPaper-root": {
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
              },
            }}
          >
            <MenuItem
              component="a"
              onClick={() => {
                setMenuAnchor(null);
                if (record.patientId) {
                  navigate(`/treatmentviewdisplay/${record.patientId}`);
                }
              }}
            >
              Treatment View
            </MenuItem>
            <MenuItem
              component="a"
              onClick={() => {
                setMenuAnchor(null);
                if (record.patientId) {
                  navigate(`/auditview/${record.patientId}/${record.key}`);
                }
              }}
            >
              Prep View
            </MenuItem>
            <MenuItem
              component="a"
              onClick={() => {
                setMenuAnchor(null);
                if (record.patientId) {
                  navigate(`/filters/${record.patientId}`);
                }
              }}
            >
              Filter View
            </MenuItem>
          </Menu>
        </div>
      ),
    },
    {
      title: "LPDV",
      key: "lpdv",
      render: (record) => {
        return (

          <div className="flex justify-center items-center" style={{ columnGap: '2px' }}>

            <Badge className="cursor-pointer" key={'L'} count={'L'} style={{ backgroundColor: record.labs && record.labs.length > 0 ? "#b9f6ca" : "#e3f2fd", color: '#000' }} onClick={() => handleOpenLabsModal(record.key, record)} />
            <Badge className="cursor-pointer" key={'P'} count={'P'} style={{ backgroundColor: record.procedures && record.procedures.length > 0 ? "#b9f6ca" : "#90caf9", color: '#000' }} onClick={() => handleOpenProcedureModal(record.key, record)} />
            <Badge className="cursor-pointer" key={'D'} count={'D'} style={{ backgroundColor: record.dispensed && record.dispensed.filter((item) => !item.endsWith("SUP ")).length > 0 ? "#b9f6ca" : "#42a5f5", color: '#000' }} onClick={() => handleOpenDispenseModal(record.key, record)} />
            <Badge className="cursor-pointer" key={'N'} count={'N'} style={{ backgroundColor: record.treatmentNotes && record.treatmentNotes.includes("Notes by:") ? "#81e984" : "#0e75e5", color: '#000' }} onClick={() => handleOpenTreatmentNoteModal(record)} />
            {record.status === 'pickup' ?
              <Badge className="cursor-pointer" key={'S'} count={'S'} style={{ backgroundColor: record.dispensed && record?.dispensed?.length > 0 && record?.dispensed?.some((item) => item.endsWith("SUP ")) ? "#00cc00" : "#f0ad4e", color: '#000' }} onClick={() => handleOpenSupplementsModal(record.key, record)} />
              :
              <Badge className="cursor-pointer" key={'V'} count={'V'} style={{ backgroundColor: record.vitals && record?.vitals?.length > 0 ? "#81e984" : "#1e88e5", color: '#000' }} onClick={() => handleOpenVitalsModal(record.key, record)} />
            }
          </div>

        );
      },
    },
    {
      title: "Final",
      dataIndex: "review",
      key: "review",
      render: (review, record) => (

        <Checkbox
          className="w-full"
          checked={record.review}
          onChange={(e) => {
            handleReviewChange(record.key, e.target.checked);
          }}
        />
      ),
    },
    {
      title: "RM/AS",
      dataIndex: "RM/AS",
      width: 200,
      key: "RM/AS",
      render: (room, record) => (
        <div className="flex justify-between items-center">
          <div className="flex justify-center items-center flex-col p-2">
            <p className="text-lg font-medium">{record.room}</p>
            <p className="text-lg font-medium">{record.assignees.length > 0 && record.assignees.join(", ")}</p>
          </div>
          <EditRoomModal
            record={record}
            assigneeOptions={assigneeOptions}
            roomOptions={roomOptions}
            onCellChange={handleCellChange}
          />
        </div>
      ),
    },
    {
      title: "Procedures",
      dataIndex: "procedures",
      key: "procedures",
      onCell: (record) => ({
        "data-column-key": "procedures",  
        onClick: () => {
            if (!dropdownVisible) {
              handleOpenProcedureModal(record.key, record);
            }
            setDropdownVisible(false);
        },
        style: {
          cursor: 'pointer', 
          position: 'relative',
        },
      }),
      render: (procedures, record) => {
        const ivPrepData = ivPrepByTreatment[record.key] || {
          procedures: [],
          dispensed: [],
        };
    
        const procedureElements = procedures
          ? procedures.map((procedure, index) => {
              const isComped = ivPrepData.procedures.some(
                (p) =>
                  procedure.includes(p.name) &&
                  p.comped &&
                  !["Red Light Bed", "Hydrogen In"].includes(procedure)
              );
              const isCustom = procedure.includes("(Custom)");
              return (
                <span
                  key={index}
                  style={{
                    color: isComped ? "orange" : isCustom ? "blue" : "inherit",
                  }}
                >
                  {index > 0 ? ", " : ""}
                  {procedure.replace(" (Custom)", "")}
                </span>
              );
            })
          : [];
    
        return (
          <Dropdown
          overlay={
            ContextMenu({
              onEdit: () => {
                onCopyClick(record);
              },
              label: "Copy Procedures",
            })
          }
            trigger={["contextMenu"]}
            onVisibleChange={(visible) => {              
              if (visible) {
                onCheckboxChange({
                  procedures: true,
                  labs: false,
                  dispensed: false,
                  supplements: false, });
              }
            }}
            onOpenChange={(open) => {
              if(open){
              setDropdownVisible(true);
            }
              else{
                setTimeout(() => {
                  setDropdownVisible(false);
                }, 100); 
              }
            }}
          >
            <div className="flex justify-start items-center w-full"
             onMouseDown={(e) => {
              e.stopPropagation();
            }}>
              {/* Procedure items container */}
              <div className="flex flex-wrap items-center" style={{ flexGrow: 1 }}>
                {procedureElements && procedureElements.length > 0 && (
                  <div className="text-lg font-medium p-2">{procedureElements}</div>
                )}
              </div>
            </div>          
            </Dropdown>
        );
      },
    },
    {
      title: "Dispensed",
      dataIndex: "dispensed",
      key: "dispensed",
      onCell: (record) => ({
        "data-column-key": "dispensed",  
        onClick: () => {
          if (!dropdownVisible) {
            handleOpenDispenseModal(record.key, record);
          }
          setDropdownVisible(false);
      },
        style: {
          cursor: 'pointer', 
          position: 'relative',
        },
      }),
      render: (dispensed, record) => {
        const ivPrepData = ivPrepByTreatment[record.key] || {
          procedures: [],
          dispensed: [],
        };
    
        const extractName = (str) => {
          const match = str.match(/^[^(]+/);
          return match ? match[0].trim() : str.trim();
        };
    
        const dispensedElements = dispensed
          ? dispensed.map((item, index) => {
              const isComped = ivPrepData.dispensed.some(
                (d) => extractName(d.name) === extractName(item) && d.comped
              );
              item = item.replace("SUP", "");
              const isCustom = item.includes("(Custom)");
              return (
                <span
                  key={index}
                  style={{
                    color: isComped ? "orange" : isCustom ? "blue" : "inherit",
                  }}
                >
                  {index > 0 ? ", " : ""}
                  {item.replace(" (Custom)", "")}
                </span>
              );
            })
          : [];
    
        return (
          <Dropdown
            overlay={
              ContextMenu({
                onEdit: () => {
                  onCopyClick(record);
                },
                label: "Copy Dispensed",
              })
          }
            trigger={["contextMenu"]}
            onVisibleChange={(visible) => {
              if (visible) {
                onCheckboxChange({
                  dispensed: true,
                  supplements: true,
                  procedures: false,
                  labs: false,
                });
              }
            }}
            onOpenChange={(open) => {
              if (open) {
                setDropdownVisible(true);
              }
              else {
                setTimeout(() => {
                  setDropdownVisible(false);
                }, 100);
              }
            }}
          >
            <div className="flex justify-start items-center w-full" 
            onMouseDown={(e) => {
              e.stopPropagation();
            }}>
              {/* Dispensed items container */}
              <div className="flex flex-wrap items-center" style={{ flexGrow: 1 }}>
                {dispensedElements && dispensedElements.length > 0 && (
                  <div className="text-lg font-medium p-2">{dispensedElements}</div>
                ) }
              </div>
            </div>  
            </Dropdown>        
        );
      },
    },
    {
      title: "Labs",
      className: "labs-column",
      onCell: (record) => ({
        className: "labs-column-cell",
        "data-column-key": "labs",
        onClick: () => {
          if (!dropdownVisible) {
            handleOpenLabsModal(record.key, record);
          }
          setDropdownVisible(false);
        },
        style: {
          cursor: 'pointer',
          position: 'relative',
        },
      }),
      dataIndex: "labs",
      key: "labs",
      render: (labs, record) => {
        const labTestsElements = labs
          ? labs.map((lab, index) => {
              const isCustom = lab.includes("(Custom)");
              return (
                <span
                  key={index}
                  style={{ color: isCustom ? "blue" : "inherit" }}
                >
                  {lab.replace(" (Custom)", "")}
                  {index < labs.length - 1 ? ", " : ""}
                </span>
              );
            })
          : [];
    
        return (
          <Dropdown
          overlay={
            ContextMenu({
              onEdit: () => {
                onCopyClick(record);
              },
              label: "Copy Labs",
            })
          }
          trigger={["contextMenu"]}
          onVisibleChange={(visible) => {
            if (visible) {
              onCheckboxChange({
                labs: true,
                procedures: true,
                dispensed: false,
                supplements: false,
              });
            }
          }}
          onOpenChange={(open) => {
            if(open){
            setDropdownVisible(true);
          }
            else{
              setTimeout(() => {
                setDropdownVisible(false); 
              }, 100);
            }
          }}
        >
            <div className="flex justify-start items-center w-full" onMouseDown={(e) => {
              e.stopPropagation();
            }}>
              {/* Lab items container */}
              <div className="flex flex-wrap items-center" style={{ flexGrow: 1 }}>
                {labTestsElements && labTestsElements.length > 0 && (
                  <div className="text-lg font-medium p-2">{labTestsElements}</div>
                ) }
              </div>
            </div>
            </Dropdown>
        );
      },
    },
    
    
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      render: (note, record) => {
        const handleInputChange = (e) => {
          const newText = e.target.value;
          setTreatments((prevRecords) =>
            prevRecords.map((treatment) =>
            treatment._id === record.key ? { ...treatment, note: newText } : treatment
          ));
        };

        return <div className="flex justify-center items-center w-full">
          {Number(inputWidth) + 20 >= 300 ? (
            <Tooltip title={<p className="text-lg">{note}</p>}>
              <Input
                key={record.key}
                value={note}
                style={{ minWidth: "100px", maxWidth: '300px' }}
                onChange={handleInputChange}
                onFocus={handleNoteFocus}
                onMouseEnter={handleNoteHover}
                onBlur={(e) => {
                  handleNoteBlur(e, note, record.key);
                  handleAddNoteToMultipleTxNotes(record, e);
                }}
              />
            </Tooltip>
          ) : (
            <Input
              key={record.key} // Ensure the key is unique for each row
              value={note} // The current value of the input
              style={{ minWidth: "100px", maxWidth: '300px' }} // Dynamically adjust width              
              onMouseEnter={handleNoteHover} // Trigger on hover
              onChange={handleInputChange} // Update text and width on typing
              onFocus={handleNoteFocus} // Trigger focus handling
              onBlur={(e) => {
                handleNoteBlur(e,note,record.key);
                handleAddNoteToMultipleTxNotes(record, e);
              }}
            />
          )}
        </div>
      }
    },
  ];
 // Table status Bg

 const getStatusStyle = (status) => {
  switch (status) {
    case "scheduled":
      return {
        boxShadow: "inset 5px 0 0 0 #89cff0", // Border color for scheduled
      };
    case "arrived":
      return {
        boxShadow: "inset 5px 0 0 0 #ffd590", // Border color for arrived
      };
    case "pickup":
      return {
        backgroundColor: "#f6c4f8", // Background color for 'pickup'
        boxShadow: "inset 5px 0 0 0 #f6c4f8", // Border color for pickup
      };
    case "complete":
      return {
        boxShadow: "inset 5px 0 0 0 #00a36c", // Border color for complete
      };
    default:
      return {}; // Default case when no specific status
  }
};

  if(true){
    return (
    <>
      <div className="flex flex-col md:flex-row h-screen bg-gray-100">
        <div className="flex-1 overflow-auto">
          <header className="bg-white shadow p-4 flex flex-col md:flex-row justify-between items-center">
            <div className="flex items-center space-x-4 mb-4 md:mb-0">
              <h1 className="text-2xl font-bold mb-0">Today View</h1>
              <div className="flex items-center space-x-2">
                <ChevronLeft
                  className="h-4 w-4 cursor-pointer hover:text-gray-600 transition-colors"
                  onClick={() => navigateDate(-1)}
                />
                <span>{displayDate}</span>
                <ChevronRight
                  className="h-4 w-4 cursor-pointer hover:text-gray-600 transition-colors"
                  onClick={() => navigateDate(1)}
                />
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <Button
                onClick={resetToToday}
                className="bg-black text-white hover:bg-gray-800 transition-colors"
                disabled={isLoading}
              >
                Today
              </Button>
              <Input
                type="date"
                value={formattedDateForInput}
                onChange={handleDateChange}
                disabled={isLoading}
                className="cursor-pointer"
              />
            </div>
          </header>
          <div className="p-6 space-y-6">
            <StyledSelect
              className="w-full md:w-[200px]"
              placeholder="Select patient"
              onChange={(value) => setSelectedPatient(value)}
              showSearch
              loading={patientLoader}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children
                  ?.toLowerCase()
                  ?.indexOf(input?.toLowerCase()) >= 0
              }
              value={selectedPatient}
            >
              {activePatients?.map((patient) => (
                <Option key={patient._id} value={patient["Customer ID"]}>
                  {patient["Full Name"]}
                </Option>
              ))}
              {showInactivePatients &&
                inActivePatients?.map((patient) => (
                  <Option
                    key={patient._id}
                    value={patient["Customer ID"]}
                  >
                    {patient["Full Name"]}
                  </Option>
                ))}
            </StyledSelect>

            <div className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-4 md:space-y-0">
              <div className="space-x-2">
                <Button className="bg-black text-white" onClick={() => setIsScheduleModalVisible(true)} loading={false}>Quick Schedule</Button>
                {selectedPatient && <Button className="bg-black text-white" onClick={createSchedule} loading={isCreateScheduleLoading}>Schedule</Button>}
                {selectedPatient && <Button className="bg-black text-white" onClick={createPickup} loading={isCreatePickupLoading}>Pick Up</Button>}
              </div>
              <div className="flex flex-col md:flex-row items-start md:items-center space-y-2 md:space-y-0 md:space-x-4">
                <div className="flex items-center space-x-2">
                  <input type="checkbox" id="showInactive" checked={showInactivePatients}
                    onChange={(e) => setShowInactivePatients(e.target.checked)} />
                  <label htmlFor="showInactive">Show inactive patients</label>

                </div>
                <TasksModal/>
                {/* <ConversationsModal patientIDs={patientIDs} unreadMessagesCount={unreadMessagesCount} /> */}
                <Button className="bg-white text-black" onClick={() => fetchEventsNew(scheduledCalendar, "scheduled")} loading={isFetchingEvents}>Show Events</Button>
                <Button className={`${byRoom ? 'bg-black text-white' : 'bg-white text-black'}`} onClick={() => setByRoom((prev) => !prev)}>By Room</Button>
              </div>
            </div>


            {!byRoom ? <DndProvider backend={HTML5Backend}>
              <Row gutter={[16, 16]}>
                {statuses.map((status) => (
                  <StatusColumn
                    key={status}
                    status={status}
                    treatments={treatments
                      .filter((t) => t.status === status)
                    }
                    calendarEvents={calendarEvents}
                    moveCardStatus={moveCardStatus}
                    calendar={getCalendarByType(status)}
                    ignoredEvents={ignoredEvents}
                    fetchEventsNew={fetchEventsNew}
                    isLoading={isLoading}
                    ivPrepByTreatment={ivPrepByTreatment}
                    eventDetailsList={eventDetailsList}
                    deleteTreatment={deleteTreatment}
                    handleEventTimeChange={handleEventTimeChange}
                  />

                ))}
              </Row>
            </DndProvider>
              :
              <DndProvider backend={HTML5Backend}>
                <div className="w-auto space-y-6">
                  <div className="w-full">
                    <div className="grid grid-cols-1 md:grid-cols-2">
                    <RoomColumnUnassigned
                      key={'scheduled'}
                      room={'scheduled'}
                      treatments={treatments.filter((t) => t.status === 'scheduled' && t.room === 'unassigned')}
                      moveCardRoom={moveCardRoom}
                      ivPrepByTreatment={ivPrepByTreatment}
                      isLoading={isLoading}
                      eventDetailsList={eventDetailsList}
                      deleteTreatment={deleteTreatment}
                      handleEventTimeChange={handleEventTimeChange}
                    />
                    <RoomColumnUnassigned
                      key={'arrived'}
                      room={'arrived'}
                      treatments={treatments.filter((t) => t.status === 'arrived' && t.room === 'unassigned')}
                      moveCardRoom={moveCardRoom}
                      ivPrepByTreatment={ivPrepByTreatment}
                      isLoading={isLoading}
                      eventDetailsList={eventDetailsList}
                      deleteTreatment={deleteTreatment}
                      handleEventTimeChange={handleEventTimeChange}
                    />
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-6 xl:grid-cols-9">
  {rooms.map((room) => (
    <RoomColumn
      key={room}
      room={room}
      ivPrepByTreatment={ivPrepByTreatment}
      // Filter for pickup and complete statuses
      treatments={
        room === 'Pickup'
          ? treatments.filter((t) => t.status === 'pickup')
          : room === 'Complete'
          ? treatments.filter((t) => t.status === 'complete')
          : treatments.filter((t) => t.room === room && t.status !== 'pickup' && t.status !== 'complete')
      }
      moveCardRoom={moveCardRoom}
      isLoading={isLoading}
      eventDetailsList={eventDetailsList}
      deleteTreatment={deleteTreatment}
      handleEventTimeChange={handleEventTimeChange}
    />
  ))}
</div>

                  </div>
                </div>
              </DndProvider>
            }

<Card
  title="Patients"
>
  <div className="flex flex-col md:flex-row md:justify-between gap-4 items-center mb-4">
    <div className="flex items-center gap-2">
      <p>Actions</p>
      <Select
        placeholder="Bulk Actions"
        style={{ width: 200 }}
        onChange={(value) => setBulkAction(value)}
        value={bulkAction}
      >
        <Option value="scheduled">Set to Scheduled</Option>
        <Option value="arrived">Set to Arrived</Option>
        <Option value="complete">Set to Complete</Option>
        <Option value="review">Final review complete</Option>
        <Option value="Date">Change Date</Option>
        <Option value="Duplicate">Duplicate to another Date</Option>
        <Option value="delete">Delete Selected</Option>
      </Select>
      {(bulkAction === "Date" || bulkAction === "Duplicate") && (
        <DatePicker
          style={{ width: 200 }}
          onChange={(date, datestring) => setBulkActionDate(datestring)}
        />
      )}
      <Button onClick={executeBulkAction} disabled={selectedRowKeys.length === 0}>
        Apply
      </Button>
    </div>

<div className="flex items-center gap-2">
      <Space>
        <Input
          placeholder="Search..."
          style={{ width: 200 }}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          />
        <Button icon={<SearchOutlined />} />
      </Space>
    <div className="flex items-center gap-2">
      {statuses.map((status) => (
        <Badge
          key={status}
          count={status.charAt(0).toUpperCase() + status.slice(1)}
          className="cursor-pointer"
          style={{
            backgroundColor: selectedStatuses.includes(status)
              ? "rgb(156 163 175)"
              : "rgb(229 231 235)",
            color: "#000",
            cursor: "pointer",
          }}
          onClick={() => handleStatusFilter(status)}
        />
      ))}
    </div>
</div>
    
  </div>
  <Dropdown overlay={ContextMenuTable()} trigger={["contextMenu"]}>
    <div>
      <div id="new-today-view-treatments-table">
    <Table
  className="overflow-auto"
  columns={columns}
  dataSource={filteredTreatments}
  size="small"
  pagination={false}
  rowKey="name"
  loading={{
    indicator: <ClipLoader color="#000" size={25} />,
    spinning: isLoading,
  }}
  responsive
  onRow={(record) => ({
    style: getStatusStyle(record.status), // Apply styles based on status
    onContextMenu: (event) => {
        const targetCell = event.target.closest('td');
      if (!targetCell) return;
        const columnKey = targetCell.getAttribute('data-column-key');
      if (columnKey !== 'labs' && columnKey !== 'procedures' && columnKey !== 'dispensed' ) {
        event.preventDefault();
        event.data = record;
        setTableContextMenuCurrentRecord(record);
      }
      else{
        setTableContextMenuCurrentRecord(null); 
      }
    },
  })}
/>
</div>


    </div>
  </Dropdown>
</Card>


          </div>
        </div>

      </div>

      <EventsModal
        open={isEventModalVisible}
        treatments={treatments}
        events={eventsNew}
        ignoredEvents={ignoredEvents}
        patients={activePatients}
        currentCalendar={currentCalendar}
        onClose={() => setIsEventModalVisible(false)}
        handleCellChange={handleCellChange}
        status={status}
        toast={toast}
        fetchTreatments={getTreatments}
        formattedDate={formatDateForAPI}
        setStatus={setStatus}
        fetchEventsNew={fetchEventsNew}
        scheduledCalendar={scheduledCalendar}
        arrivedCalendar={arrivedCalendar}
        pickupCalendar={pickupCalendar}
        completedCalendar={completedCalendar}
        allEvents={allEvents}
      />
      <ToastContainer />
      <ScheduleTreatmentModal
        isOpen={isScheduleModalVisible}
        closeModal={() => setIsScheduleModalVisible(false)}
        patientProfiles={[...activePatients, ...(showInactivePatients ? inActivePatients : [])]}
        createWatch={createWatch}
        getCalendarCidByType={getCalendarCIDByType}
        getCalendarIdByType={getCalendarIDByType}
        treatments={treatments}
        setTreatments={setTreatments}
      />
      <Dialog
        open={labsModalOpen}
        onClose={() => setLabsModalOpen(false)}
        aria-labelledby="labs-modal-title"
        maxWidth="lg"
        fullWidth
      >
        <Box
          sx={{
            padding: "24px",
          }}
        >
          {toast && (
            <CustomToast
              message={toastifyToast.message}
              variant={toastifyToast.variant}
            />
          )}
          <div>
            <div className="flex justify-between mb-[16px]">
              {" "}
              <span className="labstitleStyling">
                <IconButton disabled={true}>
                  <ChevronLeft />
                </IconButton>
                Patient Labs
                <IconButton onClick={handleNextLabs}>
                  <ChevronRight />
                </IconButton>
                <MuiTooltip
                  title={
                    <h1 className="text-sm">
                      Copy Labs From Previous Treatment
                    </h1>
                  }
                  placement="right"
                >
                  <IconButton
                    onClick={() => {
                      if (clickedTreatmentData.review) {
                        return message.warning(
                          "This treatment is finalized so can't make any changes in it"
                        );
                      } else {
                        handleCopyLabsFromPreviousTreatment();
                      }
                    }}
                    sx={{ ml: "10px" }}
                  >
                    <ContentCopyRounded sx={{ fontSize: 30 }} />
                  </IconButton>
                </MuiTooltip>
              </span>{" "}
              <IconButton onClick={handleRefreshLabsAndTubes}>
                <RefreshIcon
                  style={{
                    fontSize: 30,
                    animation: isLabsRefreshLoading
                      ? "spin 0.5s linear infinite"
                      : "none",
                  }}
                />
              </IconButton>
            </div>
          </div>
          {labsModalLoader && (
            <div className="loader-backdrop">
              <BounceLoader color="#000" />
            </div>
          )}

          <div>
            <div
              className={`${styles.activeStylingD} mb-[16px] flex items-center justify-between`}
            >
              {currentPatientLabs?.patientName}
              <div>
                <AntdCheckbox
                  name="redrawnLabs"
                  checked={selectRedrawnLabs}
                  onChange={(e) => setSelectRedrawnLabs(e.target.checked)}
                >
                  Select Redrawn Labs
                </AntdCheckbox>
              </div>
            </div>
            <div className="flex">
              <div className="flex">
                {Object.entries(tubesSummary).map(
                  ([tubeType, quantity]) => (
                    <div key={tubeType} className="p-1 rounded">
                      <span
                        className="text-white rounded p-1"
                        style={{
                          backgroundColor: tubeColors[tubeType] || "black",
                        }}
                      >
                        {tubeType} ({quantity})
                      </span>
                    </div>
                  )
                )}
              </div>
            </div>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search labs..."
              value={searchTermLabs}
              onChange={handleSearchChangeLabs}
              sx={{ marginBottom: "16px" }}
            />
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold" }} // optional, for emphasis
            >
              Active Labs: {getCheckedLabsSummary()}
            </Typography>

            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold" }} // optional, for emphasis
            >
              Redrawn: {getCheckedRedrawnLabsSummary()}
            </Typography>

            <div className="outer-labs-container">
              <div className="inner-labs-container py-3" ref={containerRef}>
                <div className="w-1/2 space-y-3">
                  {firstColumnItems.map((lab, index) => {
                     const loader = itemLoader.find(
                      (item) => {
                  
                       if( item.labItemId === lab._id && item.show){
                        return true
                       }
                       return false
                      }
                    );
                    return(
                    lab?.editItemName ? (
                      <ListItem key={lab._id} className="flex items-center gap-1">
                          <input
                            value={lab.test_name}
                            onChange={(e) =>
                              handleInputChangeNew(e, lab?.originalIndex)
                            }
                            placeholder="Custom lab"
                            className="w-full dispensedTextInputHere"
                          />
                          <Checkbox
                            checked={lab.checked}
                            onChange={(e) =>
                              handleCheckboxChangeNew(lab?.originalIndex, e.target.checked)
                            }
                          />
                      </ListItem>)
                    : (
                    <ListItem key={lab._id}>
                      <ListItemText
                        primary={`${lab.test_name} ${lab.item_number ? ` - (${lab.item_number})` : ''}`}
                      />
                      <Checkbox
                        checked={
                          (!selectRedrawnLabs && lab.checked) ||
                          (selectRedrawnLabs && lab.redrawn)
                        }
                        onChange={(e) => {
                          if (clickedTreatmentData.review) {
                            return message.warning(
                              "This treatment is finalized so can't make any changes in it"
                            );
                          } else {
                            handleLabsCheckboxChange(
                              lab._id,
                              e.target.checked
                            );
                          }
                        }}
                      />
                      {lab.custom && (
                        loader
                        ?
                        <Spin indicator={<LoadingOutlined spin />} size="small" />

                        : <IconButton
                          onClick={() => handleDeleteLabItem(lab._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </ListItem>)
                  )})}
                </div>
                <div className="w-1/2 space-y-3">
                  {secondColumnItems.map((lab,index) => {
                     const loader = itemLoader.find(
                      (item) => {
                  
                       if( item.labItemId === lab._id && item.show){
                        return true
                       }
                       return false
                      }
                    );
                    return(
                    lab?.editItemName ? (
                      <ListItem key={lab._id} className="flex items-center gap-1">
                          <input
                            value={lab.test_name}
                            onChange={(e) =>
                              handleInputChangeNew(e, lab?.originalIndex)
                            }
                            placeholder="Custom lab"
                            className="w-full dispensedTextInputHere"
                          />
                        
                          <Checkbox
                            checked={lab.checked}
                            onChange={(e) =>
                              handleCheckboxChangeNew(lab?.originalIndex, e.target.checked)
                            }
                          />
                      </ListItem>)
                      :( 
                      <ListItem key={lab._id}>
                      <ListItemText
                        primary={`${lab.test_name} ${lab.item_number ? ` - (${lab.item_number})` : ''}`}
                      />
                      <Checkbox
                        checked={
                          (!selectRedrawnLabs && lab.checked) ||
                          (selectRedrawnLabs && lab.redrawn)
                        }
                        onChange={(e) => {
                          if (clickedTreatmentData.review) {
                            return message.warning(
                              "This treatment is finalized so can't make any changes in it"
                            );
                          } else {
                            handleLabsCheckboxChange(
                              lab._id,
                              e.target.checked
                            );
                          }
                        }}
                      />
                      {(lab.custom || lab.isNew) && (
                        loader
                        ?
                        <Spin indicator={<LoadingOutlined spin />} size="small" />

                        : <IconButton
                          onClick={() => handleDeleteLabItem(lab._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </ListItem>)
                  )})}
                </div>
              </div>
            </div>
            {/* Seperate View was appearing for custom items now hidden */}
            {/* {customItems.map((item) => (
              <div key={item.id} className="w-[50%]">
                <ListItem>
                  <input
                    className="dispensedTextInputHere"
                    value={item.test_name}
                    onChange={(e) =>
                      handleInputChangeNew(item.id, e.target.value)
                    }
                    placeholder="Custom item"
                  />
                  <Checkbox
                    checked={item.checked}
                    onChange={(e) =>
                      handleCheckboxChangeNew(item.id, e.target.checked)
                    }
                  />
                </ListItem>
              </div>
            ))} */}
            <button
              className="btn-color py-2 mt-5 px-14 text-xs md:text-sm w-full md:w-auto"
              onClick={() => {
                if (clickedTreatmentData.review) {
                  return message.warning(
                    "This treatment is finalized so can't make any changes in it"
                  );
                } else {
                  handleAddEmptyRowNew();
                }
              }}
            >
              Add Custom Item
            </button>
          </div>
        </Box>
      </Dialog>
      <ProcedureModal
        open={procedureModalOpen}
        procedures={currentProcedures}
        onClose={() => setProcedureModalOpen(false)}
        patientId={currentPatientIdForModal}
        patientIdForPreviousTreatment={patientId}
        handleCellChange={handleCellChange}
        handleOpenLabsModal={handleOpenLabsModal}
        handleOpenDispenseModal={handleOpenDispenseModal}
        recordForNotes={recordForNotes}
        selectedDate={formattedDateForAPI}
        clickedTreatmentData={clickedTreatmentData}
      />

      <SupplementsModalPopup
        open={supplementsPopup}
        onClose={() => setSupplementsPopup(false)}
        supplements={currentSupplements}
        activePatients={activePatients}
        patientId={currentPatientIdForModal}
        patientIdForPreviousTreatment={patientId}
        patientData={patientData}
        handleCellChange={handleCellChange}
        handleOpenTreatmentNoteModal={handleOpenTreatmentNoteModal}
        recordForNotes={recordForNotes}
        selectedDate={formattedDateForAPI}
        allSupplements={allSupplements}
        toastifyToast={toastifyToast}
        clickedTreatmentData={clickedTreatmentData}
      />

      <DispenseModal
        open={dispenseModalOpen}
        dispensedItems={currentDispensedItems}
        onClose={() => setDispenseModalOpen(false)}
        patientId={currentPatientIdForModal}
        patientIdForPreviousTreatment={patientId}
        handleCellChange={handleCellChange}
        recordForNotes={recordForNotes}
        handleOpenProcedureModal={handleOpenProcedureModal}
        handleOpenTreatmentNoteModal={handleOpenTreatmentNoteModal}
        selectedDate={formattedDateForAPI}
        clickedTreatmentData={clickedTreatmentData}
      />

      <VitalsModal
        open={vitalsModalOpen}
        VitalsItems={currentVitalsItems}
        onClose={() => setVitalsModalOpen(false)}
        patientId={currentPatientIdForModal}
        patientIdForPreviousTreatment={patientId}
        handleCellChange={handleCellChange}
        recordForNotes={recordForNotes}
        handleOpenTreatmentNoteModal={handleOpenTreatmentNoteModal}
        selectedDate={formattedDateForInput}
      />
      <AntdModal
        visible={treatmentNoteModalOpen} // Changed to use the state variable for treatment notes
        onCancel={() => setTreatmentNoteModalOpen(false)} // Changed to the corresponding close handler function for treatment notes
        footer={null}
        width="80vw" // Set the width to 80% of the viewport width
        styles={{ maxHeight: "70vh", overflowY: "scroll" }}
      >
        <span className="vitalstitleStyling">
          {" "}
          <IconButton onClick={handlePreviousNotes}>
            <ChevronLeft />
          </IconButton>
          Treatment Notes
          <IconButton onClick={handleNextNotes}>
            <ChevronRight />
          </IconButton>
        </span>

        <TreatmentNotes
          treatment={currentTreatment}
          isTreatmentNoteEditing={isTreatmentNoteEditing.current}
          setIsTreatmentNoteEditing={setIsTreatmentNoteEditing}
        />
      </AntdModal>
      <MergeTemplatesModal
        orderId={tableContextMenuCurrentRecord ? tableContextMenuCurrentRecord.key : ""}
        visible={tableContextMenuCurrentRecord && isMergeTemplatesModalVisible}
        onClose={() => setIsMergeTemplatesModalVisible(false)}
      />
      {/* IF key === copy_to_scheduled_patient, only pass the patients that are in the treatments so map the patients Customer ID with treatment.patientId */}
      <SelectPatientModal
        visible={isSelectPatientModalOpen}
        patients={selectPatientAction === 'copy_to_scheduled_patient' ? [...activePatients, ...inActivePatients].filter((patient) => treatments.map((treatment) => treatment.PatientId).includes(patient["Customer ID"])) : [...activePatients, ...(showInactivePatients ? inActivePatients : [])]}
        onClose={() => setIsSelectPatientModalOpen(false)}
        treatment={tableContextMenuCurrentRecord}
        onSelect={selectPatientAction === 'change_patient' ? changeScheduledPatient : selectPatientAction === 'copy_to_add_patient' ? copyTreatmentToNewPatient : selectPatientAction === 'copy_to_scheduled_patient' ? copyTreatmentToScheduledPatient : null}

      />
      <SelectDateModal
        visible={selectDataAction && isSelectDateModalOpen}
        onClose={() => setIsSelectDateModalOpen(false)}
        treatment={tableContextMenuCurrentRecord}
        onSelect={selectDataAction === 'date' ? handleBulkUpdateDate : selectDataAction === 'duplicate' ? handleBulkDuplicate : null}
      />

    </>

  )}
  else{
    return(<IPADTodayView/>)
  }
};


const MessageIcon = ({ patient }) => {
  const [modalState, setModalState] = useState(false);

  const openModal = () => {
    setModalState(true);
  };

  const closeModal = () => {
    setModalState(false);
  };

  const unreadCount = patient?.messages?.filter((msg) => !msg.readStatus).length || 0;

  return (
    <>
      <Badge count={unreadCount} overflowCount={99} offset={[-5, 5]}>
        <img
          src={smsIcon}
          alt="sms"
          style={{
            width: "36px",
            height: "36px",
            background: "none",
            cursor: "pointer",
          }}
          onClick={openModal}
        />
      </Badge>
      {modalState && <Messages patient={patient} onClose={closeModal} />}
    </>
  );
};

const RenderMessages = ({ patientProfile }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      {patientProfile !== undefined ? (
        <MessageIcon patient={patientProfile} />
      ) : (
        <CircularProgress size={"15px"} />
      )}
    </div>
  );
};




export default TodayView;
